<template>
  <f7-sheet
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.08);
      --f7-sheet-bg-color: #fff;
    "
    :class="modalClass + ' ' + modalClassId"
  >
    <f7-page-content>
      <div class="hangger"></div>
      <span class="modal-title-custom">
        POSB My Preferred Payment Plan –
        {{ paymentMethod.instalmentNumber }} months
      </span>
      <div class="payment-method">
        <img :src="paymentMethod.logo" alt="" width="32" height="21.5" />
        <span>{{ paymentMethod.label }}</span>
      </div>
      <f7-button
        @click="modalBtnClick"
        style="margin-bottom: 30px"
        class="btn-red-text btn-red"
        >Make Payment</f7-button
      >
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";
import option3 from "@/assets/payment-option-3.svg";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    modalClassId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalClass: "",
      paymentMethod: {
        logo: option3,
        label: "POSB Everyday Card *4309 ",
        instalmentNumber: 6,
      },
    };
  },
  mounted() {},
  methods: {
    modalBtnClick() {
      this.$emit("modalBtnClick");
    },
  },
};
</script>

<style scoped>
.modal-title-custom {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #07182b;
  padding: 0px 48px;
  display: flex;
  margin-top: 20px;
}

.payment-method {
  display: flex;
  padding: 0px 21px;
  margin-top: 25px;
  margin-bottom: 31px;
}

.payment-method img {
  margin-right: 15px;
}

.payment-method span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #182632;
}
</style>

<style>
.hangger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5px;
}

.hangger::before {
  margin-top: 8px;
  content: "";
  display: flex;
  background: #c4c4c4;
  width: 29px;
  height: 3px;
}
</style>
