/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:091f5cfd-e30f-4dbf-ba7e-554f7a947650",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_MTTVwywS1",
    "aws_user_pools_web_client_id": "1hg3a5t0jh9sj7glm3k6bd1brh",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fail51i6p5.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "policies",
            "endpoint": "https://42h6xm3mm1.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "insureds",
            "endpoint": "https://hcf5plvob9.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "products",
            "endpoint": "https://msnuj7bpv7.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "questionnaires",
            "endpoint": "https://nezrshnhc3.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "coverageAnalysis",
            "endpoint": "https://z3q42m9ynb.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        },
        {
            "name": "sharingGroups",
            "endpoint": "https://ifi3sgzyja.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "dbswallet-document-uploads134218-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "policies-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "products-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "labelsExtractionMapping-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "insureds-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "insurers-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "productTypes-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "questionnaires-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "coverages-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "coverageAnalysis-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "groups-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "userGroups-dev",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "policyGroups-dev",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
