<template>
  <f7-swiper
    :spaceBetween="20"
    slidesPerView="auto"
    :centeredSlides="true"
    :loop="true"
    @realIndexChange="updatedBannerIndex"
    v-if="coverages && coverages.length > 0"
    @swiper="onSwiper"
  >
    <f7-swiper-slide v-for="coverage in coverages" :key="coverage.code">
      <div class="analysis-banner" :class="coverage.statusLabel">
        <div class="banner-title-wrapper">
          <span class="banner-title"> {{ coverage.title }}</span>
        </div>

        <img class="banner-logo" :src="coverage.logo" :alt="coverage.name" />
        <span class="banner-policy">{{ coverage.name }}</span>
        <div class="banner-amount">
          <span class="covered">{{ 
            $filters.numberShorten(
              coverage.detail
                ? (coverage.detail.current == -1 ? "Unlim" :  parseFloat(coverage.detail.current ?? 0).toFixed(coverage.decimals ?? 0))
                : 0.0,
              (coverage.decimals ?? 0)
            )
          }}</span
          ><span class="separator">/</span
          ><span class="limit">{{
            $filters.numberShorten(
              coverage.detail
                ? (coverage.detail.current == -1 ? "Unlim" : parseFloat(coverage.detail.target ?? 0).toFixed(coverage.decimals ?? 0))
                : 0.0,
              (coverage.decimals ?? 0)
            )
          }}</span>
        </div>
        <f7-range
          :min="0"
          :max="100"
          :step="1"
          :value="coverage.detail ? coverage.detail.score ?? 0 : 0"
          :class="coverage.statusLabel"
          class="analysis-banner-slider disabled"
        />
        <span class="banner-desciption">{{ coverage.description }}</span>
        <f7-link
          @click="showMoreDetails(coverage.code)"
          class="banner-button"
          :class="{
            active: coverage.code == 'RETIREMENT' && isOwnerSelected,
            disabled: coverage.code != 'RETIREMENT' && isOwnerSelected,
          }"
          >MORE DETAILS</f7-link
        >
      </div>
    </f7-swiper-slide>
  </f7-swiper>
</template>

<script>
import {
  f7Block,
  f7Link,
  f7Range,
  f7Swiper,
  f7SwiperSlide,
} from "framework7-vue";

export default {
  components: [f7Block, f7Link, f7Range, f7Swiper, f7SwiperSlide],
  props: {
    coverages: {
      type: Array,
      default: [],
    },
    selectedCoverageId: null,
    isOwnerSelected: false,
  },
  data() {
    return {
      count: 0,
      coveredAmount: 0,
      selectedIndex: 0,
      swiper: null,
    };
  },
  mounted() {},
  updated() {
    this.coverages.forEach((c, i) => {
      switch (c.statusLabel) {
        case "alert":
          if (c.detail) {
            if (c.detail.current > 0) c.title = "Low coverage";
            else c.title = "No coverage";
          } else c.title = "No coverage";
          break;
        case "warn":
          c.title = "Room for improvement";
          break;
        case "pass":
          c.title = "Good coverage";
          break;
        default:
          c.title = "No coverage";
          break;
      }

      if (c.code == this.selectedCoverageId) this.selectedIndex = i;
    });
    // swiperCards
    if (this.swiper) {
      this.swiper.slideTo(this.selectedIndex ?? 0);
    }
  },
  computed: {},
  methods: {
    showOthersCoverage() {
      f7.sheet.open(".policy-details");
    },
    updatedBannerIndex(e) {
      this.$emit("updateCoverageSelected", this.coverages[e.realIndex]);
    },
    showMoreDetails(coverageId) {
      this.$emit("showMoreDetails", coverageId);
    },
    getSliderClass(count) {
      if (count < 60) {
        return "alert";
      } else if (count < 90) {
        return "warn";
      } else {
        return "pass";
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>

<style>
:root {
  --f7-range-bar-size: 4px;
}
.swiper-container {
  padding-top: 27px;
  padding-bottom: 15px;
}
.swiper-slide {
  background: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 200px;
  width: 80% !important;
}
</style>