<template>
  <f7-sheet
    class="coverage-details-modal"
    style="
      height: 90%;
      border-radius: 25px 25px 0px 0px;
      --f7-sheet-bg-color: #fff;
    "
    swipe-to-close
    backdrop
  >
    <f7-page-content>
      <div class="modal-header">
        <f7-button class="modal-close" sheet-close icon-ios="f7:xmark">
        </f7-button>
        <span class="modal-title">{{ name }}</span>

        <f7-button
          sheet-close
          href="/landing-need-boy"
          class="modal-settings"
          icon-ios="f7:slider_horizontal_3"
        ></f7-button>
      </div>
      <div class="modal-body">
        <div class="coverage-status">
          <div>
            <span class="title">{{ Analysis.health }}</span>
            <div class="coverage-amount">
              <span class="covered">{{
                $filters.numberShorten(parseFloat(Analysis.current ?? 0).toFixed(2), 1)
              }}</span>
              /
              <span class="limit" :class="getSliderClass">{{
                $filters.numberShorten(parseFloat(Analysis.target ?? 0).toFixed(2), 1)
              }}</span>
            </div>
          </div>
          <div>
            <f7-range
              :min="0"
              :max="100"
              :step="1"
              :value="Analysis.score"
              :class="getSliderClass"
              class="analysis-banner-slider disabled"
            />
          </div>
          <div>
            <span class="legend"
              >Great job! You are on your way to a comfortable retirement.</span
            >
          </div>
        </div>

        <div class="retirement-goal">
          <div class="retirement-goal-chart">
            <span class="legend">Inflation</span>
            <div class="chart">
              <div class="savings" :style="getSavings">
                <span>Your Savings</span>
              </div>
            </div>
          </div>

          <div class="retirment-details">
            <div>
              <div class="title">Your Retirement Goal</div>
              <div class="row">
                <span class="row-title">Years to Retirement</span>
                <span class="row-value">{{
                  Analysis.yearsToRetirement ?? 0
                }}</span>
              </div>
              <div class="row">
                <span class="row-title"
                  >Desired Retirement Monthly Savings</span
                >
                <span class="row-value">{{
                  $filters.currency(
                    Analysis.desiredRetirementMonthlySaving ?? 0, 2
                  )
                }}</span>
              </div>
            </div>
            <div class="separator"></div>
            <div>
              <div class="title">Your Savings</div>
              <div class="row">
                <span class="row-title">Current Monthly Savings</span>
                <span class="row-value">{{
                  $filters.currency(Analysis.currentMonthlySaving ?? 0, 2)
                }}</span>
              </div>
              <div class="row">
                <span class="row-title"
                  >Required Retirement Monthly Savings (Inflation
                  adjusted)</span
                >
                <span class="row-value">{{
                  $filters.currency(
                    Analysis.requiredRetirementMonthlySaving ?? 0, 2
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dbs-card saving-alert" v-if="(Analysis.requiredRetirementMonthlySaving -
                Analysis.currentMonthlySaving > 0)">
          <span class="legend">Monthy Savings Deficit</span>
          <span class="value">{{
            $filters.currency(
              Analysis.requiredRetirementMonthlySaving -
                Analysis.currentMonthlySaving ?? 0
            )
          }}</span>
        </div>

        <div>
          <f7-button sheet-close class="btn-red-text btn-red">OK</f7-button>
        </div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    Analysis: {},
  },
  data() {
    return {
      count: 0,
      coveredAmount: 0,
      limit: 0,
      name: "Retirement Planning",
    };
  },
  computed: {
    getSliderClass() {
      console.log(this.Analysis);
      if (this.Analysis.score < 60) {
        return "alert";
      } else if (this.Analysis.score < 90) {
        return "warn";
      } else {
        return "pass";
      }
    },
    getSavings() {
      return "height: " + this.Analysis.score + "%";
    },
  },
};
</script>

<style scoped>
.page-content {
  overflow-y: auto;
}
.coverage-details-modal {
  padding: 18px 19px 30px 21px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}

.modal-title {
  width: 198px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
}

.coverage-status {
  background: #f9fcfe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 21px 19px 19px 20px;
  margin-bottom: 32px;
}
.coverage-status > div {
  display: flex;
  justify-content: space-between;
}

.coverage-status .title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #07182b;
  margin-bottom: 12px;
}
.coverage-status .coverage-amount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: rgba(192, 203, 210, 0.5);
}

.coverage-status .coverage-amount .covered {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #07182b;
  opacity: 0.5;
}

.coverage-status .coverage-amount .limit {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #11bba2;
}

.coverage-status .coverage-amount .limit.warn {
  color: #FDA937;
}

.coverage-status .coverage-amount .limit.alert {
  color: #FF3333;
}

.coverage-status .legend {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #07182b;
  opacity: 0.5;
}
.retirement-goal {
  display: flex;
  margin-bottom: 21px;
}

.retirement-goal-chart {
  display: flex;
  align-items: flex-start;
  margin-right: 25px;
}

.retirement-goal-chart .legend {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  /* display: inline-block; */
  overflow: visible;
  margin-top: 5px;
  margin-right: 3px;
}

.retirement-goal-chart .chart {
  width: 44px;
  height: 325px;
  background: linear-gradient(
      0deg,
      #ffe5eb 61.38%,
      rgba(255, 251, 252, 0.44) 100%
    ),
    #ff3333;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.retirement-goal-chart .chart::before {
  height: 24.6%;
  width: 100%;
  background: rgba(255, 51, 51, 0.27);
  border-radius: 50px 50px 0px 0px;
  content: "";
  position: absolute;
  top: 0;
}

.retirement-goal-chart .chart .savings {
  width: 100%;
  height: 53.5%;
  background: #ffffff;
  /* border: 0.5px solid rgba(0, 0, 0, 0.04); */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
    0px 3px 1px rgba(0, 0, 0, 0.05);
  border-radius: 36px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
}

.retirement-goal-chart .chart .savings span {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  display: inline-block;
  overflow: visible;
}

.retirment-details {
  padding-right: 5px;
}
.retirment-details,
.retirment-details > div {
  display: flex;
  flex-direction: column;
}
.retirment-details .title {
  margin-bottom: 14px;
}

.retirment-details .title,
.retirment-details .row-value {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #07182b;
}

.retirment-details .row-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #07182b;
  opacity: 0.5;
  max-width: 60%;
}

.retirment-details .row {
  margin-bottom: 10px;
  align-items: center;
}

.retirment-details .separator {
  height: 1px;
  background: #e1e7eb;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 21px;
}

.saving-alert {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 21px 23px 21px 27px;
  margin-bottom: 30px;
}

.saving-alert .legend {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #07182b;
}

.saving-alert .value {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-align: right;
  color: #ff3333;
}
</style>

<style>
.coverage-details-modal .analysis-banner-slider .range-knob {
  background-color: #f9fcfe;
}
.coverage-details-modal .modal-header .icon.f7-icons {
  color: #9eaab1;
}

.coverage-details-modal .modal-header .modal-close .f7-icons {
  font-size: 18px;
  font-weight: bold;
}

.coverage-details-modal .modal-header .modal-settings .f7-icons {
  font-size: 22px;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.04em;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>