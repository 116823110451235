<template>
  <f7-page
    @page:beforeremove="onPageBeforeRemove"
    @page:beforeout="onPageBeforeOut"
  >
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-block class="policy-summary-modal">
      <img src="@/assets/thinking-girl.svg" alt="" />
    </f7-block>

    <f7-block class="need-analysis-title">
      {{ addPolicyTitle }}
    </f7-block>

    <f7-block class="need-analysis-landing-text">
      {{ needInsuranceP1 }}
    </f7-block>

    <f7-block class="need-analysis-landing-text">
      {{ needInsuranceP2 }}
    </f7-block>

    <f7-block class="need-analysis-landing-text">
      {{ needInsuranceP3 }}
    </f7-block>

    <f7-button
      style="margin-bottom: 25px"
      href="/saving-habits"
      class="need-analysis-btn-red"
    >
      <div>Get My Analysis</div>
    </f7-button>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Button,
  //  f7BlockTitle,
  f7Block,
  f7NavRight,
  f7Sheet,
  f7,

  //  f7Card,
} from "framework7-vue";
import $ from "dom7";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Button,
    f7Sheet,
    //    f7BlockTitle,
    f7Block,

    //    f7Card,
  },
  data() {
    return {
      screenStep: "step 1",
      sheetOpened: false,
      addPolicyTitle: "How much insurance do I need?",
      needInsuranceP1:
        "Your insurance needs change at different stages of your life.",
      needInsuranceP2:
        "Answer a few questions about your current lifestyle and our recommendation engine will help you figure out your coverage gaps and needs compared to your peers!",
      needInsuranceP3:
        "Ready? There's 4 parts to the form and it will take 5 minutes but we'll try to prepopulate what we can based on your records with us.",
      uploadedPhoto: null,
    };
  },
  methods: {
    onPageBeforeOut() {
      // Close opened sheets on page out
      f7.sheet.close();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy sheet modal when page removed
      if (self.sheet) self.sheet.destroy();
    },
  },
};
</script>

<style scoped>
.add-policy-nav-bar-shadow::before,
.add-policy-nav-bar-shadow::after {
  display: none;
}

.add-policy-navbar-btn-text {
  letter-spacing: 0.04em;
  text-transform: none !important;
  color: #9eaab1;
  padding-left: 5px;
}
.add-policy-navbar-btn {
  background: #ffffff !important;
  border-radius: 64px !important;
  box-shadow: none !important;
  border: 1px solid #e1e7eb !important;
  box-sizing: border-box !important;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -18px;
  margin-bottom: 0px;
}

.policy-summary-modal {
  /* TEXT */
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}

.policy-summary-modal-text {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  opacity: 0.5;

  /* Maybe this is not requied */
  justify-content: center;
  align-items: center;
  margin: auto;
}

.policy-summary-modal-tip {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #929ba2;
}

.policy-summary-modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #1a2531;
  padding-bottom: 10px;
}
.policy-summary-modal-sentence {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07182b;
  margin-left: 15px;
  opacity: 0.5;
  padding-bottom: 5px;
}

.policy-modal-dotted {
  color: #e1e7eb;
  border-radius: 15px;
  border-style: dashed;
}

.add-policy-btn-white-2 {
  justify-content: center;
  align-items: center;
  margin: auto;

  width: 298px;
  height: 43px;
  /* #FF3333 */
  background: #ff3333;
  border-radius: 34px;
  box-shadow: none;
}

.add-policy-btn-white-text-2 {
  text-transform: none;

  font-size: 15px;

  line-height: 23px;

  text-align: center;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin: 0px 10px;
}
</style>