<template>
  <f7-app v-bind="f7params">
    <!--  -->
    <f7-view
      v-if="authState === 'signedin' && user"
      main
      class="safe-areas"
      url="/"
    ></f7-view>
    <f7-view v-else class="view-main safe-areas SignUp-page" url="/SignUp"></f7-view>
    <!-- <amplify-authenticator >



    </amplify-authenticator> -->
    <!-- <SignInProcess  /> -->
    <!-- Your main view, should have "view-main" class -->
  </f7-app>
</template>
<script>
import { ref, onMounted } from "vue";
import { f7, f7ready } from "framework7-vue";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

import SignInProcess from "@/components/sign-up/phone-input.vue";

import routes from "../js/routes.js";
import store from "../js/store";

import coverageLogo1 from "@/assets/Death_2.svg";
import coverageLogo2 from "@/assets/Hospitalization_2.svg";
import coverageLogo3 from "@/assets/Critical_Illness.svg";
import coverageLogo4 from "@/assets/Personal_accident.svg";
import coverageLogo5 from "@/assets/Retirement.svg";

export default {
  components: {
    SignInProcess,
  },
  Auth,

  setup() {
    // Framework7 Parameters
    const f7params = {
      name: "dbs", // App name
      theme: "ios", // Automatic theme detection
      // App store
      store: store,
      // App routes
      routes: routes,
    };
    onAuthUIStateChange((nextAuthState, authData) => {
      console.log("onAuthUIStateChange 2");
      if (nextAuthState === AuthState.SignedIn) {
        console.log("user successfully signed in!");
      }
    });
    onMounted(() => {
      f7ready(() => {
        // Call F7 APIs here
      });
    });

    return {
      f7params,
      username: "test",
      password: "test",
    };
  },

  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      console.log("onAuthUIStateChange -> ", authState, authData);
      this.authState = authState;
      this.user = authData;
    });
    // console.log("authState -> ", this.authState);
    // console.log("user -> ", this.user);
    localStorage.selectedInsuredId = null;
    localStorage.ownerInsuredId = null;
    localStorage.showPolicyTab = false;
    localStorage.phoneNumberPrefix = "+65";
    localStorage.coverages = JSON.stringify([
      {
        attributeName: "hospitalizationCoverage",
        name: "Hospitalisation",
        code: "HC",
        logo: coverageLogo2,
        description:
          "Covers your hospitalisation bills if you get injured, sick or disabled.",
        sectionId: "CF",
        decimals: 0,
      },
      {
        attributeName: "coverageTPD",
        name: "TPD/Death",
        code: "TPD",
        logo: coverageLogo1,
        description:
          "Offers a lump sum payout upon your death to help provide financially for your dependents.",
        sectionId: "CF",
        decimals: 0,
      },
      // {
      //   attributeName: "coverageDeath",
      //   name: "Death",
      //   code: "DEATH",
      //   logo: coverageLogo1,
      //   description:
      //     "Offers a lump sum payout upon your death to help provide financially for your dependents.",
      //   sectionId: "CF",
      // },
      {
        attributeName: "coverageCI",
        name: "Critical Illness ",
        code: "CI",
        logo: coverageLogo3,
        description:
          "Offers a lump sum payout upon diagnosis that helps cover loss of income while you recover.",
        sectionId: "CF",
        decimals: 0,
      },
      {
        attributeName: "personalCoverage",
        name: "Personal Accident",
        code: "PC",
        logo: coverageLogo4,
        description:
          "Offers compensation for injuries, disability and death arising from accidents, and HFMD for kids.",
        sectionId: "NEXT",
        decimals: 0,
      },
      {
        attributeName: "coverageRetirement",
        name: "Retirement Planning",
        code: "RETIREMENT",
        logo: coverageLogo5,
        description:
          "Plan for the future and achieve financial freedom at your goal retirement age.",
        sectionId: "NEXT",
        decimals: 1,
      },
    ]);
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  mounted() {
    // console.log("unsubscribeAuth -> ", this.unsubscribeAuth);
    if (this.authState === undefined) {
      Auth.currentAuthenticatedUser().then((authData) => {
        this.authState = AuthState.SignedIn;
        this.user = authData;
      });
    }

    // const current = Auth.currentAuthenticatedUser();
    // console.log("currentAuthenticatedUser -> ", current);
  },
  methods: {
    testState() {
      console.log("testState");
    },
  },
};
</script>

<style>
/* Custom color theme */
:root {
  --f7-page-bg-color: #f4f8fb;
  --f7-bars-link-color: #98a0a7;
  --f7-tabbar-link-active-color: #07182b;
  --f7-toolbar-font-size: 14px;
  --f7-navbar-title-font-size: 17px;
  --f7-navbar-title-font-weight: bold;
  --f7-navbar-text-color: #07182b;
  --f7-navbar-height: 54px;
  --f7-navbar-bg-color: #ffffff;
  --f7-toolbar-bg-color: #ffffff;
  --f7-block-margin-vertical: 27px;
  --f7-button-height: 31px;
  --f7-toggle-inactive-color: #e5e5ea;
}

* {
  font-family: "Open Sans", sans-serif;
}

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000000;
  margin-top: 0;
}
.SignUp-page {
  padding-top: 54px;
}
</style>
