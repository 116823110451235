<template>
  <f7-page hide-toolbar-on-scroll>
    <f7-navbar class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-toolbar
      @click="goPrevStep()"
      class="toolbar-aligment"
      :position="'top'"
    >
      <f7-link
        class="link back"
        href="#"
        style="padding-left: 5%; position: absolute; left: 15px"
      >
        <img src="@/assets/customIcons/custom-up-btn.svg" alt="" />
      </f7-link>

      <div class="">
        {{ previusStep }}
      </div>
    </f7-toolbar>
    <div :class="{ 'skeleton-effect-wave': loading }">
      <f7-block class="add-policy-step">
        {{ screenStep }}
      </f7-block>

      <f7-block class="add-policy-title"> {{ screenTitle }} </f7-block>
       
      <f7-block class="need-analysis-subtitle">
        {{ screenSubTitle }}
        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 19px;
            height: 19px;
            border-radius: 50%;
            margin-left: 7px;
          "
        ></div>
        <span
          v-else
          :class="[
            dependentCount > 0 ? 'dependent-couter' : 'dependent-couter-zero',
          ]"
          >{{ dependentCount }}</span
        >
      </f7-block>

      <f7-block class="need-analysis-description">
        {{ screenDescription }}
      </f7-block>

      <div v-if="loading" style="margin-top: 10px">
        <f7-row no-gap class="dependent-row" v-for="index in 5" :key="index">
          <f7-col class="dependent-image" align="center" width="20">
            <div
              class="skeleton-block"
              style="
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-bottom: 7px;
              "
            ></div>
          </f7-col>
          <f7-col class="dependent-name" width="50">
            <div class="skeleton-text" style="border-radius: 4px">
              Profle name
            </div>
          </f7-col>

          <f7-col class="dependent-name" width="20">
            <div class="skeleton-text" style="border-radius: 4px">50,000</div>
          </f7-col>
        </f7-row>
      </div>

      <div
        v-if="dependentCount == 0 && !loading"
        style="margin-top: 20px; margin-bottom: 20px"
      ></div>

      <f7-block-title
        class="loved-ones-block-title"
        v-if="dependentCount > 0 && !loading"
        >ANNUAL EXPENSES</f7-block-title
      >
      <div v-if="dependentCount > 0 && !loading" class="dependent-list">
        <f7-row
          v-for="insured in myDependent"
          @click="showDependantDetails(insured.relationship)"
          :key="insured.id"
          no-gap
          class="dependent-row"
        >
          <f7-col class="dependent-image" align="center" width="20">
            <img :src="insured.avatar"
          /></f7-col>
          <f7-col class="dependent-name" width="50">
            {{ insured.firstName + " " + insured.lastName }}
          </f7-col>
          <f7-col
            align="right"
            style="opacity: 0.5; font-size: 13px; padding-top: 5%"
            width="10"
          >
            S$
          </f7-col>
          <f7-col class="dependent-amount" width="20">
            {{ insured.annualExpense ?? 0.0 }}
          </f7-col>
          <div
            style="
              border-bottom-width: 1px;
              border-bottom-style: solid;
              border-bottom-color: rgba(0, 0, 0, 0.2);
              width: 100%;
              heigh: 1px;
            "
          ></div>
        </f7-row>
      </div>
      <f7-button
        v-if="dependentCount > 0 && !loading"
        class="add-dependent-regular"
        sheet-open=".select-dependent"
      >
        <img src="@/assets/customIcons/custom-plus-regular.svg" alt="" />
        <div style="padding-left: 20px; opacity: 0.5">Add</div>
      </f7-button>

      <f7-button
        v-else-if="!loading"
        class="add-dependent-red"
        sheet-open=".select-dependent"
      >
        <img src="@/assets/customIcons/custom-plus-red.svg" alt="" />
        <div style="padding-left: 20px">Add</div>
      </f7-button>

      <f7-block style="" align="center">
        <f7-button
          v-if="dependentCount > 0 && !loading"
          @click="showHealthStatus"
          class="need-analysis-btn-gray"
        >
          <div class="analysis-gray-btn-text">Next</div>
        </f7-button>

        <f7-button
          v-else
          @click="showHealthStatus"
          class="need-analysis-btn-gray"
        >
          <div class="analysis-gray-btn-text">I have no dependents</div>
        </f7-button>
      </f7-block>
    </div>

    <!-- select-dependent -->
    <f7-sheet
      class="select-dependent"
      style="
        height: auto;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon class="close-modal" f7="xmark"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title">
              Select dependent
            </f7-col>
          </f7-row>
        </f7-block>

        <div class="">
          <f7-list
            style="
              --f7-list-item-border-color: transparent;
              --f7-list-border-color: transparent;
            "
          >
            <f7-list-item
              :key="pUser.id"
              v-for="pUser in listOfDependent"
              class="simple-list-dependent"
              @click="selectedUser(pUser.id, pUser.user)"
              :v-model="pUser.id"
              style="
                --f7-list-item-border-color: transparent;
                --f7-list-border-color: transparent;
              "
            >
              {{ pUser.user }}
              <div :key="pUser.id" :v-model="pUser.id" v-if="pUser.isActive">
                <img src="@/assets/customIcons/custom-red-check.svg" alt="" />
              </div>
            </f7-list-item>
          </f7-list>

          <f7-block align="center" class="policy-information-modal">
            <f7-button
              @click="showDependantDetails(null)"
              class="need-analysis-btn-gray"
            >
              <div class="add-policy-btn-red-text">Next</div>
            </f7-button>
          </f7-block>
        </div>
      </f7-page-content>
    </f7-sheet>

    <!-- dependent-details -->
    <f7-sheet
      class="dependent-details"
      style="
        height: 90%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      backdrop
    >
      <f7-page-content>
        <f7-block class="modal-header">
          <f7-button sheet-close>
            <f7-icon class="close-modal" f7="xmark"></f7-icon>
          </f7-button>
          <span>Dependent details</span>
          <div>
            <f7-button v-if="existingProfile" @click="removeDependent">
              remove
            </f7-button>
          </div>
        </f7-block>

        <div class="scrollable-body-modal">
          <f7-row no-gap class="dependent-row-modal">
            <img :src="insuredAvatar" />
            <span class="dependent-name">
              {{ dependentRelationship }}
            </span>
          </f7-row>
          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="{
              'invalid-Box-Input': invalidDependentFirstName,
              'valid-Box-Input': !invalidDependentFirstName,
              disabled: existingProfile,
            }"
          >
            <f7-list-item @click="focusText('first-name')" class="">
              <f7-row class="need-analysis-saving-habit-insets-modal">
                <span> First Name </span>
              </f7-row>

              <div>
                <f7-input
                  @keyup="inputvalidator('first-name')"
                  @change="inputvalidator('first-name')"
                  v-bind:value="dependentFirstName"
                  v-on:input="dependentFirstName = $event.target.value"
                  class="d-first-name"
                  ref="d-first-name"
                  type="text"
                  placeholder="e.g. Derrick"
                  clear-button
                  error-message="Only letters please!"
                  required
                  validate
                  pattern="^([a-zA-Z]+[\s]?)*$"
                ></f7-input>
              </div>
            </f7-list-item>
          </f7-list>

          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="{
              'invalid-Box-Input': invalidDependentLastName,
              'valid-Box-Input': !invalidDependentLastName,
              disabled: existingProfile,
            }"
          >
            <f7-list-item @click="focusText('last-name')" class="">
              <f7-row class="need-analysis-saving-habit-insets-modal">
                <span> Last Name </span>
              </f7-row>

              <div>
                <f7-input
                  @keyup="inputvalidator('last-name')"
                  @change="inputvalidator('last-name')"
                  v-bind:value="dependentLastName"
                  v-on:input="dependentLastName = $event.target.value"
                  class="d-last-name"
                  ref="d-last-name"
                  type="text"
                  placeholder="e.g. Lee"
                  clear-button
                  error-message="Only letters please!"
                  required
                  validate
                  pattern="[a-zA-Z]*"
                ></f7-input>
              </div>
            </f7-list-item>
          </f7-list>

          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="
              invalidDependentBDay ? 'invalid-Box-Input' : 'valid-Box-Input'
            "
          >
            <f7-list-item @click="focusText('d-bday')" class="">
              <f7-row class="need-analysis-saving-habit-insets-modal">
                <span> Birth Year </span>
              </f7-row>

              <div>
                <f7-input
                  @keyup="inputvalidator('d-bday')"
                  @change="inputvalidator('d-bday')"
                  v-bind:value="dependentBDay"
                  v-on:input="dependentBDay = $event.target.value"
                  class="d-bday"
                  ref="d-bday"
                  type="text"
                  placeholder="1990"
                  clear-button
                  error-message="Please enter a valid year!"
                  required
                  validate
                  pattern="[0-9]*"
                ></f7-input>
              </div>
            </f7-list-item>
          </f7-list>

          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="{
              disabled: isGenderFixed,
            }"
          >
            <f7-list-item @click="focusText('gender-selector')" class="">
              <f7-row class="need-analysis-saving-habit-insets">
                <span> Gender </span>
              </f7-row>

              <f7-row
                class="btns-block"
                style="padding-bottom: 10px; padding-top: 10px"
              >
                <f7-button
                  id="maleBtn"
                  @click="toggleGender($event)"
                  :class="[
                    isMale
                      ? 'need-analysis-retirement-fill-btn'
                      : 'need-analysis-retirement-no-fill-btn',
                  ]"
                >
                  MALE</f7-button
                >
                <f7-button
                  id="femaleBtn"
                  style="margin-left: 5px; margin-right: 5px"
                  @click="toggleGender($event)"
                  :class="[
                    !isMale
                      ? 'need-analysis-retirement-fill-btn'
                      : 'need-analysis-retirement-no-fill-btn',
                  ]"
                >
                  FEMALE</f7-button
                >
              </f7-row>
            </f7-list-item>
          </f7-list>

          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="
              invalidMonthIncomeInput
                ? 'invalid-Box-Input input-invalid'
                : 'valid-Box-Input'
            "
          >
            <f7-list-item class="">
              <f7-row class="need-analysis-saving-habit-insets-modal">
                <span style="display: flex">
                  Annual Income
                  <img
                    @click="aIncome()"
                    class="tool-tip-info"
                    src="@/assets/customIcons/custom-info-circle.svg"
                    alt=""
                  />
                </span>
              </f7-row>

              <f7-row no-gap>
                <input
                  v-model="monthIncomeInput"
                  v-inputmask
                  :data-inputmask="maskOpt"
                  @keyup="inputvalidator('month-income')"
                  @change="inputvalidator('month-income')"
                  class="saving-input"
                  placeholder="S$ 1,000"
                />
                <span class="input-error-message"
                  >Please, verify the input value!</span
                >
              </f7-row>
            </f7-list-item>
          </f7-list>

          <f7-list
            media-list
            inset
            class="saving-habits-tab"
            style="border: 1px solid #e1e7eb"
            :class="
              invalidAnnualExpenses
                ? 'invalid-Box-Input input-invalid'
                : 'valid-Box-Input'
            "
          >
            <f7-list-item class="">
              <f7-row class="need-analysis-saving-habit-insets-modal">
                <span style="display: flex">
                  Annual Expenses
                  <img
                    @click="aExpenses"
                    class="tool-tip-info"
                    src="@/assets/customIcons/custom-info-circle.svg"
                    alt=""
                  />
                </span>
              </f7-row>

              <f7-row no-gap>
                <input
                  v-model="annualExpenses"
                  v-inputmask
                  :data-inputmask="maskOpt"
                  @keyup="inputvalidator('annual-expenses')"
                  @change="inputvalidator('annual-expenses')"
                  class="saving-input"
                  placeholder="S$ 1,000"
                />
                <span class="input-error-message"
                  >Please, verify the input value!</span
                >
              </f7-row>
            </f7-list-item>
          </f7-list>
          <f7-block align="center" class="policy-information-modal">
            <f7-button
              @click="saveDependent"
              :disabled="!validForm"
              :class="[
                validForm ? 'need-analysis-btn-red' : 'need-analysis-btn-red',
              ]"
            >
              <div class="add-policy-btn-red-text">
                {{ existingProfile ? "Update" : "Add" }}
              </div></f7-button
            >
          </f7-block>
        </div>
      </f7-page-content>
    </f7-sheet>

    <AnnualIncomeToolTip />
    <AnnualExpensesToolTip />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
} from "framework7-vue";

import { API } from "aws-amplify";
import AnnualExpensesToolTip from "@/components/modals/annual-expenses-tool-tip.vue";
import AnnualIncomeToolTip from "@/components/modals/annual-income-tooltip.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    AnnualIncomeToolTip,
    AnnualExpensesToolTip,
  },
  props: {
    questionnerParam: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      insureds: [],
      previusStep: "My Retirement Needs",
      screenStep: "STEP 3/4",
      screenTitle: "My Loved Ones",
      screenSubTitle: "Your Dependents",
      screenDescription:
        "Dependents are people you are supporting financially. How much would they need, without you around?",
      screenPolicy1:
        "After examining your current expenses, you might decide that you might need $1,500 per month during retirement. This figure would be adjusted for inflation, using the inflation rate predetermined based on the lifestyle that you select, so that it maintains its purchasing power over the years.",
      dependentCount: 0,
      listOfDependent: [
        { id: "spouse", user: "My spouse", isActive: false },
        { id: "mother", user: "My mother", isActive: false },
        { id: "father", user: "My father", isActive: false },
        { id: "child-1", user: "First child", isActive: false },
        { id: "child-2", user: "Second child", isActive: false },
        { id: "child-3", user: "Third child", isActive: false },
        { id: "child-4", user: "Fourth child", isActive: false },
      ],
      isMale: true,
      activeListOfDependent: true,

      // Input Fields
      selectedDependent2: "-",
      dependentRelationship: "",
      selectedDependentId: "",
      dependentFirstName: "",
      dependentLastName: "",
      dependentBDay: "",
      monthIncomeInput: "",
      annualExpenses: "",

      myDependent: [],
      questionner: {},
      // variables to Add color to the boxes
      invalidDependentFirstName: false,
      invalidDependentLastName: false,
      invalidDependentBDay: false,
      invalidMonthIncomeInput: false,
      invalidAnnualExpenses: false,

      //Full Form Variable
      validForm: false,

      existingProfile: false,
      isGenderFixed: false,
      insuredAvatar: null,
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    showHealthStatus() {
      f7.views.main.router.navigate(
        { name: "HealthStatus" },
        {
          props: {
            questionnerParam: this.questionner,
          },
        }
      );
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goPrevStep() {
      // console.log("From Loved Ones");
      f7.views.main.router.back();
    },
    aIncome() {
      f7.sheet.open(".a-expenses-tt");
    },
    aExpenses() {
      f7.sheet.open(".a-income-tt");
    },

    toggleGender(event) {
      if (event.currentTarget.id == "maleBtn") {
        this.isMale = true;
      } else {
        this.isMale = false;
      }

      this.insuredAvatar = this.$filters.getInsuredAvatar(
        this.selectedDependent2,
        this.isMale ? "male" : "female"
      );
      this.inputvalidator();
    },
    selectedUser(selectedUser, relationship) {
      this.dependentRelationship = relationship;
      this.selectedDependent2 = selectedUser;
      this.listOfDependent.forEach((element) => {
        if (element.id == selectedUser) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    },
    showDependantDetails(dependentParam) {
      if (dependentParam) this.selectedDependent2 = dependentParam;

      let selected = this.insureds.filter(function (ins) {
        return ins.relationship == this;
      }, this.selectedDependent2)[0];

      this.listOfDependent.forEach((d) => {
        if (d.id == this.selectedDependent2) {
          this.dependentRelationship = d.user;
          // console.log(d);
          // this.insuredAvatar = selected.avatar;
        }
      });

      this.isGenderFixed = false;
      switch (this.selectedDependent2) {
        case "mother":
          this.isMale = false;
          this.isGenderFixed = true;
          break;
        case "father":
          this.isMale = true;
          this.isGenderFixed = true;
          break;
        default:
          break;
      }

      // console.log(this.dependentRelationship);
      if (selected) {
        this.selectedDependentId = selected.id;
        this.dependentFirstName = selected.firstName;
        this.dependentLastName = selected.lastName;
        this.dependentBDay = selected.yearOfBirth ?? "";
        this.isMale = selected.gender == "male";
        this.monthIncomeInput = selected.annualIncome ?? "";
        this.annualExpenses = selected.annualExpense ?? "";
        this.existingProfile = true;
        this.insuredAvatar = selected.avatar;
      } else {
        this.dependentFirstName = "";
        this.dependentLastName = "";
        this.dependentBDay = "";
        // this.isMale = true;
        this.monthIncomeInput = "";
        this.annualExpenses = "";
        this.existingProfile = false;
        this.insuredAvatar = this.$filters.getInsuredAvatar(
          this.selectedDependent2,
          this.isMale ? "male" : "female"
        );
      }

      this.inputvalidator();
      f7.sheet.close(".select-dependent");
      f7.sheet.open(".dependent-details");
    },
    async removeDependent() {
      f7.dialog.preloader("Processing...");
      var profile = await this.getInsuredProfile(this.selectedDependentId);

      if (profile) {
        profile.dependent = false;

        profile = await this.updateProfile(this.selectedDependentId, profile);
      }
      f7.sheet.close(".dependent-details");
      f7.dialog.close();
      this.loadDataScreen();
    },
    async saveDependent() {
      // console.log("he guardado");
      // this.dependentCount = 1;
      f7.dialog.preloader("Processing...");
      if (this.existingProfile) {
        var profile = await this.getInsuredProfile(this.selectedDependentId);

        if (profile) {
          profile.yearOfBirth = Number(this.dependentBDay);
          profile.gender = this.isMale ? "male" : "female";
          profile.annualIncome = Number(
            this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
          );
          profile.annualExpense = Number(
            this.annualExpenses.toString().replace(/,|[S$ ]/g, "")
          );
          profile.dependent = true;
          await this.updateProfile(this.selectedDependentId, profile);
        }
      } else {
        var profile = {};
        profile.firstName = this.dependentFirstName;
        profile.lastName = this.dependentLastName;
        profile.yearOfBirth = Number(this.dependentBDay);
        profile.gender = this.isMale ? "male" : "female";
        profile.annualIncome = Number(
          this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
        );
        profile.annualExpense = Number(
          this.annualExpenses.toString().replace(/,|[S$ ]/g, "")
        );
        profile.dependent = true;
        profile.relationship = this.selectedDependent2;

        profile = await API.post("insureds", "/insureds", {
          body: profile,
        })
          .then((result) => {
            // console.log("insured created -> ", result);
            return result;
          })
          .catch((err) => {
            // console.log("insured created error -> ", err);
          });
      }
      f7.sheet.close(".dependent-details");
      f7.dialog.close();
      this.loadDataScreen();
    },

    async getInsuredProfile(insuredId) {
      let path = "/insureds/" + insuredId;

      var profile = await API.get("insureds", path, {})
        .then((result) => {
          // console.log(result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      return profile;
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      // console.log('profile -> ', profile);
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      switch (selectedBox) {
        case "first-name":
          var test = regExpString.test(this.dependentFirstName);
          if (!test) {
            this.invalidDependentFirstName = true;
          } else {
            this.invalidDependentFirstName = false;
          }
          break;
        case "last-name":
          var test = regExpString.test(this.dependentLastName);
          if (!test) {
            this.invalidDependentLastName = true;
          } else {
            this.invalidDependentLastName = false;
          }
          break;
        case "d-bday":
          var currentYear = new Date().getFullYear();
          var test = regExp.test(this.dependentBDay);
          if (!test) {
            this.invalidDependentBDay = true;
          } else {
            if (
              this.dependentBDay > 1900 &&
              this.dependentBDay < currentYear + 2
            )
              this.invalidDependentBDay = false;
            else this.invalidDependentBDay = true;
          }
          break;
        case "month-income":
          let valueMI = this.monthIncomeInput
            .toString()
            .replace(/,|[S$ ]/g, "");
          var test = regExp.test(valueMI) || parseFloat(valueMI) < 0;
          if (!test) {
            this.invalidMonthIncomeInput = true;
          } else {
            this.invalidMonthIncomeInput = false;
          }
          break;
        case "annual-expenses":
          let valueAE = this.annualExpenses.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valueAE) || parseFloat(valueAE) < 0;
          if (!test) {
            this.invalidAnnualExpenses = true;
          } else {
            this.invalidAnnualExpenses = false;
          }
          break;
      }

      if (
        this.dependentFirstName.length > 0 &&
        this.dependentLastName.length > 0 &&
        this.dependentBDay > 0 &&
        parseFloat(
          this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
        ).toFixed(2) >= 0 &&
        parseFloat(
          this.annualExpenses.toString().replace(/,|[S$ ]/g, "")
        ).toFixed(2) >= 0
      ) {
        // console.log("valid form");
        this.validForm = true;
      } else {
        // console.log("invalid form");
        this.validForm = false;
      }
    },
    async loadDataScreen() {
      this.loading = true;
      this.insureds = [];
      await API.get("insureds", "/insureds", {})
        .then((result) => {
          let insuredList = result.data;
          let sortedArr = [];
          for (var x in insuredList) {
            if (insuredList[x].relationship == "myself") {
              sortedArr.unshift(insuredList[x]);
            } else {
              sortedArr.push(insuredList[x]);
            }
          }

          for (var ins in sortedArr) {
            let insured = sortedArr[ins];
            insured.avatar = this.$filters.getInsuredAvatar(
              insured.relationship,
              insured.gender
            );
            this.insureds.push(insured);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.myDependent = this.insureds.filter(function (ins) {
        return ins.dependent ?? false;
      });

      this.dependentCount = this.myDependent.length;
      this.questionner = this.questionnerParam;
      this.loading = false;
      return true;
    },
  },

  async mounted() {
    this.loadDataScreen();
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

.need-analysis-subtitle {
  font-size: 17px;
  margin-top: 0px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #07182b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loved-ones-block-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: right;

  color: #929ba2;
}

.add-dependent-regular {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-dependent-red {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ff3333;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dependent-couter {
  display: inline;
  align-items: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-couter-zero {
  display: inline;
  align-items: center;
  background: #c0cbd2;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-row {
  background-color: white;
  padding-top: 3px;
  /*   border-bottom-width: 13px;
  border-bottom-style: solid;
  border-bottom-color: #f4f8fb; /* f4f8fb */
}

.dependent-row-modal {
  background-color: white;
  padding-top: 3px;
  justify-content: center;
}

.dependent-image {
  padding-top: 3px;
}

.dependent-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;

  padding-top: 4%;
  padding-left: 15px;
}

.dependent-amount {
  font-size: 16px;
  line-height: 22px;

  text-align: left;

  color: #000000;
  padding-top: 4%;
  padding-left: 3px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.dependent-details .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.dependent-details .modal-header > span {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}
</style>
<style>
.disabled .input-clear-button {
  display: none;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}
</style>


