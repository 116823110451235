<template>
  <div
    @click="click"
    class="policy-card"
    :class="{ suggestedStyle: isSuggested }"
  >
    <div
    @click="goSharedPoliciesGroup"
      class="policy-header"
      :class="{ CoverageStyle: isCoverageStyle, noborder: hideCoverageAmount }"
    >
      <div class="" :class="{ shared: true }">
        <img
          class="product-icon"
          :class="{ noborder: hideCoverageAmount }"
          style="
            padding-left: 5px;
            padding-top: 5px;
            padding-right: 5px;
            padding-bottom: 5px;
          "
          :src="
            isSuggested ? policy.insurerLogo : cardLogo ?? policy.InsuredLogo
          "
          :alt="policy.productName"
        />
      </div>
      <div class="product-details" style="padding-right: 15px">
        <span class="product-name">{{ policy.productName }}</span>
        <span class="product-type">{{ policy.productType ?? "-" }}</span>
        <span
          v-if="isCoverageStyle && !hideCoverageAmount"
          class="coverage-type"
          :class="currentCoverageStatus ?? 'pass'"
          >{{ coverageType ?? " - " }}
          {{
            isSuggested ? "" : " : " + $filters.currency(coverageAmount ?? 0)
          }}</span
        >
      </div>
      <a class="default-link" href="#">{{ isSuggested ? "learn more" : "" }}</a>
    </div>
    <div
      v-if="!isCoverageStyle"
      style="border-bottom: 1px solid #e1e7eb"
      class="policy-detail"
    >
      <div class="policy-premium">
        <span class="value">{{
          $filters.currency(policy.premiumAmount ?? 0)
        }}</span>
        <span class="type">{{
          $filters.frequencyLegend(policy.frequency)
        }}</span>
      </div>

      <div style="display: flex">
        <div class="policy-status">
          {{ insured.firstName }}
        </div>
        <div class="">
          <img class="policy-dependent" :src="insuredAvatar" />
        </div>
      </div>
    </div>

    <div class="policy-detail">
      <div
        class="sharing-status"
        v-if="isMine"
        style="color: #5E6D78;"
        @click="removePolicy"
      >
        <img style="padding-bottom: 2px" :src="sharedStatusIcon" alt="" />
        {{ sharedStatus }}
      </div>

      <div class="sharing-status" v-else @click="goPortfolio" >
        <img style="padding-bottom: 2px" :src="sharedStatusIcon" alt="" />
        {{ sharedStatus }}
      </div>
    </div>
  </div>
</template>

<script>
import { f7 } from "framework7-vue";
import { Storage, API } from "aws-amplify";
import female from "@/assets/family_member1.svg";
import male from "@/assets/family_member2.svg";

import plus from "@/assets/customIcons/custom-plus-blue.svg";
import stop from "@/assets/customIcons/custom-stop-blue.svg";
import check from "@/assets/customIcons/custom-check-small.svg";

export default {
  props: {
    policy: {
      type: Object,
    },
    insured: {
      type: Object,
    },
    isCoverageStyle: {
      type: Boolean,
      default: false,
    },
    isSuggested: {
      type: Boolean,
      default: false,
    },
    hideCoverageAmount: {
      type: Boolean,
      default: true,
    },
    selectedCoverageId: {
      type: String,
      default: "",
    },
    currentCoverageStatus: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    let logoRef = this.policy.insurerLogo ?? "DEFAULT.png";

    if (this.policy.owner === localStorage.ownerInsuredId) {
      this.sharedStatus = "Stop sharing";
      this.isMine = true;
      this.sharedStatusIcon = stop;
    } else {
      this.sharedStatus = "Add to my vault";
      this.sharedStatusIcon = plus;
    }
    if (this.insured.gender === 'female') {
      this.insuredAvatar = female;
    }

    const signedURL = await Storage.get("insurers/" + logoRef, {
      level: "public",
    });

    // console.log("this.policy ->", this.policy);
    // console.log("this.selectedCoverageId ->", this.selectedCoverageId);

    switch (this.selectedCoverageId) {
      case "coverageTPD":
        this.coverageType = "DEATH/TPD COVERAGE";
        this.coverageAmount = this.policy.coverageTPD ?? 0;
        break;
      case "coverageCI":
        this.coverageType = "C.I. COVERAGE";
        this.coverageAmount = this.policy.coverageCI ?? 0;
        break;
      case "coverageRetirement":
        break;
      default:
        this.coverageType = this.policy.coverageType;
        this.coverageAmount = this.policy.premiumAmount;
        break;
    }

    // console.log("signedURL -> ", signedURL);
    // console.log("policy card mounted -> ", this.policy);
    this.cardLogo = signedURL;
    switch (this.policy.status) {
      case 1:
        this.policy.statusLabel = "active";
        break;

      default:
        this.policy.statusLabel = "pending";
        break;
    }
  },
  data() {
    return {
      cardLogo: null,
      coverageType: "",
      coverageAmount: 0,
      insuredAvatar: male,
      sharedStatus: "",
      sharedStatusIcon: "",
      isMine: false,
    };
  },
  methods: {
    goPortfolio() {
      // f7.sheet.open(".select-portfolio");
    },

    showDetail() {
      if (this.isSuggested) return;
      f7.views.main.router.navigate(
        { name: "PolicyInformation" },
        {
          props: {
            policyid: this.policy.id,
          },
        }
      );
    },

    checkPolicy() {
      switch (this.policy.shareStatus) {
        case 1:
          this.sharedStatus = "Add to my vault";
          this.sharedStatusIcon = plus;
          break;
        case 2:
          this.sharedStatus = "Stop sharing";
          this.sharedStatusIcon = stop;

          break;
        case 3:
          this.sharedStatus = "In my vault";
          this.sharedStatusIcon = check;

          break;
      }
    },

    goSharedPoliciesGroup() {
        f7.views.main.router.navigate({ name: "PolicyInformation" }, {
          props: {
            policyid: this.policy.id,
          },
        });
    },

    removePolicy() {
      this.$emit('stopSharing');
    },
  },
};
</script>

<style scoped>
/* policy tab */

.policy-card {
  display: flex;
  flex-direction: column;
  padding: 19px 21px 16px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin-bottom: 16px;
}

.policy-card.suggestedStyle {
  background: #f4f8fb;
  border: 1px dashed #c0cbd2;
}

.policy-card .policy-header {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e7eb;
}

.policy-card .policy-header.noborder {
  align-items: flex-start;
}

.policy-card .policy-header.CoverageStyle {
  border-bottom: none;
  padding-bottom: 0px;
}

.policy-card .policy-header a::before {
  content: "chevron_right_ios";
  font-family: "framework7-core-icons";
  color: #c0cbd2;
  display: block;
  width: 8px;
  height: 14px;
  right: 0px;
  position: absolute;
}

.policy-card.suggestedStyle .policy-header a {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #9eaab1;
  padding: 2px 10px;
  border: 1px solid #9eaab1;
  box-sizing: border-box;
  border-radius: 34px;
  background: #ffffff;
  right: 0px;
  position: absolute;
  top: 0px;
}

.policy-card.suggestedStyle .policy-header a::before {
  display: none;
}

.policy-card .policy-header .product-icon {
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e1e7eb;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  margin-right: 17px;
  /*   padding: 11px 9px 9.61px 10px; */
  position: relative;
}
.policy-card .policy-header .product-icon.noborder {
  border: 1px solid transparent;
}

.policy-card .policy-header .product-icon.shared::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #5e6d78;
  width: 24px;
  height: 24px;
  top: -7px;
  left: -12px;
  background-image: url("../assets/share-icon.svg");
  background-repeat: no-repeat;
  background-position: 50%;
}

.policy-card .policy-header .product-details {
  display: flex;
  flex-direction: column;
}

.policy-card .policy-header .product-name {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #07182b;
  margin-bottom: 5px;
}

.policy-card.suggestedStyle .policy-header .product-name {
  width: 65%;
}

.policy-card .policy-header .product-type {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #07182b;
  opacity: 0.5;
}

.policy-card .policy-header .coverage-type {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0px 0px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  width: fit-content;
  margin-top: 9px;
}

.policy-card .policy-header .coverage-type.pass {
  color: #11bba2;
  border-color: #11bba2;
}

.policy-card .policy-header .coverage-type.warn {
  color: #fda937;
  border-color: #fda937;
}

.policy-card .policy-header .coverage-type.alert {
  color: #ff3333;
  border-color: #ff3333;
}

.policy-detail {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.policy-detail .policy-premium .value {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  color: #5f6162;
  margin-right: 7px;
}

.policy-detail .sharing-status {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* #3677C4 */

  color: #3677c4;
}

.policy-detail .policy-premium .type {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #07182b;
  opacity: 0.5;
}

.policy-detail .policy-status {
  font-style: normal;
  line-height: 19px;
  text-align: right;
  padding: 0px 0px;
  font-size: 12px;
  color: #07182b;
  opacity: 0.5;
}

.policy-detail .policy-status.due {
  color: #ffffff;

  background: #ff3333;
  border: 1px solid #ff3333;
}

.policy-detail .policy-status.active {
  color: #09bcc3;
  height: 19px;

  border: 1px solid #09bcc3;
}

.policy-detail .policy-status.cancelled {
  color: #9eaab1;
  height: 19px;

  border: 1px solid #9eaab1;
}

.policy-detail .policy-status.pending {
  color: #fda937;
  height: 19px;

  border: 1px solid #fda937;
}

.policy-detail .policy-dependent {
  width: 32px;
  height: 32px;
  padding-left: 9px;
  margin-top: -5px;
}
</style>