<template>
  <f7-page>
    <f7-navbar class="nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title"
        >Calculate Your Benefits</f7-nav-title
      >
      <f7-nav-right>
        <f7-button @click="cancelBtn" class="custom-cancel">Cancel</f7-button>
      </f7-nav-right>
    </f7-navbar>
    <div class="workflow-progress">
      <div class="status"></div>
    </div>
    <f7-block class="steps-details">
      <small>STEP 1/3</small>
      <span>What is your preferred single premium amount?</span>
      <div class="premium-amount">
        <span class="currency">SGD</span>
        <span class="amount">10,000</span>
        <f7-link class="edit-link" href="#"></f7-link>
      </div>
      <f7-range
        :min="5000"
        :max="100000"
        :step="1"
        :value="10000"
        style="--f7-range-bar-size: 5px"
        class="disabled"
      ></f7-range>
      <div class="range-limits-min-max">
        <span class=""> SGD 5,000 </span>
        <span class=""> SGD 100,000 </span>
      </div>
    </f7-block>
    <f7-block-header>Plan Summary</f7-block-header>
    <f7-block strong inset class="plan-summary-block">
      <div class="summary-section vertical">
        <span class="section-label"
          >Total Maturity Value at the end of 3 years</span
        >
        <div class="section-value">
          <div>
            <span class="currency">SGD</span>
            <span class="amount">10,247</span>
          </div>
          <span>0.82% p.a.</span>
        </div>
      </div>
      <hr />
      <div>
        <!--  -->
        <div class="summary-section top">
          <span class="section-label">Guaranteed Maturity Value</span>
          <div class="section-value">
            <div>
              <span class="currency">SGD</span>
              <span class="amount">10,136</span>
            </div>
            <span>0.45% p.a.</span>
          </div>
        </div>
        <!--  -->

        <div class="summary-section">
          <span class="section-label"
            >Non-Guaranteed <br />
            Maturity Bonus*</span
          >
          <div class="section-value">
            <div>
              <span class="currency">SGD</span>
              <span class="amount">111</span>
            </div>
          </div>
        </div>
        <!--  -->

        <div>
          <span class="section-label small"
            >*Based on illustrated investment rate of return at 1.12% p.a.</span
          >
        </div>
      </div>
      <hr />
      <div>
        <div class="summary-section">
          <span class="section-label">Policy Years</span>
          <div class="section-value">
            <div>
              <span class="amount">3 Years</span>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="summary-section">
          <span class="section-label">Death Benefit</span>
          <div class="section-value">
            <div>
              <span class="currency">SGD</span>
              <span class="amount">10,100</span>
            </div>
          </div>
        </div>
      </div>
    </f7-block>

    <f7-list accordion-list style="margin-top: 0px">
      <f7-list-item link="#" title="FAQ" @click="openFAQ" class="faqs-class">
        <template #media>
          <img src="@/assets/FAQ-icon.svg" width="22" height="31.11" />
        </template>
      </f7-list-item>
      <f7-list-item link="#" title="Need help" @click="openHelp" class="need-help">
        <template #media>
          <div class="assistantAvatar online">
            <img src="@/assets/raw_teleadvisor.png" alt="" />
          </div>
        </template>
      </f7-list-item>
    </f7-list>
    <f7-block align="center" class="policy-information-modal">
      <f7-button class="need-analysis-btn-gray" @click="nextStep">
        <div class="add-policy-btn-red-text">Next</div></f7-button
      >
    </f7-block>
  </f7-page>
</template>

<script>
import { f7, f7Page, f7Block, f7Range, f7Link } from "framework7-vue";

export default {
  components: {
    f7,
    f7Page,
    f7Block,
    f7Link,
    f7Range,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    nextStep() {
      f7.views.main.router.navigate(
        { name: "PlanCalculate2" },
        {
          props: {},
        }
      );
    },
    cancelBtn(){
      f7.views.main.router.navigate(
        { name: "PlanSelection" },
        {
          props: {},
        }
      );
    },
    openFAQ() {
      window.open(
        "https://www.dbs.com.sg/ibanking/help/insurance.html#savvy-endowment"
      );
    },
    openHelp() {
      window.open("https://www.dbs.com.sg/personal/TeleAdvisory");
    },
  },
};
</script>

<style scoped>
.workflow-progress .status {
  width: 33.34%;
  background: rgba(244, 176, 74, 1);
  height: 3px;
}
</style>
<style>
:root {
  --f7-range-bar-bg-color: #e1e7eb;
  --swiper-theme-color: #ff3333;
  --f7-range-bar-active-bg-color: #ff3333;
}
.custom-cancel {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: right;
  color: #3677c4;
  text-transform: capitalize;
}
.add-policy-navbar-title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #07182b;
}

.workflow-progress {
  display: flex;
  width: 100%;
  background: rgba(204, 204, 204, 1);
  height: 3px;
}

.steps-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  margin-top: 0px;
  padding: 19.75px 15px 19px 18px;
  color: rgba(146, 155, 161, 1);
}

.steps-details > small {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
}
.steps-details > span {
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 21px;
  color: #07182b;
  opacity: 0.5;
  margin-top: 13px;
  margin-bottom: 15px;
}
.premium-amount {
  margin-bottom: 15px;
}

.premium-amount .currency {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  color: #929ba2;
}

.premium-amount .amount {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 23px;
  text-transform: uppercase;
  color: #07182b;
  margin-left: 5px;
  margin-right: 10px;
}

.edit-link {
  background-image: url("../../assets/customIcons/edit-icon.svg");
  width: 15px;
  height: 15px;
}

.range-limits-min-max {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.plan-summary-block {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
}
.plan-summary-block hr {
  border: 0;
  border-top: 1px solid rgba(188, 187, 193, 0.7);
  height: 1px;
}

.summary-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.summary-section.top {
  align-items: normal;
}

.summary-section.vertical {
  flex-direction: column;
  align-items: normal;
}

.summary-section.vertical .section-label {
  margin-bottom: 10px;
}

.section-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 21px;
  color: #07182b;
  opacity: 0.5;
}

.section-label.small {
  font-size: 12.5px;
  line-height: 20px;
}

.section-value {
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 21px;
  color: #929ba2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.section-value .currency {
  font-weight: 600;
  margin-right: 5px;
}

.summary-section.vertical .section-value .amount {
  font-size: 25px;
  line-height: 23px;
}

.section-value .amount {
  font-weight: 600;
  color: #07182b;
}

.plan-header {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/purchase-bg.svg");
  padding: 31px 27px;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  background-repeat: no-repeat;
  background-size: cover;
}

.plan-header .plan-title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.plan-header .plan-description {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 23px;
}

.plan-header > .plan-header-values {
  display: flex;
}

.plan-header > .plan-header-values div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.plan-header > .plan-header-values img {
  width: 24px;
  height: 27px;
}

.plan-header > .plan-header-values .value {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.plan-header > .plan-header-values .label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.plan-benefits {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.plan-benefits span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #07182b;
  margin-top: 10px;
  margin-bottom: 13px;
  display: flex;
}
.plan-benefits span::before {
  content: "";
  margin-right: 10px;
  display: flex;
  width: 25px;
  min-width: 25px;
  height: 25px;
  background-image: url("../../assets/benefits-bullets.svg");
}

.accordion-list .item-content {
  padding-left: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #07182b;
}

.accordion-list.list ul:before {
  display: none;
}

.benefits .item-media {
  margin-left: 8px !important;
}
.benefits .item-inner {
  padding-right: 0px !important;
  margin-left: 13px !important;
}

.how-works .item-media {
  margin-left: 8px !important;
}
.how-works .item-inner {
  padding-right: 0px !important;
  margin-left: 10px !important;
}

.faqs-class .item-media {
  margin-left: 10px !important;
}
.faqs-class .item-inner {
  padding-right: 0px !important;
  margin-left: 21px !important;
}
.need-help .item-inner {
  padding-right: 0px !important;
  margin-left: 0px !important;
}
</style>