<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-row no-gap style="margin-bottom: 8px; margin-top: 20px; margin-bottom: 20px; display: flex; align-items: center;">
      <f7-col class="list-title-block" style="" width="50" align="left"> GROUPS</f7-col>
      <f7-col class="" style="padding-right: 15px" width="50" align="right">
        <f7-button class="add-policy-btn icon" style="max-width: 160px;" @click="createSharingGroups">
          Create a group
        </f7-button>
        
        <!-- <img
          @click="createSharingGroups"
          src="@/assets/customIcons/custom-red-plus.svg"
          alt=""
        /> -->
      </f7-col>
    </f7-row>

    <div v-if="loading" class="skeleton-effect-wave" v-for="i in 3">
      <f7-row class="group-row">
        <f7-col
            style="padding-left: 15px; padding-top: 3px"
            class="group-members"
            align="left"
            width="75"
        >
          <span class="skeleton-block"></span>
        </f7-col>
        <f7-col
            align="right"
            style="padding-top: 4px; padding-right: 9px"
            width="20"
        >
          <span
              :class="'group-quantity skeleton-block'"
              style="padding-left: 11px"
          >
          </span>
        </f7-col>
        <div style="padding-top: 7px; padding-right: 15px" slot="after">
          <img src="@/assets/customIcons/custom-right-arrow.svg" alt="" />
        </div>
      </f7-row>

      <div
          style="
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 1px;
          opacity: 0.5;
        "
      ></div>
    </div>

    <div :key="group.id" v-for="group in groups" @click="goToSharedPolicies(group.id)">
      <f7-row class="group-row">
        <f7-col v-if="!isPolicyDetail && policy.id" @click.stop="goTo()" class="" align="center" width="20">
          <f7-toggle
            color="green"
            class="toggle-insets"
            v-model:checked="group.active"
            @change="groupToggl(group.id, ! group.active)"
          ></f7-toggle>
        </f7-col>

        <f7-col
          v-if="!isPolicyDetail && policy.id"
          style="padding-top: 3px"
          class="group-members"
          align="left"
          width="60"
        >
          <span> {{ group.title }} </span>
        </f7-col>

        <f7-col
          v-else
          style="padding-left: 15px; padding-top: 3px"
          class="group-members"
          align="left"
          width="75"
        >
          <span> {{ group.title }} </span>
        </f7-col>

        <f7-col
          align="right"
          style="padding-top: 4px; padding-right: 9px"
          width="20"
        >
          <span
            :class="[group.notification ? 'group-quantity-active' : 'group-quantity']"
          >
            {{ group.policies.length }}
          </span>
        </f7-col>

        <div style="padding-top: 7px; padding-right: 15px" slot="after">
          <img src="@/assets/customIcons/custom-right-arrow.svg" alt="" />
        </div>
      </f7-row>

      <div
        style="
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 1px;
          opacity: 0.5;
        "
      ></div>
    </div>

    <InvitePeople :groupLink="groupLink" />
    <ShareWithYou :groupId="invitationGroupId" :owner="invitationOwner" @join="joinSharingGroup" @decline="declineSharingGroup" />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";

import InvitePeople from "@/components/modals/invite-people.vue";
import ShareWithYou from "@/components/modals/share-policy-with-you.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
    InvitePeople,
    ShareWithYou,
  },
  props: {
    isPolicyDetail: { type: Boolean, default: true },
    policy: { type: Object, default: { id: '' } },
    uniqueCode: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      insureds: [],
      navbarTitle: "Sharing",
      showToggl: false,
      // TODO Delete "Route" is useless. But it was Required for testing
      groups: [],
      groupLink: "",
      invitationGroupId: '',
      invitationOwner: {}
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    invitePeople() {
      f7.sheet.open(".invite-people");
    },

    goToSharedPolicies(groupId) {
      f7.views.main.router.navigate({ name: 'SharedPolicies', params: { groupId } });
    },

    openGroupInvitation() {
      f7.sheet.open(".share-policy-with-you");
    },

    // SharedPoliciesGroups, SharedPeople
    goTo() {
      return false;
    },

    groupToggl(groupId, action) {
      if (action) {
        this.addPolicyToGroup(groupId);
      } else {
        this.deletePolicyToGroup(groupId);
      }
    },
    declineSharingGroup() {
      this.getSharingGroups();
    },
    async joinSharingGroup() {
      f7.dialog.preloader("Loading...");
      API.post("sharingGroups", "/sharing-groups/" + this.invitationGroupId + "/members")
          .then(result => {
            this.getSharingGroups();
            f7.dialog.close();
            f7.sheet.close(".share-policy-with-you");
          })
          .catch(err => {
            console.log(err)
          });
    },
    async getSharingGroups() {
      this.loading = true;
      API.get("sharingGroups", "/sharing-groups")
      .then(result => {
        this.loading = false;
        if (! result.data.length) {
          this.createSharingGroups();
        } else {
          this.groups = result.data.map(group => {
            return {
              ...group,
              active: group.policies.includes(this.policy.id),
            }
          });
        }
      })
      .catch(err => {
        console.log(err)
      });
    },
    async createSharingGroups() {
      this.groups = [];
      API.post("sharingGroups", "/sharing-groups")
        .then(result => {
          this.getSharingGroups();
          this.groupLink = window.location.origin + '?isSharing=true&sharingId=' + result.data.uniqueCode;
          this.invitePeople();
        })
        .catch(err => {
          console.log(err)
        });
    },
    async addPolicyToGroup(groupId) {
      API.post("sharingGroups", "/sharing-groups/" + groupId + "/policies", {
        body: {
          policyId: this.policy.id,
        }
      })
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        console.log(err)
      });
    },
    async deletePolicyToGroup(groupId) {
      API.del("sharingGroups", "/sharing-groups/" + groupId + "/policies/" + this.policy.id)
          .then(result => {
            console.log(result);
          })
          .catch(err => {
            console.log(err)
          });
    },
    async getSharingGroup(groupId) {
      API.get("sharingGroups", "/sharing-groups/" + groupId)
          .then(result => {
            if (! result.data.members.includes(localStorage.ownerInsuredId)) {
              this.invitationGroupId = result.data.id;
              this.invitationOwner = result.data.owner;
              this.openGroupInvitation();
            } else {
              this.getSharingGroups();
            }
          })
          .catch(err => {
            console.log(err)
            this.getSharingGroups();
          });
    },
  },

  async mounted() {
    console.log("mounted");
    console.log(this.isPolicyDetail);
    console.log(this.policy);

    if (this.uniqueCode) {
      this.getSharingGroup(this.uniqueCode);
    } else {
      this.getSharingGroups();
    }
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #07182b;
}

.group-row {
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
}

.group-members {
  font-weight: normal;
  font-size: 17px;
  letter-spacing: -0.408px;
  color: #07182b;
}

.group-quantity-active {
  display: inline;
  align-items: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
}

.group-quantity {
  display: inline;
  align-items: center;
  background: #5e6d78;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
}

.list-title-block {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  padding-left: 15px;
}

.toggle-insets {
  --f7-toggle-handle-color: #fff;
  --f7-toggle-inactive-color: #e5e5ea;
  --f7-toggle-width: 51px;
  --f7-toggle-height: 31px;
  --f7-theme-color: #11bba2;
}

</style>
