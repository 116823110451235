import HomePage from '../pages/home.vue';
import CoverageMain from '../pages/coverage-gap.vue';
import CoverageDetail from '../pages/coverage-detail.vue';
import PolicyInformation from '../pages/policy-information.vue';
import AddPolicy1 from '../components/add-policy/add-policy-1';
import AddPolicy2 from '../components/add-policy/add-policy-2';
import AddPolicy3 from '../components/add-policy/add-policy-3';

// Edit Policy Screens
import EditPolicy from '../components/edit-policy/edit-policy';
import EditPolicyInsuredProfile from '../components/edit-policy/edit-policy-insured-profile';
import EditPolicyPolicyDetails from '../components/edit-policy/edit-policy-policy-details';
import EditPolicyPremiumDetails from '../components/edit-policy/edit-policy-premium-details';
import EditPolicyCoverage from '../components/edit-policy/edit-policy-coverage-details';

import UploadPolicyDoc from '../components/upload-file';

// Edit Policy Screens
import Sharing from '../pages/sharing-home';
import SharedPolicies from '../pages/shared-policies';
import SharedPoliciesGroups from '../pages/shared-policies-group';
import SharedPeople from '../pages/sharing-people';


// demo progress bar
import DemoProgressBar from '../pages/progress-demo.vue';

// Temp Demo Progress bar
import TempProgressBar1 from '../pages/progress-demo-temp-1.vue';
import TempProgressBar2 from '../pages/progress-demo-temp-2.vue';

// Need Analysis
import LandingNeed from '../components/needs-analysis/landing-need.vue';
import LandingNeedBoy from '../components/needs-analysis/landing-need-boy.vue';
import SavingHabits from '../components/needs-analysis/saving-habits.vue';
import RetirementNeeds from '../components/needs-analysis/retirement-needs.vue';
import LovedOnes from '../components/needs-analysis/loved-ones.vue';
import HealthStatus from '../components/needs-analysis/health-status.vue';

// Add Insueed
import InsuredDetails from '../components/insured-details.vue';
import UploadInsured from '../components/upload-insured.vue';
import InsuranceDependentDetail from '../components/insured-details-dependent.vue';

// Settings Screens 
import settings from '../pages/settings.vue';
import SettingsLovedOnes from '../pages/settings-loved-ones.vue';
import settingsProfile from '../components/settings/my-profile-editor.vue';
import settingsMyLovedOnes from '../components/settings/my-loved-ones-editor.vue';

// Sign UP Screens
import SignUp from '../pages/sign-up.vue';
import SigningProcess from '..//components/sign-up/signing-process.vue';
import PhoneInput from '..//components/sign-up/phone-input.vue';
import PhoneVerification from '../components/sign-up/phone-verification.vue';
import ProfileCreation from '../components/sign-up/profile-creation.vue';
import SignUpSuccess from '../components/sign-up-success.vue';

//Purchase
import PlanSelection from '../components/purchase/plan-selection.vue';
import PlanDetails from '../components/purchase/plan-details.vue';
import PlanCalculate1 from '../components/purchase/calculate-benefits-1.vue';
import PlanCalculate2 from '../components/purchase/calculate-benefits-2.vue';
import PlanCalculate3 from '../components/purchase/calculate-benefits-3.vue';
import PlanPurchase from '../components/purchase/purchase-successful.vue';



var routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/',
    name: "CoverageMain",
    component: CoverageMain,
    options: {
      clearPreviousHistory: true,
    },
  },
  {
    path: '/settings',
    name: "settings",
    component: settings,
  },
  {
    path: '/settings-loved-ones',
    name: "SettingsLovedOnes",
    component: SettingsLovedOnes,
  },
  {
    path: '/settings-profile',
    name: "settingsProfile",
    component: settingsProfile,
  },
  {
    path: '/settings-my-loved-ones',
    name: "settingsMyLovedOnes",
    component: settingsMyLovedOnes,
  },
  {
    path: '/policy-information',
    name: "PolicyInformation",
    component: PolicyInformation,
  },
  {
    path: '/coverage-detail',
    name: "CoverageDetail",
    component: CoverageDetail,
  },
  {
    path: '/Demo-ProgressBar',
    name: "DemoProgressBar",
    component: DemoProgressBar,
  },
  {
    path: '/add-policy-1',
    name: "AddPolicy1",
    component: AddPolicy1,
  },
  {
    path: '/add-policy-2',
    name: "AddPolicy2",
    component: AddPolicy2,
  },
  {
    path: '/add-policy-3',
    name: "AddPolicy3",
    component: AddPolicy3,
  },
  {
    path: '/edit-policy',
    name: "EditPolicy",
    component: EditPolicy,
    options: {
      clearPreviousHistory: true,
    },
  },
  {
    path: '/edit-policy-insured-profile',
    name: "EditPolicyInsuredProfile",
    component: EditPolicyInsuredProfile,
  },
  {
    path: '/edit-policy-policy-details',
    name: "EditPolicyPolicyDetails",
    component: EditPolicyPolicyDetails,
  },
  {
    path: '/edit-policy-premium-details',
    name: "EditPolicyPremiumDetails",
    component: EditPolicyPremiumDetails,
  },
  {
    path: '/edit-policy-coverage-details',
    name: "EditPolicyCoverage",
    component: EditPolicyCoverage,
  },
  {
    path: '/landing-need-boy',
    name: "LandingNeedBoy",
    component: LandingNeedBoy,
  },
  {
    path: '/landing-need',
    name: "LandingNeed",
    component: LandingNeed,
  },
  {
    path: '/saving-habits',
    name: "SavingHabits",
    component: SavingHabits,
  },
  {
    path: '/retirement-needs',
    name: "RetirementNeeds",
    component: RetirementNeeds,
  },
  {
    path: '/loved-ones',
    name: "LovedOnes",
    component: LovedOnes,
  },
  {
    path: '/health-status',
    name: "HealthStatus",
    component: HealthStatus,
  },
  {
    path: '/insured-details',
    name: "InsuredDetails",
    component: InsuredDetails,
  },
  {
    path: '/insured-details-dependent',
    name: "InsuranceDependentDetail",
    component: InsuranceDependentDetail,
  },
  {
    path: '/upload-insured',
    name: "UploadInsured",
    component: UploadInsured,
  },
  {
    path: '/Temp-ProgressBar-1',
    name: "TempProgressBar1",
    component: TempProgressBar1,
  },
  {
    path: '/Temp-ProgressBar-2',
    name: "TempProgressBar2",
    component: TempProgressBar2,
  },
  {
    path: '/UploadPolicyDoc',
    name: "UploadPolicyDoc",
    component: UploadPolicyDoc,
  },
  {
    path: '/SignUp',
    name: "SignUp",
    component: SignUp,
  },
  {
    path: '/sign-up-success',
    name: "SignUpSuccess",
    component: SignUpSuccess,
  },
  {
    path: '/signing-process',
    name: "SigningProcess",
    component: SigningProcess,
  },
  {
    path: '/phone-input',
    name: "PhoneInput",
    component: PhoneInput,
  },

  {
    path: '/phone-verification',
    name: "PhoneVerification",
    component: PhoneVerification,
  },
  {
    path: '/profile-creation',
    name: "ProfileCreation",
    component: ProfileCreation,
  },
  {
    path: '/sharing',
    name: "Sharing",
    component: Sharing,
  },
  {
    path: '/shared-policies/:groupId',
    name: "SharedPolicies",
    component: SharedPolicies,
  },
  {
    path: '/shared-policies-group',
    name: "SharedPoliciesGroups",
    component: SharedPoliciesGroups,
  },
  {
    path: '/shared-people/:groupId',
    name: "SharedPeople",
    component: SharedPeople,
  },
  {
    path: '/plan-selection',
    name: "PlanSelection",
    component: PlanSelection,
  },
  {
    path: '/plan-details',
    name: "PlanDetails",
    component: PlanDetails,
  },
  {
    path: '/plan-calculate-1',
    name: "PlanCalculate1",
    component: PlanCalculate1,
  },
  {
    path: '/plan-calculate-2',
    name: "PlanCalculate2",
    component: PlanCalculate2,
  },
  {
    path: '/plan-calculate-3',
    name: "PlanCalculate3",
    component: PlanCalculate3,
  },
  {
    path: '/plan-purchase',
    name: "PlanPurchase",
    component: PlanPurchase,
  },
  
];

export default routes;