<template>
  <f7-page class="page-suw">
    <div class="page-header">
      <div style="width: 28px"></div>
      <!-- <span>DBS Insurance Family Vault</span> -->
      <span>Insurance Family Vault</span>
      <f7-button @click="goToWelcome()" class="btn-close" icon-ios="f7:xmark">
      </f7-button>
    </div>
    <div class="page-subtitle">
      <span>Register or log in </span>
    </div>
    <form action="/">
      <f7-list media-list inset class="insurance-details-tab">
        <f7-list-item @click="focusText('e-mail')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Email address</span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <div v-else>
            <f7-input
              @keyup="inputvalidator('e-mail')"
              @change="inputvalidator('e-mail')"
              v-bind:value="email"
              v-on:input="email = $event.target.value"
              class="e-mail"
              ref="e-mail"
              type="email"
              placeholder="e.g. name@mail.com"
              clear-button
              error-message="Enter a valid email please!"
              required
              validate
              pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]*"
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list media-list inset class="insurance-details-tab">
        <f7-list-item @click="focusText('e-mail')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Password </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <div v-else>
            <f7-input
              @keyup="inputvalidator('e-mail')"
              @change="inputvalidator('e-mail')"
              v-bind:value="password"
              v-on:input="password = $event.target.value"
              class="e-mail"
              ref="e-mail"
              type="password"
              placeholder="*****"
              clear-button
              required
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>
      <div class="btn-wapper">
        <f7-button class="btn-suw" @click="signIn"> Next </f7-button>
      </div>
    </form>
  </f7-page>
</template>

<script>
import {
  f7Block,
  f7ListItem,
  f7Page,
  f7Input,
  f7Button,
  f7Icon,
  f7,
} from "framework7-vue";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { Auth } from "aws-amplify";

export default {
  components: {
    f7Block,
    f7ListItem,
    f7Page,
    f7Input,
    f7Button,
    f7Icon,
    VueTelInput,
  },
  data() {
    return {
      phoneNumber: "",
      password: "",
      email: "",
      loading: false,

      bindProps: {
        mode: "international",
        defaultCountry: "SG",
        placeholder: "Enter a phone number",
        enabledCountryCode: true,
        enabledFlags: true,
        onlyCountries: ["SG"],
        maxLen: 25,
      },
    };
  },
  // beforeCreate() {
  //   // console.log("created -> ", window);

  //   // Feature detection
  //   if ("OTPCredential" in window) {
  //     alert("WebOTP supported!.");
  //     window.addEventListener("DOMContentLoaded", (e) => {
  //       alert("WebOTP supported 0!.");
  //       const input = document.querySelector(
  //         'input[autocomplete="one-time-code"]'
  //       );
  //       alert("WebOTP supported 1!.");
  //       // if (!input) return;
  //       // Cancel the WebOTP API if the form is submitted manually.
  //       const ac = new AbortController();
  //       // const form = input.closest("form");
  //       // if (form) {
  //       //   form.addEventListener("submit", (e) => {
  //       //     // Cancel the WebOTP API.
  //       //     ac.abort();
  //       //   });
  //       // }
  //       alert("WebOTP supported 2!.");
  //       // Invoke the WebOTP API
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal,
  //         })
  //         .then((otp) => {
  //           alert(otp.code);
  //           // input.value = otp.code;
  //           // Automatically submit the form when an OTP is obtained.
  //           // if (form) form.submit();
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           alert("WebOTP error, " + err);
  //         });
  //     });
  //   } else {
  //     alert("WebOTP not supported!.");
  //   }

  //   // if ("OTPCredential" in window) {
  //   //   alert("WebOTP supported!.");
  //   //   window.addEventListener("DOMContentLoaded", (e) => {
  //   //     alert("WebOTP event created.");
  //   //     const ac = new AbortController();
  //   //     navigator.credentials
  //   //       .get({
  //   //         otp: { transport: ["sms"] },
  //   //         signal: ac.signal,
  //   //       })
  //   //       .then((otp) => {
  //   //         alert(otp.code);
  //   //       })
  //   //       .catch((err) => {
  //   //         console.log(err);
  //   //         alert("WebOTP error, " + err);
  //   //       });
  //   //   });
  //   // } else {
  //   //   alert("WebOTP not supported!.");
  //   // }
  // },
  methods: {
    submitForm() {
      f7.views.main.router.navigate(
        { name: "PhoneVerification" },
        {
          props: {},
        }
      );
    },

    async signUp() {
      try {
        let newUserRequest = {
          username: this.email.toLowerCase(),
          password: this.password,
          attributes: {
            email: this.email.toLowerCase(), // optional
            // phone_number, // optional - E.164 number convention
            // other custom attributes
          },
        };
        const { user } = await Auth.signUp(newUserRequest);
        console.log(user);
      } catch (error) {
        console.log("error signing up:", error);
      }
    },

    async confirmSignUp() {
      try {
        await Auth.confirmSignUp(username, code);
      } catch (error) {
        console.log("error confirming sign up", error);
      }
    },

    async signIn() {
      console.log("Start of log in");
      try {
        f7.dialog.preloader("Loading...");
        const user = await Auth.signIn(this.email.toLowerCase(), this.password);
        console.log(user.challengeName);
        if (user.challengeName == "SMS_MFA") {
          f7.views.main.router.navigate(
            { name: "PhoneVerification" },
            {
              props: {
                username: this.email.toLowerCase(),
                password: this.password,
                isLogin: true,
                loggedUser: user,
              },
            }
          );
          f7.dialog.close();
          console.log(user);
        } else {
          f7.views.main.router.navigate(
            { name: "CoverageMain" },
            {
              props: {},
            }
          );
          f7.dialog.close();
        }
      } catch (error) {
        console.log("error signing in", error);
        switch (error.code) {
          case "NotAuthorizedException":
            f7.dialog.close();
            f7.dialog.alert(error.message, () => {
              f7.loginScreen.close();
            });
            break;
          case "UserNotFoundException":
            try {
              let newUserRequest = {
                username: this.email.toLowerCase(),
                password: this.password,
                attributes: {
                  email: this.email.toLowerCase(), // optional
                  phone_number: "", // optional - E.164 number convention
                  // other custom attributes
                },
              };
              // const { user } = await Auth.signUp(newUserRequest);
              // console.log(user);
              f7.dialog.close();
              f7.views.main.router.navigate(
                { name: "PhoneInput" },
                {
                  props: {
                    username: this.email.toLowerCase(),
                    password: this.password,
                  },
                }
              );
            } catch (error) {
              f7.dialog.close();
              f7.dialog.alert(error.message, () => {
                f7.loginScreen.close();
              });
              console.log("error signing up:", error);
            }
            break;
          case "UserNotConfirmedException":
            try {
              const response = await Auth.resendSignUp(
                this.email.toLowerCase()
              );

              console.log("response -> ", response);

              f7.dialog.close();
              f7.views.main.router.navigate(
                { name: "PhoneVerification" },
                {
                  props: {
                    username: this.email.toLowerCase(),
                    password: this.password,
                    phoneNumber: response.CodeDeliveryDetails.Destination,
                  },
                }
              );
              console.log("code resent successfully");
            } catch (err) {
              console.log("error resending code: ", err);
            }
          default:
            break;
        }
      }

      // f7.views.main.router.navigate(
      //   { name: "CoverageMain" },
      //   {
      //     props: {},
      //   }
      // );

      console.log("Logged in!");
    },

    async resendConfirmationCode() {
      try {
        await Auth.resendSignUp(username);
        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    },
    inputvalidator() {},

    goToWelcome() {
      f7.views.main.router.navigate(
        { name: "SignUp" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style>
</style>