<template>
  <f7-page @page:beforeremove="onPageBeforeRemove" @page:init="onPageInit">
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-block class="screen-title"> {{ ScreenTitle }} </f7-block>

    <f7-list media-list inset class="insurance-details-tab">
      <f7-list-item @click="insurerSelector" class="">
        <img
          v-if="activeInsurerName == 'Select'"
          class="insurer-logo"
          :src="activeInsurerLogo"
          alt=""
        />

        <div v-else class="insurer-logo icon-warper-home" style="">
          <img
            class=""
            style="
              position: absolute;
              top: 11px;
              left: 9px;
              width: 23px;
              height: 23px;
            "
            :src="activeInsurerLogo"
            alt=""
          />
        </div>

        <f7-row class="need-analysis-saving-habit-insets">
          <span> Insurer </span>
        </f7-row>

        <div
          v-if="activeInsurerName == 'Select'"
          style="
            color: #a9a9a9;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
          "
        >
          {{ activeInsurerName }}
        </div>

        <div
          v-else
          style="text-align: center; padding-top: 10px; padding-bottom: 5px"
        >
          {{ activeInsurerName }}
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list media-list inset class="insurance-details-tab">
      <f7-list-item @click="productSelector" class="">
        <img
          v-if="activeProductType == 'Select'"
          class="insurer-logo"
          :src="activeProductLogo"
          alt=""
        />

        <div v-else class="insurer-logo icon-warper-home" style="">
          <img
            class=""
            style="
              position: absolute;
              top: 11px;
              left: 11px;
              width: 20px;
              height: 20px;
            "
            :src="activeProductLogo"
            alt=""
          />
        </div>

        <f7-row class="need-analysis-saving-habit-insets">
          <span> Product type </span>
        </f7-row>

        <div
          v-if="activeProductType == 'Select'"
          style="
            color: #a9a9a9;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
          "
        >
          {{ activeProductType }}
        </div>

        <div
          v-else
          style="text-align: center; padding-top: 10px; padding-bottom: 5px"
        >
          {{ activeProductType }}
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        'invalid-Box-Input': invalidProductName,
        'valid-Box-Input': !invalidProductName,
      }"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Product name </span>
        </f7-row>

        <div>
          <div class="item-input-wrap">
            <input
              id="autocomplete-dropdown-all"
              type="text"
              placeholder="Search"
              style="text-align: center; margin: 7px 0"
              :value="productName"
              clear-button
              autocomplete="off"
              @closed="inputvalidator('productName')"
            />
          </div>
        </div>
        <f7-row v-if="invalidProductName" class="no-product-selected">
          <span> Please select an option. </span>
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list media-list inset class="insurance-details-tab">
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span class="span-hint"> Coverage term <img
                 @click="coverageTermInfo()"
                class="tool-tip-info"
                src="@/assets/customIcons/custom-info-circle.svg"
                alt=""
              /></span>
        </f7-row>

        <f7-row
          class="btns-block"
          style="padding-bottom: 10px; padding-top: 10px"
        >
          <f7-input
            @keyup="inputvalidator('coverageTerm')"
            @change="inputvalidator('coverageTerm')"
            v-bind:value="coverageTerm"
            v-on:input="coverageTerm = $event.target.value"
            class="d-last-name"
            ref="d-last-name"
            type="text"
            placeholder="10"
            pattern="[0-9]*"
            maxlength="3"
            style="width: 50px; margin-top: -7px"
          ></f7-input>

          <f7-button
            id="yearsBtn"
            @click="toggleTerm($event)"
            :class="[
              isYears
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            YEARS</f7-button
          >
          <f7-button
            id="monthsBtn"
            style="margin-left: 5px; margin-right: 5px; opacity: 0.5 !important"
            @click="toggleTerm($event)"
            class="disabled"
            :class="[
              !isYears
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            MONTHS</f7-button
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidPolicyNumber ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Policy number </span>
        </f7-row>

        <div>
          <f7-input
            @keyup="inputvalidator('policyNumber')"
            @change="inputvalidator('policyNumber')"
            v-bind:value="policyNumber"
            v-on:input="policyNumber = $event.target.value"
            class="d-last-name"
            ref="d-last-name"
            type="text"
            placeholder="e.g. ######"
            clear-button
            error-message="Please enter a valid policy number!"
            required
            validate
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidStartDate ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Start date </span>
        </f7-row>
        <div>
          <!-- <f7-input
            @keyup="inputDateFormater($event.target.value)"
            @change="inputDateFormater($event.target.value)"
            v-bind:value="startDate"
            v-on:input="startDate = $event.target.value"
            class="d-bday"
            ref="startDate"
            type="text"
            placeholder="DD/MM/YYYY"
            clear-button
            pattern="[0-9]*"
          ></f7-input> -->

          <f7-list-input
            type="datepicker"
            placeholder="YYYY-MM-DD"
            @keyup="inputvalidator('startDate')"
            @change="inputvalidator('startDate')"
            v-bind:value="startDate"
            @calendar:change="(values) => (startDate[0] = values[0])"
            class="d-bday"
            ref="startDate"
            :calendar-params="{ dateFormat: ' yyyy-mm-dd' }"
          ></f7-list-input>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-block align="center" class="policy-information-modal">
      <f7-button
        @click="savePolicy"
        :disabled="!validForm"
        :class="[
          validForm
            ? 'need-analysis-btn-gray'
            : 'need-analysis-btn-gray disabled',
        ]"
      >
        <div class="add-policy-btn-red-text">
          <!-- TODO: Pending Validation -->
          Save
        </div></f7-button
      >
    </f7-block>

    <!-- select-product -->
    <f7-sheet
      class="select-product"
      style="
        height: 40%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="close-modal" style="margin-top: 10px">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon class="close-modal" f7="xmark"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title">
              Select product type
            </f7-col>
          </f7-row>
        </f7-block>

        <div class="">
          <f7-list
            :key="insurerProduct.id"
            v-for="insurerProduct in listOfProducts"
            :v-model="insurerProduct.id"
            media-list
            inset
            :class="
              insurerProduct.isActive
                ? 'product-type-inset-active'
                : 'product-type-inset'
            "
          >
            <f7-list-item class="" @click="productSetActivate(insurerProduct)">
              <f7-row no-gap style="align-items: center">
                <f7-col width="20">
                  <div class="icon-warper" style="display: flex">
                    <img
                      style="
                        width: 20px;
                        height: 20px;
                        padding-top: 6px;
                        padding-left: 6px;
                      "
                      :src="insurerProduct.productimg"
                      alt=""
                    />
                  </div>
                </f7-col>
                <f7-col width="80">
                  <span class="product-type-text">
                    {{ insurerProduct.legend }}
                  </span>
                </f7-col>
              </f7-row>
            </f7-list-item>
          </f7-list>

          <f7-block></f7-block>
        </div>
      </f7-page-content>
    </f7-sheet>

    <!-- select-insurer -->
    <f7-sheet
      class="select-insurer"
      style="
        height: 50%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="close-modal" style="margin-top: 10px">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon class="close-modal" f7="xmark"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title"> Select insurer </f7-col>
          </f7-row>
        </f7-block>

        <div class="">
          <f7-list
            :key="insurerType.id"
            v-for="insurerType in listOfInsurer"
            :v-model="insurerType.id"
            media-list
            inset
            :class="
              insurerType.isActive
                ? 'insurer-type-inset-active'
                : 'insurer-type-inset'
            "
          >
            <f7-list-item class="" @click="insurerSetActivate(insurerType)">
              <f7-row no-gap style="align-items: center">
                <f7-col width="20">
                  <div class="icon-warper" style="display: flex">
                    <img
                      style="
                        width: 20px;
                        height: 20px;
                        padding-top: 6px;
                        padding-left: 6px;
                      "
                      :src="insurerType.logoURL"
                      alt=""
                    />
                  </div>
                </f7-col>
                <f7-col width="80">
                  <span class="product-type-text">
                    {{ insurerType.name }}
                  </span>
                </f7-col>
              </f7-row>
            </f7-list-item>
          </f7-list>

          <f7-block></f7-block>
        </div>
      </f7-page-content>
    </f7-sheet>

    <GenericModal
      modalTitle="Coverage term"
      modalText="Enter 99 if coverage is for life"
      modalClassId="coverageTerm-info"
      :showDefaultPic="false"
      :showActionBtn="false"
    />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
  f7ListInput,
  f7BlockTitle,
} from "framework7-vue";

import { Storage, API } from "aws-amplify";

import $ from "dom7";

import defaultImg from "@/assets/big-elipsis-btn.svg";
import product3 from "@/assets/customIcons/product-type-term-life.svg";
import product4 from "@/assets/customIcons/product-type-whole-life.svg";

import GenericModal from "@/components/modals/generic-modal.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
    f7ListInput,
    f7BlockTitle,
    GenericModal
  },
  props: {
    policy: {},
    insured: {},
  },
  data() {
    return {
      ScreenTitle: "Policy Details",
      loading: true,
      navbarTitle: "Edit Policy",
      isYears: true,

      // Input Fields
      productName: "",
      productSearchedName: "",
      policyNumber: "",
      startDate: null,
      coverageTerm: "",

      // variables to Add color to the boxes
      invalidProductName: false,
      invalidPolicyNumber: false,
      invalidStartDate: false,

      // DEFAULT VALUES
      selectedProduct: null,
      insurerId: "",
      activeInsurerLogo: defaultImg,
      activeInsurerName: "Select",
      productTypeId: "",
      activeProductType: "Select",
      activeProductLogo: defaultImg,
      activeProductLogoName: "",

      //Full Form Variable
      validForm: false,
      productList: [],
      productSuggestion: [],

      listOfInsurer: [
        {
          code: "MANULIFE",
          logo: "MANULIFE.png",
          name: "Manulife",
        },
        {
          code: "GE",
          logo: "GE.png",
          name: "Great Eastern",
        },
        // {
        //   code: "AIA",
        //   logo: "AIA.png",
        //   name: "AIA",
        // },
        // {
        //   code: "AVIVA",
        //   logo: "AVIVA.png",
        //   name: "Aviva",
        // },
        {
          code: "PRUDENTIAL",
          logo: "PRUDENTIAL.png",
          name: "Prudential",
        },
        // {
        //   code: "AXA",
        //   logo: "AXA.png",
        //   name: "AXA",
        // },
        // {
        //   id: 2,
        //   insurerimg: insurercompany2,
        //   legend: "Manulife",
        //   isActive: false,
        // },
        // {
        //   id: 3,
        //   insurerimg: insurercompany3,
        //   legend: "Great Eastern",
        //   isActive: false,
        // },
        // {
        //   id: 4,
        //   insurerimg: insurercompany4,
        //   legend: "Prudential",
        //   isActive: false,
        // },
      ],

      listOfProducts: [
        {
          id: "TERM_LIFE",
          productimg: product3,
          legend: "Term Life",
          isActive: false,
        },
        {
          id: "WHOLE_LIFE",
          productimg: product4,
          legend: "Whole Life",
          isActive: false,
        },
      ],
      // autocompleteDropdownAll: null,
    };
  },
  methods: {
    coverageTermInfo(){
      f7.sheet.open(".coverageTerm-info");
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy all autocompletes

      try {
        if (self.autocompleteDropdownAll)
          self.autocompleteDropdownAll.destroy();
      } catch (error) {
        console.log("onPageBeforeRemove", error);
      }
    },
    onPageInit() {
      const self = this;

      // Dropdown with ajax data
      self.autocompleteDropdownAjax = f7.autocomplete.create({
        inputEl: "#autocomplete-dropdown-all",
        openIn: "dropdown",
        preloader: true, //enable preloader
        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: "name", //object's "value" property name
        textProperty: "name", //object's "text" property name
        limit: 20, //limit to 20 results
        // dropdownPlaceholderText: 'Try "JavaScript"',
        source: function (query, render) {
          // console.log('autocomplete', query);
          var autocomplete = this;
          var results = [];
          self.invalidProductName = false;
          if (query.length === 0) {
            // console.log("no value");
            render(results);
            self.selectedProduct = null;
            // self.invalidProductName = true;
            return;
          }
          // Show Preloader
          autocomplete.preloaderShow();
          self.getProductSuggestion();
          const products = self.productSuggestion;
          // console.log(self.productSuggestion);
          // Find matched items
          for (var i = 0; i < products.length; i++) {
            if (
              products[i].name.toLowerCase().includes(query.toLowerCase()) // >= 0
            )
              results.push(products[i]);
          }

          if (results.length == 0 && query.replace(/\s/g, "") != "") {
            let product = {
              code: "",
              insurerCode: self.insurerId,
              name: query.includes("?") ? query : query + " ?",
              productType: self.productTypeId,
              searchedName: query.replace(/\s/g, "").replace("?", ""),
            };

            results.push(product);
          }

          // Hide Preoloader
          autocomplete.preloaderHide();
          // Render items by passing array with result items
          render(results);
        },
        // renderItem: function (item, index) {
        //   // console.log(item, index);
        //   let text = item.value == "" ? item.text + " ?" : item.text;
        //   return "<span class='autocomplete-custom-item'>" + text + "</span>";
        // },
      });

      // self.autocompleteDropdownAjax.on("render", function (value) {
      //   // value[0].name = value[0].name.replace("? ", "");
      //   self.selectedProduct = value[0];
      //   self.inputvalidator();
      //   self.productName = self.productName.replace("?", "");
      // });

      self.autocompleteDropdownAjax.on("change", function (value) {
        // value[0].name = value[0].name.replace("? ", "");
        self.selectedProduct = value[0];
        self.inputvalidator();
        // self.productName = self.productName.replace("?", "");
        // self.invalidProductName = false;
        // this.productName = this.productName.replace("?", "");
      });
      self.autocompleteDropdownAjax.on("closed", function (value) {
        console.log("self.selectedProduct  -> ", self.selectedProduct);

        self.invalidProductName = self.selectedProduct == null;
        self.inputvalidator();
      });
    },
    setDateValue(e, value) {
      // console.log("setDateValue", e, value);
      // console.log(this.startDate[0], new Date(e));
      // if (this.startDate[0] !== new Date(e)) this.startDate = [new Date(e)];
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    async savePolicy() {
      this.inputvalidator();
      console.log("productName ->", this.productName);
      if (this.validForm) {
        f7.dialog.preloader("Loading...");
        console.log("selectedProduct -> ", this.selectedProduct);
        this.policy.productTypeCode = this.selectedProduct.productType;
        this.policy.searchedProductName = this.selectedProduct.searchedName;
        this.policy.productName = this.selectedProduct.name.replace("?", "");
        this.policy.productCode =
          this.selectedProduct.code == "" ? null : this.selectedProduct.code;
        this.policy.productType = this.activeProductType;
        this.policy.insurerLogo = this.selectedProduct.logo;
        this.policy.policyNumber = this.policyNumber;
        this.policy.insurerLogo = this.activeProductLogoName;

        var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = new Date(this.startDate[0] - tzoffset)
          .toISOString()
          .slice(0, 10);

        this.policy.startDate = localISOTime;
        this.policy.coverageYearTerm = parseInt(this.coverageTerm ?? 0);
        // if (this.isYears) {
        //   this.policy.coverageYearTerm = parseInt(this.coverageTerm ?? 0);
        //   delete this.policy.coverageMonthTerm;
        // } else {
        //   this.policy.coverageMonthTerm = parseInt(this.coverageTerm ?? 0);
        //   delete this.policy.coverageYearTerm;
        // }
        // this.policy.frequency = this.isYears ? 12 : 1;

        console.log('policy to be saved -> ',this.policy);

        let path = "/policies/" + this.policy.id;
        var policyRequest = this.policy;

        var policyResponse = await API.patch("policies", path, {
          body: policyRequest,
        })
          .then((result) => {
            console.log(result);
            return result;
          })
          .catch((err) => {
            console.log(err);
          });

        f7.dialog.close();
        f7.views.main.router.navigate(
          { name: "EditPolicy" },
          {
            props: {
              policy: this.policy,
              insured: this.insured,
            },
          }
        );
      }
    },

    toggleTerm(event) {
      if (event.currentTarget.id == "yearsBtn") {
        this.isYears = true;
      } else {
        this.isYears = false;
      }
      this.inputvalidator();
    },

    productSelector() {
      f7.sheet.open(".select-product");
    },

    insurerSelector() {
      f7.sheet.open(".select-insurer");
    },
    selectProductName(product) {
      this.productName = product.name;
      this.productSearchedName = product.searchedName;
      this.selectedProduct = product;
      this.productSuggestion = [];
      this.inputvalidator();
    },
    productSetActivate(productType) {
      this.productSuggestion = [];
      this.listOfProducts.forEach((element) => {
        if (element.id == productType.id) {
          element.isActive = true;
          this.productTypeId = element.id;
          this.activeProductType = element.legend;
          this.activeProductLogo = element.productimg;
          this.getProductList();
        } else {
          element.isActive = false;
        }
      });

      f7.sheet.close(".select-product");
    },
    async getProductList(isLoadingData) {
      let products = await API.get("products", "/products", {
        queryStringParameters: {
          insurerCode: this.insurerId,
          productType: this.productTypeId,
        },
      })
        .then((result) => {
          // console.log(result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      this.productList = products;

      if (!isLoadingData) {
        this.productName = "";
        this.productSearchedName = "";
        this.selectedProduct = null;
        this.inputvalidator();
        // this.getProductSuggestion();
      }
    },
    getProductSuggestion() {
      this.productSuggestion = this.productList;
      // let counter = 3;
      // if (this.productList) {
      //   this.productList.forEach((p) => {
      //     if (
      //       (p.name.toUpperCase().includes(this.productName.toUpperCase()) ||
      //         this.productName == "") &&
      //       counter > 0
      //     ) {
      //       this.productSuggestion.push(p);
      //       counter--;
      //     }
      //   });
      // }
      // if (
      //   this.productSuggestion.length == 0 &&
      //   this.productName.replace(/\s/g, "") != ""
      // ) {
      //   let product = {
      //     productType: this.productTypeId,
      //     code: "",
      //     name: this.productName,
      //     searchedName: this.productName.replace(/\s/g, ""),
      //     insurerCode: this.insurerId,
      //   };
      //   this.productSuggestion.push(product);
      // }
    },
    insurerSetActivate(insurer) {
      this.productSuggestion = [];
      this.listOfInsurer.forEach((element) => {
        if (element.code == insurer.code) {
          element.isActive = true;
          this.insurerId = element.code;
          this.activeInsurerName = element.name;
          this.activeInsurerLogo = element.logoURL;
          this.activeProductLogoName = element.logo;
          console.log(element);
          this.getProductList();
        } else {
          element.isActive = false;
        }
      });
      f7.sheet.close(".select-insurer");
    },

    async inputvalidator(selectedBox) {
      // console.log("inputvalidator");
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z0-9]+[\s]?)*$/g;
      var regExpEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      switch (selectedBox) {
        case "productName":
          var test = true; // regExpString.test(this.productName);
          if (!test) {
            this.invalidProductName = true;
          } else {
            this.invalidProductName = false;
            // this.getProductSuggestion();
          }
          break;
        case "coverageTerm":
          var test = regExp.test(this.coverageTerm);
          // if (!test) {
          //   this.invalidDependentLastName = true;
          // } else {
          this.invalidDependentLastName = false;
          // }
          break;
        case "policyNumber":
          // var test = regExpString.test(this.policyNumber);
          // if (!test) {
          //   this.invalidPolicyNumber = true;
          // } else {
          this.invalidPolicyNumber = false;
          // }
          break;
        case "startDate":
          var currentYear = new Date();
          var test = true;
          if (!test) {
            this.invalidStartDate = true;
          } else {
            if (this.startDate[0] < currentYear) this.invalidStartDate = false;
            else this.invalidStartDate = true;
          }
          break;
      }

      // this.invalidProductName == (this.selectedProduct != null);
      // console.log('selectedProduct -> ' + this.selectedProduct);
      // console.log('invalidProductName ->' + this.invalidProductName);
      // console.log(
      //   this.productName.length > 0,
      //   this.policyNumber.length > 0,
      //   parseInt(this.coverageTerm) >= 0,
      //   this.selectedProduct != null
      // );

      if (
        // this.productName.length > 0 &&
        this.policyNumber.length > 0 &&
        parseInt(this.coverageTerm) >= 0 &&
        this.selectedProduct != null
      ) {
        // console.log("valid form");
        this.validForm = true;
      } else {
        // console.log("invalid form");
        this.validForm = false;
      }
    },
  },
  async created() {
    this.listOfInsurer.forEach(async (ins) => {
      let signedURL = await Storage.get("insurers/" + ins.logo, {
        level: "public",
      });
      ins.logoURL = signedURL;
    });
  },
  async mounted() {
    // console.log("mounted");
    f7.dialog.preloader("Loading...");
    if (this.policy) {
      console.log("this.policy -> ", this.policy);
      let product = await API.get("products", "/products", {
        queryStringParameters: {
          code: this.policy.productCode,
        },
      })
        .then((result) => {
          // console.log(result);
          // let product = result.data[0];
          let product = result.data.filter(function (p) {
            // console.log(this);
            return p.code == this;
          }, this.policy.productCode)[0];
          console.log("product -> ", product);
          if (!product) {
            product = {
              code: "",
              insurerCode: this.policy.insurerLogo.split(".")[0],
              name: this.policy.productName,
              productType: this.policy.productTypeCode,
              searchedName: this.policy.searchedProductName,
            };
          }
          let insurer = this.listOfInsurer.filter(function (ins) {
            return ins.code == this;
          }, product.insurerCode)[0];
          product.insurer = insurer;

          // console.log("product -> ", product);

          return product;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      console.log("products -> ", product);
      let logoRef = "DEFAULT.png";
      if (product) {
        this.insurerId = product.insurer.code;
        this.activeInsurerName = product.insurer.name;
        this.selectedProduct = product;
        logoRef = product.insurer.logo ?? "DEFAULT.png";
      } else {
        logoRef = this.policy.insurerLogo ?? "DEFAULT.png";
      }

      this.activeProductLogoName = logoRef;
      const signedURL = await Storage.get("insurers/" + logoRef, {
        level: "public",
      });

      this.activeInsurerLogo = signedURL;

      console.log(this.listOfProducts);

      let productType = this.listOfProducts.filter(function (prod) {
        return prod.id == this;
      }, this.policy.productTypeCode)[0];
      if (productType) {
        this.productTypeId = productType.id;
        this.activeProductType = productType.legend;
        this.activeProductLogo = productType.productimg;
      }
      this.productName = this.policy.productName;

      this.isYears = true;
      this.coverageTerm = this.policy.coverageYearTerm;

      // if (this.policy.coverageYearTerm) {
      //   this.isYears = true;
      //   this.coverageTerm = this.policy.coverageYearTerm;
      // } else {
      //   this.isYears = false;
      //   this.coverageTerm = this.policy.coverageMonthTerm;
      // }

      this.policyNumber = this.policy.policyNumber;
      if (this.policy.startDate) {
        let parts = this.policy.startDate.split("-");

        this.startDate = [new Date(parts[0], parts[1] - 1, parts[2])];
      } else {
        this.startDate = [new Date()];
      }
      this.getProductList(true);
      this.inputvalidator();
    }

    f7.dialog.close();
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #07182b;
}

.product-type {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;

  opacity: 0.2;
}

.product-type-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #07182b;
}

.icon-warper {
  border: 4px solid #e1e7eb;
  border-radius: 25px;
  width: 32px;
  height: 32px;
}

.icon-warper-home {
  border: 1px solid #e1e7eb;
  border-radius: 25px;
  width: 42px;
  height: 42px;
}

.product-type-inset {
  background: #ffffff;
  border: 1px solid #e1e7eb;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.product-type-inset-active {
  background: #ffffff;
  border: 1px solid red;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.insurer-type-inset {
  background: #ffffff;
  border: 1px solid #e1e7eb;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.insurer-type-inset-active {
  background: #ffffff;
  border: 1px solid red;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  padding-top: 10px;
  margin-bottom: 0px;
}

.insurer-logo {
  position: absolute;
  top: 20px;
  left: 10px;
}
</style>
<style>
.product-badge-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.product-badge {
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
  display: block;
  text-overflow: ellipsis;
  color: rgba(2, 24, 43, 0.5);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  border: 1px solid #c0cbd2;
  padding: 4px 10px;
  border-radius: 34px;
  margin-right: 6px;
}

.product-badge:hover {
  color: #ffffff;
  background: #5e6d78;
  border: 1px solid #5e6d78;
}

.disabled .input-clear-button {
  display: none;
}

.disabled {
  opacity: 1 !important;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}

.disabled-dependent-text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.2;
}
.autocomplete-custom-item {
  padding-top: 10px;
  padding-bottom: 11px;
  /* padding-left: 10px; */
  width: 300px;
  max-width: 300px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 2px;
  margin-right: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis ellipsis;
  display: flex;
}
.no-product-selected {
  color: #ff3333;
  font-size: 12px;
  text-align: center;
  justify-content: center;
}
</style>


