 <template>
  <div v-if="loading" class="skeleton-effect-wave">
    <PolicyCardSkeleton v-for="index in 3" :key="index" />
  </div>
  <div v-if="policies.length == 0 && !loading">
    <f7-block
      v-if="!insured || insured.relationship == 'myself'"
      class="coverage-banner update-policy"
    >
      <h3>Upload your first policy</h3>
      <f7-link href="/add-policy-1">Add Policy </f7-link>
    </f7-block>

    <f7-block v-else class="coverage-banner update-policy">
      <h3>
        Upload a policy for your
        {{
          insured
            ? $filters.getRelationshipLabel(insured.relationship, true).label
            : ""
        }}
      </h3>
      <f7-link href="/add-policy-1">Add Policy </f7-link>
    </f7-block>
  </div>
  <div v-else-if="!loading">
    <f7-block class="premium-summary">
      <div class="total">
        <span class="value">{{
          $filters.currency((getTotalPremiums) ?? 0)
        }}</span>
        <span class="legend">Total Annual Premiums</span>
      </div>
      <div class="toggle-control">
        <!-- <span class="legend">Show Cancelled</span>
        <f7-toggle checked></f7-toggle> -->
        <f7-link href="/add-policy-1" class="dbs-button-base alert add"
        >Add Policy</f7-link
      >
      </div>
    </f7-block>
    <!-- <f7-block class="links-wrapper">
      <f7-link href="/add-policy-1" class="dbs-button-base alert"
        >Add Policy</f7-link
      >
      <f7-link @click="goTo" class="dbs-button-base share">Share</f7-link>
    </f7-block> -->

    <f7-block>
      <PolicyCard
        v-for="policy in policies"
        :key="policy"
        :policy="policy"
        :insured="insured"
      />
    </f7-block>
    <f7-block class="assistant">
      <f7-list media-list class="coverage-options">
        <f7-list-item
          @click="openHelp"
          link="#"
          :title="getSelectedInsurer"
          subtitle="Let me know if I can help :)"
        >
          <template #media>
            <div class="assistantAvatar online">
              <img src="@/assets/raw_teleadvisor.png" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>

    
  </div>
  <f7-block>
      <div class="footer-coverage">
        <!-- <h3>Insure with DBS</h3> -->
        <h3>Insure with us</h3>
        <span>Choose from our diverse range of insurance policies</span>
        <f7-button href="/plan-selection">VIEW RECOMMENDATIONS</f7-button>
      </div>
    </f7-block>
</template>

<script>
import { f7Link, f7Block, f7Toggle, f7 } from "framework7-vue";

import PolicyCard from "@/components/policy-card.vue";
import PolicyCardSkeleton from "@/components/policy-card-skeleton.vue";

export default {
  components: {
    f7Link,
    f7Block,
    f7Toggle,
    PolicyCard,
    PolicyCardSkeleton,
  },
  props: {
    ThereIsPolicy: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    policies: {
      type: Array,
      default: [],
    },
    insured: {
      type: Object,
    },
  },
  mounted() {
    //    console.log("policy tab", this.policies);
  },
  methods: {
    goTo() {
      f7.views.main.router.navigate(
        { name: "Sharing" },
        {
          props: {
            isPolicyDetail: true,
          },
        }
      );
    },
    openHelp() {
      window.open(
        "https://www.dbs.com.sg/personal/TeleAdvisory"
      );
    },
  },
  computed: {
    getSelectedInsurer() {
      return "Hi " + localStorage.OwnerName + "!";
    },
    // TODO GET ALL THE POLICIES VALUES
    getTotalPremiums() {
      var totalPremium = 0;
      var total_1 = 0,
        total_4 = 0,
        total_6 = 0,
        total_12 = 0.0;

      for (var i in this.policies) {
        let premiumAmount = Number(this.policies[i].premiumAmount ?? 0)

        totalPremium += premiumAmount ?? 0;

        if (this.policies[i].frequency == 1) {
          total_1 += premiumAmount ?? 0;
        } else if (this.policies[i].frequency == 4) {
          total_4 += premiumAmount ?? 0;
        } else if (this.policies[i].frequency == 6) {
          total_6 += premiumAmount ?? 0;
        } else if (this.policies[i].frequency == 12) {
          total_12 += premiumAmount ?? 0;
        } else {
          total_12 += premiumAmount ?? 0;
        }
      }

      totalPremium =
        (total_1 ?? 0) * 12 +
        (total_4 ?? 0) * 4 +
        (total_6 ?? 0) * 2 +
        (total_12 ?? 0);

      return totalPremium;
    },
  },
};
</script>

<style scoped>
.coverage-banner h3 {
  max-width: 165px;
}
</style>

<style >
.links-wrapper,
.premium-summary {
  display: flex;
  align-items: center;
}

.premium-summary {
  justify-content: space-between;
  margin-top: 32px;
}

.premium-summary .total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3px;
}

.premium-summary .total .value {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 23px;
  color: #07182b;
  margin-bottom: 11px;
}
.premium-summary .total .legend {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #07182b;
  opacity: 0.5;
}

.premium-summary .toggle-control {
  display: flex;
}

.premium-summary .toggle-control .legend {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #07182b;
  opacity: 0.5;
  width: 80px;
  max-width: 80px;
  margin-right: 12px;
}
</style>