// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';
// import Vue2Filters from 'vue2-filters'

// Import VueClipboard Plugin
import VueClipboard from 'vue3-clipboard'

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import "../css/dbs.css";

// Import App Component
import App from '../components/app.vue';


import {
    applyPolyfills,
    defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';


import Inputmask from 'inputmask'

import insurer1 from "@/assets/family_member1.svg";
import insurer2 from "@/assets/family_member2.svg";
import insurer3 from "@/assets/family_member3.svg";
import insurer4 from "@/assets/family_member4.svg";
import insurer5 from "@/assets/family_member5.svg";
import insurer6 from "@/assets/family_member6.svg";

Amplify.configure(aws_exports);

applyPolyfills().then(() => {
    defineCustomElements(window);
});



// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Framework7.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})

// Init App
const app = createApp(App);

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})

app.config.globalProperties.$filters = {
    currency(value, decPlaces) {
        value = parseFloat(value);
        if (typeof value !== "number") {
            return value;
        }

        let minimumFractionDigits = decPlaces ?? 2;
        let valueToFormat = value.toFixed(2);

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: minimumFractionDigits
        });
        return formatter.format(valueToFormat);
    },
    frequencyLegend(value) {

        switch (value) {
            case 1:
                return "Monthly";
            case 3:
                return "Quarterly";
            case 6:
                return "Bi-Annually";
            case 12:
                return "Annually";
            default:
                return "";
        }
    },
    numberShorten(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);

        // Enumerate number abbreviations
        var abbrev = ["k", "m", "b", "t"];

        // Go through the array backwards, so we do the largest first
        for (var i = abbrev.length - 1; i >= 0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10, (i + 1) * 3);

            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((number == 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }
        return '$' + number;
    },
    getCoverageClass(percentage) {
        if (percentage < 0) {
            return "pass";
        }
        else if (percentage < 60) {
            return "alert";
        } else if (percentage < 90) {
            return "warn";
        } else {
            return "pass";
        }
    },
    getCoverageGraphColor(percentage) {
        if (percentage < 0) {
            return "#11BBA2";
        }
        else if (percentage < 60) {
            return "#FF3333";
        } else if (percentage < 90) {
            return "#FDA937";
        } else {
            return "#11BBA2";
        }
    },
    getCoverageStatus(score, isOwner) {
        if (isOwner) {
            if (score < 60) {
                return "case1";
            } else if (score < 90) {
                return "case2";
            } else {
                return "case3";
            }
        }
        else {
            if (score < 60) {
                return "case4";
            } else {
                return "case5";
            }
        }
    },
    getInsuredAvatar(relationship, gender) {
        if (typeof gender === 'undefined') {
            if (relationship == 'spouse') {
                if (localStorage.Ownergender == 'male')
                    gender = 'female'
                else
                    gender = 'male'
            } else {
                gender = 'male'
            }

        }
        switch (relationship) {
            case "spouse":
                if (gender == "male") return insurer2;
                else return insurer1;
            case "mother":
            // return insurer5;
            case "father":
                if (gender == "male") return insurer6;
                else return insurer5;
            case "child-1":
            case "child-2":
            case "child-3":
            case "child-4":
                if (gender == "male") return insurer4;
                else return insurer3;
            case "myself":
                if (gender == "male") return insurer2;
                else return insurer1;
            default:
                return insurer4;
        }
    },
    processCoverageAnalysis(storedCoverages, apiData, isOwner) {
        // console.log('apiData -> ', apiData);
        // console.log('isOwner->', isOwner);
        storedCoverages.forEach((sc) => {
            // console.log(sc.code);
            let coverageTmp = apiData.filter((c) => c.code == sc.code)[0];
            // console.log('coverageTmp -> ', coverageTmp);
            // console.log(!isOwner, sc.code, sc);
            if (!isOwner && sc.code == 'RETIREMENT') {
                sc.isCovered = false;
                sc.statusLabel = "alert";
                sc.graphColor = "#FF3333";
            }
            else if (coverageTmp) {
                sc.isCovered = coverageTmp.isCovered ?? false;
                sc.attributeName = coverageTmp.attributeName;
                sc.name = sc.name;//coverageTmp.name;
                sc.detail = coverageTmp.detail;
                sc.statusLabel = this.getCoverageClass(coverageTmp.detail.score ?? 0);
                sc.graphColor = this.getCoverageGraphColor(coverageTmp.detail.score ?? 0);
            }
            else {
                sc.isCovered = false;
                sc.statusLabel = "alert";
                sc.graphColor = "#FF3333";
            }
        });
        // console.log('storedCoverages -> ', storedCoverages);
        return storedCoverages;
    },
    getRelationshipLabel(id, isShort) {
        let listOfDependent = {
            "myself": { label: "myself", index: 1 },
            "spouse": { label: "spouse", index: 2 },
            "child-1": { label: "first child", index: 3 },
            "child-2": { label: "second child", index: 4 },
            "child-3": { label: "third child", index: 5 },
            "child-4": { label: "fourth child", index: 6 },
            "father": { label: "father", index: 7 },
            "mother": { label: "mother", index: 8 },
        };
        let response = listOfDependent[id];
        // listOfDependent.forEach(d => {
        //     if (d.id == id) response = d;
        // });
        if (isShort && id.includes('child')) {
            response.label = 'child';
        }
        return response;
    }
}




app.directive('inputmask', {
    beforeMount(el, binding, vnode) {
        let maskOpt = vnode.props['data-inputmask'];
        new Inputmask(maskOpt).mask(el);
    }
})
// app.config.isCustomElement = tag => tag.startsWith('amplify-');

// Register Framework7 Vue components
registerComponents(app);

// Mount the app
app.mount('#app');