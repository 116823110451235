<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-block class="screen-title"> {{ ScreenTitle }} </f7-block>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidPremiumAmount ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Premium amount </span>
        </f7-row>

        <f7-row no-gap>
          <input
              v-model="premiumAmount"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('premium-amount')"
              @change="inputvalidator('premium-amount')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >

          <!-- <f7-col class="box-dolar-sign" width="50" align="right"> S$</f7-col>
          <f7-col width="50" align="left" style="padding-left: 10px">
            <f7-input
              v-bind:value="premiumAmount"
              v-on:input="premiumAmount = $event.target.value"
              @keyup="inputvalidator('premium-amount')"
              @change="inputvalidator('premium-amount')"
              class="total-saving-input"
              ref="totalSaving"
              type="text"
              placeholder="1000"
              clear-button
              error-message="Only numbers please!"
              required
              validate
              pattern="[0-9]*"
            ></f7-input>
          </f7-col> -->
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list media-list inset class="insurance-details-tab">
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Payment frequency (months) </span>
        </f7-row>

        <f7-row
          class="btns-block"
          style="padding-bottom: 10px; padding-top: 10px"
        >
          <f7-button
            :key="paymentFrequency.id"
            v-for="paymentFrequency in listOfYears"
            :v-model="paymentFrequency.id"
            style="margin-right: 10px"
            :style="[
              paymentFrequency.label == 'LUMP SUM/ONE TIME'
                ? 'width: 175px; margin-top: 7px;'
                : '',
            ]"
            @click="togglePaymentFrequency(paymentFrequency.id)"
            :class="[
              paymentFrequency.isActive
                ? 'payment-frequency-fill-btn'
                : 'payment-frequency-no-fill-btn',
            ]"
          >
            {{ paymentFrequency.label }}</f7-button
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidpremiumTerm ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Premium term </span>
        </f7-row>

        <f7-row
          class="btns-block"
          style="padding-bottom: 10px; padding-top: 10px"
        >
          <f7-input
            @keyup="inputvalidator('premiumTerm')"
            @change="inputvalidator('premiumTerm')"
            v-bind:value="premiumTerm"
            v-on:input="premiumTerm = $event.target.value"
            :class="{ disabled: isLumpSum }"
            class="d-last-name"
            ref="d-last-name"
            type="text"
            placeholder="01"
            pattern="[0-9]*"
            maxlength="3"
            style="width: 50px; margin-top: -7px"
          ></f7-input>

          <f7-button
            id="yearsBtn"
            style="width: 85px; !important"
            @click="togglePremiumTerm($event)"
            :class="[
              isYears
                ? 'payment-frequency-fill-btn'
                : 'payment-frequency-no-fill-btn',
            ]"
          >
            YEARS</f7-button
          >
          <f7-button
            id="monthsBtn"
            style="margin-left: 5px; margin-right: 5px; width: 85px; !important; opacity: 0.5!important;"
            class="disabled"
            @click="togglePremiumTerm($event)"
            :class="[
              !isYears
                ? 'payment-frequency-fill-btn'
                : 'payment-frequency-no-fill-btn',
            ]"
          >
            MONTHS</f7-button
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-block
      align="center"
      class="policy-information-modal"
      style="position: absolute; bottom: 0px; padding-left: 40px"
    >
      <f7-button
        @click="savePremiumDetails"
        :disabled="!validForm"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="add-policy-btn-red-text">
          <!-- TODO: Pending Validation -->
          Save
        </div></f7-button
      >
    </f7-block>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
  },
  props: {
    policy: {},
    insured: {},
  },
  data() {
    return {
      ScreenTitle: "Premium Details",

      loading: true,
      insureds: [],
      navbarTitle: "Edit Policy",

      isYears: true,
      isLumpSum: false,

      activeMonth: false,

      // Input Fields
      premiumAmount: "",
      premiumTerm: "",
      policyNumber: "",
      premiumPaymentFrequency: "",

      // variables to Add color to the boxes
      invalidpremiumTerm: false,
      invalidPremiumAmount: false,

      //Full Form Variable
      validForm: true,

      listOfYears: [
        { id: 1, label: "1", isActive: false },
        { id: 3, label: "3", isActive: false },
        { id: 6, label: "6", isActive: false },
        { id: 12, label: "12", isActive: false },
        { id: -1, label: "LUMP SUM/ONE TIME", isActive: false },
      ],
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    async savePremiumDetails() {
      f7.dialog.preloader("Loading...");
      this.policy.premiumAmount = parseFloat(this.premiumAmount.toString().replace(/,|[S$ ]/g, "")).toFixed(2);
      this.policy.frequency = this.premiumPaymentFrequency;

      if (this.isYears) {
        this.policy.premiumTermYear =  parseInt(this.premiumTerm);
        this.policy.premiumTermMonth = null;
      } else {
        this.policy.premiumTermMonth =  parseInt(this.premiumTerm);
        this.policy.premiumTermYear = null;
      }

      if (this.isLumpSum) {
        this.policy.premiumTermYear = -2;
      }

      let path = "/policies/" + this.policy.id;
      var policyRequest = this.policy;
      // console.log("policyRequest -> ", policyRequest);
      var policyResponse = await API.patch("policies", path, {
        body: policyRequest,
      })
        .then((result) => {
          // console.log(result);
          return result;
        })
        .catch((err) => {
          console.log(err);
        });
      f7.dialog.close();

      f7.views.main.router.navigate(
        { name: "EditPolicy" },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
      // f7.views.main.router.navigate({ name: "EditPolicy" });
    },

    togglePaymentFrequency(paymentFrequency) {
      this.premiumPaymentFrequency = null;
      this.isLumpSum = false;
      this.listOfYears.forEach((element) => {
        if (element.id == paymentFrequency) {
          element.isActive = true;
          this.premiumPaymentFrequency = parseInt(paymentFrequency);
          if (element.id == -1) {
            this.premiumTerm = "";
            this.isLumpSum = true;
          }
        } else {
          element.isActive = false;
        }
      });
      this.inputvalidator("premiumTerm");
    },

    togglePremiumTerm(event) {
      if (event.currentTarget.id == "yearsBtn") {
        this.isYears = true;
      } else {
        this.isYears = false;
      }
      this.inputvalidator();
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      switch (selectedBox) {
        case "premium-amount":
          let valuePA = this.premiumAmount.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valuePA) || parseFloat(valuePA) < 0;
          if (!test) {
            this.invalidPremiumAmount = true;
          } else {
            this.invalidPremiumAmount = false;
          }
          break;
        case "premiumTerm":
          if (!this.isLumpSum) {
            let test = true;
            if (!this.premiumTerm) test = false;
            else test = regExp.test(this.premiumTerm);
            // console.log(test);
            if (!test) {
              this.invalidpremiumTerm = true;
            } else {
              this.invalidpremiumTerm = false;
            }
          } else {
            this.invalidpremiumTerm = false;
          }

          break;
      }
      this.validForm = !this.invalidpremiumTerm && !this.invalidPremiumAmount;
    },

    async loadDataScreen() {},
  },

  async mounted() {
    this.premiumAmount =  parseFloat(this.policy.premiumAmount).toFixed(2);

    if (this.policy.premiumTermYear) {
      this.premiumTerm = this.policy.premiumTermYear;
      this.isYears = true;
    } else {
      this.premiumTerm = this.policy.premiumTermMonth;
      this.isYears = false;
    }
    this.isYears = true;

    this.togglePaymentFrequency(this.policy.frequency);
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #07182b;
}

.product-type {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;

  opacity: 0.2;
}

.product-type-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #07182b;
}
</style>
<style>
.disabled .input-clear-button {
  display: none;
}

.disabled {
  opacity: 1 !important;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}

.disabled-dependent-text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.2;
}

.payment-frequency-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  position: static;
  width: 51px;
  height: 31px;
  left: 0px;
  top: 4px;
  background: #5e6d78;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}

.payment-frequency-no-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  position: static;
  width: 51px;
  height: 31px;
  left: 170px;
  top: 4px;
  background: #ffffff;
  border: 1px solid #5e6d78;
  box-sizing: border-box;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #5e6d78;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}
</style>


