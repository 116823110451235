<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="showPolicyInfo"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title">Edit Policy</f7-nav-title>
    </f7-navbar>

    <div class="policy-plus-avatar">
      <div class="policy-plus-title">{{ policy.productName ?? " - " }}</div>

      <img src="@/assets/custom-inspector-girl.svg" alt="" />

      <div class="policy-owner-row">
        <f7-col width="15" style="margin-right: 5px">
          <img :src="insured.avatar" alt="" />
        </f7-col>
        <f7-col
          class="policy-owner-name"
          :class="insured.healthStatus"
          width="80"
          style="margin-left: 5px"
        >
          {{ insured.firstName }} {{ insured.lastName }}
        </f7-col>
      </div>
    </div>

    <f7-list inset class="edit-policy-inset" @click="editPolicy('EditPolicyInsuredProfile')">
      <f7-list-item class="inner-element-inset">
        <div class="edit-title">
          <img
            style="padding-right: 15px"
            src="@/assets/customIcons/custom-red-check-no-fill.svg"
            alt=""
          />
          Insured's Profile
        </div>
        <div
          slot="after"
          @click="editPolicy('EditPolicyInsuredProfile')"
          class="edit-link"
        >
          Edit
          <f7-link>
            <img
              src="@/assets/customIcons/custom-tiny-right-blue-arrow.svg"
              alt=""
              style="padding-left: 10px"
            />
          </f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      inset
      class="edit-policy-inset"
      @click="editPolicy('EditPolicyPolicyDetails')"
    >
      <f7-list-item class="inner-element-inset">
        <div class="edit-title">
          <img
            style="padding-right: 15px"
            src="@/assets/customIcons/custom-red-check-no-fill.svg"
            alt=""
          />
          Policy Details
        </div>
        <div
          slot="after"
          @click="editPolicy('EditPolicyPolicyDetails')"
          class="edit-link"
        >
          Edit
          <f7-link>
            <img
              src="@/assets/customIcons/custom-tiny-right-blue-arrow.svg"
              alt=""
              style="padding-left: 10px"
            />
          </f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      inset
      class="edit-policy-inset"
      @click="editPolicy('EditPolicyPremiumDetails')"
    >
      <f7-list-item class="inner-element-inset">
        <div class="edit-title">
          <img
            style="padding-right: 15px"
            src="@/assets/customIcons/custom-red-check-no-fill.svg"
            alt=""
          />
          Premium Details
        </div>
        <div
          slot="after"
          class="edit-link"
          @click="editPolicy('EditPolicyPremiumDetails')"
        >
          Edit
          <f7-link>
            <img
              src="@/assets/customIcons/custom-tiny-right-blue-arrow.svg"
              alt=""
              style="padding-left: 10px"
            />
          </f7-link>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      inset
      class="edit-policy-inset"
      :class="{pending: !policy.productTypeCode}"
      @click="editPolicy('EditPolicyCoverage')"
      style="margin-bottom: 35px"
    >
      <f7-list-item class="inner-element-inset">
        <div class="edit-title">
          <img
            style="padding-right: 15px"
            src="@/assets/customIcons/custom-red-check-no-fill.svg"
            alt=""
          />
          Coverage Details
        </div>
        <div
          slot="after"
          class="edit-link"
          v-if="policy.productTypeCode"
          @click="editPolicy('EditPolicyCoverage')"
        >
          Edit
          <f7-link>
            <img
              src="@/assets/customIcons/custom-tiny-right-blue-arrow.svg"
              alt=""
              style="padding-left: 10px"
            />
          </f7-link>
        </div>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Button,
  f7Block,
  f7NavRight,
  f7,
} from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Button,
    f7Block,
  },
  props: {
    policy: {},
    insured: {},
  },
  data() {
    return {
      policyName: "Vivocare",
      firstName: "Omari",
      lastName: "Takashima",
    };
  },
  mounted() {
    f7.dialog.preloader("Loading...");
    console.log(this.policy);
    console.log(this.insured);
    f7.dialog.close();
  },
  methods: {
    showPolicyInfo() {
      // if (this.policy.statusLabel == "pending") return;
      f7.views.main.router.navigate(
        { name: "PolicyInformation" },
        {
          props: {
            policyid: this.policy.id,
            askReloadMainPage: true,
          },
        }
      );
    },
    goTo(newPage) {
      f7.views.main.router.navigate({ name: newPage });
    },
    editProfile() {
      //EditPolicyInsuredProfile
      f7.views.main.router.navigate(
        { name: "EditPolicyInsuredProfile" },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
    },
    editPolicy(screenName) {
      if (!this.policy.productTypeCode && screenName == 'EditPolicyCoverage') return;
      f7.views.main.router.navigate(
        { name: screenName },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
    },
  },
};
</script>

<style>
.add-policy-nav-bar-shadow::before,
.add-policy-nav-bar-shadow::after {
  display: none;
}

.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}

.edit-policy-inset .item-inner{
  width: 90%;
}
</style>

<style scoped>
.policy-plus-avatar {
  background-color: white;
  text-align: center;
}

.policy-plus-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #5e6d78;
  padding: 0 15px;
  padding-top: 15px;
  margin-bottom: 15px;
  /* text-transform: capitalize; */
}

.edit-policy-inset {
  margin-top: 15px;
  margin-bottom: 0px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.edit-policy-inset.pending {
  filter: grayscale(1);
}

.inner-element-inset {
  padding-top: 10px;
  padding-bottom: 10px;
  /*   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07); */
}

.edit-title {
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #07182b;
}

.edit-policy-inset.pending .edit-title{
  opacity: 0.6;
}

.edit-link {
  display: flex;
  align-items: center;
  color: #3677c4;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #3677c4;
  background: none;
}

.policy-owner-name {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #000000;
}

.policy-owner-name::before {
  content: "";
  margin-right: 3px;
  width: 8px;
  height: 8px;
  display: block;
  transform: rotate(45deg);
  margin-right: 10px;
}

.policy-owner-name.alert::before {
  background: #ff3333;
}

.policy-owner-name.warn::before {
  background: #fda937;
}

.policy-owner-name.pass::before {
  background: #11bba2;
}

.policy-owner-row {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e1e7eb;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e1e7eb;

  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>