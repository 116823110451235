<template>
  <f7-block
    v-if="getBannerContent"
    class="coverage-banner"
    :class="content.class"
  >
    <h3>{{ content.title }}</h3>
    <span>{{ getCardText }}</span>
    <f7-link :class="btnClass" @click="navigateBtn">
      {{ getButtonText }}
    </f7-link>
  </f7-block>
</template>

<script>
import { f7Block, f7Link, f7 } from "framework7-vue";

export default {
  props: {
    status: "",
    isOwnerSelected: false,
    isQuizDone: false,
    relationshipSelected: "",
  },
  components: [f7Block, f7Link],
  data() {
    return {
      btnClass: "",
      banners: {
        case1: {
          title: "Your portfolio is unhealthy",
          description: "Your coverage is looking really insufficient",
          button_text: "Improve analysis",
          navigate: "/landing-need",
          class: "banner-alert",
        },
        case2: {
          title: "Your portfolio is average",
          description: "Your coverage is looking slightly insufficient",
          button_text: "Improve analysis",
          navigate: "/landing-need",
          class: "banner-warn",
        },
        case3: {
          title: "Your portfolio is healthy",
          description: "You seem to have adequate coverage for your needs",
          button_text: "View analysis",
          navigate: "/landing-need",
          class: "banner-pass",
        },
        case4: {
          title: "Oh no!",
          description: "Your #relation seems to be under-insured",
          button_text: "View analysis",
          navigate: "/landing-need",
          class: "banner-alert-s",
        },
        case5: {
          title: "Great!",
          description: "Your #relation seems to have sufficient coverage",
          navigate: "/landing-need",
          navigate: "#",
          class: "banner-pass",
        },
      },
      content: {
        title: "Great!",
      },
    };
  },
  methods: {
    navigateBtn() {
      if (!this.isOwnerSelected || this.isQuizDone) {
        f7.views.main.router.navigate(
          { name: "CoverageDetail" },
          {
            props: {
              selectedCoverageId: null,
              isQuizDone: this.isQuizDone,
            },
          }
        );
      } else {
        f7.views.main.router.navigate(
          { name: "LandingNeed" },
          {
            props: {},
          }
        );
      }
    },
  },
  computed: {
    getBannerContent() {
      // console.log(this.status);
      this.content = this.banners[this.status];
      return this.content;
    },
    getButtonText() {

      if (!this.isOwnerSelected || this.isQuizDone) {
        this.btnClass = "gray";
        return "View analysis";
      } else {
        this.btnClass = "red";
        this.content.class = 'banner-alert';
        return "Improve analysis";
      }
    },
    getCardText() {
      if (this.isOwnerSelected ) {
        return this.content.description;
      } else {
        if(this.relationshipSelected)
        {return this.content.description.replace(
          "#relation",
          this.$filters.getRelationshipLabel(this.relationshipSelected ?? 'myself').label
        );
        }
      }
    },
  },
};
</script>
<style scoped>
.coverage-banner.banner-alert a.gray,
.coverage-banner.banner-warn a.gray {
  background-color: #5e6d78;
  width: 126px;
}

.coverage-banner.banner-alert a.gray::before,
.coverage-banner.banner-warn a.gray::before {
  display: none;
}
</style>

<style>
</style>