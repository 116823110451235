<template>
  <f7-sheet
    class="invite-people coverage"
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      --f7-sheet-bg-color: #fff;
    "
    swipe-to-close
    backdrop
  >
    <f7-page-content>
      <!-- <f7-button sheet-close>
        <f7-icon class="close-modal-no-title" f7="xmark"></f7-icon>
      </f7-button> -->

      <div class="modal-header">
        <f7-button icon-ios="f7:xmark" sheet-close> </f7-button>
        <span class="modal-title">{{ modalTitle }}</span>
      </div>

      <f7-block class="policy-information-modal">
        <img src="@/assets/invite-people.svg" alt="" />

        <div class="" style="margin: 35px">
          {{ modalText }}
        </div>

        <f7-button v-clipboard:copy="groupLink" v-clipboard:success="copySuccess" v-clipboard:error="copyFail" class="btn-red-text btn-red">{{
          btnText
        }}</f7-button>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    isCoverageStyle: {
      type: Boolean,
      default: false,
    },
    groupLink: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      modalTitle: "Invite People",
      modalClass: "",
      btnText: "Copy Group Link",
      modalText: "Invite people to this group using this link",
    };
  },
  mounted() {},
  methods: {
    copySuccess() {
      f7.sheet.close(".invite-people");
    },
    copyFail() {
      f7.sheet.close(".invite-people");
    },
  },
};
</script>

<style>
.policy-information-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-information-modal-header span {
  padding: 30px 0px 0px 25px;
  width: 75%;
}

.invite-people {
  align-content: center;
}

.close-modal-no-title {
  color: #9eaab1;
  position: fixed;
  /* left: 15px; */
  /* top: 15px; */
}

.invite-people.coverage .sheet-close {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0px 0px 25px;
  height: auto;
  position: absolute;
  left: 0;
  color: #9eaab1;
}

.invite-people.coverage .close-modal-no-title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left: unset;
  top: unset;
}

.policy-information-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-information-modal.delete img {
  width: 45%;
  height: auto;
}

.policy-information-modal.delete span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.04em;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header span {
  padding: 30px 0px 0px 25px;
  width: 75%;
}
</style>