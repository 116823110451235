 <template>
  <div v-if="loading" class="skeleton-effect-wave">
    <div></div>
    <div>
      <PolicyCardSkeleton
        :isCoverageStyle="true"
        v-for="index in 3"
        :key="index"
      />
    </div>
  </div>
  <div v-else>
    <CoverageBanners
      :status="coverageStatus"
      :isOwnerSelected="isOwnerSelected"
      :isQuizDone="isQuizDone"
      :relationshipSelected="
        memberSelected ? memberSelected.relationship : 'myself'
      "
    />

    <f7-block-title class="coverage-title"
      >WHAT YOU SHOULD COVER FIRST</f7-block-title
    >
    <f7-block>
      <f7-list media-list class="coverage-options">
        <f7-list-item
          v-for="coverage in covered"
          :key="coverage"
          link="#"
          @click="ShowDetails(coverage.code)"
          :title="coverage.name"
          :class="coverage.statusLabel"
          :subtitle="
            coverage.detail && coverage.detail.current > 0
              ? $filters.currency(coverage.detail.current ?? 0, 0)
              : coverage.detail && coverage.detail.current < 0
              ? 'Unlimited'
              : 'Not covered'
          "
        >
          <template #media>
            <div class="coverage-link">
              <f7-gauge
                type="circle"
                :value="
                  coverage.detail && coverage.detail.score > 0
                    ? coverage.detail.score / 100
                    : 1
                "
                :border-color="coverage.graphColor"
                border-width="4"
                :size="52"
              ></f7-gauge>
              <img :src="coverage.logo" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block-title class="coverage-title"
      >WHAT YOU SHOULD LOOK AT NEXT</f7-block-title
    >
    <f7-block>
      <f7-list media-list class="coverage-options">
        <f7-list-item
          v-for="coverage in nonCovered"
          :key="coverage"
          link="#"
          @click="ShowDetails(coverage.code)"
          :title="coverage.name"
          class="inactive"
          :class="coverage.statusLabel"
          :subtitle="
            coverage.detail && coverage.detail.current > 0
              ? $filters.currency(coverage.detail.current ?? 0, 0)
              : coverage.detail && coverage.detail.current < 0
              ? 'Unlimited'
              : 'Not covered'
          "
        >
          <template #media>
            <div class="coverage-link">
              <f7-gauge
                type="circle"
                :value="
                  coverage.detail && coverage.detail.score > 0
                    ? coverage.detail.score / 100
                    : 1
                "
                :border-color="coverage.graphColor"
                border-width="4"
                :size="52"
              ></f7-gauge>
              <img :src="coverage.logo" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block class="assistant">
      <f7-list media-list class="coverage-options">
        <f7-list-item
          @click="openHelp"
          link="#"
          :title="getSelectedInsurer"
          subtitle="Let me know if I can help :)"
        >
          <template #media>
            <div class="assistantAvatar online">
              <img src="@/assets/raw_teleadvisor.png" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block>
      <div class="footer-coverage">
        <!-- <h3>Insure with DBS</h3> -->
        <h3>Insure with us</h3>
        <span>Choose from our diverse range of insurance policies</span>
        <f7-button href="/plan-selection">VIEW RECOMMENDATIONS</f7-button>
      </div>
    </f7-block>
  </div>
</template>

<script>
import routes from "@/js/routes.js";
import CoverageBanners from "./coverage-banner.vue";
import PolicyCardSkeleton from "@/components/policy-card-skeleton.vue";
import { API } from "aws-amplify";

import {
  f7List,
  f7ListItem,
  f7Block,
  f7BlockTitle,
  f7Gauge,
  f7Button,
  f7,
} from "framework7-vue";
export default {
  props: { memberSelected: null },
  components: {
    f7List,
    f7ListItem,
    f7Block,
    f7BlockTitle,
    f7Gauge,
    f7Button,
    CoverageBanners,
    PolicyCardSkeleton,
  },
  data() {
    return {
      covered: [],
      nonCovered: [],
      loading: true,
      isQuizDone: false,
      isRetirementQuizDone: false,
      isOwnerSelected: false,
      portfolioHealth: 0,
      coverageStatus: "",
    };
  },
  methods: {
    ShowDetails(index) {
      f7.views.main.router.navigate(
        { name: "CoverageDetail" },
        {
          props: {
            selectedCoverageId: index,
            isRetirementQuizDone: this.isRetirementQuizDone,
            isQuizDone: this.isQuizDone,
          },
        }
      );
    },
    async getCoverageAnalysis() {
      // console.log("getCoverageAnalysis");
      var analysis = await API.get("coverageAnalysis", "/coverage-analysis", {})
        .then((result) => {
          // console.log('getCoverageAnalysis -> ', result);
          return result;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      return analysis;
    },
    openHelp() {
      window.open(
        "https://www.dbs.com.sg/personal/TeleAdvisory"
      );
    },

  },
  async mounted() {
    // console.log("mounted");
    let apiResponse = await this.getCoverageAnalysis();
    this.isQuizDone = false;
    this.isRetirementQuizDone = false;
    let storedCoverages = JSON.parse(localStorage.coverages);
    // console.log("mounted 1");
    this.$filters.processCoverageAnalysis(
      storedCoverages,
      apiResponse ? apiResponse.data : [],
      this.isOwnerSelected
    );
    // console.log("storedCoverages -> ", storedCoverages);

    this.nonCovered = storedCoverages.filter((c) => c.sectionId == "NEXT");

    this.covered = storedCoverages.filter((c) => c.sectionId == "CF");

    this.portfolioHealth = apiResponse
      ? apiResponse.portfolioHealth.score ?? 0
      : 0;

    if (!this.memberSelected) this.isOwnerSelected = true;

    this.coverageStatus = this.$filters.getCoverageStatus(
      this.portfolioHealth,
      this.isOwnerSelected
    );

    await API.get("questionnaires", "/questionnaires", {})
      .then((result) => {
        if (
          typeof result.data.totalSaving !== "undefined" &&
          typeof result.data.monthlyIncome !== "undefined" &&
          typeof result.data.monthlyExpense !== "undefined" &&
          typeof result.data.monthlySaving !== "undefined" &&
          typeof result.data.retireAtAge !== "undefined" &&
          typeof result.data.needMoneyUntilAge !== "undefined" &&
          typeof result.data.retirementLifeStyle !== "undefined" &&
          typeof result.data.monthlyRetirementExpense !== "undefined"
        ) {
          this.isRetirementQuizDone = true;
        } else {
          this.isRetirementQuizDone = false;
        }

        if (
          typeof result.data.totalSaving !== "undefined" &&
          typeof result.data.monthlyIncome !== "undefined" &&
          typeof result.data.monthlyExpense !== "undefined" &&
          typeof result.data.monthlySaving !== "undefined" &&
          typeof result.data.retireAtAge !== "undefined" &&
          typeof result.data.needMoneyUntilAge !== "undefined" &&
          typeof result.data.retirementLifeStyle !== "undefined" &&
          typeof result.data.monthlyRetirementExpense !== "undefined" &&
          typeof result.data.bodyHeight !== "undefined" &&
          typeof result.data.bodyWeight !== "undefined"
        ) {
          this.isQuizDone = true;
        } else {
          this.isQuizDone = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.isQuizDone = false;
        this.isRetirementQuizDone = false;
      });
    // console.log('this.isQuizDone -> ', this.isQuizDone);
    this.loading = false;
  },
  async updated() {
    // console.log("updated");
    //if (this.memberSelected) {
    if (
      this.memberSelected !== null &&
      typeof this.memberSelected.analysis !== "undefined"
    ) {
      let storedCoverages = JSON.parse(localStorage.coverages);
      this.isOwnerSelected = this.memberSelected.relationship == "myself";
      // if (this.memberSelected.analysis) {
      // console.log("updated 1, ", typeof this.memberSelected.analysis);
      // const analysisData = {};
      if (typeof this.memberSelected.analysis !== "undefined") {
        this.$filters.processCoverageAnalysis(
          storedCoverages,
          this.memberSelected.analysis.data,
          this.isOwnerSelected
        );
      } else {
        this.$filters.processCoverageAnalysis(
          storedCoverages,
          [],
          this.isOwnerSelected
        );
      }

      this.nonCovered = storedCoverages.filter((c) => c.sectionId == "NEXT");

      this.covered = storedCoverages.filter((c) => c.sectionId == "CF");

      this.portfolioHealth = this.memberSelected.analysis
        ? this.memberSelected.analysis.portfolioHealth.score ?? 0
        : 0;
      // }
      //console.log('this.covered -> ', this.covered);
      // this.coverageStatus = this.memberSelected.coverageStatus;
      this.coverageStatus = this.$filters.getCoverageStatus(
        this.portfolioHealth,
        this.isOwnerSelected
      );

      this.isOwnerSelected = this.memberSelected.relationship == "myself";
    } else {
      this.isOwnerSelected = true;
    }
  },
  computed: {
    getSelectedInsurer() {
      return "Hi " + localStorage.OwnerName + "!";
    },
  },
};
</script>
<style scoped>
.block {
  padding-left: 21px;
  padding-right: 19px;
  margin-bottom: 5px;
}

.block.assistant {
  padding-left: 21px;
  padding-right: 13px;
}
</style>