<template>
  <f7-page name="signup">
      <f7-block class="dbs-logo">
        <!-- <img src="@/assets/customIcons/custom-dbs-logo.svg" alt="" /> -->
      </f7-block>
      <img
        class="dbs-water-mark"
        src="@/assets/customIcons/custom-dbs-watermark.svg"
        alt=""
      />

      <f7-block class="screen-title"> {{ screenTitle }} </f7-block>

      <f7-block class="screen-sub-title"> {{ screenSubTitle }} </f7-block>

      <f7-swiper
        pagination
        @swiper="onSwiper"
        @realIndexChange="updatedSwiperIndex"
        class="swipper-container"
      >
        <f7-swiper-slide class="individual-slider">
          <img class="image-onboarding image-1" src="@/assets/dbs_onboarding_01@2x.png" alt="" />
        </f7-swiper-slide>
        <f7-swiper-slide class="individual-slider">
          <img class="image-onboarding image-2" src="@/assets/dbs_onboarding_02@2x.png" alt="" />
        </f7-swiper-slide>
        <f7-swiper-slide class="individual-slider">
          <img class="image-onboarding image-3" src="@/assets/dbs_onboarding_03@2x.png" alt="" />
        </f7-swiper-slide>
      </f7-swiper>

      <f7-block class="card-title"> {{ activeCardTitle }} </f7-block>
      <f7-block class="card-sub-title"> {{ activeCardSubTitle }} </f7-block>

      <f7-block @click="signUp" v-if="selectedIndex == 3" class="sign-up-footer">
        <f7-button class="need-analysis-btn-red">
          <div class="add-policy-btn-red-text">Sign up</div></f7-button
        > 
      </f7-block>
      <f7-block   v-else class="log-in-footer">
        {{ logInFooter }} <a @click="signUp" href=""> Log in </a>
      </f7-block>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Range,
  f7Tabs,
  f7SwiperSlide,
  f7Swiper,
  f7Tab,
  f7Link,
  f7Block,
  f7,
} from "framework7-vue";

import { Auth, API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Range,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    Auth,
    f7SwiperSlide,
    f7Swiper,
  },

  data() {
    return {
      // screenTitle: "Welcome to DBS ",
      screenTitle: "Welcome to ",
      screenSubTitle: "Insurance Family Vault",
      cardTitle1: "All your insurance policies in one place",
      cardSubTitle1:
        "Always know and have on hand your coverage details and your family members’ ",
      cardTitle2: "Personalised portfolio analysis",
      cardSubTitle2:
        "Data-backed recommendations on what you need as you move through different life stages",
      cardTitle3: "Upload your policy in a simple shutter click  ",
      cardSubTitle3: "Get ready your policy document! ",
      logInFooter: "Already have an account? ",
      // Get selected index
      swiper: null,
      selectedIndex: 0,

      activeCardTitle: "-",
      activeCardSubTitle: "-",
    };
  },
  methods: {
    onSwiper(swiper) {
      this.activeCardTitle = this.cardTitle1;
      this.activeCardSubTitle = this.cardSubTitle1;
    },
    updatedSwiperIndex(e) {
      if (e.realIndex == 0) {
        this.activeCardTitle = this.cardTitle1;
        this.activeCardSubTitle = this.cardSubTitle1;
        this.selectedIndex = 1;
      } else if (e.realIndex == 1) {
        this.activeCardTitle = this.cardTitle2;
        this.activeCardSubTitle = this.cardSubTitle2;
        this.selectedIndex = 2;
      } else {
        this.activeCardTitle = this.cardTitle3;
        this.activeCardSubTitle = this.cardSubTitle3;
        this.selectedIndex = 3;
      }
    },
    signUp(){
            f7.views.main.router.navigate({ name: "SigningProcess" });

    }
  },
};
</script>

<style scoped>
.dbs-logo {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 10px;
}

.dbs-water-mark {
  position: absolute;
  top: 5px;
  right: 5px;
}

.screen-title {
  font-size: 26px;
  line-height: 37px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;

  color: #07182b;
}

.screen-sub-title {
  font-weight: bold;
  font-size: 26px;
  line-height: 37px;
  text-align: center;

  color: #07182b;
  margin-top: 0%;
  margin-bottom: 0px;
}

.swipper-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.individual-slider {
  width: 100% !important;
  background-color: #f4f8fb;
  height: 280px;
}

.swiper-pagination-bullet-active {
  background: #ff3333;
}

.card-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;

  text-align: center;

  color: #07182b;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.card-sub-title {
  font-size: 17px;
  line-height: 23px;

  text-align: center;

  color: #07182b;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.log-in-footer {
  /* box position */
  position: absolute;
  margin-top: 30px;
  margin-bottom: 0px !important;
  width: 100%;
  /* font styling */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  color: #07182b;
  /* box styling */
  background-color: white;
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  bottom: 0px;
}

.sign-up-footer {
  /* box position */
  position: absolute;
  margin-top: 30px;
  margin-bottom: 0px !important;
  width: 100%;
  /* box styling */
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  bottom: 0px;
}

.image-onboarding{
  object-fit: scale-down;
  width           : 280px;
  height          : 280px;
  }

.image-1{
    object-position : 0px 5px;
}
/* .image-2{} */
.image-3{
      object-position : 0px 5px;

}
</style>