<template>
  <f7-page name="coverage-detail">
    <f7-navbar class="add-policy-progress-demo">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goTo('CoverageMain')"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title">Add Insured</f7-nav-title>
    </f7-navbar>

    <f7-page class="progress-FullPage">
      <CustomProgress
        :percentage="progressBar"
        :hidePercentage="true"
        :legend="legend"
        :error="uploadError"
      />
      <!-- Upload error -->

      <div
        style="
          position: fixed;
          bottom: 5%;
          display: block;
          align-content: center;
        "
      >
        <f7-row class="upload-insured-screen">
          <span> Ready to upload policy for insured? </span>
        </f7-row>

        <f7-row class="btns-block">
          <f7-button
            @click="goTo('CoverageMain')"
            class="add-insured-no-fill-btn"
          >
            No</f7-button
          >
          <f7-button
            style="margin-left: 5px; margin-right: 5px"
            @click="goTo('AddPolicy1')"
            class="add-insured-fill-btn"
          >
            Yes</f7-button
          >
        </f7-row>
      </div>

      <f7-sheet
        class="upload-error"
        style="
          height: auto;
          border-radius: 25px 25px 0px 0px;
          --f7-sheet-bg-color: #fff;
        "
        backdrop
      >
        <f7-page-content>
          <f7-block>
            <f7-row align="left" class="policy-information-close-modal">
              <f7-col>
                <!-- <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button> -->
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col align="center" class="modal-title">
                Unable to add Insured. Please try again.
              </f7-col>
            </f7-row>
          </f7-block>

          <f7-block class="policy-information-modal delete">
            <img src="@/assets/customIcons/custom-policy-summary.svg" alt="" />
            <span> You will be redirected in a few seconds.</span>
          </f7-block>
        </f7-page-content>
      </f7-sheet>
    </f7-page>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7Block,
  f7,
} from "framework7-vue";

import CustomProgress from "@/components/custom-progress.vue";
import { Storage, API } from "aws-amplify";

import { uuid } from "vue-uuid";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    CustomProgress,
    Storage,
    API,
  },
  props: {
    f7route: Object,
    f7router: Object,
    uploadedPhoto: Object,
    filename: String,
    uploaded: false,
  },
  data() {
    return {
      progressBar: 100,
      legend: "Successfully added!",
      attemps: 5,
      uuid_v4: null,
      uploadError: false,
    };
  },
  mounted() {
    //    this.uuid_v4 = uuid.v4();
    //    this.uploadFile();
  },
  methods: {
    goTo(screenName) {
      f7.views.main.router.navigate({ name: screenName });
    },
  },
};
</script>

<style >
.progress-FullPage,
.progress-FullPage > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  padding-left: 10%;
  padding-right: 10%;
}

.custom-progress-wrapper .legend {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #07182b;
  max-width: 212px;
  width: 190px !important;
  margin-top: 20px;
}

.policy-information-modal span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}

.add-insured-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  position: static;
  width: 49%;
  height: 43px;
  left: 0px;
  top: 4px;
  background: #5e6d78;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  text-transform: none;
  vertical-align: center;

  color: #ffffff;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}

.add-insured-no-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  position: static;
  width: 49%;
  height: 43px;
  left: 170px;
  top: 4px;
  background: #ffffff;
  border: 1px solid #c0cbd2;
  box-sizing: border-box;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  vertical-align: center;
  text-transform: none;
  color: #5e6d78;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}

.upload-insured-screen {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;

  /* identical to box height, or 153% */
  text-align: center;

  /* #07182B */
  color: #07182b;
}
</style>