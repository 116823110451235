<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title"
        >Endowment/Savings Plans</f7-nav-title
      >
      <f7-nav-right @click="openHelp">
        <div class="assistantAvatar online">
          <img src="@/assets/raw_teleadvisor.png" alt="" />
        </div>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="block-header">
      <small>Plans chosen for you</small>
    </f7-block>
    <div v-if="loading" class="skeleton-effect-wave">
      <PolicyCardSkeleton v-for="index in 3" :key="index" />
    </div>
    <div v-else-if="!loading">
      <f7-block>
        <PolicyCard v-for="policy in policies" :key="policy" :policy="policy" />
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { f7, f7Page, f7Block, f7Link } from "framework7-vue";
import PolicyCard from "@/components/purchase/policy-card";
import PolicyCardSkeleton from "@/components/policy-card-skeleton.vue";

import logo2 from "@/assets/logo_manulife.png";

export default {
  components: {
    f7,
    f7Page,
    f7Block,
    f7Link,
    PolicyCard,
    PolicyCardSkeleton,
  },
  data() {
    return {
      policies: [
        {
          insurerLogo: logo2,
          productName: "SavvyEndowment 5",
          yearTerms: "3",
          return: "2.47%",
          savingAmount: "$5,000",
        },
        {
          insurerLogo: logo2,
          productName: "Manulife Goal 9",
          yearTerms: "1",
          return: "1.1%",
          savingAmount: "$10,000",
          isDisabled: true,
        },
      ],
      loading: false,
    };
  },
  methods: {
    openHelp() {
      window.open("https://www.dbs.com.sg/personal/TeleAdvisory");
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
  },
};
</script>

<style>
.block-header {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.block-header a,
.block-header i {
  color: #929ba2;
  font-size: 20px;
}

.assistantAvatar {
  position: relative;
  display: flex;
  margin-right: 10px;
}

.assistantAvatar img {
  object-fit: contain;
  object-position: 10px 5px;
  width: 42px;
  height: 36.8px;
  border-radius: 50%;
  background-color: #ecf3f8;
}

.assistantAvatar::after {
  content: "";
  border-radius: 50%;
  width: 8.36px;
  height: 8.36px;
  top: -2px;
  right: -2px;
  background: #c0cbd2;
  position: absolute;
}

.assistantAvatar.online::after {
  background: #52b485;
}
</style>