<template>
  <f7-sheet
    class="a-retirement-tt coverage"
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      --f7-sheet-bg-color: #fff;
    "
    swipe-to-close
    backdrop
  >
    <f7-page-content>
      <!-- <f7-button sheet-close>
        <f7-icon class="close-modal-no-title" f7="xmark"></f7-icon>
      </f7-button> -->

      <div class="policy-information-modal-header">
        <!-- <f7-button icon-ios="f7:xmark" sheet-close> </f7-button> -->
        <span class="modal-title"></span>
      </div>

      <f7-block class="policy-information-modal">
        <img src="@/assets/customIcons/custom-star.svg" alt="" />

        <div class="" style="margin: 35px">
          After examining your current expenses, you might decide that you might
          need $1,500 per month during retirement. This figure would be adjusted
          for inflation, using the inflation rate predetermined based on the
          lifestyle that you select, so that it maintains its purchasing power
          over the years. 
          <br/>
          <br/>
          Alternatively, a less accurate way is to estimate
          based on a percentage of your salary (say, 70%). So if your monthly
          salary is $2,500, using the 70% estimation, your retirement needs
          would be $1,750 per month (in today’s dollars)
        </div>

        <f7-button @click="modalBtnClick" class="btn-red-text btn-red">{{
          btnText
        }}</f7-button>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    isCoverageStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalClass: "",
      btnText: "Close",
      modalText:
        "After examining your current expenses, you might decide that you might need $1,500 per month during retirement. This figure would be adjusted for inflation, using the inflation rate predetermined based on the lifestyle that you select, so that it maintains its purchasing power over the years.",
    };
  },
  mounted() {},
  methods: {
    modalBtnClick() {
      f7.sheet.close(".a-retirement-tt");
    },
  },
};
</script>

<style>
.policy-information-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-information-modal-header span {
  padding: 30px 0px 0px 25px;
  width: 75%;
}

.a-retirement-tt {
  align-content: center;
}

.close-modal-no-title {
  color: #9eaab1;
  position: fixed;
  /* left: 15px; */
  /* top: 15px; */
}

.a-retirement-tt.coverage .sheet-close {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0px 0px 25px;
  height: auto;
  position: absolute;
  left: 0;
  color: #9eaab1;
}

.a-retirement-tt.coverage .close-modal-no-title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left: unset;
  top: unset;
}

.policy-information-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-information-modal.delete img {
  width: 45%;
  height: auto;
}

.policy-information-modal.delete span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.04em;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>