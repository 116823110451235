<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        sharingGroup.title
      }}</f7-nav-title>

      <f7-nav-right>
        <f7-link
          @click="goToGroupDetail"
          style="color: rgb(54, 119, 196); padding-right: 10px"
          href=""
        >
          Manage
          <!-- <img  src="@/assets/customIcons/custom-nav-tooltip.svg" alt="" /> -->
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-row
      no-gap
      style="
        margin-bottom: 16px;
        margin-top: 20px;
        display: flex;
        align-items: center;
      "
    >
      <f7-col class="list-title-block" width="50" align="left">
        SHARED POLICIES</f7-col
      >
      <f7-col style="padding-right: 15px" width="50" align="right">
        <f7-button
          v-if="policies.length"
          class="add-policy-btn icon"
          style="max-width: 165px"
          href="/add-policy-1"
        >
          Add from vault
        </f7-button>
      </f7-col>
    </f7-row>

    <div v-if="!policies.length">
      <f7-row class="legend-row"> Share a policy to this group </f7-row>
      <f7-link
        class="add-policy-btn gray share"
        style="max-width: 165px"
        @click="goHome"
      >
        Share
      </f7-link>
      <img
        class="image-page"
        src="@/assets/customIcons/custom-page-arrow.svg"
        alt=""
      />

      <!-- <div
          style="
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          width: 90%;
          heigh: 1px;
          opacity: 0.5;
          margin-left: 5%;
        "
      ></div> -->
    </div>

    <SharedPolicyCard
      v-for="policy in policies"
      :key="policy"
      :policy="policy.detail"
      :insured="policy.detail.insured"
      @goPortfolio="goPortfolio"
      @stopSharing="deletePolicyFromGroup(policy.groupId, policy.detail.id)"
    />

    <SelectPortfolio />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";
import SharedPolicyCard from "@/components/policy-card-shared.vue";
import SelectPortfolio from "@/components/modals/select-portfolio.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
    SharedPolicyCard,
    SelectPortfolio,
  },
  props: {
    f7route: Object,
  },
  data() {
    return {
      loading: false,
      sharingGroup: {},
      policies: [],
    };
  },
  methods: {
    goHome() {
      localStorage.showPolicyTab = true;
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goPortfolio() {
      f7.sheet.open(".select-portfolio");
    },
    goToGroupDetail() {
      f7.views.main.router.navigate({
        name: "SharedPeople",
        params: { groupId: this.sharingGroup.id },
      });
    },
    async getPolicies(groupId) {
      f7.dialog.preloader("Loading...");
      API.get("sharingGroups", "/sharing-groups/" + groupId + "/policies")
        .then((result) => {
          this.policies = result.data;
          f7.dialog.close();
        })
        .catch((err) => {
          f7.dialog.close();
          console.log(err);
        });
    },
    async getSharingGroup(groupId) {
      API.get("sharingGroups", "/sharing-groups/" + groupId)
        .then((result) => {
          this.sharingGroup = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deletePolicyFromGroup(groupId, policyId) {
      f7.dialog.preloader("Loading...");
      API.del(
        "sharingGroups",
        "/sharing-groups/" + groupId + "/policies/" + policyId
      )
        .then((result) => {
          f7.dialog.close();
          this.getPolicies(groupId);
        })
        .catch((err) => {
          f7.dialog.close();
          console.log(err);
        });
    },
  },

  async mounted() {
    console.log("mounted");
    this.getSharingGroup(this.f7route.params.groupId);
    this.getPolicies(this.f7route.params.groupId);
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.list-title-block {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  padding-left: 15px;
}

.policy-card {
  margin-left: 16px;
  margin-right: 16px;
}

.legend-row {
  width: 135px;
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #1a2531;
}

.btn-share {
  background: #5e6d78;
  border-radius: 34px;
  width: 94px;
  height: 31px;
  margin-left: 50px;
  margin-bottom: 30px;
}

.btn-share-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 10px;
}

.image-page {
  position: absolute;
  right: 15px;
  top: 125px;
}
</style>
