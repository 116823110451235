<template>
  <f7-page>
    <f7-navbar
      v-if="settingEdit"
      class="add-policy-nav-bar-shadow"
      back-link=""
    >
      <f7-nav-title class="add-policy-navbar-title">{{
        navBarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-navbar v-else class="nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>

      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-toolbar
      @click="goPrevStep()"
      class="toolbar-aligment"
      :position="'top'"
      v-if="shortQuestionare"
    >
      <f7-link
        class="link back"
        href="#"
        style="padding-left: 5%; position: absolute; left: 10px"
      >
        <img src="@/assets/customIcons/custom-up-btn.svg" alt="" />
      </f7-link>

      <div class="" id="NavBarID">
        {{ previusStep }}
      </div>
    </f7-toolbar>

    <f7-block v-if="!settingEdit" class="need-analysis-step">
      {{ screenStep }}
    </f7-block>

    <f7-block v-if="!settingEdit" class="add-policy-title">
      {{ screenTitle }}
    </f7-block>
     

    <f7-block
      class="need-analysis-saving-inset"
      :class="{ 'skeleton-effect-wave': loading }"
    >
      <f7-list
        media-list
        inset
        class="saving-habits-tab"
        :class="invalidTotalSavings ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('total-saving')">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Total Savings </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row no-gap v-else>
            <input
              v-model="totalSavingInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('total-saving')"
              @change="inputvalidator('total-saving')"
              class="saving-input"
              placeholder="S$ 1,000"
              inputmode="decimal"
            />
            <span class="input-error-message">Please, verify the input value!</span>
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        media-list
        inset
        class="saving-habits-tab"
        :class="invalidMonthlyIncome ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('month-income')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Monthly Income </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row no-gap v-else>
            <input
              v-model="monthIncomeInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('month-income')"
              @change="inputvalidator('month-income')"
              class="saving-input"
              placeholder="S$ 1,000"
              inputmode="decimal"
            />
            <span class="input-error-message">Please, verify the input value!</span>
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        media-list
        inset
        class="saving-habits-tab"
        :class="
          invalidMonthlyExpenses ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'
        "
      >
        <f7-list-item @click="focusText('month-expenses')">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Monthly Expenses </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row no-gap v-else>
            <input
              v-model="monthExpensesInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('month-expenses')"
              @change="inputvalidator('month-expenses')"
              class="saving-input"
              placeholder="S$ 1,000"
              inputmode="decimal"
            />
            <span class="input-error-message">Please, verify the input value!</span>
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        media-list
        inset
        class="saving-habits-tab"
        :class="invalidMonthlySavings ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('month-saving')">
          <f7-row

            class="need-analysis-saving-habit-insets"
          >
            <span> Monthly Savings </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row no-gap v-else>
            <input
              v-model="monthSavingInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('month-saving')"
              @change="inputvalidator('month-saving')"
              class="saving-input"
              placeholder="S$ 1,000"
              inputmode="decimal"
            />
            <span class="input-error-message">Please, verify the input value!</span>
          </f7-row>
        </f7-list-item>
      </f7-list>

      <div v-if="shortQuestionare">
        <f7-button
          @click="submitForm(validForm)"
          style="margin-top: 20%"
          class="need-analysis-btn-red"
        >
          <div class="add-policy-btn-red-text">Analyse</div>
        </f7-button>

        <f7-button
          @click="goHome"
          style="margin-top: 10px"
          class="need-analysis-btn-white"
        >
          <div class="">Skip</div>
        </f7-button>
      </div>
      <div v-else>
        <f7-button
          v-if="settingEdit"
          @click="submitForm(validForm)"
          style="margin-top: 10%"
          :disabled="!validForm"
          :class="[
            validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
          ]"
        >
          <div class="analysis-gray-btn-text">Save</div>
        </f7-button>

        <f7-button
          v-else
          @click="submitForm(validForm)"
          style="margin-top: 20%"
          :disabled="!validForm"
          :class="[
            validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
          ]"
        >
          <div class="analysis-gray-btn-text">Next</div>
        </f7-button>
      </div>
    </f7-block>
  </f7-page>
</template>
<script>
import { f7Page, f7Navbar, f7Button, f7Block, f7 } from "framework7-vue";
import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7Button,
    f7Block,
  },
  props: {
    shortQuestionare: false,
    settingEdit: false,
    navBarTitle: "",
  },
  data() {
    return {
      screenStep: "STEP 1/4",
      policyOwner: "-",
      screenTitle: "My Saving Habits",
      screenPolicy:
        "We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.",
      previusStep: "-",

      // Input Fields
      totalSavingInput: "",
      monthIncomeInput: "",
      monthExpensesInput: "",
      monthSavingInput: "",

      // variables to Add color to the boxes
      invalidTotalSavings: false,
      invalidMonthlyIncome: false,
      invalidMonthlyExpenses: false,
      invalidMonthlySavings: false,
      // Full Form variable
      validForm: false,
      loading: true,
      questionner: {},
      /////
      inputMask: 0,
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goPrevStep() {
      f7.views.main.router.back();
    },
    async submitForm(validForm) {
      f7.dialog.preloader("Processing...");
      // href="/retirement-needs"
      this.questionner.totalSaving = parseInt(
        this.totalSavingInput.toString().replace(/,|[S$ ]/g, "")
      );
      this.questionner.monthlyIncome = parseInt(
        this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
      );
      this.questionner.monthlyExpense = parseInt(
        this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "")
      );
      this.questionner.monthlySaving = parseInt(
        this.monthSavingInput.toString().replace(/,|[S$ ]/g, "")
      );
      // retireAtAge: 0,
      // needMoneyUntilAge: 0,
      // retirementLifeStyle: "modest",
      // monthlyRetirementExpense: 0,
      // bodyHeight: 0,
      // bodyWeight: 0,
      // smoker: true,
      // healthInsuranceConcerns: "string",

      await API.put("questionnaires", "/questionnaires", {
        body: this.questionner,
      })
        .then((result) => {
          // console.log("questionnaires -> ", result);
          f7.dialog.close();
          if (this.shortQuestionare) {
            f7.views.main.router.navigate(
              { name: "CoverageDetail" },
              {
                props: {
                  selectedCoverageId: null,
                  isQuizDone: true,
                  openRetirementAnalysis: true,
                },
              }
            );
          } else if (this.settingEdit) {
            // From Settings Edit Mode
            f7.views.main.router.navigate(
              { name: "settings" },
              {
                props: {},
              }
            );
          } else {
            f7.views.main.router.navigate(
              { name: "RetirementNeeds" },
              {
                props: {
                  questionnerParam: this.questionner,
                },
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          f7.dialog.close();
        });
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    focusText(tab1) {
      // console.log("option selected --->");
      // console.log(tab1);
    },
    inputvalidator(selectedBox) {
      var regExp = /[a-zA-Z]/g;

      switch (selectedBox) {
        case "total-saving":
          let valueS = this.totalSavingInput.replace(/,|[S$ ]/g, "");
          if (regExp.test(valueS) || parseFloat(valueS) < 0) {
            this.invalidTotalSavings = true;
          } else {
            this.invalidTotalSavings = false;
          }
          break;
        case "month-income":
          let valueI = this.monthIncomeInput.replace(/,|[S$ ]/g, "");
          if (regExp.test(valueI) || parseFloat(valueI) < 0) {
            this.invalidMonthlyIncome = true;
          } else {
            this.invalidMonthlyIncome = false;
            //
            // console.log(this.monthIncomeInput.toString().replace(/,/g, ""));
            // console.log(this.monthExpensesInput.toString().replace(/,/g, ""));
            this.monthSavingInput =
              parseFloat(
                this.monthIncomeInput != ""
                  ? this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
                  : 0
              ) -
              parseFloat(
                this.monthExpensesInput != ""
                  ? this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "")
                  : 0
              );
              this.invalidMonthlySavings = this.monthSavingInput < 0;
          }
          break;
        case "month-expenses":
          let valueME = this.monthExpensesInput.replace(/,|[S$ ]/g, "");
          if (regExp.test(valueME) || parseFloat(valueME) < 0) {
            this.invalidMonthlyExpenses = true;
          } else {
            this.invalidMonthlyExpenses = false;
            //
            this.monthSavingInput =
              parseFloat(
                this.monthIncomeInput != ""
                  ? this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")
                  : 0
              ) -
              parseFloat(
                this.monthExpensesInput != ""
                  ? this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "")
                  : 0
              );

            this.invalidMonthlySavings = this.monthSavingInput < 0;
          }
          break;
        case "month-saving":
          let valueMS = this.monthSavingInput.replace(/,|[S$ ]/g, "");

          if (regExp.test(valueMS) || parseFloat(valueMS) < 0) {
            this.invalidMonthlySavings = true;
          } else {
            this.invalidMonthlySavings = false;
          }
          break;
      }

      if (
        parseInt(this.totalSavingInput.toString().replace(/,|[S$ ]/g, "")) >=
          0 &&
        parseInt(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")) >=
          0 &&
        parseInt(this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "")) >=
          0 &&
        parseInt(this.monthSavingInput.toString().replace(/,|[S$ ]/g, "")) >= 0
      ) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }

      // console.log(this.validForm);
    },
  },
  async mounted() {
    this.loading = true;
    // Comes from Secondary Landing Page
    if (this.shortQuestionare) {
      this.inputvalidator();
      this.screenStep = "STEP 2/2";
      this.previusStep = "My Retirement Needs";

      // this.loading = false;
    } else if (this.settingEdit) {
      // From Settings Edit Mode
      // console.log("Setting Edit Mode");
      // this.loading = false;
    } else {
    }

    await API.get("questionnaires", "/questionnaires", {})
      .then((result) => {
        // console.log("questionnaires -> ", result);
        this.totalSavingInput = result.data.totalSaving
          ? parseInt(result.data.totalSaving)
          : "";
        this.monthIncomeInput = result.data.monthlyIncome
          ? parseInt(result.data.monthlyIncome)
          : "";
        this.monthExpensesInput = result.data.monthlyExpense
          ? parseInt(result.data.monthlyExpense)
          : "";
        this.monthSavingInput = result.data.monthlySaving
          ? parseInt(result.data.monthlySaving)
          : "";
        this.questionner = result.data;
        this.inputvalidator();
      })
      .catch((err) => {
        console.log(err);
      });
    this.loading = false;
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow::before,
.add-policy-nav-bar-shadow::after {
  display: none;
}

.add-policy-user-columns {
  margin-top: 0px;
}

.policyTerms {
  color: #07182b;

  opacity: 0.22;

  font-weight: 600;
  font-size: 11px;
  line-height: 17px;

  text-align: center;

  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
}

.close-modal {
  color: #9eaab1;

  position: fixed;
  left: 15px;
  top: 15px;
}
.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.other-relative-question-label {
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
  margin-bottom: 20px;
  color: #8e8e93;
}

.other-relative-question-input {
  margin-left: 15px;
  margin-right: 35px;

  border-bottom: 1px solid #e1e7eb;
}

.add-policy-other-modal {
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;
  border-radius: 30px;
}

.box-dolar-sign {
  padding-top: 14px;
  font-size: 13px;
  line-height: 18px;

  /* identical to box height */
  color: #929ba2;
}
</style>


