<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">
        {{ navbarTitle }}</f7-nav-title
      >
    </f7-navbar>

    <f7-block class="add-policy-step">
      {{ screenStep }}
    </f7-block>

    <f7-block v-if="addMember" class="add-policy-title">
      Choose Relationship
    </f7-block>

    <f7-block v-else class="add-policy-title">
      Who is {{ policy.insuredNamePrediction ?? "the insured" }}?
    </f7-block>
     

    <f7-block class="add-policy-user-columns">
      <div :key="pUser.id" v-for="pUser in policyUser">
        <f7-button
          style="margin-bottom: 15px"
          class="add-policy-btn-white"
          v-if="pUser.visible"
          :disabled="processing"
          @click="SelectProfile(pUser.id)"
        >
          <div class="add-policy-btn-white-text">{{ pUser.user }}</div>
        </f7-button>
      </div>

      <f7-button
        v-if="showBtn"
        style="margin-bottom: 15px"
        class="btn-transparent-red"
      >
        <div @click="otherChild" class="btn-transparent-red-text">
          Other Child
        </div>
      </f7-button>
      <!-- 20210621: Out of scope  -->
      <!-- <f7-button
        style="margin-bottom: 15px"
        class="btn-transparent-red"
        sheet-open=".other-relative"
      >
        <div class="btn-transparent-red-text">Other - Please specify</div>
      </f7-button> -->
    </f7-block>

    <div class="policyTerms">{{ screenPolicy }}</div>

    <!-- other-relative -->
    <f7-sheet
      class="other-relative"
      style="
        height: auto;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon class="close-modal" f7="xmark"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title"> Others </f7-col>
          </f7-row>
        </f7-block>

        <div class="other-relative-question-label">How are you related?</div>

        <f7-input
          type="text"
          v-bind:value="otherRelationship"
          v-on:input="otherRelationship = $event.target.value"
          class="other-relative-question-input"
          placeholder="e.g. Mother-in-law"
        ></f7-input>

        <f7-block align="center" class="policy-information-modal">
          <f7-button
            sheet-close
            @click="customRelationship"
            class="btn-red-text btn-red"
            >Save & Continue
          </f7-button>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>
<script>
import { f7Page, f7Navbar, f7Button, f7Block, f7 } from "framework7-vue";
import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7Button,
    f7Block,
  },

  props: {
    policy: {
      default: {},
    },
    addMember: null,
  },
  data() {
    return {
      navbarTitle: "Add Policy",
      screenStep: "Almost there!",
      policyOwner: "Marvin",
      screenTitle: "Uploading the policy of",
      screenPolicy:
        "We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.",
      policyUser: [
        { id: "myself", user: "Myself", visible: true },
        { id: "spouse", user: "My spouse", visible: true },
        { id: "mother", user: "My mother", visible: true },
        { id: "father", user: "My father", visible: true },
        { id: "child-1", user: "First child", visible: true },
        { id: "child-2", user: "Second child", visible: true },
        { id: "child-3", user: "Third child", visible: false },
        { id: "child-4", user: "Fourth child", visible: false },
      ],
      otherRelationship: "",
      isFirstProfile: true,
      profiles: [],
      processing: false,
      showBtn: true, // Other Child Btn
    };
  },
  methods: {
    otherChild() {
      this.policyUser[6].visible = true;
      this.policyUser[7].visible = true;
      this.showBtn = !this.showBtn;
      // console.log("visible changed");
    },
    customRelationship() {
      this.SelectProfile(this.otherRelationship);
    },
    async SelectProfile(relationship) {
      // Add [Insured] from Home Screen
      if (this.addMember) {
        console.log(relationship);
        let existingProfile = this.profiles.filter(function (r) {
          return r.relationship == relationship;
        });

        let _insuredId = "";
        if (existingProfile.length > 0) {
          _insuredId = existingProfile[0].id;
        }

        let relationshipLabel =
          this.$filters.getRelationshipLabel(relationship).label;
        f7.views.main.router.navigate(
          { name: "InsuredDetails" },
          {
            props: {
              addMember: true,
              insuredId: _insuredId,
              relationshipLabel: relationshipLabel,
              defaultRelationship: relationship,
            },
          }
        );
      }
      // Regular flow
      else {
        this.processing = true;
        // console.log("SelectProfile");

        let existingProfile = this.profiles.filter(function (r) {
          return r.relationship == relationship;
        });

        if (existingProfile.length > 0) {
          ///TODO: update insured
          let path = "/policies/" + this.policy.id;
          var policyRequest = this.policy;

          policyRequest.insuredId = existingProfile[0].id;
          if (!policyRequest.productCode) policyRequest.productCode = "-";
          console.log("policyRequest 2 ->", policyRequest);
          var policyResponse = await API.patch("policies", path, {
            body: policyRequest,
          })
            .then((result) => {
              // console.log("policy updated -> ", result);
              return result;
            })
            .catch((err) => {
              console.log("policy updated error -> ", err);
            });

          f7.views.main.router.navigate(
            { name: "PolicyInformation" },
            {
              props: {
                policyid: this.policy.id,
                isNewPolicy: true,
              },
            }
          );
        } else {
          /// Create new insured
          // console.log("Creating a new profile");

          var newInsured = {
            relationship: relationship,
            firstName: this.policy.insuredFirstName ?? "-",
            lastName: this.policy.insuredLastName ?? "-",
          };
          if (newInsured.relationship == "mother") newInsured.gender = "female";

          if (typeof this.policy.insuredYearOfBirth !== "undefined") {
            newInsured.yearOfBirth = this.policy.insuredYearOfBirth;
          }

          var newInsuredResponse = await API.post("insureds", "/insureds", {
            body: newInsured,
          })
            .then((result) => {
              return result;
            })
            .catch((err) => {
              console.log(err);
            });

          // console.log(newInsuredResponse);
          // console.log("Updating policy");
          if (newInsuredResponse) {
            if (newInsuredResponse.statusCode == 201) {
              let path = "/policies/" + this.policy.id;
              var policyRequest = this.policy;

              // delete policyRequest.id;

              policyRequest.insuredId = newInsuredResponse.data.id;
              if (!policyRequest.productCode) policyRequest.productCode = "-";
              console.log("policyRequest 1 ->", policyRequest);

              var policyResponse = await API.patch("policies", path, {
                body: policyRequest,
              })
                .then((result) => {
                  return result;
                })
                .catch((err) => {
                  console.log(err);
                });

              f7.views.main.router.navigate(
                { name: "PolicyInformation" },
                {
                  props: {
                    policyid: this.policy.id,
                    isNewPolicy: true,
                  },
                }
              );
            }
          }
          console.log("POST api call");
        }
      }
    },
  },
  async mounted() {
    // Add [Insured] from Home Screen
    if (this.addMember) {
      this.screenStep = "Step 1/2";
      this.screenTitle = "Choose Relationship";
      this.navbarTitle = "Add Insured";
      this.policyUser[0].visible = false;
      if (insuredsResponse) {
      }
    } else {
      console.log(this.policy);
    }

    let insuredsResponse = await API.get("insureds", "/insureds", {})
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => {
        console.log(err);
      });

    if (insuredsResponse) {
      console.log(insuredsResponse.data.length);
      if (insuredsResponse.data.length == 0) this.isFirstProfile = true;
      else this.isFirstProfile = false;
      this.profiles = insuredsResponse.data;
    }
  },
  computed: {
    activeUsers: function () {
      return this.policyUser.filter(function (user) {
        if (user.visible) {
          return user;
        }
      });
    },
  },
};
</script>

<style scoped>
.add-policy-btn-white-text {
  text-transform: capitalize;
}
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.policyTerms {
  color: #07182b;

  opacity: 0.22;

  font-weight: 600;
  font-size: 11px;
  line-height: 17px;

  text-align: center;

  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
}

.close-modal {
  color: #9eaab1;

  position: fixed;
  left: 15px;
  top: 15px;
}
.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.other-relative-question-label {
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
  margin-bottom: 20px;
  color: #8e8e93;
}

.other-relative-question-input {
  margin-left: 15px;
  margin-right: 35px;

  border-bottom: 1px solid #e1e7eb;
}

.add-policy-other-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}
</style>


