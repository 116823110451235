<template>
  <f7-page class="page-suw">
    <div class="page-header">
      <f7-link
        icon-ios="f7:chevron_left"
        @click="goBack()"
        style="color: #9eaab1"
        class="btn-back"
      ></f7-link>
      <!-- <span>DBS Insurance Family Vault</span> -->
      <span>Insurance Family Vault</span>

      <f7-button @click="goToWelcome()" class="btn-close" icon-ios="f7:xmark">
      </f7-button>
    </div>
    <div class="page-subtitle">
      <span>We just sent you an SMS</span>
      <small
        >To log in, enter the 6-digit security code we sent to:
        {{ userPhoneNumber }}</small
      >
    </div>
    <div class="CodeVerificationWrapper">
      <!-- <input
        class="CodeVerificationDigit"
        :ref="'CodeDigit' + index"
        v-for="index in codeLength"
        :key="index"
        type="number"
        :maxlength="1"
        v-bind:value="digits[index]"
        v-on:input="digits[index] = $event.target.value.slice(-1)"
        @keydown="validateInput($event, index)"
        @keyup="validatedInput($event, index)"
        @paste="onPaste"
      /> -->

      <input
        class="CodeVerificationDigit"
        :ref="'CodeDigit'"
        inputmode="numeric"
        type="text"
        :maxlength="codeLength"
        v-bind:value="digits"
        v-on:input="digits = $event.target.value"
        @keydown="validateInput($event)"
        @keyup="validatedInput($event)"
        autocomplete="one-time-code"
        required
      />
    </div>
    <div class="btn-wapper">
      <f7-link @click="resendConfirmationCode"
        >Didn't receive the verification code?</f7-link
      >
      <f7-button
        class="btn-suw"
        :class="{ disabled: isCodeCompleted }"
        @click="submitForm"
      >
        Next
      </f7-button>
    </div>
  </f7-page>
</template>



<script>
import { f7Page, f7Input, f7Button, f7Icon, f7 } from "framework7-vue";
import { Auth } from "aws-amplify";
export default {
  props: {
    username: null,
    password: null,
    phoneNumber: null,
    isLogin: false,
    loggedUser: null,
  },
  data() {
    return {
      codeLength: 6,
      digits: "",
      isCodeCompleted: true,
      userPhoneNumber: "+***********",
      _loggedUser: null,
    };
  },
  async mounted() {
    if (this.isLogin) {
      // loggedUser
      this._loggedUser = this.loggedUser;
      if (this.loggedUser.challengeName == "SMS_MFA")
        this.userPhoneNumber =
          this.loggedUser.challengeParam.CODE_DELIVERY_DESTINATION;

      console.log(this.loggedUser);
    } else {
      // phoneNumber
      this.userPhoneNumber =
        "+*******" + this.phoneNumber.substring(this.phoneNumber.length - 4);
    }

    this.$refs["CodeDigit"].focus();

    if ("OTPCredential" in window) {
      const ac = new AbortController();
      // const otp = await navigator.credentials.get({
      //   otp: { transport: ["sms"] },
      // });
      const input = document.querySelector(
        'input[autocomplete="one-time-code"]'
      );
      console.log(input);
      const otp = await navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          console.log(otp);
          // alert(otp);
          input.value = otp.code;
          // this.$refs["CodeDigit"].value = otp.code;
          // alert(otp.code);
        })
        .catch((err) => {
          // alert(err);
          console.log(err);
          this.digits = err;
        });
    } else {
      console.log("WebOTP is not supported!");
    }
  },
  methods: {
    async getValidationCode() {
      if ("OTPCredential" in window) {
        const ac = new AbortController();
        const input = document.querySelector(
          'input[autocomplete="one-time-code"]'
        );
        console.log(input);
        const otp = await navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            console.log(otp);
            input.value = otp.code;
          })
          .catch((err) => {
            console.log(err);
            this.digits = err;
          });
      } else {
        console.log("WebOTP is not supported!");
      }
    },
    validatedInput(e, index) {

      this.isCodeCompleted = this.digits.length != this.codeLength;
    },
    validateInput(e, index) {
      var key = e.keyCode;
      if (
        key === 8 ||
        key === 9 ||
        key === 35 ||
        key === 36 ||
        key === 37 ||
        key === 39 ||
        key === 46 ||
        (key >= 48 && key <= 57)
      ) {
        return true;
      }
      e.preventDefault();
      e.stopPropagation();
      // this.digits[index] = "";
      return false;
    },
    async submitForm() {
      console.log("submitForm");

      try {
        f7.dialog.preloader("Loading...");
        if (this.isLogin) {
          const usr = await Auth.confirmSignIn(
            this._loggedUser, // Return object from Auth.signIn()
            this.digits, // Confirmation code
            "SMS_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );

          f7.views.main.router.navigate(
            { name: "CoverageMain" },
            {
              props: {},
            }
          );
          f7.dialog.close();
        } else {
          await Auth.confirmSignUp(this.username, this.digits);
          try {
            const user = await Auth.signIn(this.username, this.password);
            Auth.setPreferredMFA(user, "SMS");
            f7.views.main.router.navigate(
              { name: "ProfileCreation" },
              {
                props: {
                  username: this.username,
                  signinPhoneNumber: this.phoneNumber,
                },
              }
            );
            f7.dialog.close();
            console.log(user);
          } catch (error) {
            f7.dialog.close();
            f7.dialog.alert(error.message, () => {
              f7.loginScreen.close();
            });
            console.log("error confirming sign up code", error);
          }
        }
      } catch (error) {
        f7.dialog.close();
        f7.dialog.alert(error.message, () => {
          f7.loginScreen.close();
        });
        console.log("error confirming sign up code", error);
      }
    },
    async resendConfirmationCode() {
      try {
        if (this.isLogin) {
          console.log(this._loggedUser);
          this._loggedUser = await Auth.signIn(
            this.username.toLowerCase(),
            this.password
          );
          console.log(this._loggedUser);
        } else {
          await Auth.resendSignUp(this.username);
        }
        this.getValidationCode();

        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    },
    goBack() {
      f7.views.main.router.back();
    },
    goToWelcome() {
      f7.views.main.router.navigate(
        { name: "SignUp" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style>
.CodeVerificationWrapper input {
  padding: 0 10px;
}
</style>

<style scoped>
.CodeVerificationWrapper {
  display: flex;
  flex-direction: row;
  padding: 0px 37px 0px 41px;
  justify-content: center;
}

.CodeVerificationWrapper .CodeVerificationDigit {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 26px;
  color: #07182b;
  width: 12ch;
  letter-spacing: 1ch;
  background: repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey 1ch,
      transparent 0,
      transparent 2ch
    )
    0 100%/100% 2px no-repeat;
  padding: 0px;
}
</style>