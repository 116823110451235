<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow">
      <f7-nav-left> </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title"
        >Purchase successful</f7-nav-title
      >
      <f7-nav-right>
        <f7-button @click="doneBtn" class="custom-cancel">Done</f7-button>
      </f7-nav-right>
    </f7-navbar>

    <div class="puchase-FullPage">
      <div></div>
      <CustomProgress
        :percentage="progressBar"
        :legend="legend"
        :message="message"
        hidePercentage="true"
      />
      <f7-block align="center" class="view-policy-btn">
        <f7-button class="need-analysis-btn-gray" @click="doneBtn">
          <div class="add-policy-btn-red-text">View Policy</div></f7-button
        >
      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import { f7, f7Page, f7Block, f7Link } from "framework7-vue";

import CustomProgress from "@/components/custom-progress.vue";
// import purchase from "@/assets/success-purchase-bg.svg";
// success-purchase-bg

export default {
  components: {
    f7,
    f7Page,
    f7Block,
    f7Link,
    CustomProgress,
  },
  data() {
    return {
      progressBar: 100,
      legend: "Great job growing your wealth!",
      uploadError: false,
      message:
        "Your new policy has been added into your vault for your easy retrieval and your goals have been updated.",
    };
  },
  methods: {
    doneBtn() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
  },
};
</script>

<style scoped>
.puchase-FullPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.puchase-FullPage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 95%;
  height: 100%;
  background-image: url("../../assets/success-purchase-bg.svg");
}
</style>

<style>
.block-header {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

/* .view-policy-btn {
} */

.block-header a,
.block-header i {
  color: #929ba2;
  font-size: 20px;
}
</style>