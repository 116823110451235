<template>
  <f7-page name="coverage-detail">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Coverage Analysis</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-if="isQuizDone"
          icon-ios="f7:slider_horizontal_3"
          icon-aurora="f7:slider_horizontal_3"
          icon-md="f7:slider_horizontal_3"
          href="#"
          @click="goSettings()"
        ></f7-link>
        <f7-link v-else class="twinkling-icon" href="/landing-need"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <InsurerSelector
      :no_bg="true"
      @updateSelectedInsured="updateSelectedInsured"
    />

    <AnalysisBannerS
      :coverages="coverages"
      :selectedCoverageId="selectedCoverageId"
      :isOwnerSelected="getIsOwnerSelected"
      @updateCoverageSelected="updateCoverageSelected"
      @showMoreDetails="showMoreDetails"
    >
    </AnalysisBannerS>

    <div
      class="no-policies"
      v-if="
        policiesSelected && policiesSelected.length == 0 && !loadingPolicies
      "
    >
      <f7-block class="coverage-banner update-policy">
        <h3>Already covered?</h3>
        <f7-link href="/add-policy-1">Upload policy</f7-link>
      </f7-block>
      <div v-if="getIsOwnerSelected">
        <div class="section-header">
          <small>SUGGESTED FOR YOU</small>
        </div>
        <f7-block class="policy-container">
          <PolicyCard
            :policy="policySuggested"
            :isCoverageStyle="true"
            :isSuggested="true"
            currentCoverageStatus="warn"
          />
        </f7-block>
      </div>
    </div>

    <div v-else class="analysis-policies-wrapper">
      <div>
        <div v-if="!loadingPolicies" class="section-header">
          <small>WHAT YOU OWN</small>
          <f7-link style="padding-right: 10px" href="/add-policy-1">
            <img src="@/assets/customIcons/custom-red-plus.svg" alt="" />
          </f7-link>
        </div>
        <!-- policy cards -->
        <div v-if="loadingPolicies" class="skeleton-effect-wave">
          <PolicyCardSkeleton
            v-for="index in 1"
            :key="index"
            :coverageStyle="true"
          />
        </div>
        <f7-block v-else class="policy-container">
          <PolicyCard
            v-for="policy in policiesSelected"
            :key="policy"
            :policy="policy"
            :isCoverageStyle="true"
            :hideCoverageAmount="false"
            :selectedCoverageId="currentCoverageId"
            :currentCoverageStatus="currentCoverageStatus"
          />
        </f7-block>
      </div>
      <div v-if="getIsOwnerSelected">
        <div class="section-header">
          <small>SUGGESTED FOR YOU</small>
        </div>
        <div v-if="loadingPolicies" class="skeleton-effect-wave">
          <PolicyCardSkeleton :coverageStyle="true" />
        </div>
        <f7-block v-else class="policy-container">
          <PolicyCard
            :policy="policySuggested"
            :isCoverageStyle="true"
            :isSuggested="true"
            :hideCoverageAmount="hideCoverageAmount"
            currentCoverageStatus="warn"
          />
        </f7-block>
      </div>
    </div>
    <f7-block class="assistant">
      <f7-list media-list class="coverage-options">
        <f7-list-item
          @click="openHelp"
          link="#"
          :title="getSelectedInsurer"
          subtitle="Let me know if I can help :)"
        >
          <template #media>
            <div class="assistantAvatar online">
              <img src="@/assets/raw_teleadvisor.png" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>
    <ModalOtherMembers :isCoverageStyle="true" />
    <ModalCoverageAnalysis :Analysis="retirementAnalysis" />
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7Block,
  f7,
} from "framework7-vue";
import { API } from "aws-amplify";
import PolicyCard from "@/components/policy-card.vue";
import PolicyCardSkeleton from "@/components/policy-card-skeleton.vue";
import ModalOtherMembers from "@/components/modals/coverage-family-members.vue";
import ModalCoverageAnalysis from "@/components/modals/coverage-details-modal.vue";

import AnalysisBannerS from "@/components/analysis-banner-selector.vue";
import InsurerSelector from "@/components/insured-selector.vue";

import suggestedLogo from "@/assets/logo_manulife.png";
import suggestedLogo2 from "@/assets/suggested-plan2.svg";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    AnalysisBannerS,
    InsurerSelector,
    PolicyCard,
    PolicyCardSkeleton,
    ModalOtherMembers,
    ModalCoverageAnalysis,
  },
  props: {
    f7route: Object,
    f7router: Object,
    uuid_v4: String,
    selectedCoverageId: null,
    isQuizDone: false,
    isRetirementQuizDone: false,
    openRetirementAnalysis: false,
  },
  data() {
    return {
      memberSelected: {},
      policySuggested: {},
      policySuggested1: {
        productName: "Savvy Endowment5",
        productType: "Endowment",
        coverageTypeClass: "warn",
        coverageType: "2.47% Return",
        insurerLogo: suggestedLogo,
        isPolicy: true
      },
      policySuggested2: {
        productName: "Endowment plans",
        productType:
          "Endowment plans are a safe and low risk way to increase your savings",
        coverageTypeClass: "warn",
        coverageType: "",
        insurerLogo: suggestedLogo2,
      },
      hideCoverageAmount: false,
      coverages: [],
      policiesSelected: [],
      allowAdd: false,
      showTabSelector: false,
      selectorTitle: "",

      defaultTabIndex: 1,
      progress: 0,
      legend: "We are uploading your policy...",
      timer: null,
      loading: true,
      loadingPolicies: true,
      selectedInsuredId: null,
      currentCoverageId: null,
      currentCoverageStatus: null,
      retirementAnalysis: {},
      isOwnerSelected: false,
    };
  },
  async created() {
    // console.log("this.selectedCoverageId -> ", this.selectedCoverageId);
    // if (this.selectedCoverageId == null) this.currentCoverageId == "HC";
    // else 
    this.currentCoverageId = this.selectedCoverageId ?? "HC";


    if (this.currentCoverageId == "HC" || this.currentCoverageId == "PC") {
      this.policySuggested = this.policySuggested2;
      this.hideCoverageAmount = true;
    } else {
      this.policySuggested = this.policySuggested1;
      this.hideCoverageAmount = false;
    }
    if (this.openRetirementAnalysis) {
      this.OpenRetirementAnalysis();
    }
  },
  mounted() {
    this.selectedInsuredId = localStorage.selectedInsuredId;
  },
  computed: {
    getSelectedInsurer() {
      return "Hi " + localStorage.OwnerName + "!";
    },
    getIsOwnerSelected() {
      // console.log(
      //   "getIsOwnerSelected -> ",
      //   this.memberSelected.relationship == "myself"
      // );
      return this.memberSelected.relationship == "myself";
    },
    
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goSettings() {
      f7.views.main.router.navigate({ name: "settings" });
    },
    showOthersCoverage() {
      f7.sheet.open(".policy-details");
    },
    async updateSelectedInsured(selected, analysis) {
      this.loading = false;
      this.memberSelected = selected;
      console.log("updateSelectedInsured -> ", selected);
      if (selected) {
        let storedCoverages = JSON.parse(localStorage.coverages);

        this.isOwnerSelected = selected.relationship == "myself";

        if (selected.analysis) {
          this.coverages = this.$filters.processCoverageAnalysis(
            storedCoverages,
            selected.analysis.data,
            this.isOwnerSelected
          );
        } else {
          console.log("updateSelectedInsured");
          let analysis = await API.get(
            "coverageAnalysis",
            "/coverage-analysis",
            {
              queryStringParameters: {
                insuredId: selected.id,
              },
            }
          )
            .then((result) => {
              return result;
            })
            .catch((err) => {
              console.log(err);
              return null;
            });
          this.coverages = this.$filters.processCoverageAnalysis(
            storedCoverages,
            analysis ? analysis.data : [],
            this.isOwnerSelected
          );
        }
        console.log(this.currentCoverageId, this.selectedCoverageId);
        this.getPolicies(
          this.memberSelected.id,
          this.currentCoverageId ?? this.selectedCoverageId
        );
      } else {
        console.log(6);
        this.loadingPolicies = false;
        this.policiesSelected = [];
      }
    },

    async getPolicies(insuredId, coverageId) {
      this.loadingPolicies = true;
      console.log("getPolicies -> ", insuredId, coverageId);
      await API.get("policies", "/policies", {
        queryStringParameters: {
          insuredId: insuredId,
          coverage: coverageId,
        },
      })
        .then((result) => {
          // console.log('getPolicies 1 -> ', insuredId, coverageId);
          // console.log('getPolicies 2 -> ', result);
          this.policiesSelected = result.data;
          this.loadingPolicies = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingPolicies = false;
        });
    },
    async updateCoverageSelected(coverage) {
      this.loadingPolicies = true;
      this.currentCoverageId = coverage.attributeName;
      if (coverage.code == "HC" || coverage.code == "PC") {
        this.policySuggested = this.policySuggested2;
        this.hideCoverageAmount = true;
      } else {
        this.policySuggested = this.policySuggested1;
        this.hideCoverageAmount = false;
      }
      // console.log('updateCoverageSelected -> ', coverage);
      this.currentCoverageStatus = coverage.statusLabel ?? "alert";
      this.getPolicies(this.memberSelected.id, this.currentCoverageId);
    },
    async showMoreDetails(coverageId) {
      // console.log('showMoreDetails -> ', coverageId);
      if (this.memberSelected.relationship != "myself") {
        f7.sheet.open(".policy-details");
      }
      if (
        coverageId == "RETIREMENT" &&
        this.memberSelected.relationship == "myself"
      ) {
        if (this.isRetirementQuizDone) {
          this.OpenRetirementAnalysis();
        } else {
          f7.views.main.router.navigate(
            { name: "LandingNeedBoy" },
            {
              props: {},
            }
          );
        }
      }
      // console.log(this.memberSelected.relationship);
    },
    async OpenRetirementAnalysis() {
      this.retirementAnalysis = {};
      console.log("OpenRetirementAnalysis");
      var analysis = await API.get(
        "coverageAnalysis",
        "/coverage-analysis/retirement",
        {}
      )
        .then((result) => {
          // console.log("retirement -> ", result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      // console.log('analysis -> ', analysis);
      if (analysis) {
        this.retirementAnalysis = analysis;
        f7.sheet.open(".coverage-details-modal");
      } else {
        f7.sheet.open(".policy-details");
      }
    },
    openHelp() {
      window.open(
        "https://www.dbs.com.sg/personal/TeleAdvisory"
      );
    },
  },
};
</script>

<style scoped>
.navbar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}
</style>

<style >
.progress-FullPage,
.progress-FullPage > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.analysis-policies-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 23px;
}
.no-policies .section-header,
.analysis-policies-wrapper .section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 24px 0px 20px;
}
.no-policies .section-header small,
.analysis-policies-wrapper .section-header small {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
}

.analysis-policies-wrapper .policy-container {
  margin-top: 14px;
  margin-bottom: 16px;
}

a.link.twinkling-icon {
  background-image: url("../assets/customIcons/twinkling-icon.svg");
  background-repeat: no-repeat;
  background-position: 50%;
}

.no-policies .coverage-banner {
  margin: 20px;
  background: none;
  border-bottom: 1px solid #e1e7eb;
  border-radius: unset;
  padding: 0px 7px 42px 2px;
  height: unset;
}

.no-policies .coverage-banner h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #1a2531;
  margin-top: 15px;
  margin-bottom: 20px;
}

.no-policies .coverage-banner.update-policy a {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 34px;
  background: #5e6d78;
  width: 129px;
  bottom: unset;
  position: relative;
}

.no-policies .coverage-banner::after {
  display: none;
}

.no-policies .coverage-banner::before {
  right: 0px;
}
</style>