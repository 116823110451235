<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>

      <f7-nav-right v-if="!isMyProfile">
        <f7-button style="padding-right: 10px" class="add-policy-navbar-btn">
          <img src="@/assets/customIcons/custom-red-trash-can.svg" alt="" />
        </f7-button>
      </f7-nav-right>
    </f7-navbar>
     

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        'invalid-Box-Input': invalidDependentFirstName,
        'valid-Box-Input': !invalidDependentFirstName,
        disabled: existingProfile || isMyProfile,
      }"
    >
      <f7-list-item @click="focusText('first-name')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> First Name </span>
        </f7-row>

        <div>
          <f7-input
            @keyup="inputvalidator('first-name')"
            @change="inputvalidator('first-name')"
            v-bind:value="dependentFirstName"
            v-on:input="dependentFirstName = $event.target.value"
            class="d-first-name"
            ref="d-first-name"
            type="text"
            placeholder="e.g. Derrick"
            clear-button
            error-message="Only letters please!"
            required
            validate
            pattern="^([a-zA-Z]+[\s]?)*$"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        'invalid-Box-Input': invalidDependentLastName,
        'valid-Box-Input': !invalidDependentLastName,
        disabled: existingProfile || isMyProfile,
      }"
    >
      <f7-list-item @click="focusText('last-name')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Last Name </span>
        </f7-row>

        <div>
          <f7-input
            @keyup="inputvalidator('last-name')"
            @change="inputvalidator('last-name')"
            v-bind:value="dependentLastName"
            v-on:input="dependentLastName = $event.target.value"
            class="d-last-name"
            ref="d-last-name"
            type="text"
            placeholder="e.g. Lee"
            clear-button
            error-message="Only letters please!"
            required
            validate
            pattern="[a-zA-Z]*"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- START  ONLY FOR MY PROFILE -->
    <div v-if="isMyProfile">
      <!-- EMAIL -->

      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        :class="{
          'invalid-Box-Input': invalidEmail,
          'valid-Box-Input': !invalidEmail,
          disabled: existingProfile,
        }"
      >
        <f7-list-item @click="focusText('e-mail')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Email </span>
          </f7-row>

          <div>
            <f7-input
              @keyup="inputvalidator('e-mail')"
              @change="inputvalidator('e-mail')"
              v-bind:value="email"
              v-on:input="email = $event.target.value"
              class="e-mail"
              ref="e-mail"
              type="text"
              placeholder="e.g. name@mail.com"
              clear-button
              error-message="Enter a valid email please!"
              required
              validate
              pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]*"
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- MOBILE NUMBER -->

      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        :class="invalidPhoneNumber ? 'invalid-Box-Input' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('phone-number')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Mobile number </span>
          </f7-row>

          <div>
            <f7-input
              @keyup="inputvalidator('phone-number')"
              @change="inputvalidator('phone-number')"
              v-bind:value="phoneNumber"
              v-on:input="phoneNumber = $event.target.value"
              class="phone-number"
              ref="phone-number"
              type="text"
              placeholder="e.g. +65 9900 7755"
              clear-button
              error-message="Please enter a valid phone number!"
              required
              validate
              pattern="[0-9]*"
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- MARITAL STATUS -->
      <f7-list media-list inset class="insurance-details-tab">
        <f7-list-item @click="focusText('gender-selector')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Marital Status </span>
          </f7-row>

          <f7-row
            class="btns-block"
            style="padding-bottom: 10px; padding-top: 10px"
          >
            <f7-button
              id="singleBtn"
              @click="toggleMaritalStatus($event)"
              style="margin-right: 5px"
              :class="[
                isSingle
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              SINGLE</f7-button
            >

            <f7-button
              id="marriedBtn"
              style="margin-left: 5px; margin-right: 5px"
              @click="toggleMaritalStatus($event)"
              :class="[
                isMarried
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              MARRIED</f7-button
            >
            <f7-button
              id="widowedBtn"
              style="margin-left: 5px; margin-right: 5px"
              @click="toggleMaritalStatus($event)"
              :class="[
                isWidowed
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              WIDOWED</f7-button
            >
          </f7-row>
        </f7-list-item>
      </f7-list>
    </div>
    <!-- END    ONLY FOR MY PROFILE -->

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        disabled: isGenderFixed,
      }"
    >
      <f7-list-item @click="focusText('gender-selector')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Gender </span>
        </f7-row>

        <f7-row
          class="btns-block"
          style="padding-bottom: 10px; padding-top: 10px"
        >
          <f7-button
            id="maleBtn"
            style="margin-left: 5px; margin-right: 5px"
            @click="toggleGender($event)"
            :class="[
              isMale
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            MALE</f7-button
          >
          <f7-button
            id="femaleBtn"
            style="margin-left: 5px; margin-right: 5px"
            @click="toggleGender($event)"
            :class="[
              !isMale
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            FEMALE</f7-button
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidDependentBDay ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item @click="focusText('d-bday')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Birth Year </span>
        </f7-row>

        <div>
          <f7-input
            @keyup="inputvalidator('d-bday')"
            @change="inputvalidator('d-bday')"
            v-bind:value="dependentBDay"
            v-on:input="dependentBDay = $event.target.value"
            class="d-bday"
            ref="d-bday"
            type="text"
            placeholder="1990"
            clear-button
            error-message="Please enter a valid year!"
            required
            validate
            pattern="[0-9]*"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <div v-if="!isMyProfile">
      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        style="border: 1px solid #e1e7eb"
        :class="invalidAnnualExpenses ? 'invalid-Box-Input' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('annual-expenses')" class="">
          <f7-row class="add-insured-insets-modal">
            <f7-toggle
              @change="dependentActivation()"
              color="green"
              style="
                --f7-toggle-handle-color: #fff;
                --f7-toggle-inactive-color: #e5e5ea;
                --f7-toggle-width: 51px;
                --f7-toggle-height: 31px;
                --f7-theme-color: #11bba2;
              "
            ></f7-toggle>
            <span class="disabled-dependent-text" style="margin-left: 9px"
              >Dependent</span
            >
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        v-if="isDependent"
        media-list
        inset
        class="insurance-details-tab"
        style="border: 1px solid #e1e7eb"
        :class="invalidAnnualExpenses ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('annual-expenses')" class="">
          <f7-row class="need-analysis-saving-habit-insets-modal">
            <span style="display: flex">
              Annual Expenses
              <img
                @click="aExpenses"
                class="tool-tip-info"
                src="@/assets/customIcons/custom-info-circle.svg"
                alt=""
              />
            </span>
          </f7-row>

          <f7-row no-gap>
            <input
              v-model="annualExpenses"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('annual-expenses')"
              @change="inputvalidator('annual-expenses')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
          </f7-row>
        </f7-list-item>
      </f7-list>
    </div>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidMonthIncomeInput ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
    >
      <f7-list-item @click="focusText('month-income')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span style="display: flex">
            Annual Income
            <img
              @click="aIncome()"
              class="tool-tip-info"
              src="@/assets/customIcons/custom-info-circle.svg"
              alt=""
            />
          </span>
        </f7-row>

        <f7-row no-gap>
          <input
            v-model="monthIncomeInput"
            v-inputmask
            :data-inputmask="maskOpt"
            @keyup="inputvalidator('month-income')"
            @change="inputvalidator('month-income')"
            class="saving-input"
            placeholder="S$ 1,000"
          />
          <span class="input-error-message"
            >Please, verify the input value!</span
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-block align="center" class="policy-information-modal">
      <f7-button
        @click="saveDependent"
        :disabled="!validForm"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="add-policy-btn-red-text">
          <!-- TODO: Pending Validation -->
          {{ existingProfile ? "Save" : "Save" }}
        </div></f7-button
      >
    </f7-block>

    <AnnualIncomeToolTip />
    <AnnualExpensesToolTip />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
} from "framework7-vue";

import { API } from "aws-amplify";

import insurer1 from "@/assets/family_member1.svg";
import AnnualExpensesToolTip from "@/components/modals/annual-expenses-tool-tip.vue";
import AnnualIncomeToolTip from "@/components/modals/annual-income-tooltip.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    AnnualIncomeToolTip,
    AnnualExpensesToolTip,
  },
  props: {
    isMyProfile: false,
    screenHeader: null,
    //    existingProfile: false,
    relationship: null,
    questionnerParam: {
      type: Object,
    },
    insuredId: null,
  },
  data() {
    return {
      loading: true,
      insureds: [],
      navbarTitle: "My Spouse",

      isMale: true,
      activeListOfDependent: true,

      // Input Fields
      selectedDependent2: "-",
      dependentRelationship: " My Spouce",
      selectedDependentId: "",
      dependentFirstName: "",
      dependentLastName: "",
      dependentBDay: "",
      monthIncomeInput: "",
      annualExpenses: "",

      // my profile only
      email: "",
      phoneNumber: "",
      maritalStatus: "",
      isSingle: false,
      isMarried: false,
      isWidowed: false,

      myDependent: [],
      questionner: {},

      // variables to Add color to the boxes
      invalidDependentFirstName: false,
      invalidDependentLastName: false,
      invalidDependentBDay: false,
      invalidMonthIncomeInput: false,
      invalidAnnualExpenses: false,

      // my profile only
      invalidEmail: false,
      invalidPhoneNumber: false,

      //Full Form Variable
      validForm: false,

      existingProfile: false,
      isGenderFixed: false,
      insuredAvatar: insurer1, // "@/assets/family_member1.svg",
      isDependent: false,
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    showHealthStatus() {
      f7.views.main.router.navigate(
        { name: "HealthStatus" },
        {
          props: {
            questionnerParam: this.questionner,
          },
        }
      );
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    dependentActivation() {
      this.isDependent = !this.isDependent;
    },
    aIncome() {
      f7.sheet.open(".a-expenses-tt");
    },
    aExpenses() {
      f7.sheet.open(".a-income-tt");
    },

    toggleMaritalStatus(event) {
      if (event.currentTarget.id == "singleBtn") {
        this.isSingle = true;
        this.isMarried = false;
        this.isWidowed = false;
      } else if (event.currentTarget.id == "marriedBtn") {
        this.isSingle = false;
        this.isMarried = true;
        this.isWidowed = false;
      } else {
        this.isSingle = false;
        this.isMarried = false;
        this.isWidowed = true;
      }
    },

    toggleGender(event) {
      if (event.currentTarget.id == "maleBtn") {
        this.isMale = true;
      } else {
        this.isMale = false;
      }
      this.inputvalidator();
    },
    selectedUser(selectedUser, relationship) {
      this.dependentRelationship = relationship;
      this.selectedDependent2 = selectedUser;
      this.listOfDependent.forEach((element) => {
        if (element.id == selectedUser) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    },
    showDependantDetails(dependentParam) {
      if (dependentParam) this.selectedDependent2 = dependentParam;

      let selected = this.insureds.filter(function (ins) {
        return ins.relationship == this;
      }, this.selectedDependent2)[0];

      //console.log(selected);

      this.listOfDependent.forEach((d) => {
        if (d.id == this.selectedDependent2)
          this.dependentRelationship = d.user;
      });

      if (selected) {
        this.selectedDependentId = selected.id;
        this.dependentFirstName = selected.firstName;
        this.dependentLastName = selected.lastName;
        this.dependentBDay = selected.yearOfBirth ?? "";
        this.isMale = selected.gender == "male";
        this.monthIncomeInput = selected.annualIncome ?? "";
        this.annualExpenses = selected.annualExpense ?? "";
        this.existingProfile = true;
        this.insuredAvatar = selected.avatar;
      } else {
        this.dependentFirstName = "";
        this.dependentLastName = "";
        this.dependentBDay = "";
        this.isMale = true;
        this.monthIncomeInput = "";
        this.annualExpenses = "";
        this.existingProfile = false;
      }
      this.isGenderFixed = false;
      switch (this.selectedDependent2) {
        case "mother":
          this.isMale = false;
          this.isGenderFixed = true;
          break;
        case "father":
          this.isMale = true;
          this.isGenderFixed = true;
          break;
        default:
          break;
      }
      this.inputvalidator();
      f7.sheet.close(".select-dependent");
      f7.sheet.open(".dependent-details");
    },
    async removeDependent() {
      f7.dialog.preloader("Processing...");
      var profile = await this.getInsuredProfile(this.selectedDependentId);

      if (profile) {
        profile.dependent = false;

        profile = await this.updateProfile(this.selectedDependentId, profile);
      }
      f7.sheet.close(".dependent-details");
      f7.dialog.close();
      this.loadDataScreen();
    },
    focusText(tab1) {
      // console.log("option selected --->");
      // console.log(tab1);
      // this.$refs.totalSaving.$el.focus();
    },
    async saveDependent() {
      // console.log("he guardado");
      // this.dependentCount = 1;

      f7.views.main.router.navigate(
        { name: "UploadInsured" },
        {
          props: {
            uploaded: true,
          },
        }
      );

      f7.dialog.preloader("Processing...");
      if (this.existingProfile) {
        var profile = await this.getInsuredProfile(this.selectedDependentId);

        if (profile) {
          profile.yearOfBirth = Number(this.dependentBDay);
          profile.gender = this.isMale ? "male" : "female";
          profile.annualIncome = Number(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, ""));
          profile.annualExpense = Number(this.annualExpenses.toString().replace(/,|[S$ ]/g, ""));
          profile.dependent = true;
          await this.updateProfile(this.selectedDependentId, profile);
        }
      } else {
        var profile = {};
        profile.firstName = this.dependentFirstName;
        profile.lastName = this.dependentLastName;
        profile.yearOfBirth = Number(this.dependentBDay);
        profile.gender = this.isMale ? "male" : "female";
        profile.annualIncome = Number(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, ""));
        profile.annualExpense = Number(this.annualExpenses.toString().replace(/,|[S$ ]/g, ""));
        profile.dependent = true;
        profile.relationship = this.selectedDependent2;

        profile = await API.post("insureds", "/insureds", {
          body: profile,
        })
          .then((result) => {
            // console.log("insured created -> ", result);
            return result;
          })
          .catch((err) => {
            // console.log("insured created error -> ", err);
          });
      }
      f7.sheet.close(".dependent-details");
      f7.dialog.close();
    },

    async getInsuredProfile(insuredId) {
      let path = "/insureds/" + insuredId;

      var profile = await API.get("insureds", path, {})
        .then((result) => {
          // console.log(result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      return profile;
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      // console.log('profile -> ', profile);
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      var regExpEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      switch (selectedBox) {
        case "first-name":
          var test = regExpString.test(this.dependentFirstName);
          if (!test) {
            this.invalidDependentFirstName = true;
          } else {
            this.invalidDependentFirstName = false;
          }
          break;
        case "last-name":
          var test = regExpString.test(this.dependentLastName);
          if (!test) {
            this.invalidDependentLastName = true;
          } else {
            this.invalidDependentLastName = false;
          }
          break;
        case "d-bday":
          var currentYear = new Date().getFullYear();
          var test = regExp.test(this.dependentBDay);
          if (!test) {
            this.invalidDependentBDay = true;
          } else {
            if (
              this.dependentBDay > 1900 &&
              this.dependentBDay < currentYear + 2
            )
              this.invalidDependentBDay = false;
            else this.invalidDependentBDay = true;
          }
          break;
        case "e-mail":
          var test = regExpEmail.test(this.email);
          if (!test) {
            this.invalidEmail = true;
          } else {
            this.invalidEmail = false;
          }
          break;
        case "phone-number":
          var test = regExp.test(this.phoneNumber);
          if (!test) {
            this.invalidPhoneNumber = true;
          } else {
            this.invalidPhoneNumber = false;
          }
          break;
        case "month-income":
          let valueMI = this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valueMI) || parseFloat(valueMI) < 0;
          if (!test) {
            this.invalidMonthIncomeInput = true;
          } else {
            this.invalidMonthIncomeInput = false;
          }
          break;
        case "annual-expenses":
          let valueME = this.monthExpensesInput.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valueME) || parseFloat(valueME) < 0;
          if (!test) {
            this.invalidAnnualExpenses = true;
          } else {
            this.invalidAnnualExpenses = false;
          }
          break;
      }

      if (
        this.dependentFirstName.length > 0 &&
        this.dependentLastName.length > 0 &&
        this.dependentBDay > 0 &&
        parseFloat(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")).toFixed(2) >=
          0
      ) {
        // console.log("valid form");
        this.validForm = true;
      } else {
        // console.log("invalid form");
        this.validForm = false;
      }
    },
    async loadDataScreen() {
      this.loading = true;
      this.insureds = [];
      await API.get("insureds", "/insureds", {})
        .then((result) => {
          let insuredList = result.data;
          let sortedArr = [];
          for (var x in insuredList) {
            if (insuredList[x].relationship == "myself") {
              sortedArr.unshift(insuredList[x]);
            } else {
              sortedArr.push(insuredList[x]);
            }
          }

          for (var ins in sortedArr) {
            let insured = sortedArr[ins];
            insured.avatar = this.$filters.getInsuredAvatar(
              insured.relationship,
              insured.gender
            );
            this.insureds.push(insured);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.myDependent = this.insureds.filter(function (ins) {
        return ins.dependent ?? false;
      });

      this.dependentCount = this.myDependent.length;
      this.questionner = this.questionnerParam;
      this.loading = false;
      return true;
    },
  },

  async mounted() {
    if (this.isMyProfile) {
      this.navbarTitle = this.screenHeader;
    }
    this.dependentRelationship = this.relationship;
    // console.log(this.relationship);
    // console.log(this.dependentRelationship);

    this.loadDataScreen();
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

.need-analysis-subtitle {
  font-size: 17px;
  margin-top: 0px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #07182b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loved-ones-block-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: right;

  color: #929ba2;
}

.add-dependent-regular {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-dependent-red {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ff3333;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dependent-couter {
  display: inline;
  align-items: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-couter-zero {
  display: inline;
  align-items: center;
  background: #c0cbd2;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-row {
  background-color: white;
  padding-top: 3px;
  /*   border-bottom-width: 13px;
  border-bottom-style: solid;
  border-bottom-color: #f4f8fb; /* f4f8fb */
}

.dependent-row-modal {
  background-color: white;
  padding-top: 3px;
  justify-content: center;
}

.dependent-image {
  padding-top: 3px;
}

.dependent-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;

  padding-top: 4%;
  padding-left: 15px;
}

.dependent-amount {
  font-size: 16px;
  line-height: 22px;

  text-align: left;

  color: #000000;
  padding-top: 4%;
  padding-left: 3px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.dependent-details .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.dependent-details .modal-header > span {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}
</style>
<style>
.disabled .input-clear-button {
  display: none;
}

.disabled {
  opacity: 1 !important;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}

.disabled-dependent-text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.2;
}
</style>


