<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-block class="screen-title"> {{ ScreenTitle }} </f7-block>

    <f7-block-title class="unlimited-block">UNLIMITED</f7-block-title>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidsumAssured ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Death sum assured </span>
        </f7-row>

        <div v-if="!unlimitedSumAssured">
          <f7-row no-gap>
            <input
              v-model="sumAssured"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('sumAssured')"
              @change="inputvalidator('sumAssured')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
            <!-- <f7-col class="box-dolar-sign" width="50" align="right"> S$</f7-col>
            <f7-col width="50" align="left" style="padding-left: 10px">
              <f7-input
                v-bind:value="sumAssured"
                v-on:input="sumAssured = $event.target.value"
                @keyup="inputvalidator('sumAssured')"
                @change="inputvalidator('sumAssured')"
                class="total-saving-input"
                ref="totalSaving"
                type="text"
                placeholder="1000"
                pattern="[0-9]*"
              ></f7-input>
            </f7-col> -->
          </f7-row>
        </div>

        <div v-else class="disabled-unlimited">Unlimited</div>
      </f7-list-item>
      <f7-toggle
        color="green"
        class="toggle-insets"
        @click="unlimitedToggl($event.target.checked, 'dsa')"
        :checked="unlimitedSumAssured ?? false"
      ></f7-toggle>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab disabled"
      :class="invalidPermanentDis ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span style="width: 175px; text-align: center">
            Total Permanent Disability sum assured
          </span>
        </f7-row>

        <div v-if="!unlimitedSumAssured">
          <f7-row no-gap>
            <input
              v-model="sumAssured"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('permanentDis')"
              @change="inputvalidator('permanentDis')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
          </f7-row>
        </div>

        <div v-else class="disabled-unlimited">Unlimited</div>
      </f7-list-item>
      <f7-toggle
        color="green"
        class="toggle-insets disabled"
        :checked="unlimitedSumAssured ?? false"
      ></f7-toggle>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="
        invalidcriticalsumAssured ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'
      "
    >
      <f7-list-item class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span style="width: 150px; text-align: center">
            Critical Illiness sum assured
          </span>
        </f7-row>

        <div v-if="!unlimitedCriticalsumAssured">
          <f7-row no-gap>
            <input
              v-model="criticalsumAssured"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('criticalsumAssured')"
              @change="inputvalidator('criticalsumAssured')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
          </f7-row>
        </div>
        <div v-else class="disabled-unlimited">Unlimited</div>
      </f7-list-item>
      <f7-toggle
        color="green"
        class="toggle-insets"
        @click="unlimitedToggl($event.target.checked, 'cisa')"
        :checked="unlimitedCriticalsumAssured ?? false"
      ></f7-toggle>
    </f7-list>

    <f7-block
      align="center"
      class="policy-information-modal"
      style="position: absolute; bottom: 0px; padding-left: 40px"
    >
      <f7-button
        @click="savePremiumDetails"
        :disabled="!validForm"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="add-policy-btn-red-text">
          <!-- TODO: Pending Validation -->
          Save
        </div></f7-button
      >
    </f7-block>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
  },
  props: {
    policy: {},
    insured: {},
  },
  data() {
    return {
      ScreenTitle: "Coverage Details",

      loading: false,
      insureds: [],
      navbarTitle: "Edit Policy",

      //unlimited values
      unlimitedSumAssured: false,
      unlimitedPermanentDis: false,
      unlimitedCriticalsumAssured: false,
      unlimitedEarlycriticalsumAssured: false,

      // Input Fields
      sumAssured: "",
      permanentDis: "",
      criticalsumAssured: "",
      earlycriticalsumAssured: "",

      // variables to Add color to the boxes
      invalidsumAssured: false,
      invalidPermanentDis: false,
      invalidcriticalsumAssured: false,
      invalidEarlycriticalsumAssured: false,

      //Full Form Variable
      validForm: true,
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    async savePremiumDetails() {
      f7.dialog.preloader("Loading...");

      if (this.unlimitedSumAssured) {
        this.policy.coverageDeath = -1;
        this.policy.coverageTPD = -1;
      } else {
        this.policy.coverageDeath = parseFloat(this.sumAssured ?? 0).toFixed(2);
        this.policy.coverageTPD = parseFloat(this.sumAssured ?? 0).toFixed(2);
      }

      if (this.unlimitedCriticalsumAssured) {
        this.policy.coverageCI = -1;
      } else {
        this.policy.coverageCI = parseFloat(
          this.criticalsumAssured ?? 0
        ).toFixed(2);
      }

      let path = "/policies/" + this.policy.id;
      var policyRequest = this.policy;
      // console.log("policyRequest -> ", policyRequest);
      var policyResponse = await API.patch("policies", path, {
        body: policyRequest,
      })
        .then((result) => {
          // console.log(result);
          return result;
        })
        .catch((err) => {
          console.log(err);
        });
      f7.dialog.close();
      f7.views.main.router.navigate(
        { name: "EditPolicy" },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
      // f7.views.main.router.navigate({ name: "EditPolicy" });
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      switch (selectedBox) {
      }
    },

    unlimitedToggl(value, toggBox) {
      if (typeof value !== "undefined") {
        switch (toggBox) {
          case "dsa":
            this.unlimitedSumAssured = !this.unlimitedSumAssured;
            break;
          case "tpdsa":
            this.unlimitedPermanentDis = !this.unlimitedPermanentDis;
            break;
          case "cisa":
            this.unlimitedCriticalsumAssured =
              !this.unlimitedCriticalsumAssured;
            break;
        }
      }
    },
  },

  async mounted() {
    this.unlimitedSumAssured = false;
    this.unlimitedPermanentDis = false;
    this.unlimitedCriticalsumAssured = false;

    if (this.policy.coverageDeath) {
      if (this.policy.coverageDeath == -1) {
        this.unlimitedSumAssured = true;
      } else {
        this.sumAssured = this.policy.coverageDeath;
      }
    }

    // if (this.policy.coverageTPD) {
    //   if (this.policy.coverageTPD == -1) {
    //     this.unlimitedPermanentDis = true;
    //   } else {
    //     this.permanentDis = this.policy.coverageTPD;
    //   }
    // }

    if (this.policy.coverageCI) {
      if (this.policy.coverageCI == -1) {
        this.unlimitedCriticalsumAssured = true;
      } else {
        this.criticalsumAssured = this.policy.coverageCI;
      }
    }
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #07182b;
}

.product-type {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;

  opacity: 0.2;
}

.product-type-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #07182b;
}

.unlimited-block {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  margin-right: 20px;
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
}

.disabled-unlimited {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.toggle-insets {
  --f7-toggle-handle-color: #fff;
  --f7-toggle-inactive-color: #e5e5ea;
  --f7-toggle-width: 51px;
  --f7-toggle-height: 31px;
  --f7-theme-color: #11bba2;
  position: absolute;
  right: 15px;
  top: 35%;
}
</style>
<style>
.disabled .input-clear-button {
  display: none;
}

.disabled {
  opacity: 1 !important;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}

.disabled-dependent-text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.2;
}

.payment-frequency-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  position: static;
  width: 51px;
  height: 31px;
  left: 0px;
  top: 4px;
  background: #5e6d78;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}

.payment-frequency-no-fill-btn {
  /* btns values */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  position: static;
  width: 51px;
  height: 31px;
  left: 170px;
  top: 4px;
  background: #ffffff;
  border: 1px solid #5e6d78;
  box-sizing: border-box;
  border-radius: 34px;
  /* internal text values */
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #5e6d78;

  --f7-button-pressed-bg-color: rgba(0, 0, 0, 0.15);
  --f7-button-pressed-text-color: #ffffff;
}
</style>


