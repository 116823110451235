<template>
  <f7-page name="coverage-detail">
    <f7-navbar class="add-policy-progress-demo" back-link="">
      <f7-nav-title class="add-policy-navbar-title">Add Policy</f7-nav-title>

      <f7-nav-right>
        <f7-link style="padding-right: 10px" href="/policy-information">
          <img src="@/assets/customIcons/custom-red-plus.svg" alt="" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-page class="progress-FullPage">
      <CustomProgress :percentage="progress" :legend="legend" />
    </f7-page>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7Block,
} from "framework7-vue";

import CustomProgress from "@/components/custom-progress.vue";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    CustomProgress,
  },
  props: {
    f7route: Object,
    f7router: Object,
    uuid_v4: String,
  },
  data() {
    return {
      defaultTabIndex: 1,
      progress: 0,
      legend: "We are uploading your policy...",
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.progress == 100) {
        clearInterval(this.timer);
        this.legend = "Done";
      } else this.progress++;
    }, 100);
  },
  methods: {},
};
</script>

<style >
.progress-FullPage,
.progress-FullPage > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>