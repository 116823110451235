<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-block class="add-policy-step">
      {{ screenStep }}
    </f7-block>

    <f7-block class="add-policy-title"> {{ screenTitle }} </f7-block>

    <!-- <ProfileForm
      :insuredId="null"
      :showAvatar="true"
      :defaultRelationship="relationship"
      @profileSaved="profileSaved"
    /> -->

    <ProfileForm
      :insuredId="insuredId"
      :showAvatar="true"
      @profileSaved="profileSaved"
      :defaultRelationship="defaultRelationship"
    />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
} from "framework7-vue";

import { API } from "aws-amplify";

import insurer1 from "@/assets/family_member1.svg";
import ProfileForm from "@/components/forms/profile-form.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    ProfileForm,
  },
  props: {
    relationship: null,
    questionnerParam: {
      type: Object,
    },
    addMember: false,
    insuredId: null,
    relationshipLabel: "",
    defaultRelationship: "",
  },
  data() {
    return {
      loading: true,
      insureds: [],
      previusStep: "My Retirement Needs",
      screenStep: "STEP 2/2",
      navbarTitle: "Add Insured",

      screenTitle: "Insured Details",

      isMale: true,
      activeListOfDependent: true,

      // Input Fields
      selectedDependent2: "-",
      dependentRelationship: " My Spouce",
      selectedDependentId: "",
      dependentFirstName: "",
      dependentLastName: "",
      dependentBDay: "",
      monthIncomeInput: "",
      annualExpenses: "",

      myDependent: [],
      questionner: {},

      // variables to Add color to the boxes
      invalidDependentFirstName: false,
      invalidDependentLastName: false,
      invalidDependentBDay: false,
      invalidMonthIncomeInput: false,
      invalidAnnualExpenses: false,

      //Full Form Variable
      validForm: false,

      existingProfile: false,
      isGenderFixed: false,
      insuredAvatar: insurer1, // "@/assets/family_member1.svg",
      isDependent: false,
    };
  },
  methods: {
    profileSaved() {
      console.log('profileSaved');
      if (this.addMember) {
        
        f7.views.main.router.navigate({ name: "UploadInsured" });
      } else {
        f7.views.main.router.navigate(
          { name: "UploadInsured" },
          {
            props: {
              uploaded: true,
            },
          }
        );
      }
    },
    showHealthStatus() {
      f7.views.main.router.navigate(
        { name: "HealthStatus" },
        {
          props: {
            questionnerParam: this.questionner,
          },
        }
      );
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    dependentActivation() {
      this.isDependent = !this.isDependent;
    },

    toggleGender(event) {
      if (event.currentTarget.id == "maleBtn") {
        this.isMale = true;
      } else {
        this.isMale = false;
      }

      this.inputvalidator();
    },
    selectedUser(selectedUser, relationship) {
      this.dependentRelationship = relationship;
      this.selectedDependent2 = selectedUser;
      this.listOfDependent.forEach((element) => {
        if (element.id == selectedUser) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    },
    
    async removeDependent() {
      f7.dialog.preloader("Processing...");
      var profile = await this.getInsuredProfile(this.selectedDependentId);

      if (profile) {
        profile.dependent = false;

        profile = await this.updateProfile(this.selectedDependentId, profile);
      }
      f7.sheet.close(".dependent-details");
      f7.dialog.close();
      this.loadDataScreen();
    },
    focusText(tab1) {
      // console.log("option selected --->");
      // console.log(tab1);
      // this.$refs.totalSaving.$el.focus();
    },

    async getInsuredProfile(insuredId) {
      let path = "/insureds/" + insuredId;

      var profile = await API.get("insureds", path, {})
        .then((result) => {
          // console.log(result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      return profile;
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      // console.log('profile -> ', profile);
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      switch (selectedBox) {
        case "first-name":
          var test = regExpString.test(this.dependentFirstName);
          if (!test) {
            this.invalidDependentFirstName = true;
          } else {
            this.invalidDependentFirstName = false;
          }
          break;
        case "last-name":
          var test = regExpString.test(this.dependentLastName);
          if (!test) {
            this.invalidDependentLastName = true;
          } else {
            this.invalidDependentLastName = false;
          }
          break;
        case "d-bday":
          var currentYear = new Date().getFullYear();
          var test = regExp.test(this.dependentBDay);
          if (!test) {
            this.invalidDependentBDay = true;
          } else {
            if (
              this.dependentBDay > 1900 &&
              this.dependentBDay < currentYear + 2
            )
              this.invalidDependentBDay = false;
            else this.invalidDependentBDay = true;
          }
          break;
        case "month-income":
          var test = regExp.test(this.monthIncomeInput);
          if (!test) {
            this.invalidMonthIncomeInput = true;
          } else {
            this.invalidMonthIncomeInput = false;
          }
          break;
        case "annual-expenses":
          var test = regExp.test(this.annualExpenses);
          if (!test) {
            this.invalidAnnualExpenses = true;
          } else {
            this.invalidAnnualExpenses = false;
          }
          break;
      }

      if (
        this.dependentFirstName.length > 0 &&
        this.dependentLastName.length > 0 &&
        this.dependentBDay > 0 &&
        parseFloat(this.monthIncomeInput).toFixed(2) >= 0
      ) {
        // console.log("valid form");
        this.validForm = true;
      } else {
        // console.log("invalid form");
        this.validForm = false;
      }
    },
    async loadDataScreen() {
      this.loading = true;
      this.insureds = [];
      await API.get("insureds", "/insureds", {})
        .then((result) => {
          let insuredList = result.data;
          let sortedArr = [];
          for (var x in insuredList) {
            if (insuredList[x].relationship == "myself") {
              sortedArr.unshift(insuredList[x]);
            } else {
              sortedArr.push(insuredList[x]);
            }
          }

          for (var ins in sortedArr) {
            let insured = sortedArr[ins];
            insured.avatar = this.$filters.getInsuredAvatar(
              insured.relationship,
              insured.gender
            );
            this.insureds.push(insured);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.myDependent = this.insureds.filter(function (ins) {
        return ins.dependent ?? false;
      });

      this.dependentCount = this.myDependent.length;
      this.questionner = this.questionnerParam;
      this.loading = false;
      return true;
    },
  },

  async mounted() {
    this.dependentRelationship = this.relationship;
    console.log(this.relationship);
    console.log(this.dependentRelationship);

    this.loadDataScreen();
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-title{
  margin-bottom: 20px;
}
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

.need-analysis-subtitle {
  font-size: 17px;
  margin-top: 0px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #07182b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loved-ones-block-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: right;

  color: #929ba2;
}

.add-dependent-regular {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-dependent-red {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ff3333;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dependent-couter {
  display: inline;
  align-items: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-couter-zero {
  display: inline;
  align-items: center;
  background: #c0cbd2;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-row {
  background-color: white;
  padding-top: 3px;
  /*   border-bottom-width: 13px;
  border-bottom-style: solid;
  border-bottom-color: #f4f8fb; /* f4f8fb */
}

.dependent-row-modal {
  background-color: white;
  padding-top: 3px;
  justify-content: center;
}

.dependent-image {
  padding-top: 3px;
}

.dependent-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;

  padding-top: 4%;
  padding-left: 15px;
}

.dependent-amount {
  font-size: 16px;
  line-height: 22px;

  text-align: left;

  color: #000000;
  padding-top: 4%;
  padding-left: 3px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.dependent-details .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.dependent-details .modal-header > span {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.disabled .input-clear-button {
  display: none;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}
</style>


