<template>
  <div class="policy-card">
    <div class="policy-header" :class="{hasCoverageStyle: isCoverageStyle}">
      <div v-if="isCoverageStyle"
        class="skeleton-block"
        style="width: 48px; height: 48px; border-radius: 50%"
      ></div>
      <div v-else
        class="skeleton-block"
        style="width: 48px; height: 48px; border-radius: 12px"
      ></div>
      <div class="product-details">
        <span class="skeleton-text" style="border-radius: 30px"
          >Product name</span
        >
        <span class="skeleton-text" style="border-radius: 30px"
          >Product type</span
        >
      </div>
      <a href="#"></a>
    </div>

    <div v-if="!isCoverageStyle" class="policy-detail">
      <div class="policy-premium">
        <span class="skeleton-text" style="border-radius: 30px"
          >Premium Amount</span
        >
      </div>
      <div class="policy-status">
        <span class="skeleton-text" style="border-radius: 30px">status</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCoverageStyle: false,
  },
};
</script>

<style scoped>
.policy-card {
  display: flex;
  flex-direction: column;
  padding: 19px 21px 16px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin-bottom: 16px;
  margin: 16px;
}

.policy-card .policy-header {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e7eb;
}

.policy-card .policy-header .product-details {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.policy-detail {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.policy-card .policy-header.hasCoverageStyle{
  padding-bottom: 0px;
  border-bottom: none;
}
</style>

