<template>
  <f7-page class="page-suw">
    <div class="page-header">
      <f7-link
        icon-ios="f7:chevron_left"
        @click="goBack()"
        style="color: #9eaab1"
        class="btn-back"
      ></f7-link>
      <!-- <span>DBS Insurance Family Vault</span> -->
      <span>Insurance Family Vault</span>

      <f7-button @click="goToWelcome()" class="btn-close" icon-ios="f7:xmark">
      </f7-button>
    </div>
    <div class="page-subtitle">
      <span>Tell us about yourself</span>
    </div>
    <ProfileForm
      :isSignup="true"
      :insuredId="null"
      defaultRelationship="myself"
      @profileSaved="profileSaved"
      :username="username"
      :signinPhoneNumber="signinPhoneNumber"
    />
  </f7-page>
</template>

<script>
import { f7Page, f7Input, f7Button, f7Icon, f7 } from "framework7-vue";
import ProfileForm from "@/components/forms/profile-form.vue";

export default {
  components: {
    ProfileForm,
  },
  props: {
    username: null,
    signinPhoneNumber: null,
  },
  data() {
    return {
      dependentFirstName: "",
      dependentLastName: "",
      dependentBDay: "",
      isMale: true,
      monthIncomeInput: "",

      // fields validation
      invalidDependentFirstName: false,
      invalidDependentLastName: false,
      invalidDependentBDay: false,
      invalidMonthIncomeInput: false,
      invalidAnnualExpenses: false,

      //Full Form Variable
      validForm: false,
    };
  },
  methods: {
    submitForm() {
      f7.views.main.router.navigate(
        { name: "SignUpSuccess" },
        {
          props: {},
        }
      );
    },
    goBack() {
      f7.views.main.router.back();
    },
    goToWelcome() {
      f7.views.main.router.navigate(
        { name: "SignUp" },
        {
          props: {},
        }
      );
    },
    profileSaved() {
      f7.views.main.router.navigate(
        { name: "SignUpSuccess" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style>
.need-analysis-saving-habit-insets-modal {
  padding-bottom: 0px;
}
</style>

<style scoped>
.btn-wapper {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  bottom: unset;
}

.saving-habits-tab {
  margin-bottom: 15px;
  margin-top: 0;
}
.need-analysis-retirement-fill-btn,
.need-analysis-retirement-no-fill-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
