<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>

      <f7-nav-right>
        
      </f7-nav-right>
    </f7-navbar>
    <f7-block class="screen-title"> {{ ScreenTitle }}</f7-block>
    <ProfileForm
      :insuredId="insured.id"
      :showAvatar="true"
      @profileSaved="profileSaved"
      @frmPostUpdated="frmPostUpdated"
    />
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";

import ProfileForm from "@/components/forms/profile-form.vue";

export default {
  components: {
    ProfileForm,
  },
  props: {
    policy: {},
    insured: {},
  },
  data() {
    return {
      ScreenTitle: "Insured Details",
      loading: true,
      navbarTitle: "Edit Policy",
    };
  },
  methods: {
    profileSaved(isProfileSaved) {
      f7.views.main.router.navigate(
        { name: "EditPolicy" },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #07182b;
}


.add-policy-navbar-title {
  text-transform: capitalize;
}
</style>