<template>
  <f7-page hide-toolbar-on-scroll>
    <f7-navbar
      v-if="settingEdit"
      class="add-policy-nav-bar-shadow"
      back-link=""
    >
      <f7-nav-title class="add-policy-navbar-title">{{
        navBarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-navbar v-else class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>

      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-toolbar
      @click="goPrevStep()"
      class="toolbar-aligment"
      :position="'top'"
      v-if="!shortQuestionare && !settingEdit"
    >
      <f7-link
        class="link back"
        href="#"
        style="padding-left: 5%; position: absolute; left: 10px"
      >
        <img src="@/assets/customIcons/custom-up-btn.svg" alt="" />
      </f7-link>

      <div class="">
        {{ previusStep }}
      </div>
    </f7-toolbar>

    <f7-block v-if="!settingEdit" class="add-policy-step" id="ScreenTitle">
      {{ screenStep }}
    </f7-block>

    <f7-block v-if="!settingEdit" class="add-policy-title">
      {{ screenTitle }}
    </f7-block>
     
    <f7-block v-if="!settingEdit" class="need-analysis-description">
      {{ screenDescription }}
    </f7-block>

    <f7-block
      class="add-policy-user-columns"
      :class="{ 'skeleton-effect-wave': loading }"
    >
      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row class="need-analysis-inset-title">
            I want to retire at
          </f7-row>
          <f7-row v-if="loading" style="justify-content: center">
            <div
              class="skeleton-block"
              style="width: 54px; height: 54px; border-radius: 4px"
            ></div>
          </f7-row>

          <f7-row v-if="!loading" class="need-analysis-scroll-value">
            {{ retirementValue }}
          </f7-row>

          <f7-row class="range-limits-min-max">
            <f7-col align="left" width="50">
              <div
                v-if="loading"
                class="skeleton-block"
                style="width: 22px; height: 17px; border-radius: 4px"
              ></div>
              <span v-else class=""> 30 </span>
            </f7-col>
            <f7-col align="right" width="50">
              <div
                v-if="loading"
                class="skeleton-block"
                style="width: 22px; height: 17px; border-radius: 4px"
              ></div>
              <span v-else class=""> 72 </span>
            </f7-col>
          </f7-row>

          <f7-row
            v-if="loading"
            style="justify-content: center; margin-top: 5px; margin-bottom: 5px"
          >
            <div
              class="skeleton-block"
              style="width: 100%; height: 28px; border-radius: 4px"
            ></div>
          </f7-row>
          <f7-range
            v-if="!loading"
            :min="30"
            :max="72"
            :step="1"
            :value="retirementValue"
            color="red"
            @range:change="retirementValueUpdater"
            style="--f7-range-bar-size: 5px"
          ></f7-range>

          <f7-row class="need-analysis-retirement-terms">
            {{ retirementPolicy }}
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row class="need-analysis-inset-title">
            I need enough money to last till I'm
          </f7-row>
          <f7-row v-if="loading" style="justify-content: center">
            <div
              class="skeleton-block"
              style="width: 54px; height: 54px; border-radius: 4px"
            ></div>
          </f7-row>

          <f7-row v-if="!loading" class="need-analysis-scroll-value">
            {{ moneyLastValue }}
          </f7-row>

          <f7-row class="range-limits-min-max">
            <f7-col align="left" width="50">
              <div
                v-if="loading"
                class="skeleton-block"
                style="width: 22px; height: 17px; border-radius: 4px"
              ></div>
              <span v-else class=""> 62 </span>
            </f7-col>
            <f7-col align="right" width="50">
              <div
                v-if="loading"
                class="skeleton-block"
                style="width: 22px; height: 17px; border-radius: 4px"
              ></div>
              <span v-else class=""> 102 </span>
            </f7-col>
          </f7-row>

          <f7-row
            v-if="loading"
            style="justify-content: center; margin-top: 5px; margin-bottom: 5px"
          >
            <div
              class="skeleton-block"
              style="width: 100%; height: 28px; border-radius: 4px; margin-top 3px; margin-bottom: 5px"
            ></div>
          </f7-row>
          <f7-range
            v-if="!loading"
            :min="62"
            :max="102"
            :step="1"
            :value="moneyLastValue"
            color="red"
            @range:change="moneyLastValueUpdater"
            style="--f7-range-bar-size: 5px"
          ></f7-range>
          <f7-row class="need-analysis-retirement-terms">
            {{ moneyLastPolicy }}
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row
            style="padding-bottom: 15px"
            class="need-analysis-inset-title"
          >
            My desired retirement lifestyle
          </f7-row>
          <f7-row class="btns-block">
            <f7-button
              id="modestBtn"
              @click="toggleLifeStyle($event)"
              :class="[
                activeModes
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              MODEST</f7-button
            >
            <f7-button
              id="lavishBtn"
              style="margin-left: 5px; margin-right: 5px;     width: 98px;}"
              @click="toggleLifeStyle($event)"
              :class="[
                activeLavish
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              COMFORTABLE</f7-button
            >
            <f7-button
              id="premierBtn"
              @click="toggleLifeStyle($event)"
              :class="[
                activePremier
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              LUXURIOUS</f7-button
            >
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        media-list
        inset
        class="saving-habits-tab"
        :class="
          invalidExpensesInput
            ? 'invalid-Box-Input  input-invalid'
            : 'valid-Box-Input'
        "
        style="margin-bottom: 25px"
      >
        <f7-list-item @click="focusText('month-saving')">
          <f7-row
            style="padding-bottom: 10px"
            class="need-analysis-saving-habit-insets"
          >
            <span style="display: flex; text-align: center">
              Est. monthly retirement expenses
              <img
                @click="retirementExpensesInfo()"
                class="tool-tip-info"
                src="@/assets/customIcons/custom-info-circle.svg"
                alt=""
              />
            </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row no-gap v-else>
            <input
              v-model="monthRetirementExInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator"
              @change="inputvalidator"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
            <!-- <f7-col class="box-dolar-sign" width="50" align="right"> S$</f7-col>
            <f7-col width="50" align="left" style="padding-left: 10px">
              <f7-input
                @keyup="inputvalidator"
                @change="inputvalidator"
                v-bind:value="monthRetirementExInput"
                v-on:input="monthRetirementExInput = $event.target.value"
                class="month-retirement-expenses-input"
                ref="monthretirement"
                type="text"
                placeholder="1000"
                clear-button
                error-message="Only numbers please!"
                required
                validate
                pattern="[0-9]*"
              ></f7-input>
            </f7-col> -->
          </f7-row>
        </f7-list-item>
      </f7-list>

      <!-- <f7-block class="need-analysis-retirement-terms">{{
        screenPolicy1
      }}</f7-block> -->

      <!-- <f7-block class="need-analysis-retirement-terms">{{
        screenPolicy2
      }}</f7-block> -->

      <f7-button
        v-if="settingEdit"
        @click="submitForm(validForm)"
        :disabled="!validForm || invalidExpensesInput"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="analysis-gray-btn-text">Save</div>
      </f7-button>

      <f7-button
        v-else
        @click="submitForm(validForm)"
        :disabled="!validForm || invalidExpensesInput"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="analysis-gray-btn-text">Next</div>
      </f7-button>
    </f7-block>
    <MonthtlyExpensesInfo />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
} from "framework7-vue";

import { API } from "aws-amplify";

import MonthtlyExpensesInfo from "@/components/modals/retirement-monthy-expenses-info.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Block,
    MonthtlyExpensesInfo,
  },
  props: {
    shortQuestionare: false,
    questionnerParam: {
      type: Object,
    },
    settingEdit: false,
    navBarTitle: "",
  },
  data() {
    return {
      screenStep: "STEP 2/4",
      screenTitle: "My Retirement Needs",
      screenDescription: "This will help us calculate your coverage needs",
      screenPolicy1:
        "After examining your current expenses, you might decide that you might need $1,500 per month during retirement. This figure would be adjusted for inflation, using the inflation rate predetermined based on the lifestyle that you select, so that it maintains its purchasing power over the years.",
      screenPolicy2:
        "Alternatively, a less accurate way is to estimate based on a percentage of your salary (say, 70%). So if your monthly salary is $2,500, using the 70% estimation, your retirement needs would be $1,750 per month (in today's dollars).",

      retirementValue: 62,
      retirementPolicy:
        "This is the age you want to stop working. Perhaps it is more appropriate to think of the age you want to be financially independent, though you may still continue to work, not because you have to but because you choose to.",
      moneyLastValue: 82,
      moneyLastPolicy:
        "Typically your nest egg should be sufficient to provide for your lifetime. Currently the average life expectancy for males is 80 and 85 for females. For conservative reasons, you should add some buffer, say 3 to 5 years in addition to the average life expectancy (which is how long, on average, people are likely to live)",

      activeModes: false,
      activeLavish: false,
      activePremier: false,
      loading: true,
      questionner: {},
      validExpensesInput: false,

      invalidExpensesInput: false,
      validForm: false,
      monthRetirementExInput: "",
      retirementLifeStyle: "",
      previusStep: "My Savings Habits",
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    async submitForm(validForm) {
      if (validForm) {
        f7.dialog.preloader("Processing...");

        this.questionner.retireAtAge = this.retirementValue ?? 0;
        this.questionner.needMoneyUntilAge = this.moneyLastValue ?? 0;
        this.questionner.retirementLifeStyle = this.retirementLifeStyle ?? "";
        // this.questionner.monthlyRetirementExpense =
        //   this.monthRetirementExInput ?? 0;
        this.questionner.monthlyRetirementExpense = parseInt(
          this.monthRetirementExInput.toString().replace(/,|[S$ ]/g, "")
        );

        await API.put("questionnaires", "/questionnaires", {
          body: this.questionner,
        })
          .then((result) => {
            f7.dialog.close();

            if (this.shortQuestionare) {
              f7.views.main.router.navigate(
                { name: "SavingHabits" },
                {
                  props: {
                    questionnerParam: this.questionner,
                    shortQuestionare: true,
                  },
                }
              );
            } else if (this.settingEdit) {
              // From Settings Edit Mode
              f7.views.main.router.navigate(
                { name: "settings" },
                {
                  props: {},
                }
              );
            } else {
              f7.views.main.router.navigate(
                { name: "LovedOnes" },
                {
                  props: {
                    questionnerParam: this.questionner,
                  },
                }
              );
            }
          })
          .catch((err) => {
            console.log(err);
            f7.dialog.close();
          });
      } else {
        // TODO: error message
      }
    },
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goPrevStep() {
      f7.views.main.router.back();
    },
    retirementExpensesInfo() {
      f7.sheet.open(".a-retirement-tt");
    },
    retirementValueUpdater(values) {
      this.retirementValue = values;
    },
    moneyLastValueUpdater(values) {
      this.moneyLastValue = values;
    },
    toggleLifeStyle(event) {
      // console.log("The pressed btn");
      // console.log(event.currentTarget.id);
      this.toggleLifeStyleSelect(event.currentTarget.id);
      this.inputvalidator();
    },
    toggleLifeStyleSelect(id) {
      // console.log(id);
      this.activeModes = false;
      this.activeLavish = false;
      this.activePremier = false;
      this.retirementLifeStyle = "";
      switch (id) {
        case "modestBtn":
          this.activeModes = true;
          this.activeLavish = false;
          this.activePremier = false;
          this.retirementLifeStyle = "modest";
          break;

        case "lavishBtn":
          this.activeModes = false;
          this.activeLavish = true;
          this.activePremier = false;
          this.retirementLifeStyle = "lavish";
          break;
        case "premierBtn":
          this.activeModes = false;
          this.activeLavish = false;
          this.activePremier = true;
          this.retirementLifeStyle = "premier";
          break;
      }

      // this.activeBTN = !this.activeBTN;
      // this.inactiveBTN = !this.inactiveBTN;
    },

    inputvalidator() {
      var regExp = /[a-zA-Z]/g;

      this.validForm = true;
      let valueMRE = this.monthRetirementExInput.toString().replace(/,|[S$ ]/g, "");
      if (regExp.test(valueMRE)) {
        this.invalidExpensesInput = true;
      } else {
        this.invalidExpensesInput = false;
      }

      if (parseInt(valueMRE) >= 0) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }

      if (!(this.activeModes || this.activeLavish || this.activePremier)) {
        this.validForm = false;
      }
      // console.log(this.validForm, this.invalidExpensesInput);
    },
  },
  async mounted() {
    /*     
    var goUp = this.$el.querySelector("#ttitle");
    goUp.scrollTop = 0;
    console.log("goUp");
    console.log(goUp);
 */
    // Comes from Secondary Landing Page
    this.questionner = this.questionnerParam;
    if (this.shortQuestionare) {
      // this.loading = false;
      this.screenStep = "STEP 1/2";
    } else if (this.settingEdit) {
      // From Settings Edit Mode
      // console.log("Setting Edit Mode");
      // console.log(this.navBarTitle);

      this.questionner = await API.get("questionnaires", "/questionnaires", {})
        .then((result) => {
          // console.log("questionnaires -> ", result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // this.loading = false;
    }

    // else {
    // console.log(this.questionner);
    this.retirementValue = this.questionner.retireAtAge ?? 62;
    this.moneyLastValue = this.questionner.needMoneyUntilAge ?? 82;
    this.retirementLifeStyle = this.questionner.retirementLifeStyle ?? "";
    this.monthRetirementExInput =
      Math.round(
        this.questionner.monthlyRetirementExpense ??
          this.questionner.monthlyIncome * 0.7
      ) ?? "";
    // this.questionner = this.questionnerParam;
    this.toggleLifeStyleSelect(this.retirementLifeStyle + "Btn");
    this.inputvalidator();
    this.loading = false;
    // }
  },
  computed: {
    activeUsers: function () {
      return this.policyUser.filter(function (user) {
        if (user.visible) {
          return user;
        }
      });
    },
  },
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.policyTerms {
  color: #07182b;

  opacity: 0.22;

  font-weight: 600;
  font-size: 11px;
  line-height: 17px;

  text-align: center;

  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
}

.close-modal {
  color: #9eaab1;

  position: fixed;
  left: 15px;
  top: 15px;
}
.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.other-relative-question-label {
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
  margin-bottom: 20px;
  color: #8e8e93;
}

.other-relative-question-input {
  margin-left: 15px;
  margin-right: 35px;

  border-bottom: 1px solid #e1e7eb;
}

.add-policy-other-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}

.retirement-boxes {
  margin-bottom: 0px;
  margin-top: 15px;
}

.retirement-inner-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}
</style>


