<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>

      <f7-nav-right>
        <f7-link style="padding-right: 10px" href="">
          <img src="@/assets/customIcons/custom-nav-tooltip.svg" alt="" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div>
      <f7-row class="legend-row">
        {{ legend }}
      </f7-row>

      <f7-row class="group-row">
        <f7-button @click="goTo" style="padding-right: 10px" class="btn-share">
          <img src="@/assets/customIcons/custom-share-white.svg" alt="" />
          <span class="btn-share-text"> Share </span>
        </f7-button>
      </f7-row>

      <img
        class="image-page"
        src="@/assets/customIcons/custom-page-arrow.svg"
        alt=""
      />

      <div
        style="
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          width: 90%;
          heigh: 1px;
          opacity: 0.5;
          margin-left: 5%;
        "
      ></div>
    </div>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
  },
  props: {},
  data() {
    return {
      loading: false,
      insureds: [],
      navbarTitle: "Gabi, Sarah, Yuko",
      legend: "Share a policy to this group",
      btnLegend: "Share",
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    goTo() {
      f7.views.main.router.navigate({ name: "SharedPeople" });
    },

    async loadDataScreen() {},
  },

  async mounted() {
    console.log("mounted");
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.legend-row {
  width: 135px;
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #1a2531;
}

.btn-share {
  background: #5e6d78;
  border-radius: 34px;
  width: 94px;
  height: 31px;
  margin-left: 50px;
  margin-bottom: 30px;
}

.btn-share-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 10px;
}

.image-page {
  position: absolute;
  right: 15px;
  top: 85px;
}
</style>
