<template>
  <f7-page hide-toolbar-on-scroll>
    <f7-navbar
      v-if="settingEdit"
      class="add-policy-nav-bar-shadow"
      back-link=""
    >
      <f7-nav-title class="add-policy-navbar-title">{{
        navBarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-navbar v-else class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-toolbar
      @click="goPrevStep()"
      class="toolbar-aligment"
      :position="'top'"
      v-if="!settingEdit"
    >
      <f7-link
        class="link back"
        href="#"
        style="padding-left: 5%; position: absolute; left: 10px"
      >
        <img src="@/assets/customIcons/custom-up-btn.svg" alt="" />
      </f7-link>

      <div v-if="!settingEdit" class="">
        {{ previusStep }}
      </div>
    </f7-toolbar>

    <f7-block v-if="!settingEdit" class="add-policy-step">
      {{ screenStep }}
    </f7-block>

    <f7-block v-if="!settingEdit" class="add-policy-title">
      {{ screenTitle }}
    </f7-block>
     
    <f7-block class="add-policy-user-columns">
      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row class="need-analysis-inset-title"> Height (cm) </f7-row>
          <f7-row class="need-analysis-scroll-value">
            {{ heightValue }}
          </f7-row>

          <f7-range
            :min="50"
            :max="220"
            :step="1"
            :scale="true"
            :value="heightValue"
            color="red"
            @range:change="heightValueUpdater"
            style="
              --f7-range-bar-size: 5px;
              --f7-range-scale-step-height: 10px;
              margin-bottom: 23px;
            "
          ></f7-range>
        </f7-list-item>
      </f7-list>

      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row class="need-analysis-inset-title"> Weight (kg) </f7-row>
          <f7-row class="need-analysis-scroll-value">
            {{ weightValue }}
          </f7-row>

          <f7-row class="range-limits-min-max">
            <f7-col align="left" width="50">
              <span class=""> 32 </span>
            </f7-col>
            <f7-col align="right" width="50">
              <span class=""> 200 </span>
            </f7-col>
          </f7-row>

          <f7-range
            :min="32"
            :max="200"
            :step="1"
            :value="weightValue"
            color="red"
            @range:change="weightValueUpdater"
            style="--f7-range-bar-size: 5px"
          ></f7-range>
        </f7-list-item>
      </f7-list>

      <f7-list media-list inset class="policy-detail-tab retirement-boxes">
        <f7-list-item class="retirement-inner-box">
          <f7-row
            style="padding-bottom: 15px"
            class="need-analysis-inset-title"
          >
            Smoking Habits
          </f7-row>
          <f7-row class="btns-block">
            <f7-button
              id="smokerBtn"
              @click="toggleSmoking($event)"
              :class="[
                activeSmoker
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              SMOKER</f7-button
            >
            <f7-button
              id="nonSmokerBtn"
              style="margin-left: 5px; margin-right: 5px; width: 120px"
              @click="toggleSmoking($event)"
              :class="[
                !activeSmoker
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              NON-SMOKER</f7-button
            >
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list inset class="">
        <f7-list-item
          class="my-health-insurance-title"
          style="
            --f7-list-item-border-color: transparent;
            --f7-list-border-color: transparent;
            justify-content: center;
          "
        >
          <f7-row class="need-analysis-inset-title">
            My Health insurance Concerns
          </f7-row>
        </f7-list-item>

        <!-- Hospitalisation expenses -->
        <f7-list-item
          v-if="activeHE"
          class="health-insurance-concern-list-active"
        >
          Hospitalisation expenses

          <div slot="after" @click="toggleConcerns('activeHE')">
            <img src="@/assets/customIcons/custom-red-checked.svg" alt="" />
          </div>
        </f7-list-item>

        <f7-list-item v-else class="health-insurance-concern-list">
          Hospitalisation expenses

          <div slot="after" @click="toggleConcerns('activeHE')">
            <img src="@/assets/customIcons/custom-disabled-plus.svg" alt="" />
          </div>
        </f7-list-item>

        <!-- Outpatient medical expenses -->
        <f7-list-item
          v-if="activeOME"
          class="health-insurance-concern-list-active"
        >
          Outpatient medical expenses

          <div slot="after" @click="toggleConcerns('activeOME')">
            <img src="@/assets/customIcons/custom-red-checked.svg" alt="" />
          </div>
        </f7-list-item>

        <f7-list-item v-else class="health-insurance-concern-list">
          Outpatient medical expenses

          <div slot="after" @click="toggleConcerns('activeOME')">
            <img src="@/assets/customIcons/custom-disabled-plus.svg" alt="" />
          </div>
        </f7-list-item>

        <!-- Major Illnesses -->
        <f7-list-item
          v-if="activeMI"
          class="health-insurance-concern-list-active"
        >
          Major illnesses (e.g. cancer)

          <div slot="after" @click="toggleConcerns('activeMI')">
            <img src="@/assets/customIcons/custom-red-checked.svg" alt="" />
          </div>
        </f7-list-item>

        <f7-list-item v-else class="health-insurance-concern-list">
          Major illnesses (e.g. cancer)

          <div slot="after" @click="toggleConcerns('activeMI')">
            <img src="@/assets/customIcons/custom-disabled-plus.svg" alt="" />
          </div>
        </f7-list-item>

        <!-- Loss of income due to illness -->
        <f7-list-item
          v-if="activeLIDI"
          class="health-insurance-concern-list-active"
          style="padding-bottom: 15px; border-bottom: 0px solid pink !important"
        >
          Loss of income due to illness

          <div slot="after" @click="toggleConcerns('activeLIDI')">
            <img src="@/assets/customIcons/custom-red-checked.svg" alt="" />
          </div>
        </f7-list-item>

        <f7-list-item
          v-else
          class="health-insurance-concern-list"
          style="padding-bottom: 15px; border-bottom: 0px solid pink !important"
        >
          Loss of income due to illness

          <div slot="after" @click="toggleConcerns('activeLIDI')">
            <img src="@/assets/customIcons/custom-disabled-plus.svg" alt="" />
          </div>
        </f7-list-item>
      </f7-list>

      <f7-button
        v-if="settingEdit"
        @click="goHome"
        class="need-analysis-btn-gray"
      >
        <div class="analysis-gray-btn-text">Save</div>
      </f7-button>

      <f7-button v-else @click="goHome" class="need-analysis-btn-red">
        <div class="add-policy-btn-red-text">Analyse</div>
      </f7-button>
    </f7-block>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7List,
  f7ListItem,
} from "framework7-vue";

import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Block,
    f7List,
    f7ListItem,
  },
  props: {
    testPicture: Object,
    questionnerParam: {
      type: Object,
    },
    settingEdit: false,
    navBarTitle: "",
  },
  data() {
    return {
      screenStep: "STEP 4/4",
      screenTitle: "My Health Status",
      screenDescription: "This will help us calculate your coverage needs",
      screenPolicy1:
        "After examining your current expenses, you might decide that you might need $1,500 per month during retirement. This figure would be adjusted for inflation, using the inflation rate predetermined based on the lifestyle that you select, so that it maintains its purchasing power over the years.",
      screenPolicy2:
        "Alternatively, a less accurate way is to estimate based on a percentage of your salary (say, 70%). So if your monthly salary is $2,500, using the 70% estimation, your retirement needs would be $1,750 per month (in today's dollars).",

      heightValue: 168,
      retirementPolicy:
        "This is the age you want to stop working. Perhaps it is more appropriate to think of the age you want to be financially independent, though you may still continue to work, not because you have to but because you choose to.",
      weightValue: 60,
      moneyLastPolicy:
        "Typically your nest egg should be sufficient to provide for your lifetime. Currently the average life expectancy for males is 80 and 85 for females. For conservative reasons, you should add some buffer, say 3 to 5 years in addition to the average life expectancy (which is how long, on average, people are likely to live)",

      activeSmoker: false,

      activeHE: false,
      activeOME: false,
      activeMI: false,
      activeLIDI: false,

      previusStep: "My Loved Ones",
      questionner: {},
    };
  },
  methods: {
    async goHome() {
      f7.dialog.preloader("Processing...");
      this.questionner.bodyHeight = this.heightValue ?? 0;
      this.questionner.bodyWeight = this.weightValue ?? 0;
      this.questionner.smoker = this.activeSmoker ?? false;
      this.questionner.healthInsuranceConcerns = this.getHealthConcerns() ?? "";

      await API.put("questionnaires", "/questionnaires", {
        body: this.questionner,
      })
        .then((result) => {
          f7.dialog.close();

          // f7.views.main.router.navigate({ name: "CoverageMain" });
          this.ShowDetails(0);
        })
        .catch((err) => {
          console.log(err);
          f7.dialog.close();
        });
    },
    ShowDetails(index) {
      if (this.settingEdit) {
        f7.views.main.router.navigate(
          { name: "settings" },
          {
            props: {},
          }
        );
      } else {
        f7.views.main.router.navigate(
          { name: "CoverageDetail" },
          {
            props: {
              selectedCoverageId: null,
              isQuizDone: true,
            },
          }
        );
      }
    },
    goPrevStep() {
      f7.views.main.router.back();
    },

    getHealthConcerns() {
      let result = "";
      if (this.activeHE) result += "HE;";
      if (this.activeOME) result += "OME;";
      if (this.activeMI) result += "MI;";
      if (this.activeLIDI) result += "LIDI;";

      return result.slice(0, -1);
    },
    heightValueUpdater(values) {
      this.heightValue = values;
    },
    weightValueUpdater(values) {
      this.weightValue = values;
    },
    toggleSmoking(event) {
      if (event.currentTarget.id == "smokerBtn") {
        this.activeSmoker = true;
      } else {
        this.activeSmoker = false;
      }
    },
    toggleConcerns(event) {
      switch (event) {
        case "activeHE":
          this.activeHE = !this.activeHE;
          break;
        case "activeOME":
          this.activeOME = !this.activeOME;
          break;
        case "activeMI":
          this.activeMI = !this.activeMI;
          break;
        case "activeLIDI":
          this.activeLIDI = !this.activeLIDI;
          break;
      }
    },
  },
  async mounted() {
    f7.dialog.preloader("Processing...");
    if (this.settingEdit) {
      this.questionner = await API.get("questionnaires", "/questionnaires", {})
        .then((result) => {
          console.log("questionnaires -> ", result);
          return result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.questionner = this.questionnerParam;
    }
    this.heightValue = this.questionner.bodyHeight ?? 160;
    this.weightValue = this.questionner.bodyWeight ?? 60;
    this.activeSmoker = this.questionner.smoker ?? false;
    if (this.questionner.healthInsuranceConcerns) {
      this.questionner.healthInsuranceConcerns.split(";").forEach((concern) => {
        this.toggleConcerns("active" + concern);
      });
    }
    f7.dialog.close();
  },
  computed: {},
};
</script>

<style scoped>
.weight-range-items {
  font-size: 18px;
  color: #9eaab1;
  vertical-align: top;
  font-weight: normal;
  padding-right: 10px;
}

.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.policyTerms {
  color: #07182b;

  opacity: 0.22;

  font-weight: 600;
  font-size: 11px;
  line-height: 17px;

  text-align: center;

  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
}

.add-policy-other-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}

.retirement-boxes {
  margin-bottom: 0px;
  margin-top: 15px;
}

.retirement-inner-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.health-insurance-concern-list {
  font-size: 16px;
  line-height: 16px;
  color: #07182b;

  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid #e1e7eb;

  padding-top: 5px;
  padding-bottom: 5px;
}

.health-insurance-concern-list-active {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #07182b;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid #e1e7eb;

  padding-top: 5px;
  padding-bottom: 5px;
}

.my-health-insurance-title * {
  justify-content: center;
}
</style>


