<template>
  <f7-sheet
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.08);
      --f7-sheet-bg-color: #fff;
    "
    backdrop
    :class="modalClass + ' ' + modalClassId"
  >
    <f7-page-content>
      <div class="puchase-modal-header">
        <f7-button icon-ios="f7:xmark" @click="resetWorkflow"> </f7-button>
        <span class="modal-title">{{ modalTitle }}</span>
        <f7-link @click="modalBackClick">Back</f7-link>
      </div>
      <div class="paymentPlan-options disabled">
        <f7-button
          id="isInstalments"
          style="margin-left: 5px; margin-right: 5px"
          @click="togglePaymentPlan($event)"
          :class="[
            isPayInInstalments
              ? 'need-analysis-retirement-fill-btn'
              : 'need-analysis-retirement-no-fill-btn',
          ]"
        >
          YES</f7-button
        >
        <f7-button
          id="notInstalments"
          style="margin-left: 5px; margin-right: 5px"
          @click="togglePaymentPlan($event)"
          :class="[
            !isPayInInstalments
              ? 'need-analysis-retirement-fill-btn'
              : 'need-analysis-retirement-no-fill-btn',
          ]"
        >
          NO</f7-button
        >
      </div>
      <div class="instalment-options disabled">
        <span class="title">Select instalment period</span>
        <div class="paymentPlan-options small">
          <f7-button
            v-for="option in options"
            :key="option.id"
            :id="'instalment-' + option.id"
            :value="option.id"
            style="margin-left: 5px; margin-right: 5px"
            @click="toggleInstalmentPeriod($event)"
            :class="[
              (option.selected && isPayInInstalments) 
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
              !isPayInInstalments ? 'disabled' : '',
            ]"
          >
            {{ option.label }}</f7-button
          >
        </div>
        <span>0% interest up to 6 months. <a href="#">More details.</a></span>
      </div>
      <f7-button
        @click="modalBtnClick"
        style="margin-bottom: 30px"
        class="btn-red-text btn-red"
        >Continue</f7-button
      >
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    modalClassId: {
      type: String,
      default: "",
    },
    cards: null,
  },
  data() {
    return {
      modalClass: "",
      modalTitle: "Pay in instalments with DBS My Preferred Payment Plan?",
      isPayInInstalments: true,
      options: [
        {
          id: 3,
          label: "3",
          selected: false,
        },
        {
          id: 6,
          label: "6",
          selected: true,
        },
        {
          id: 9,
          label: "9",
          selected: false,
        },
        {
          id: 12,
          label: "12",
          selected: false,
        },
        {
          id: 18,
          label: "18",
          selected: false,
        },
        {
          id: 24,
          label: "24",
          selected: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    togglePaymentPlan(event) {
      console.log("togglePaymentPlan", event.currentTarget.id);
      if (event.currentTarget.id == "isInstalments") {
        this.isPayInInstalments = true;
      } else {
        this.isPayInInstalments = false;
      }
    },

    toggleInstalmentPeriod(event) {
      console.log("toggleInstalmentPeriod", event.currentTarget.id);
      let value = event.currentTarget.id.replace("instalment-", "");
      console.log("new value -> ", value);
      this.options.forEach(op => {
        op.selected = value == op.id;
      });
    },

    modalBtnClick() {
      this.$emit("modalBtnClick");
    },
    modalBackClick() {
      this.$emit("modalBackClick");
    },
    resetWorkflow(){
      this.$emit("resetWorkflow");
    },
  },
};
</script>

<style scoped>
.modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  padding-left: 5%;
  padding-right: 5%;
}

.puchase-modal-header {
  display: flex;
  align-items: baseline;
  padding: 24px 22px 8px 22px;
}
</style>

<style>
.puchase-modal-header .btnBack {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: right;
  color: #3677c4;
}

.puchase-modal-header .sheet-close {
  color: #9eaab1;
}

.puchase-modal-header .sheet-close .f7-icons {
  font-size: 25px;
}
.payment-option {
  display: flex;
  margin-bottom: 12px;
  padding: 21px 20px;
  border: 1px solid #e1e7eb;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  align-items: center;
}

.payment-option.selected {
  border: 1px solid #ff3333;
}

.payment-option .img-wrapper {
  display: flex;
  width: 42px;
  height: 32px;
}
.payment-option span {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #07182b;
}

.paymentPlan-options {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.paymentPlan-options .need-analysis-retirement-fill-btn,
.paymentPlan-options .need-analysis-retirement-no-fill-btn {
  width: 53px;
}

.paymentPlan-options.small .need-analysis-retirement-fill-btn,
.paymentPlan-options.small .need-analysis-retirement-no-fill-btn {
  width: 51px;
}

.paymentPlan-options.small {
  padding: 0 25px;
}

.instalment-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.instalment-options .title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  opacity: 0.5;
}

.instalment-options span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #07182b;
  opacity: 0.22;
}

.instalment-options span a {
  color: #07182b;
  text-decoration: underline;
}
</style>
