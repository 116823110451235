<template>
  <div class="custom-progress-wrapper">
    <span v-if="!error && !hidePercentage" class="title"
      >{{ percentage }}%</span
    >
    <circle-progress
      v-if="!error"
      :size="size"
      :is-gradient="true"
      :border-width="BorderWidth"
      :border-bg-width="0"
      :percent="percentage"
      class="custom-progress"
      :gradient="{
        angle: 90,
        startColor: 'rgba(54, 119, 196, 1)',
        stopColor: 'rgba(54, 119, 196, 1)',
      }"
    >
    </circle-progress>
    <circle-progress
      v-if="error"
      :size="size"
      :is-gradient="true"
      :border-width="BorderWidth"
      :border-bg-width="0"
      :percent="0"
      class="custom-progress error"
      :gradient="{
        angle: 90,
        startColor: 'rgba(255, 51, 51, 1)',
        stopColor: 'rgba(255, 51, 51, 1)',
      }"
    >
    </circle-progress>
    <span class="legend" :class="{ error: error }">{{ legend }}</span>
    <span class="subtitle">{{ message }}</span>
  </div>
</template>

<script>
import CircleProgress from "vue3-circle-progress";
import custombg from "@/assets/progress-stroke-bg.svg";
export default {
  components: { CircleProgress, custombg },
  props: {
    size: {
      type: Number,
      default: 94,
    },
    BorderWidth: {
      type: Number,
      default: 4,
    },
    percentage: {
      type: Number,
      default: 74,
      required: true,
    },
    legend: {
      type: String,
      default: "We are uploading your policy...",
    },
    error: {
      type: Boolean,
      default: false,
    },
    hidePercentage: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  computed: {},
};
</script>

<style scoped>
.custom-progress-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
}

.custom-progress-wrapper .title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3677c4;
  margin-bottom: 14px;
}

.custom-progress::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-image: url("../assets/progress-stroke-bg.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: contain;
}

.custom-progress::after {
  content: "";
  width: 42px;
  height: 34px;
  display: block;
  background-image: url("../assets/progress-bg.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  top: calc(50% - 17px);
  left: calc(50% - 21px);
}

.custom-progress.error::after {
  background-image: url("../assets/progress-error-bg.svg");
  top: calc(50% - 19px);
}

.custom-progress-wrapper .legend {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #07182b;
  max-width: 212px;
  width: 212px;
  margin-top: 20px;
}

.custom-progress-wrapper .legend.error {
  color: #ff3333;
  font-size: 14px;
}

.custom-progress-wrapper .subtitle {
  padding: 0 40px;
  margin-top: 47px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
}
</style>