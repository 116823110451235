<template>
  <f7-page
    @page:beforeremove="onPageBeforeRemove"
    @page:beforeout="onPageBeforeOut"
  >
    <f7-navbar class="nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          v-if="isNewPolicy"
          @click="goHome()"
          style="color: #3677c4; padding-left: 9px"
          >Done</f7-link
        >

        <f7-link
          v-else
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Policy information</f7-nav-title>
      <f7-nav-right>
        <f7-button class="add-policy-btn icon" href="/add-policy-1">
          Add policy
        </f7-button>
        <!-- <f7-link class="add-policy-btn" style="padding-right: 10px" href="/add-policy-1">
          
          <img src="@/assets/customIcons/custom-red-plus.svg" alt="" />
        </f7-link> -->
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar class="toolbar-edit" :position="'top'" v-if="isReadOnly">
      <div class="" style="padding-left: 10px">
        This policy has been shared with you
      </div>
    </f7-toolbar>

    <f7-toolbar
      class="toolbar-edit"
      :position="'top'"
      v-else-if="!shortQuestionare && !settingEdit"
    >
      <div class="" style="padding-left: 10px">
        Something not looking right?
      </div>

      <div @click="editPolicy" class="link-edit-policy">
        Edit policy

        <f7-link icon-ios="f7:chevron_right" style="color: #3677c4"></f7-link>
      </div>
    </f7-toolbar>

    <f7-block>
      <f7-row class="logo-row">
        <f7-col style="padding-top: 6px" align="left" width="15">
          <f7-link
            v-if="!isReadOnly"
            class="add-policy-btn white"
            style="padding-right: 10px"
            @click="goTo"
          >
            <img src="@/assets/customIcons/custom-share.svg" alt="" />
            Share
          </f7-link>

          <!-- <img
            v-if="!isReadOnly"
            @click="goTo"
            src="@/assets/customIcons/custom-share.svg"
            alt=""
          /> -->
        </f7-col>

        <f7-col align="center" width="70">
          <img style="width: 75px; height: 75px" :src="InsurerLogo" alt="" />
          <div class="policy-title">
            {{ policy.productName ?? " - " }}
          </div>

          <div v-if="policy.statusLabel == 'active'" class="policy-subtitle">
            {{ policy.productType ?? "-" }}
          </div>
        </f7-col>

        <f7-col width="15">
          <f7-link popover-open=".popover-menu" v-if="!isReadOnly">
            <img src="@/assets/customIcons/custom-ellipsis.svg" alt="" />
          </f7-link>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block>
      <f7-row>
        <f7-col width="50">
          <span class="policy-amount">
            <!-- {{  $filters.currency( policy.insuredAmount ?? 0 )  }} -->
            {{ $filters.currency(policy.premiumAmount ?? 0) }}
          </span>
          <span class="policy-amount-peryear">
            {{ $filters.frequencyLegend(policy.frequency) }}</span
          >
        </f7-col>
        <f7-col align="right" width="50">
          <div class="policy-status" :class="policy.statusLabel">
            {{ policy.statusLabel }}
          </div>
          <!-- <f7-button v-if="isPending" class="pending-btn" @click="updateStatus"
            >PENDING</f7-button
          >
          <f7-button v-else class="active-btn" @click="updateStatus"
            >ACTIVE</f7-button
          > -->
        </f7-col>
      </f7-row>
    </f7-block>

    <div class="break-line"></div>

    <f7-list media-list inset class="policy-owner-tab">
      <f7-list-item>
        <f7-row style="align-items: center">
          <f7-col width="15" style="margin-right: 10px">
            <img :src="insured.avatar" alt="" />
          </f7-col>
          <f7-col
            class="policy-owner-name"
            :class="insured.healthStatus"
            width="80"
            style="margin-left: 5px"
          >
            {{ insured.firstName }} {{ insured.lastName }}
          </f7-col>
        </f7-row>
      </f7-list-item>

      <f7-list-item
        v-if="!isReadOnly && (insured.relationship ?? '') != 'myself'"
        class="dependant-toggle-wrapper"
      >
        <f7-row>
          <f7-col width="50">
            <f7-toggle
              color="green"
              style="
                --f7-toggle-handle-color: #fff;
                --f7-toggle-inactive-color: #e5e5ea;
                --f7-toggle-width: 51px;
                --f7-toggle-height: 31px;
                --f7-theme-color: #11bba2;
              "
              :checked="insured.dependent ?? false"
              @click="toggleDependent($event.target.checked)"
            ></f7-toggle>
            <span
              class="policy-block-primary-text"
              style="margin-left: 9px"
              :class="[
                insured.dependent ? 'active-dependent' : 'inactive-dependent',
              ]"
              >Dependent</span
            >
          </f7-col>

          <f7-col align="right" width="50">
            <span class="disabled-text"
              >Improves analysis
              <f7-button
                @click="improveAnalysis"
                class="info-test-btn"
                style="opacity: 0.5"
              >
                <img src="@/assets/customIcons/custom-info-circle.svg" alt="" />
              </f7-button>
            </span>
          </f7-col>
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-block-title class="coverage-title">POLICY DETAILS</f7-block-title>

    <f7-list media-list inset class="policy-detail-tab">
      <f7-list-item>
        <f7-row class="policy-block-primary-text">
          <f7-col>Policy number </f7-col>
          <f7-col class="policy-block-left-column">Coverage term</f7-col>
        </f7-row>
        <f7-row class="policy-block-secondary-text">
          <f7-col> {{ policy.policyNumber ?? "-" }}</f7-col>
          <f7-col class="policy-block-left-column">
            {{
              policy.coverageYearTerm
                ? policy.coverageYearTerm == "-1"
                  ? "Whole of life"
                  : policy.coverageYearTerm == "-2"
                  ? "Lump sum"
                  : policy.coverageYearTerm + " Years"
                : "-"
            }}
          </f7-col>
        </f7-row>
      </f7-list-item>

      <f7-list-item>
        <f7-row class="policy-block-primary-text">
          <f7-col>Start date </f7-col>
          <f7-col class="policy-block-left-column">Expiry date</f7-col>
        </f7-row>
        <f7-row class="policy-block-secondary-text">
          <f7-col> {{ policy.startDate ?? "-" }}</f7-col>
          <f7-col class="policy-block-left-column">
            {{ policy.expiryDate ?? "-" }}</f7-col
          >
        </f7-row>
      </f7-list-item>

      <f7-list-item>
        <f7-row class="policy-block-primary-text">
          <f7-col>Premium amount </f7-col>
          <f7-col class="policy-block-left-column">Premium term</f7-col>
        </f7-row>
        <f7-row class="policy-block-secondary-text">
          <f7-col>
            {{ $filters.currency(policy.premiumAmount ?? 0) }}
            {{ $filters.frequencyLegend(policy.frequency) }}</f7-col
          >
          <f7-col class="policy-block-left-column">
            {{
              policy.premiumTermYear
                ? policy.premiumTermYear == "-1"
                  ? "Whole of life"
                  : policy.premiumTermYear == "-2"
                  ? "Lump sum"
                  : policy.premiumTermYear + " Years"
                : "-"
            }}
          </f7-col>
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-block class="assistant">
      <f7-list media-list class="coverage-options">
        <f7-list-item
          @click="openHelp"
          link="#"
          :title="getSelectedInsurer"
          subtitle="Let me know if I can help :)"
        >
          <template #media>
            <div class="assistantAvatar online">
              <img src="@/assets/raw_teleadvisor.png" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block-title class="coverage-title">
      <f7-row>
        <f7-col>Coverage Details</f7-col>
        <f7-col style="text-align: right; margin-right: 15px"
          >Basic Sum Assured</f7-col
        >
      </f7-row>
    </f7-block-title>
    <div class="coverage-sum-detail-tab-wrapper">
      <CustomLoader :isActive="isPending" />
      <f7-list
        media-list
        inset
        class="coverage-sum-detail-tab"
        style="margin-top: 0px; margin-bottom: 0px"
      >
        <f7-list-item class="coverage-sum-blocks">
          <f7-row>
            <f7-col class="policy-block-primary-text">Death</f7-col>
            <f7-col
              :class="
                typeof policy.coverageDeath !== 'undefined'
                  ? 'policy-block-secondary-text'
                  : 'policy-block-primary-text'
              "
              class="policy-block-left-column"
            >
              {{
                policy.coverageDeath
                  ? policy.coverageDeath == -1
                    ? "Unlimited"
                    : $filters.currency(policy.coverageDeath ?? 0, 0)
                  : "Not covered"
              }}
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item class="coverage-sum-blocks-second">
          <f7-row>
            <f7-col class="policy-block-primary-text"
              >Total &amp; Permanent Disability</f7-col
            >
            <f7-col
              :class="
                typeof policy.coverageTPD !== 'undefined'
                  ? 'policy-block-secondary-text'
                  : 'policy-block-primary-text'
              "
              class="policy-block-left-column"
              >{{
                policy.coverageTPD
                  ? policy.coverageTPD == -1
                    ? "Unlimited"
                    : $filters.currency(policy.coverageTPD ?? 0, 0)
                  : "Not covered"
              }}</f7-col
            >
          </f7-row>
        </f7-list-item>

        <f7-list-item class="coverage-sum-blocks">
          <f7-row>
            <f7-col class="policy-block-primary-text">Critical Illiness</f7-col>
            <f7-col
              :class="
                typeof policy.coverageCI !== 'undefined'
                  ? 'policy-block-secondary-text'
                  : 'policy-block-primary-text'
              "
              class="policy-block-left-column"
            >
              {{
                policy.coverageCI
                  ? policy.coverageCI == -1
                    ? "Unlimited"
                    : $filters.currency(policy.coverageCI ?? 0, 0)
                  : "Not covered"
              }}
            </f7-col>
          </f7-row>
        </f7-list-item>

        <!-- <f7-list-item class="coverage-sum-blocks-last">
          <f7-row>
            <f7-col class="policy-block-primary-text"
              >Early Critical Illiness</f7-col
            >
            <f7-col
              :class="
                typeof policy.coverageECI !== 'undefined'
                  ? 'policy-block-secondary-text'
                  : 'policy-block-primary-text'
              "
              class="policy-block-left-column"
              >{{
                policy.coverageECI
                  ? $filters.currency(policy.coverageECI ?? 0, 0)
                  : "Not covered"
              }}</f7-col
            >
          </f7-row>
        </f7-list-item> -->
      </f7-list>
    </div>

    <f7-block-title class="coverage-title"> MORE INFO </f7-block-title>
    <f7-list inset class="more-info-tab">
      <f7-list-item
        class="policy-block-primary-text more-info-bocks"
        style=""
        title="Policy Wording"
        v-if="policy.statusLabel == 'active'"
      >
        <f7-button
          @click="OpenPolicyWording"
          outline
          round
          class="more-info-btn"
          >Read More</f7-button
        >
      </f7-list-item>
      <f7-list-item
        v-if="!isReadOnly"
        class="policy-block-primary-text more-info-bocks"
        style=""
        title="Attachment"
      >
        <f7-button
          @click="OpenPolicyAttachment"
          outline
          round
          class="more-info-btn"
          >Open</f7-button
        >
      </f7-list-item>
    </f7-list>

    <!-- POPOVER -->

    <f7-popover class="popover-menu" v-if="!isReadOnly">
      <f7-list>
        <f7-list-item
          @click="editPolicy"
          class="popover-list-element"
          popover-close
          title="Edit Policy"
          style="--f7-list-item-border-color: transparent"
        >
          <img src="@/assets/customIcons/custom-pen.svg" alt="" />
        </f7-list-item>

        <f7-list-item
          @click="confirmDelete"
          style="color: red"
          popover-close
          title="Delete Policy"
        >
          <img src="@/assets/customIcons/custom-trashcan.svg" alt="" />
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <!-- MODALS -->

    <!-- policy-details -->
    <!-- <f7-sheet
      class="policy-details"
      style="
        height: auto;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-button sheet-close>
          <f7-icon class="close-modal-no-title" f7="xmark"></f7-icon>
        </f7-button>

        <f7-block class="policy-information-modal">
          <img src="@/assets/customIcons/custom-star.svg" alt="" />

          <div class="" style="margin: 35px">
            Coverage needs for other insureds are indicative only. Only the
            insured will be able to view their personalised needs analysis.
          </div>

          <f7-button sheet-close class="btn-red-text btn-red">OK</f7-button>
        </f7-block>
      </f7-page-content>
    </f7-sheet> -->
    <ModalOtherMembers />

    <!-- view-details -->
    <f7-sheet
      class="view-policy"
      style="
        height: auto;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="policy-information-close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title">
              We have finished processing your policy!
            </f7-col>
          </f7-row>
        </f7-block>

        <f7-block class="policy-information-modal">
          <img src="@/assets/customIcons/custom-policy-summary.svg" alt="" />

          <f7-button class="btn-red-text btn-red">View policy</f7-button>
        </f7-block>
      </f7-page-content>
    </f7-sheet>

    <!--     <f7-button sheet-open=".view-policy">view-details</f7-button>
 -->
    <!-- uploaded-file -->
    <f7-sheet
      class="uploaded-file"
      style="
        height: 90%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="policy-information-close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title">
              {{ "Attachment" }}
            </f7-col>
          </f7-row>
        </f7-block>

        <div class="policy_information_uploaded_file">
          <div id="pdf-container">
            <!-- <a :href="fileUrl">
              <canvas :id="pdf_id" />
            </a> -->
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>

    <!-- policy-wording-file -->
    <f7-sheet
      class="policy-wording-file"
      style="
        height: 90%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="policy-information-close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title">
              {{ "Policy wording" }}
            </f7-col>
          </f7-row>
        </f7-block>

        <div class="policy_information_uploaded_file">
          <div id="pdf-wording-container"></div>
        </div>
      </f7-page-content>
    </f7-sheet>

    <ImproveAnalysisTT />

    <!-- delete policy -->
    <f7-sheet
      class="delete-policy"
      style="
        height: auto;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-block>
          <f7-row align="left" class="policy-information-close-modal">
            <f7-col>
              <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col align="center" class="modal-title"> Delete policy </f7-col>
          </f7-row>
        </f7-block>

        <f7-block class="policy-information-modal delete">
          <img src="@/assets/customIcons/custom-policy-summary.svg" alt="" />
          <span
            >Deleting this policy will remove it from your vault and vaults of
            people you have shared this policy with.</span
          >
          <f7-button @click="deletePolicy" class="btn-red-text btn-red"
            >Confirm delete</f7-button
          >
        </f7-block>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Button,
  f7BlockTitle,
  f7Block,
  f7NavRight,
  //  f7Card,
  f7List,
  f7ListItem,
  f7Gauge,
  f7Toggle,
  f7Icon,
  f7Row,
  f7Col,
  f7,
} from "framework7-vue";

import CustomLoader from "@/components/custom-loader.vue";
import ModalOtherMembers from "@/components/modals/coverage-family-members.vue";

import * as pdfjsLib from "pdfjs-dist/es5/build/pdf.js";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

import pdfFile from "@/assets/Contract_Sample.pdf";

import $ from "dom7";

import { Storage, API } from "aws-amplify";

import logo1 from "@/assets/logo_great_eastern.png";
import logo2 from "@/assets/logo_manulife.png";
import logo3 from "@/assets/logo_prudential.png";
import logo4 from "@/assets/customIcons/custom-axa-logo.svg";

import policyWording from "@/assets/policy_wording_sample.pdf";
import ImproveAnalysisTT from "@/components/modals/improve-analysis-tool-tip.vue";

export default {
  name: "PdfThumbnail",
  props: {
    fileUrl: {
      type: String,
      default: pdfFile,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 600,
    },
    newInsured: null,
    policyOwnerProduct: null,
    ///////////////////
    policyid: {
      type: String,
      default: null,
    },
    isNewPolicy: {
      type: Boolean,
      default: false,
    },
    askReloadMainPage: false,
  },
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Button,
    f7BlockTitle,
    f7Toggle,
    f7Block,
    f7List,
    f7ListItem,
    f7Icon,
    f7Gauge,
    f7Row,
    f7Col,
    CustomLoader,
    ModalOtherMembers,
    ImproveAnalysisTT,
  },
  data() {
    return {
      isReadOnly: true,
      sheetOpened: false,
      iconTitle: "Vivocare",
      iconSubTitle: "Whole Life",
      policyAmount: "$1,212",
      policyOwnerFirstName: "Omari",
      policyOwnerLastName: "Takashima",
      isPending: true,
      //  fileUrl: pdfFile, //"./Contract_Sample.pdf",
      pdf_id: 1,
      _uid: 1,
      product: {},
      policy: {},
      insured: {},
      accountOwner: {},
      InsurerLogo: null,
      reloadHomePage: false,
      shortQuestionare: false,
      settingEdit: false,
    };
  },
  async mounted() {
    f7.dialog.preloader("Loading...");
    // console.log("policyid -> ", this.policyid);
    let policiyResult = await API.get(
      "policies",
      "/policies/" + this.policyid,
      {}
    )
      .then((result) => {
        // console.log("policy -> ", result);
        return result;
      })
      .catch((err) => {
        console.log(err);
      });
    if (policiyResult) {
      // this.policy = result;
      this.policy = policiyResult.data;

      if (this.policy.owner === localStorage.ownerInsuredId) {
        this.isReadOnly = false;
      }

      this.policy.logo = logo2;
      this.policy.productName = this.policy.productName
        ? this.policy.productName
        : "-";
      // this.policy.status = "pending";

      if (this.policy.status && this.policy.status.toString() == "1") {
        this.policy.statusLabel = "active";
        this.isPending = false;
      } else {
        this.policy.statusLabel = "pending";
        this.isPending = true;
      }
      console.log(this.policy);
      let insurerResponse = await API.get(
        "insureds",
        "/insureds/" + this.policy.insuredId,
        {}
      )
        .then((result) => {
          console.log("insured -> ", result);
          return result;
        })
        .catch((err) => {
          console.log(err);
        });

      this.insured = insurerResponse.data;

      this.insured.avatar = this.$filters.getInsuredAvatar(
        this.insured.relationship,
        this.insured.gender
      );
      console.log("mouted");
      let coverageAnalysis = await API.get(
        "coverageAnalysis",
        "/coverage-analysis",
        {
          queryStringParameters: {
            insuredId: this.insured.id,
          },
        }
      )
        .then((result) => {
          return result;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });

      this.insured.healthStatus = this.$filters.getCoverageClass(
        coverageAnalysis ? coverageAnalysis.portfolioHealth.score ?? 0 : 0
      );

      // console.log("this.insured -> ", this.insured);
      // console.log("this.policy.document -> ", this.policy.document);
      if (this.policy.document) {
        //   let fileExt = this.getFileExtension(this.policy.document);
        //   // console.log("fileExt -> ", fileExt);
        //   const signedURL = await Storage.get(this.policy.document, {
        //     level: "private",
        //   });

        this.policy.documentURL = await Storage.get(this.policy.document, {
          level: "private",
        });

        //   // console.log(policyWording);

        //   if (fileExt.toLowerCase() == "pdf") {
        //     this.renderThumbnails(signedURL, "pdf-container");
        //   } else {
        //     this.renderPicture(signedURL, "pdf-container");
        //   }

        //   this.renderThumbnails(policyWording, "pdf-wording-container");
      }

      let logoRef = this.policy.insurerLogo ?? "DEFAULT.png";

      const signedURL = await Storage.get("insurers/" + logoRef, {
        level: "public",
      });

      this.InsurerLogo = signedURL;
    }
    f7.dialog.close();
  },
  methods: {
    async OpenPolicyWording() {
      console.log("this.policy.document -> ", this.policy.document);
      try {
        if (this.policy.document) {
          f7.dialog.preloader("Loading...");
          await this.renderThumbnails(policyWording, "pdf-wording-container");

          f7.dialog.close();
          f7.sheet.open(".policy-wording-file");
        }
      } catch (error) {
        f7.dialog.close();
        f7.dialog.alert("Ups!, something went wrong, please try again");
      }
    },
    async OpenPolicyAttachment() {
      try {
        if (this.policy.document) {
          f7.dialog.preloader("Loading...");

          let fileExt = this.getFileExtension(this.policy.document);
          // console.log("fileExt -> ", fileExt);
          // console.log("this.policy.documentURL -> ", this.policy.documentURL);

          if (fileExt.toLowerCase() == "pdf") {
            await this.renderThumbnails(
              this.policy.documentURL,
              "pdf-container"
            );
          } else {
            this.renderPicture(this.policy.documentURL, "pdf-container");
          }

          f7.dialog.close();
          f7.sheet.open(".uploaded-file");
        }
      } catch (error) {
        f7.dialog.close();
        f7.dialog.alert("Ups!, something went wrong, please try again");
      }
    },
    getFileExtension(filename) {
      var ext = /^.+\.([^.]+)$/.exec(filename);
      return ext == null ? "" : ext[1];
    },
    async renderThumbnails(docURL, containerId) {
      // console.log(this._uid);
      const worker = new pdfjsLib.PDFWorker();
      let pdf = await pdfjsLib
        .getDocument({ url: docURL, worker: worker })
        .promise.then((pdf) => pdf);

      // console.log("numPages->", pdf.numPages);
      var container = document.getElementById(containerId);
      container.classList.remove("has-picture");
      container.innerHTML = "";
      for (let index = 0; index < pdf.numPages; index++) {
        const page = await pdf.getPage(index + 1);
        // console.log("page -> ", page);
        let viewport = page.getViewport({ scale: 3.0 });

        var canvas = document.createElement("canvas");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.id = containerId + "_" + index;
        // console.log("canvas -> ", canvas);
        container.appendChild(canvas);
        // console.log("container -> ", container);

        const context = canvas.getContext("2d");
        // console.log("context -> ", context);
        // console.log("viewport -> ", viewport);
        page.render({ canvasContext: context, viewport: viewport });
        // console.log("container 2 -> ", container);
      }
    },
    renderPicture(docURL) {
      var container = document.getElementById("pdf-container");
      container.innerHTML = "";
      container.classList.add("has-picture");
      var img = document.createElement("img");
      container.appendChild(img);
      img.src = docURL;
    },
    onPageBeforeOut() {
      // Close opened sheets on page out
      f7.sheet.close();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy sheet modal when page removed
      if (self.sheet) self.sheet.destroy();
    },
    updateStatus() {
      let newVal = this.isPending;
      this.isPending = !newVal;
    },
    goHome() {
      if (this.isNewPolicy || this.reloadHomePage || this.askReloadMainPage)
        f7.views.main.router.navigate({ name: "CoverageMain" });
      else f7.views.main.router.back();
    },
    improveAnalysis() {
      f7.sheet.open(".improve-analysis-tt");
    },

    deletePolicy() {
      let path = "/policies/" + this.policyid;

      API.del("policies", path, {})
        .then((result) => {
          // console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
      this.reloadHomePage = true;
      this.goHome();
    },
    confirmDelete() {
      f7.popover.close();
      f7.sheet.open(".delete-policy");
    },
    async toggleDependent(value) {
      if (typeof value !== "undefined") {
        f7.dialog.preloader("Loading...");
        this.insured.dependent = value;

        await this.updateProfile(this.insured.id, this.insured);
        f7.dialog.close();
      }
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          // console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },
    editPolicy() {
      f7.views.main.router.navigate(
        { name: "EditPolicy" },
        {
          props: {
            policy: this.policy,
            insured: this.insured,
          },
        }
      );
    },
    goTo() {
      f7.views.main.router.navigate(
        { name: "Sharing" },
        {
          props: {
            isPolicyDetail: false,
            policy: this.policy,
          },
        }
      );
    },
    openHelp() {
      window.open("https://www.dbs.com.sg/personal/TeleAdvisory");
    },
  },

  computed: {
    getSelectedInsurer() {
      return "Hi " + localStorage.OwnerName + "!";
    },
  },
};
</script>

<style scoped>
.policy-owner-name::before {
  content: "";
  margin-right: 3px;
  width: 8px;
  height: 8px;
  display: block;
  transform: rotate(45deg);
  margin-right: 5px;
}

.policy-owner-name.alert::before {
  background: #ff3333;
}

.policy-owner-name.warn::before {
  background: #fda937;
}

.policy-owner-name.pass::before {
  background: #11bba2;
}

.logo-row {
  align-content: center;
}

.more-info-btn {
  color: #5e6d78;
  border-color: #5e6d78;
  border: 1px solid #5e6d78;
}

.disabled-text {
  color: #c0cbd2;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.info-test-btn {
  width: 30px !important;
  height: 30px !important;
}

.policy-owner-name {
  color: #07182b;
  font-size: 17px;
  font-weight: bold;
  text-transform: none;
  display: flex;
  align-items: center;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  /*   display: flex !important; */
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  /* #FF3333 */
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;

  font-size: 15px;
  line-height: 23px;

  text-align: center;

  color: #ffffff;
  letter-spacing: 0.04em;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.close-modal-no-title {
  color: #9eaab1;
  position: fixed;
  left: 15px;
  top: 15px;
}

.policy-information-close-modal {
  font-size: 25px;
  color: #9eaab1;
  width: 30px;
  height: 30px;

  position: fixed;
  left: 15px;
  top: 25px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  padding-left: 10%;
  padding-right: 10%;
}

.coverage-sum-detail-tab-wrapper {
  /* box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07); */
  position: relative;
}

.coverage-title {
  margin-left: 30px;
  margin-bottom: 10px;
}

.policy-status {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0px 10px;
  color: #9eaab1;
  border: 1px solid #9eaab1;
  border-radius: 4px;
  max-width: 40px;
  display: flex;
  justify-content: center;
}

.policy-status.due {
  color: #ffffff;
  background: #ff3333;
  border: 1px solid #ff3333;
}

.policy-status.active {
  color: #09bcc3;
  border: 1px solid #09bcc3;
}

.policy-status.cancelled {
  color: #9eaab1;
  border: 1px solid #9eaab1;
}

.policy-status.pending {
  color: #fda937;
  border: 1px solid #fda937;
}

.toolbar-edit {
  justify-content: space-between;
  height: 50px;
}

.link-edit-policy {
  display: flex;
  align-items: center;
  color: #3677c4;
  font-weight: bold;

  line-height: 24px;
}

.popover-list-element {
  color: black;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color: rgba(60, 60, 67, 0.36);
}

.active-dependent {
  opacity: 1;
}

.inactive-dependent {
  opacity: 0.5;
}
</style>

<style>
.dependant-toggle-wrapper .item-inner {
  border-top: 1px solid #e1e7eb;
}
#pdf-container,
#pdf-wording-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow-y: auto;
}

#pdf-container.has-picture,
#pdf-wording-container.has-picture {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
#pdf-container canvas,
#pdf-wording-container canvas {
  width: 90%;
  height: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  margin-bottom: 5px;
}

#pdf-container img,
#pdf-wording-container img {
  object-fit: contain;
  height: auto;
  width: 90%;
}
</style>