<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>

    <f7-row no-gap style="margin-bottom: 20px; margin-top: 20px">
      <f7-col class="list-title-block" width="50" align="left"> PEOPLE</f7-col>
      <f7-col class="" style="padding-right: 15px" width="50" align="right">
        <!-- <img
          v-if="ownerId == sharingGroup.owner.id"
          @click="invitePeople"
          src="@/assets/customIcons/custom-red-plus.svg"
          alt=""
        /> -->

        <f7-button class="add-policy-btn icon" style="max-width: 140px;" @click="invitePeople">
          Invite people
        </f7-button>
      </f7-col>
    </f7-row>

    <div :key="member.id" v-for="member in members">
      <f7-row class="group-row">
        <f7-col class="" align="center" width="20">
          <img
            v-if="member.gender === 'female'"
            style="width: 42px; height: 42px"
            src="@/assets/family_member1.svg"
          />
          <img
            v-else
            style="width: 42px; height: 42px"
            src="@/assets/family_member2.svg"
          />
        </f7-col>

        <f7-col
          v-if="ownerId == sharingGroup.owner.id && member.owner !== ownerId"
          style="padding-top: 9px"
          class="group-members"
          align="left"
          width="55"
        >
          <span>
            {{
              member.firstName + (member.lastName ? ` ${member.lastName}` : "")
            }}
          </span>
        </f7-col>
        <f7-col
          v-else
          style="padding-top: 9px"
          class="group-members"
          align="left"
          width="55"
        >
          <span>
            {{
              member.firstName + (member.lastName ? ` ${member.lastName}` : "")
            }}
          </span>
        </f7-col>

        <f7-col
          v-if="member.owner !== sharingGroup.owner.id"
          align="right"
          style="padding-top: 12px; padding-right: 9px"
          width="25"
        >
          <span v-if="ownerId == sharingGroup.owner.id"
            class="group-quantity-active"
            style="cursor: pointer"
            @click="removeMember(member)"
          >
            Remove
          </span>
        </f7-col>
        <f7-col
          v-else
          align="right"
          style="padding-top: 12px; padding-right: 9px"
          width="25"
        >
          <span class="group-quantity-active admin"> Admin </span>
        </f7-col>
      </f7-row>

      <div
        style="
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          width: 100%;
          heigh: 1px;
          opacity: 0.5;
        "
      ></div>
    </div>

    <f7-row
      v-if="ownerId == sharingGroup.owner.id"
      no-gap
      class="delete-row top-bottom-border"
      style=""
      @click="deleteGroupSheet"
    >
      <f7-col class="" style="padding-left: 5px" align="center" width="10">
        <img src="@/assets/customIcons/custom-red-trash-can.svg" alt="" />
      </f7-col>

      <f7-col class="delete-group" align="left" width="90">
        Delete Group
      </f7-col>
    </f7-row>
    <f7-row>
      <span class="audit-info">Date created: {{ creationDate }}</span>
    </f7-row>
    <InvitePeople :groupLink="groupLink" />
    <DeleteConfirmation
      :modalTitle="modalTitle"
      :btnText="btnText"
      :modalText="modalText"
      @delete="confirmDelete"
    />
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
  f7Sheet,
} from "framework7-vue";

import { API } from "aws-amplify";

import insurer1 from "@/assets/family_member1.svg";

import defaultImg from "@/assets/big-elipsis-btn.svg";

import DeleteConfirmation from "@/components/modals/delete-confirmation.vue";
import InvitePeople from "@/components/modals/invite-people.vue";

export default {
  components: {
    insurer1,
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    f7Sheet,
    InvitePeople,
    DeleteConfirmation,
  },
  props: {
    f7route: Object,
    isPolicyDetail: { type: Boolean, default: false },
  },
  data() {
    return {
      modalTitle: "Delete Group",
      btnText: "Confirm delete",
      modalText:
        "All policies added into this group by all members will be unshared",
      loading: false,
      navbarTitle: "People",
      sharingGroup: {
        owner: {
          id: "",
        },
      },
      selectedMemberToDelete: {},
      selectAction: "",
      groupLink: "",
      members: [],
      ownerId: "",
      groups: [
        { id: 1, members: "Gabi Takashima", active: false },
        { id: 2, members: "Omari Takashima", active: true },
      ],
      creationDate: null,
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },

    goSharingGroups() {
      f7.views.main.router.navigate({ name: "Sharing" });
    },

    invitePeople() {
      f7.sheet.open(".invite-people");
    },

    removeMember(member) {
      this.modalTitle = "Remove " + member.firstName;
      this.btnText = "Remove";
      this.modalText =
        member.firstName +
        " will lose access to all the policies added into this group by all members";
      this.selectedMemberToDelete = member;
      this.selectAction = "member";
      f7.sheet.open(".delete-confirmation");
    },

    deleteGroupSheet() {
      this.modalTitle = "Delete Group";
      this.btnText = "Confirm delete";
      this.modalText =
        "All policies added into this group by all members will be unshared";
      this.selectAction = "group";
      f7.sheet.open(".delete-confirmation");
    },

    confirmDelete() {
      if (this.selectAction === "group") {
        return this.deleteGroup(this.sharingGroup.id);
      }
      if (this.selectAction === "member") {
        return this.removeUserFromGroup(
          this.selectedMemberToDelete.id,
          this.sharingGroup.id
        );
      }
    },

    groupToggl(toggBox) {
      switch (toggBox) {
        case "dsa":
          this.unlimitedSumAssured = !this.unlimitedSumAssured;
          break;
        case "tpdsa":
          this.unlimitedPermanentDis = !this.unlimitedPermanentDis;
          break;
        case "cisa":
          this.unlimitedCriticalsumAssured = !this.unlimitedCriticalsumAssured;
          break;
      }
    },
    async removeUserFromGroup(memberId, groupId) {
      f7.dialog.preloader("Loading...");
      API.del(
        "sharingGroups",
        "/sharing-groups/" + groupId + "/members/" + memberId
      )
        .then((result) => {
          f7.dialog.close();
          f7.sheet.close(".delete-confirmation");
          this.getUsers(groupId);
        })
        .catch((err) => {
          console.log(err);
          f7.dialog.close();
        });
    },
    async deleteGroup(groupId) {
      f7.dialog.preloader("Loading...");
      API.del("sharingGroups", "/sharing-groups/" + groupId)
        .then((result) => {
          f7.dialog.close();
          f7.sheet.close(".delete-confirmation");
          this.goHome();
        })
        .catch((err) => {
          console.log(err);
          f7.dialog.close();
        });
    },
    async getSharingGroup(groupId) {
      API.get("sharingGroups", "/sharing-groups/" + groupId)
        .then((result) => {
          // console.log(result.data);
          this.sharingGroup = result.data;
          // console.log('this.sharingGroup -> ', this.sharingGroup);
          this.groupLink =
            window.location.origin +
            "?isSharing=true&sharingId=" +
            this.sharingGroup.uniqueCode;
          let _creationDate = new Date(this.sharingGroup.createdAt);
          const options = {};
          this.creationDate = _creationDate.toLocaleString("es", options);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUsers(groupId) {
      f7.dialog.preloader("Loading...");
      API.get("sharingGroups", "/sharing-groups/" + groupId + "/members")
        .then((result) => {
          this.members = result.data;
          // console.log('member -> ', this.members);
          f7.dialog.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  async mounted() {
    // console.log("mounted");
    // console.log(this.isPolicyDetail);
    this.ownerId = localStorage.ownerInsuredId;
    this.getSharingGroup(this.f7route.params.groupId);
    this.getUsers(this.f7route.params.groupId);
  },
  computed: {},
};
</script>

<style scoped>
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.screen-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #07182b;
}

.group-row {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.group-members {
  font-weight: normal;
  font-size: 17px;
  letter-spacing: -0.408px;
  color: #07182b;
}

.group-quantity-active {
  display: inline;
  align-items: center;
  color: #ff3333;
  font-weight: 600;
  font-size: 14px;

  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
}

.group-quantity-active.admin {
  color: #929ba1;
}

.group-quantity {
  display: inline;
  align-items: center;
  background: #5e6d78;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
}

.list-title-block {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
  padding-left: 15px;
}

.delete-row {
  margin-top: 30px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.delete-group {
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ff3333;
}

.top-bottom-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.audit-info {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.04em;
  color: #929ba2;
  padding-left: 15px;
  padding-top: 15px;
}
</style>
