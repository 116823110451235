<template>
  <f7-page name="coverage-main" :page-content="false">
    <f7-navbar class="no-shadow" title="Insurance Family Vault" back-link="">
      <f7-nav-right>
        <f7-link
          icon-ios="f7:slider_horizontal_3"
          icon-aurora="f7:slider_horizontal_3"
          icon-md="f7:slider_horizontal_3"
          href="/settings"
        ></f7-link>
        <!-- <amplify-sign-out></amplify-sign-out> -->
      </f7-nav-right>
    </f7-navbar>

    <!-- <f7-navbar title="Appbar"></f7-navbar> -->
    <!-- <f7-toolbar  tabbar scrollable :position="'top'">
      <f7-link :tab-link="`#tab-protection`">Emergency Savings</f7-link>
      <f7-link :tab-link="`#tab-protection`" tab-link-active
        >Protection</f7-link
      >
      <f7-link :tab-link="`#tab-protection`">Investments</f7-link>
    </f7-toolbar> -->

    <f7-tabs>
      <f7-tab :id="`tab-emergency`" class="page-content">
        <EmergencyView />
      </f7-tab>
      <f7-tab :id="`tab-protection`" class="page-content" tab-active>
        <ProtectionView @proctectionLoaded="proctectionLoaded" />
      </f7-tab>
      <f7-tab :id="`tab-investment`" class="page-content">
        <InvestmentView />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7,
} from "framework7-vue";

import EmergencyView from "@/components/emergency.vue";
import ProtectionView from "@/components/protection.vue";
import InvestmentView from "@/components/investment.vue";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    EmergencyView,
    ProtectionView,
    InvestmentView,
  },
  props: {
    f7route: Object,
    f7router: Object,
    uuid_v4: String,
  },

  data() {
    return {
      // routes,
      defaultTabIndex: 1,
    };
  },
  methods: {
    proctectionLoaded() {
      // console.log("redirect ready");
      let urlParams = new URLSearchParams(window.location.search);
      if (
        urlParams.has("isSharing") &&
        urlParams.get("isSharing").toLowerCase() == "true"
      ) {
        let sharingGroupId = urlParams.has("sharingId")
          ? urlParams.get("sharingId")
          : "";
        window.history.pushState(null, "DBS", "/");

        f7.views.main.router.navigate(
          {
            name: "Sharing",
          },
          {
            props: {
              uniqueCode: sharingGroupId,
            },
          }
        );
      }
    },
  },
};
</script>