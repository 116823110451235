<template>
  <div class="Insurers-list" :class="{ no_bg: no_bg }">
    <div
      class="Insurers-list-wrapper"
      :class="{ showShadow: !showTabSelector }"
    >
      <!-- <h3>{{ selectorTitle }}</h3> -->
      <div class="familyMember-wrapper">
        <div
          v-for="insured in insureds"
          :key="insured.id"
          :class="{ active: insured.selected }"
          class="familyMember"
          @click="toggleInsured(insured)"
        >
          <img :src="insured.avatar" :alt="insured.firstName" />
          <span class="status" :class="insured.healthStatus">{{
            insured.firstName
          }}</span>
        </div>
        <div v-if="loading" class="familyMember skeleton-effect-wave">
          <div
            class="skeleton-block"
            style="
              width: 48px;
              height: 48px;
              border-radius: 50%;
              margin-bottom: 7px;
            "
          ></div>
          <div class="skeleton-text" style="border-radius: 30px">
            Profle name
          </div>
        </div>
        <div class="addFamiliyMemberBox" v-if="allowAdd">
          <f7-link
            @click="addNewMember"
            id="addFamiliyMember"
            icon-ios="f7:plus"
            icon-aurora="f7:plus"
            icon-md="f7:plus"
          ></f7-link>
          <span class="status">Add family</span>
        </div>
      </div>
    </div>
    <div class="proctection-tabs" v-if="showTabSelector">
      <f7-link :tab-link="`#tab-coverage`" :tab-link-active="true"
        >Coverage</f7-link
      >
      <!--       <f7-link :tab-link="`#`" :tab-link-active="false">Coverage</f7-link> -->
      <f7-link :tab-link="`#tab-policies`" :tab-link-active="false"
        >Policies</f7-link
      >
    </div>
  </div>
</template>

<script>
import { f7Link, f7 } from "framework7-vue";
import { API } from "aws-amplify";

export default {
  props: {
    allowAdd: {
      type: Boolean,
      default: false,
    },
    showTabSelector: {
      type: Boolean,
      default: false,
    },
    selectorTitle: {
      type: String,
    },
    no_bg: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    f7Link,
  },
  data() {
    return {
      loading: true,
      insureds: [],
    };
  },
  async mounted() {
    localStorage.OwnerName = "there";
    // localStorage.ownerInsuredId = null;
    this.loading = true;
    var preSelectInsured = null;
    var analysis = null;
    await API.get("insureds", "/insureds", {})
      .then((result) => {
        // console.log("insureds -> ", result);
        let insuredList = result.data;

        insuredList.forEach((ins) => {
          let conf = this.$filters.getRelationshipLabel(ins.relationship);
          ins.index = conf.index;
        });
        insuredList.sort((a, b) =>
          a.index > b.index ? 1 : b.index > a.index ? -1 : 0
        );
        let sortedArr = insuredList;

        // console.log("sortedArr -> ", sortedArr);
        for (var ins in sortedArr) {
          let insured = sortedArr[ins];
          insured.selected = false;
          insured.ThereIsPolicy = false;

          insured.avatar = this.$filters.getInsuredAvatar(
            insured.relationship,
            insured.gender
          );

          if (insured.relationship == "myself") {
            localStorage.OwnerName = insured.firstName;
            localStorage.ownerInsuredId = insured.id;
            localStorage.Ownergender = insured.gender ?? "male";
          }
          // insured.coverageStatus = "case1"; // + this.getRandomCase();
          this.insureds.push(insured);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log("mounted");

    this.insureds.forEach(async (ins) => {
      // console.log("insured id: ", ins.id);
      let analysis = await API.get("coverageAnalysis", "/coverage-analysis", {
        queryStringParameters: {
          insuredId: ins.id,
        },
      })
        .then((result) => {
          // console.log('getCoverageAnalysis -> ', result);
          return result;
        })
        .catch((err) => {
          // console.log(err);
          return null;
        });
      // console.log("analysis -> ", analysis);
      ins.analysis = analysis;
      if (ins.analysis != null) {
        ins.healthStatus = this.$filters.getCoverageClass(
          ins.analysis.portfolioHealth.score
        );

        ins.coverageStatus = this.$filters.getCoverageStatus(
          ins.analysis.portfolioHealth.score,
          ins.relationship == "myself"
        );
      } else {
        ins.healthStatus = this.$filters.getCoverageClass(0);
        ins.coverageStatus = this.$filters.getCoverageStatus(0, false);
      }
    });
    // console.log(this.insureds);
    // console.log(localStorage.selectedInsuredId);
    // console.log(1);
    if (typeof this.insureds[0] !== "undefined") {
      // console.log(2);
      if (localStorage.selectedInsuredId != null) {
        // console.log(3);
        this.insureds.forEach((ins) => {
          if (ins.id == localStorage.selectedInsuredId) {
            // console.log(4);
            ins.selected = true;
            preSelectInsured = ins;
            analysis = ins.analysis;
          }
        });
        // console.log("preSelectInsured -> ", preSelectInsured);
        // console.log(5);
        if (preSelectInsured) {
          // console.log(6);
          // console.log(1, preSelectInsured);
          // console.log(123, analysis);
          localStorage.selectedInsuredId = preSelectInsured.id;
          this.$emit(
            "updateSelectedInsured",
            preSelectInsured,
            preSelectInsured.analysis
          );
        } else {
          // console.log(7);
          // console.log(2);
          this.insureds[0].selected = true;
          localStorage.selectedInsuredId = this.insureds[0].id;
          this.$emit("updateSelectedInsured", this.insureds[0]);
        }
      } else {
        // console.log(8);
        // console.log(3);
        this.insureds[0].selected = true;
        localStorage.selectedInsuredId = this.insureds[0].id;
        this.$emit("updateSelectedInsured", this.insureds[0]);
      }
    } else {
      // console.log(9);
      localStorage.selectedInsuredId = null;
      this.$emit("updateSelectedInsured", null);
    }
    // console.log("insureds -> ", this.insureds);
    // console.log(10);
    this.loading = false;
  },
  methods: {
    toggleInsured(insured) {
      this.insureds.forEach((i) => {
        i.selected = false;
      });
      insured.selected = true;
      localStorage.selectedInsuredId = insured.id;
      this.$emit("updateSelectedInsured", insured);
    },
    addNewMember() {
      f7.views.main.router.navigate(
        { name: "AddPolicy3" },
        {
          props: {
            addMember: true,
          },
        }
      );
    },
  },
};
</script>

<style>
.Insurers-list-wrapper.showShadow .familyMember-wrapper {
  border-bottom: 1px solid #e1e7eb;
}
</style>