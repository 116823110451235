<template>
  <!-- policy-details -->
  <f7-sheet
    class="policy-details"
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      --f7-sheet-bg-color: #fff;
    "
    swipe-to-close
    backdrop
    :class="{coverage: isCoverageStyle}"
  >
    <f7-page-content>
      <f7-button sheet-close>
        <f7-icon class="close-modal-no-title" f7="xmark"></f7-icon>
      </f7-button>

      <f7-block class="policy-information-modal">
        <img src="@/assets/customIcons/custom-star.svg" alt="" />

        <div class="" style="margin: 35px">
          Coverage needs for other insureds are indicative only. Only the
          insured will be able to view their personalised needs analysis.
        </div>

        <f7-button sheet-close class="btn-red-text btn-red">OK</f7-button>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    isCoverageStyle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.policy-details {
  align-content: center;
}

.close-modal-no-title {
  color: #9eaab1;
  position: fixed;
  left: 15px;
  top: 15px;
}

.policy-details.coverage .sheet-close{
    display: flex;
    justify-content: flex-start;
    padding: 30px 0px 0px 25px;
    height: auto;
}

.policy-details.coverage .close-modal-no-title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left: unset;
  top: unset;
}

.policy-information-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-information-modal.delete img {
  width: 45%;
  height: auto;
}

.policy-information-modal.delete span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  /*   display: flex !important; */
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  /* #FF3333 */
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;

  font-size: 15px;
  line-height: 23px;

  text-align: center;

  color: #ffffff;
  letter-spacing: 0.04em;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>