<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        "my " + relationshipLabel
      }}</f7-nav-title>

      <f7-nav-right v-if="!isMyProfile">
        <f7-button
          @click="confirmDelete"
          style="padding-right: 10px"
          class="add-policy-navbar-btn"
        >
          <img src="@/assets/customIcons/custom-red-trash-can.svg" alt="" />
        </f7-button>
      </f7-nav-right>
    </f7-navbar>
    <ProfileForm
      :insuredId="insuredId"
      @profileSaved="profileSaved"
      @frmPostUpdated="frmPostUpdated"
      :defaultRelationship="defaultRelationship"
    />
    <GenericModal
      :isInsuredStyle="true"
      modalTitle="Delete profile"
      modalText="Deleting this profile will remove the profile and all policies belonging to this profile permanently from your vault"
      btnText="Confirm delete"
      modalClassId="policy-processed"
      policyId=""
      @modalBtnClick="deleteInsured"
      :avatarSrc="avatarSrc"
      :relationshipLabel="relationshipLabel"
    />
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import { API } from "aws-amplify";

import ProfileForm from "@/components/forms/profile-form.vue";
import GenericModal from "@/components/modals/generic-modal.vue";

export default {
  components: {
    ProfileForm,
    GenericModal,
  },
  props: {
    insuredId: null,
    relationshipLabel: "",
    defaultRelationship: "",
  },
  data() {
    return {
      myInsuredId: null,
      isMyProfile: false,
      navbarTitle: "",
      // modalTitle: "Delete profile",
      // modalText:
        // "Deleteing this profile will remove the profile and all policies belonging to this profile permanently from your vault",
      avatarSrc: null,
      relationshipLabelModal: null,
    };
  },
  methods: {
    profileSaved(isProfileSaved) {
      f7.views.main.router.navigate({ name: "SettingsLovedOnes" });
    },
    frmPostUpdated(dependentRelationship, insuredAvatar) {
      this.avatarSrc = insuredAvatar;
      this.relationshipLabelModal = "My " + dependentRelationship;
    },
    confirmDelete() {
      f7.sheet.open(".policy-processed");
    },
    async deleteInsured() {
      f7.sheet.close(".policy-processed");
      f7.dialog.preloader("Processing...");
      let path = "/insureds/" + this.insuredId;
      await API.del("insureds", path, {})
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });

      await API.get("policies", "/policies", {
        queryStringParameters: {
          insuredId: this.insuredId,
        },
      })
        .then((result) => {
          let policies = result.data;
          policies.forEach(async (p) => {
            console.log("delete policy -> ", p.id);
            let path = "/policies/" + p.id;
            await API.del("policies", path, {})
              .then((result) => {
                console.log(result);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      f7.dialog.close();
      f7.views.main.router.navigate({ name: "SettingsLovedOnes" });
    },
  },
};
</script>

<style scoped>
.add-policy-navbar-title {
  text-transform: capitalize;
}
</style>