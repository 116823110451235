 <template>
  <div>
      Investment comming soon...
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>