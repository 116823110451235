<template>
  <f7-page>
    <f7-navbar class="nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title"
        >Check Personal Details</f7-nav-title
      >
      <f7-nav-right>
        <f7-button @click="cancelBtn" class="custom-cancel">Cancel</f7-button>
      </f7-nav-right>
    </f7-navbar>
    <div class="workflow-progress">
      <div class="status"></div>
    </div>
    <f7-block class="steps-details">
      <small>STEP 2/3</small>
    </f7-block>

    <f7-list
      accordion-list
      inset
      style="margin-top: 0px; box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14)"
    >
      <!--  -->
      <f7-list-item
        accordion-item
        title="SavvyEndowment 5"
        class="plan-details"
      >
        <template #media>
          <img src="@/assets/purchase-plan1-logo.png" width="40" height="40" />
        </template>
        <f7-accordion-content class="plan-details-content">
          <div>
            <span class="label">Premium Amount</span>
            <span class="value">SGD 10,000.00</span>
          </div>
          <div>
            <span class="label">Policy Years</span>
            <span class="value">3 Years</span>
          </div>
          <div>
            <a href="#">Have a promo code?</a>
          </div>
          <hr />
          <div class="horizontal">
            <span class="label">Payment Amount</span>
            <span class="value"
              ><span class="currency">SGD</span> 10,000.00</span
            >
          </div>
        </f7-accordion-content>
      </f7-list-item>

      <!--  -->
    </f7-list>

    <f7-block-header>Personal details</f7-block-header>
    <f7-block class="Personal-details">
      <div>
        <span class="label">Full Name (As per NRIC / Passport)</span>
        <span class="value">DERRICK LEE</span>
      </div>
      <div>
        <span class="label">Place of Residence</span>
        <span class="value">Singapore</span>
      </div>
    </f7-block>
    <f7-list class="personal-details-inputs">
      <f7-list-input
        label="NRIC / Passport"
        type="text"
        placeholder="e.g. XXXXXXX"
        clear-button
        value="S9012345A"
      >
      </f7-list-input>
      <f7-list-input
        label="Mobile Number"
        type="text"
        placeholder="e.g. +65XXXX XXXX"
        clear-button
        value="+659123 5678"
      >
      </f7-list-input>
      <f7-list-input
        label="Email Address"
        type="text"
        placeholder="e.g. name@company.com"
        clear-button
        value="CONTACT@ANCILEO.COM"
      >
      </f7-list-input>
    </f7-list>

    <span class="disclaimer">
      The contact details provided are for this application only. If you wish to
      have these details reflected in the Bank's record, please update via
      'Settings'.
      <br/>
      <br/>
      By proceeding, you declare that you are currently residing in Singapore and paying tax in Singapore only.
      </span
    >

    <f7-block align="center" class="policy-information-modal">
      <f7-button class="need-analysis-btn-gray" @click="nextStep">
        <div class="add-policy-btn-red-text">Next</div></f7-button
      >
    </f7-block>
  </f7-page>
</template>

<script>
import { f7, f7Page, f7Block, f7Range, f7Link } from "framework7-vue";

export default {
  components: {
    f7,
    f7Page,
    f7Block,
    f7Link,
    f7Range,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    nextStep() {
      f7.views.main.router.navigate(
        { name: "PlanCalculate3" },
        {
          props: {},
        }
      );
    },
    cancelBtn() {
      f7.views.main.router.navigate(
        { name: "PlanSelection" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style scoped>
:root {
  --f7-input-font-size: 16.6px;
  --f7-input-height: 20px;
}
.workflow-progress .status {
  width: 66.34%;
  background: rgba(244, 176, 74, 1);
  height: 3px;
}
</style>

<style>
:root {
  --f7-range-bar-bg-color: #e1e7eb;
  --swiper-theme-color: #ff3333;
  --f7-range-bar-active-bg-color: #ff3333;
  --f7-input-text-color: #182632;
  /* --f7-input-font-size: 16.6px; */
  /* --f7-input-height: 20px; */
}

.personal-details-inputs input {
  font: 16.6px !important;
  height: 20px !important;
}

.plan-details .item-media {
  margin-left: 5px !important;
}
.plan-details .item-inner {
  padding-right: 0px !important;
  margin-left: 14px !important;
}

.custom-cancel {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: right;
  color: #3677c4;
  text-transform: capitalize;
}
.add-policy-navbar-title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #07182b;
}

.workflow-progress {
  display: flex;
  width: 100%;
  background: rgba(204, 204, 204, 1);
  height: 3px;
}

.steps-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  margin-top: 0px;
  padding: 19.75px 15px 19px 18px;
  color: rgba(146, 155, 161, 1);
}

.steps-details > small {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #929ba2;
}
.steps-details > span {
  font-style: normal;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 21px;
  color: #07182b;
  opacity: 0.5;
  margin-top: 13px;
  margin-bottom: 15px;
}

.edit-link {
  background-image: url("../../assets/customIcons/edit-icon.svg");
  width: 15px;
  height: 15px;
}

.block-header {
  padding-left: 16px;
  margin-top: 18px;
}

.Personal-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.Personal-details > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.Personal-details .label,
.personal-details-inputs .item-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12.7px;
  line-height: 21px;
  color: #9ca5ab;
}

.Personal-details .value,
.personal-details-inputs input {
  font-style: normal;
  font-weight: normal;
  font-size: 16.6px;
  line-height: 21px;
  color: #182632;
}
.personal-details-inputs input {
  margin-bottom: 10px;
}

.personal-details-inputs {
  margin-top: 0px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
}

.personal-details-inputs .item-input-wrap .input-clear-button {
  top: 40% !important;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  color: #9ca5ab;
  opacity: 1;
  text-align: left;
  margin: 0 16px;
  display: block !important;
}

.plan-benefits {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.plan-benefits span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #07182b;
  margin-top: 10px;
  margin-bottom: 13px;
  display: flex;
}
.plan-benefits span::before {
  content: "";
  margin-right: 10px;
  display: flex;
  width: 25px;
  min-width: 25px;
  height: 25px;
  background-image: url("../../assets/benefits-bullets.svg");
}

.accordion-list {
  margin-bottom: 0px;
}

.accordion-list .item-content {
  padding-left: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #07182b;
}

.accordion-list .item-media {
  padding-top: 14px;
  padding-bottom: 19px;
}
.accordion-list.list ul:before {
  display: none;
}

.plan-details-content {
  padding: 0 16px;
  padding-bottom: 5px;
}

.plan-details-content,
.plan-details-content > div {
  display: flex;
  flex-direction: column;
}

.plan-details-content hr {
  border: 0;
  border-top: 1px solid rgba(188, 187, 193, 0.5);
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
}

.plan-details-content > div {
  margin-bottom: 10px;
}
.plan-details-content > div.horizontal {
  flex-direction: row;
  justify-content: space-between;
}
.plan-details-content .label,
.plan-details-content .value .currency {
  font-style: normal;
  font-weight: normal;
  font-size: 12.7px;
  line-height: 21px;
  color: #9ca5ab;
}

.plan-details-content .value {
  font-style: normal;
  font-weight: normal;
  font-size: 16.6px;
  line-height: 21px;
  color: #182632;
}

.plan-details-content a {
  font-style: normal;
  font-weight: normal;
  font-size: 12.7px;
  line-height: 21px;
  color: #9ca5ab;
  text-decoration: underline;
}
</style>