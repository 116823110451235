<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title"
        >Needs Analysis</f7-nav-title
      >
    </f7-navbar>

    <f7-block class="policy-summary-modal">
      <img src="@/assets/thinking-boy.svg" alt="" />
    </f7-block>

    <f7-block class="need-analysis-title">
      {{ needAnalysisTitle }}
    </f7-block>

    <f7-block class="need-analysis-landing-text">
      {{ needInsuranceP1 }}
    </f7-block>

    <f7-block class="need-analysis-landing-text">
      {{ needInsuranceP2 }}
    </f7-block>

    <f7-button
      style="margin-bottom: 25px"
      @click="showNextScreen()"
      class="need-analysis-btn-red"
      :class="{ disabled: loading, 'skeleton-effect-wave': loading }"
    >
      <div>Let's Begin!</div>
    </f7-button>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Button,
  //  f7BlockTitle,
  f7Block,
  f7NavRight,
  f7Sheet,
  f7,

  //  f7Card,
} from "framework7-vue";
import $ from "dom7";
import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Button,
    f7Sheet,
    //    f7BlockTitle,
    f7Block,

    //    f7Card,
  },
  data() {
    return {
      loading: true,
      questionner: null,
      screenStep: "step 1",
      needAnalysisTitle: "Am I saving enough for retirement?",
      needInsuranceP1:
        "Retirement plans, or annuities, when paired together with your lifelong payouts from CPF LIFE, can supplement your retirement income during your golden years. You can even expect a payout multiplier (2x) due to disability or when you are unable to perform 2/3 of 6 ADLs.",

      needInsuranceP2:
        "As you will be committing a significant portion of your life savings, ensure that the payout for your retirement plan is competitive and personalised to your needs.",
    };
  },
  async mounted() {
    await API.get("questionnaires", "/questionnaires", {})
      .then((result) => {
        this.questionner = result.data;
      })
      .catch((err) => {
        console.log(err);
      });

    this.loading = false;
  },
  methods: {
    showNextScreen() {
      f7.views.main.router.navigate(
        { name: "RetirementNeeds" },
        {
          props: {
            shortQuestionare: true,
            questionnerParam: this.questionner,
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.add-policy-navbar-btn-text {
  letter-spacing: 0.04em;
  text-transform: none !important;
  color: #9eaab1;
  padding-left: 5px;
}
.add-policy-navbar-btn {
  background: #ffffff !important;
  border-radius: 64px !important;
  box-shadow: none !important;
  border: 1px solid #e1e7eb !important;
  box-sizing: border-box !important;
}

.close-modal {
  color: #9eaab1;

  position: fixed;
  left: 15px;
  top: 15px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -18px;
  margin-bottom: 0px;
}

.policy-summary-modal {
  /* TEXT */
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}

.policy-summary-modal-text {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  opacity: 0.5;

  /* Maybe this is not requied */
  justify-content: center;
  align-items: center;
  margin: auto;
}

.policy-summary-modal-tip {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #929ba2;
}

.policy-summary-modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #1a2531;
  padding-bottom: 10px;
}
.policy-summary-modal-sentence {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07182b;
  margin-left: 15px;
  opacity: 0.5;
  padding-bottom: 5px;
}

.policy-modal-dotted {
  color: #e1e7eb;
  border-radius: 15px;
  border-style: dashed;
}

.add-policy-btn-white-2 {
  justify-content: center;
  align-items: center;
  margin: auto;

  width: 298px;
  height: 43px;
  /* #FF3333 */
  background: #ff3333;
  border-radius: 34px;
  box-shadow: none;
}

.add-policy-btn-white-text-2 {
  text-transform: none;

  font-size: 15px;

  line-height: 23px;

  text-align: center;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin: 0px 10px;
}
</style>