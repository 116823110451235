<template>
  <f7-page
    @page:beforeremove="onPageBeforeRemove"
    @page:beforeout="onPageBeforeOut"
  >
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">Add Policy</f7-nav-title>
      <f7-nav-right>
        <f7-button
          style="padding-right: 10px"
          sheet-open=".add-policy-help-modal"
          class="add-policy-navbar-btn"
        >
          <img src="@/assets/customIcons/custom-help.svg" alt="" />
          <div class="add-policy-navbar-btn-text">Help</div>
        </f7-button>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="add-policy-step" style="color: #f4f8fb">
      {{ screenStep }}
    </f7-block>

    <f7-block class="add-policy-title">
      {{ addPolicyTitle }}
    </f7-block>

    <f7-block class="add-policy-description">
      {{ addPolicyDescription }}
    </f7-block>

    <div class="btn-upload-policy">
      <f7-button
        @click="$refs.imageFileInput.click()"
        class="add-policy-btn-red"
      >
        <img src="@/assets/customIcons/custom-camera.svg" alt="" />
        <div class="add-policy-btn-red-text">Take a photo</div>
      </f7-button>

      <input
        style="display: none"
        type="file"
        accept="image/*"
        capture="camera"
        @change="onFileSelected"
        ref="imageFileInput"
      />

      <div
        class="add-policy-description"
        style="padding-top: 15px; padding-bottom: 15px"
      >
        or
      </div>

      <f7-button
        @click="$refs.documentInput.click()"
        class="add-policy-btn-red"
      >
        <img src="@/assets/customIcons/custom-upload.svg" alt="" />
        <div class="add-policy-btn-red-text">Upload Document</div>
      </f7-button>

      <input
        style="display: none"
        type="file"
        @change="onDocumentSelected"
        ref="documentInput"
      />
    </div>

    <!-- Swipe to add-policy-help-modal sheet -->
    <f7-sheet
      class="add-policy-help-modal"
      style="
        height: 90%;
        border-radius: 25px 25px 0px 0px;
        --f7-sheet-bg-color: #fff;
      "
      swipe-to-close
      backdrop
    >
      <f7-page-content>
        <f7-button sheet-close>
          <f7-icon class="close-modal" f7="xmark"></f7-icon>
        </f7-button>

        <f7-block class="modal-title">Policy Summary</f7-block>

        <f7-block class="policy-summary-modal">
          <img src="@/assets/customIcons/custom-policy-summary.svg" alt="" />

          <div class="policy-summary-modal-text">
            The Policy Summary page shows a summary of your policy including
            your personal details, premiums and policy start date.
          </div>

          <f7-list media-list inset class="policy-modal-dotted">
            <f7-list-item>
              <f7-row class="">
                <f7-col align="left" class="policy-summary-modal-tip">
                  <img src="@/assets/customIcons/custom-red-dot.svg" alt="" />
                  TIP</f7-col
                >
                <f7-col align="right" class="">
                  <img src="@/assets/customIcons/custom-open-book.svg" alt="" />
                </f7-col>
              </f7-row>

              <f7-row class="policy-summary-modal-title">
                Look for the page titled
              </f7-row>

              <f7-row class="policy-summary-modal-sentence">
                - Policy Schedule
              </f7-row>
              <f7-row class="policy-summary-modal-sentence">
                - Premium Summary or
              </f7-row>

              <f7-row class="policy-summary-modal-sentence">
                - Policy Certificate
              </f7-row>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Button,
  //  f7BlockTitle,
  f7Block,
  f7NavRight,
  f7Sheet,
  f7,

  //  f7Card,
} from "framework7-vue";
import $ from "dom7";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Button,
    f7Sheet,
    //    f7BlockTitle,
    f7Block,

    //    f7Card,
  },
  data() {
    return {
      screenStep: "step 1",
      sheetOpened: false,
      addPolicyTitle: "Choose method of upload",
      addPolicyDescription:
        "Take a photo or upload a file of your Policy Summary Page to digitalize your policy in 30 seconds.",
      uploadedPhoto: null,
    };
  },
  methods: {
    onPageBeforeOut() {
      // Close opened sheets on page out
      f7.sheet.close();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy sheet modal when page removed
      if (self.sheet) self.sheet.destroy();
    },

    onFileSelected(event) {
      // let reader = new FileReader();
      let file = event.target.files[0];

      f7.views.main.router.navigate(
        {
          name: "AddPolicy2",
        },
        {
          props: {
            uploadedPhoto: file,
            filename: file.name,
          },
        }
      );

      // let filename = event.target.files[0].name;

      // console.log(file);
      // console.log(file.name);

      // reader.onload = (event) => {
      //   let uploadedPhoto = event.target.result;

      //   console.log(event);
      //   console.log(event.name);

      //   // f7.views.main.router.navigate(
      //   //   {
      //   //     name: "AddPolicy2",
      //   //   },
      //   //   {
      //   //     props: {
      //   //       uploadedPhoto: event,
      //   //       filename: filename,
      //   //       // extension: filename.match(/\.[0-9a-z]+$/i),
      //   //     },
      //   //   }
      //   // );
      // };
      // reader.readAsDataURL(event.target.files[0]);
    },

    onDocumentSelected(event) {
      let file = event.target.files[0];
      console.log("ADD POLICY 1 Screen, file ----->");
      console.log(file);

      f7.views.main.router.navigate(
        {
          name: "UploadPolicyDoc",
        },
        {
          props: {
            uploadedPhoto: file,
            filename: file.name,
          },
        }
      );
    },
  },
};
</script>

<style>

.add-policy-nav-bar-shadow::before,
.add-policy-nav-bar-shadow::after {
  display: none;
}


.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
}
</style>

<style scoped>
.add-policy-navbar-btn-text {
  letter-spacing: 0.04em;
  text-transform: none !important;
  color: #9eaab1;
  padding-left: 5px;
}
.add-policy-navbar-btn {
  background: #ffffff !important;
  border-radius: 64px !important;
  box-shadow: none !important;
  border: 1px solid #e1e7eb !important;
  box-sizing: border-box !important;
}

.close-modal {
  color: #9eaab1;

  position: fixed;
  left: 15px;
  top: 15px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -18px;
  margin-bottom: 0px;
}

.policy-summary-modal {
  /* TEXT */
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
}

.policy-summary-modal-text {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  opacity: 0.5;

  /* Maybe this is not requied */
  justify-content: center;
  align-items: center;
  margin: auto;
}

.policy-summary-modal-tip {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #929ba2;
}

.policy-summary-modal-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #1a2531;
  padding-bottom: 10px;
}
.policy-summary-modal-sentence {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07182b;
  margin-left: 15px;
  opacity: 0.5;
  padding-bottom: 5px;
}

.policy-modal-dotted {
  color: #e1e7eb;
  border-radius: 15px;
  border-style: dashed;
}

.add-policy-btn-white-2 {
  justify-content: center;
  align-items: center;
  margin: auto;

  width: 298px;
  height: 43px;
  /* #FF3333 */
  background: #ff3333;
  border-radius: 34px;
  box-shadow: none;
}

.add-policy-btn-white-text-2 {
  text-transform: none;

  font-size: 15px;

  line-height: 23px;

  text-align: center;
  color: #ffffff;
  letter-spacing: 0.02em;
  margin: 0px 10px;
}
.btn-upload-policy {
  display: flex;
  flex-direction: column;
  padding: 0 8.5vw;
}
</style>