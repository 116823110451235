 <template>
  <f7-page>
    <InsuredSelector
      :selectorTitle="selectorTitle"
      :allowAdd="allowAdd"
      :showTabSelector="showTabSelector"
      :loading="loading"
      @updateSelectedInsured="updateSelectedInsured"
    />

    <f7-tabs>
      <f7-tab :id="`tab-coverage`" :tab-active="coverageTabActive">
        <CoverageTab :memberSelected="memberSelected" />
      </f7-tab>
      <f7-tab :id="`tab-policies`" :tab-active="!coverageTabActive">
        <PoliciesTab
          :policies="policiesSelected"
          :insured="memberSelected"
          :ThereIsPolicy="memberSelected ? memberSelected.ThereIsPolicy : false"
          :loading="loading || loadingPolicies"
        />
      </f7-tab>
    </f7-tabs>
    <GenericModal
      :isCoverageStyle="true"
      :modalTitle="modalText"
      btnText="View Policy"
      modalClassId="policy-processed"
      :policyId="policyProcessed ? policyProcessed.id : ''"
      @modalBtnClick="modalBtnClick"
    />
  </f7-page>
</template>

<script>
import { f7Page, f7Tabs, f7Tab, f7Link, f7 } from "framework7-vue";
import { API } from "aws-amplify";

import InsuredSelector from "./insured-selector.vue";
import CoverageTab from "./tabs/coverage-tab.vue";
import PoliciesTab from "./tabs/Policies-tab.vue";

import logo2 from "@/assets/logo_manulife.png";

import GenericModal from "@/components/modals/generic-modal.vue";

export default {
  components: {
    f7Page,
    f7Tabs,
    f7Tab,
    f7Link,
    CoverageTab,
    PoliciesTab,
    InsuredSelector,
    GenericModal,
  },
  props: {
    coverageTabActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      memberSelected: null,
      policiesSelected: [],
      allowAdd: true,
      showTabSelector: true,
      // selectorTitle: "DBS Insurance Family Vault",
      selectorTitle: "Insurance Family Vault",
      isCoverageActive: true,
      loading: true,
      loadingPolicies: true,
      modalText: "We have finished processing your policy!",
      policyProcessed: null,
      // accountOwner: null,
    };
  },
  mounted() {
    //  console.log('protection mounted', localStorage.showPolicyTab);

    if (localStorage.showPolicyTab == 'true') {
      console.log("showing policies");
      f7.tab.show("#tab-policies");
      localStorage.showPolicyTab = false;
    }
  },
  methods: {
    updateSelectedInsured(selected) {
      // console.log("updateSelectedInsured -> ", selected);
      this.loading = false;
      this.memberSelected = selected;
      if (selected) this.getPolicies(this.memberSelected.id);
      else {
        this.loadingPolicies = false;
        this.policiesSelected = [];
        this.$emit("proctectionLoaded");
      }
    },

    getRandomCase() {
      return parseInt(Math.random() * 6 + 1);
    },

    async getPolicies(insuredId) {
      this.policyProcessed = null;
      this.loadingPolicies = true;
      await API.get("policies", "/policies", {
        queryStringParameters: {
          insuredId: insuredId,
        },
      })
        .then((result) => {
          // console.log(result);
          result.data.forEach((policy) => {
            if (policy.status == 1 && typeof policy.notified === "undefined") {
              this.policyProcessed = policy;
            }
          });

          this.policiesSelected = result.data;
          this.loadingPolicies = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingPolicies = false;
        });

      if (this.policyProcessed != null) {
        f7.sheet.open(".policy-processed");
      } else {
        this.$emit("proctectionLoaded");
      }
    },
    async modalBtnClick(policyId) {
      let path = "/policies/" + this.policyProcessed.id;
      var policyRequest = this.policyProcessed;

      policyRequest.notified = true;
      var policyResponse = await API.patch("policies", path, {
        body: policyRequest,
      })
        .then((result) => {
          return result;
        })
        .catch((err) => {
          console.log(err);
        });

      f7.sheet.close(".policy-processed");

      f7.views.main.router.navigate(
        { name: "PolicyInformation" },
        {
          props: {
            policyid: policyId,
          },
        }
      );
    },
  },
};
</script>

<style>
</style>