<template>
  <f7-sheet
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.08);
      --f7-sheet-bg-color: #fff;
    "
    backdrop
    :class="modalClass + ' ' + modalClassId"
  >
    <f7-page-content>
      <div class="puchase-modal-header">
        <f7-button icon-ios="f7:xmark" @click="resetWorkflow"> </f7-button>
        <span class="modal-title">{{ modalTitle }}</span>
      </div>
      <f7-block>
        <div
          v-for="option in options"
          :key="option.id"
          class="payment-option"
          :class="{ selected: option.selected }"
          @click="modalBtnClick"
        >
          <div class="img-wrapper">
            <img :src="option.logo" alt="" width="42" height="32" />
          </div>
          
          <span>{{ option.label }}</span>
        </div>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

import option1 from "@/assets/payment-option-1.svg";
import option2 from "@/assets/payment-option-2.svg";
import option3 from "@/assets/payment-option-3.svg";
import option4 from "@/assets/payment-option-4.svg";
import option5 from "@/assets/payment-option-5.svg";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    modalClassId: {
      type: String,
      default: "",
    },
    cards: null,
  },
  data() {
    return {
      modalClass: "",
      modalTitle: "Select Payment Method",
      options: [
        {
          id: 1,
          logo: option1,
          label: "Supplementary Retirement Scheme (SRS)",
          selected: false,
        },
        {
          id: 2,
          logo: option2,
          label: "POSB Saving Account ****",
          selected: false,
        },
        {
          id: 3,
          logo: option3,
          label: "POSB Everyday Card *4309 ",
          selected: true,
        },
        {
          id: 4,
          logo: option4,
          label: "DBS PayLah!",
          selected: false,
        },
        {
          id: 5,
          logo: option5,
          label: "DBS/POSB Points",
          selected: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    modalBtnClick() {
      this.$emit("modalBtnClick");
    },
    resetWorkflow(){
      this.$emit("resetWorkflow");
    },
  },
};
</script>

<style>
.puchase-modal-header{
  display: flex;
  align-items: center;
  padding: 24px 8px 8px 8px;
}

.puchase-modal-header .sheet-close{
  color: #9EAAB1;
}

.puchase-modal-header  .f7-icons{
  font-size: 25px;
  color: #9EAAB1;
}
.payment-option {
  display: flex;
  margin-bottom: 12px;
  padding: 21px 20px;
  border: 1px solid #e1e7eb;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  align-items: center;
}

.payment-option.selected {
  border: 1px solid #ff3333;
}

.payment-option .img-wrapper {
  display: flex;
  width: 42px;
  height: 32px;
}
.payment-option span {
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #07182b;
}
</style>
