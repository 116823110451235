<template>
  <f7-page name="coverage-detail">
    <f7-navbar class="add-policy-progress-demo" back-link="">
      <!-- <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-aurora="f7:chevron_left"
          icon-md="f7:chevron_left"
          @click="goBackUpload()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left> -->
      <f7-nav-title class="add-policy-navbar-title">Add Policy</f7-nav-title>
    </f7-navbar>

    <f7-page class="progress-FullPage">
      <CustomProgress
        :percentage="progressBar"
        :legend="legend"
        :error="uploadError"
        :message="message"
      />
      <!-- Upload error -->

      <f7-sheet
        class="upload-error"
        style="
          height: auto;
          border-radius: 25px 25px 0px 0px;
          --f7-sheet-bg-color: #fff;
        "
        backdrop
      >
        <f7-page-content>
          <f7-block>
            <f7-row align="left" class="policy-information-close-modal">
              <f7-col>
                <!-- <f7-button sheet-close>
                <f7-icon
                  class="policy-information-close-modal"
                  f7="xmark"
                ></f7-icon>
              </f7-button> -->
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col align="center" class="modal-title">
                Unable to read document. Please try again.
              </f7-col>
            </f7-row>
          </f7-block>

          <f7-block class="policy-information-modal delete">
            <img src="@/assets/customIcons/custom-policy-summary.svg" alt="" />
            <span> You will be redirected in a few seconds.</span>
          </f7-block>
        </f7-page-content>
      </f7-sheet>
    </f7-page>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7Block,
  f7,
} from "framework7-vue";

import CustomProgress from "@/components/custom-progress.vue";
import { Storage, API } from "aws-amplify";

import { uuid } from "vue-uuid";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    CustomProgress,
    Storage,
    API,
  },
  props: {
    f7route: Object,
    f7router: Object,
    uploadedPhoto: Object,
    filename: String,
  },
  data() {
    return {
      progressBar: 0,
      legend: "We are uploading your policy...",
      attemps: 5,
      uuid_v4: null,
      uploadError: false,
      message:
        "Multi-page documents might take a bit longer to process. Thank you for your patience",
    };
  },
  mounted() {
    this.uuid_v4 = uuid.v4();
    this.uploadFile();
  },
  methods: {
    getFileExtension(filename) {
      var ext = /^.+\.([^.]+)$/.exec(filename);
      return ext == null ? "" : ext[1];
    },

    async uploadFile() {
      let extension = this.getFileExtension(this.filename);

      if (extension.toLowerCase() == "jpeg") extension = "jpg";
      let newFIleName = this.uuid_v4 + "." + extension.toLowerCase();
      const Component = this;
      console.log("[Loading Screen] uploadFile -> ", this.uploadedPhoto);
      console.log(newFIleName);

      const result = await Storage.put(newFIleName, this.uploadedPhoto, {
        level: "private",
        progressCallback(progress) {
          let prog = parseInt((progress.loaded / progress.total) * 100);
          console.log(prog);
          Component.progressBar = parseInt(prog * 0.5);
        },
      });

      console.log("file uploaded -> ", result);
      this.getPolicyStatus(this.uuid_v4);

      // console.log("end of file uploaded");
    },

    getPolicyStatus(policyid) {
      // console.log("getPolicyStatus", policyid);
      const apiName = "policies";
      const path = "/policies/" + policyid;
      const myInit = {
        headers: {},
      };
      // this.progressBar += 10;
      this.getPolicyStatusCall(apiName, path, myInit, 1);
    },
    showUploadError() {
      this.uploadError = true;
      this.legend = "";
      f7.sheet.open(".upload-error");
      setTimeout(() => {
        f7.sheet.close(".upload-error");
        f7.views.main.router.navigate({ name: "CoverageMain" });
        console.log("closing popup");
      }, 5000);
    },
    async getPolicyStatusCall(apiName, path, myInit, calls) {
      if (calls > this.attemps) {
        this.showUploadError();
        return;
      }
      console.log("getPolicyStatusCall", calls);

      const res = await API.get(apiName, path, myInit)
        .then((result) => {
          let statusCode = result.statusCode;
          let data = result.data;

          console.log("policy", result);

          if (statusCode == 200) {
            if (
              (!data.pagesToProcess && data.pagesProcessed > 0) ||
              (data.pagesToProcess !== undefined &&
                data.pagesToProcess === data.pagesProcessed)
            ) {
              // if (!data.valid) {
              //   this.showUploadError();
              //   return;
              // }

              this.progressBar = 100;
              let path = "/policies/" + data.id;
              data.originalFileName = this.filename;
              if (!data.productCode) data.productCode = "-";
              var policyResponse = API.patch("policies", path, {
                body: data,
              })
                .then((result) => {
                  console.log("policy updated(OriginalFileName) -> ", result);
                  return result;
                })
                .catch((err) => {
                  console.log("policy updated error -> ", err);
                });

              if (data.insuredId) {
                ////
                f7.views.main.router.navigate(
                  { name: "PolicyInformation" },
                  {
                    props: {
                      policyid: data.id,
                      isNewPolicy: true,
                    },
                  }
                );

                ////
              } else {
                f7.views.main.router.navigate(
                  {
                    name: "AddPolicy3",
                  },
                  {
                    props: {
                      policy: data,
                    },
                  }
                );
              }

              return data;
            } else {
              if (data.pagesToProcess !== undefined)
                this.progressBar =
                  50 +
                  parseInt((data.pagesProcessed / data.pagesToProcess) * 0.5) *
                    100;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (!res) {
        setTimeout(() => {
          this.getPolicyStatusCall(apiName, path, myInit, ++calls);
        }, 5000);
      }
    },
    goBackUpload() {
      f7.views.main.router.navigate({ name: "AddPolicy1" });
    },
  },
};
</script>

<style >
.progress-FullPage,
.progress-FullPage > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  padding-left: 10%;
  padding-right: 10%;
}

.policy-information-modal span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}
</style>