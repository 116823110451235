<template>
  <f7-block :class="{ 'skeleton-effect-wave': loading, frmAvatar: showAvatar }">
    <f7-row
      v-if="showAvatar"
      no-gap
      class="dependent-row-modal insurance-details-tab"
    >
      <img :src="insuredAvatar" />
      <span class="dependent-name">
        {{ dependentRelationship }}
      </span>
    </f7-row>
    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        'invalid-Box-Input': invalidDependentFirstName,
        'valid-Box-Input': !invalidDependentFirstName,
        disabled:
          (existingProfile || isMyProfile) && !(isSignup || !isConfirmed),
      }"
    >
      <f7-list-item @click="focusText('first-name')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> First Name </span>
        </f7-row>
        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 100%;
            height: 1.5em;
            border-radius: 4px;
            margin-bottom: 18.8px;
          "
        ></div>
        <div v-else>
          <f7-input
            @keyup="inputvalidator('first-name')"
            @change="inputvalidator('first-name')"
            v-bind:value="dependentFirstName"
            v-on:input="dependentFirstName = $event.target.value"
            class="d-first-name"
            ref="d-first-name"
            type="text"
            placeholder="e.g. Derrick"
            clear-button
            error-message="Only letters please!"
            required
            validate
            pattern="^([a-zA-Z]+[\s]?)*$"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        'invalid-Box-Input': invalidDependentLastName,
        'valid-Box-Input': !invalidDependentLastName,
        disabled:
          (existingProfile || isMyProfile) && !(isSignup || !isConfirmed),
      }"
    >
      <f7-list-item @click="focusText('last-name')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Last Name </span>
        </f7-row>

        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 100%;
            height: 1.5em;
            border-radius: 4px;
            margin-bottom: 18.8px;
          "
        ></div>
        <div v-else>
          <f7-input
            @keyup="inputvalidator('last-name')"
            @change="inputvalidator('last-name')"
            v-bind:value="dependentLastName"
            v-on:input="dependentLastName = $event.target.value"
            class="d-last-name"
            ref="d-last-name"
            type="text"
            placeholder="e.g. Lee"
            clear-button
            error-message="Only letters please!"
            required
            validate
            pattern="[a-zA-Z]*"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <!-- START  ONLY FOR MY PROFILE -->
    <div v-if="isMyProfile && !isSignup">
      <!-- EMAIL -->

      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        :class="{
          'invalid-Box-Input': invalidEmail,
          'valid-Box-Input': !invalidEmail,
          disabled: existingProfile,
        }"
      >
        <f7-list-item @click="focusText('e-mail')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Email </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <div v-else>
            <f7-input
              @keyup="inputvalidator('e-mail')"
              @change="inputvalidator('e-mail')"
              v-bind:value="email"
              v-on:input="email = $event.target.value"
              class="e-mail"
              ref="e-mail"
              type="text"
              placeholder="e.g. name@mail.com"
              clear-button
              error-message="Enter a valid email please!"
              required
              validate
              pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]*"
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- MOBILE NUMBER -->

      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        :class="{
          'invalid-Box-Input': invalidPhoneNumber,
          'valid-Box-Input': !invalidPhoneNumber,
          disabled: existingProfile,
        }"
      >
        <f7-list-item @click="focusText('phone-number')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Mobile number </span>
          </f7-row>

          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <div v-else>
            <f7-input
              @keyup="inputvalidator('phone-number')"
              @change="inputvalidator('phone-number')"
              v-bind:value="phoneNumber"
              v-on:input="phoneNumber = $event.target.value"
              class="phone-number"
              ref="phone-number"
              type="text"
              placeholder="e.g. XXXXXX"
              clear-button
              error-message="Please enter a valid phone number!"
              required
              validate
              pattern="[0-9]*"
            ></f7-input>
          </div>
        </f7-list-item>
      </f7-list>

      <!-- MARITAL STATUS -->
      <f7-list media-list inset class="insurance-details-tab">
        <f7-list-item @click="focusText('gender-selector')" class="">
          <f7-row class="need-analysis-saving-habit-insets">
            <span> Marital Status </span>
          </f7-row>
          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>

          <f7-row
            v-else
            class="btns-block"
            style="padding-bottom: 10px; padding-top: 10px"
          >
            <f7-button
              id="singleBtn"
              @click="toggleMaritalStatus($event)"
              style="margin-right: 5px"
              :class="[
                isSingle
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              SINGLE</f7-button
            >

            <f7-button
              id="marriedBtn"
              style="margin-left: 5px; margin-right: 5px"
              @click="toggleMaritalStatus($event)"
              :class="[
                isMarried
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              MARRIED</f7-button
            >
            <f7-button
              id="widowedBtn"
              style="margin-left: 5px; margin-right: 5px"
              @click="toggleMaritalStatus($event)"
              :class="[
                isWidowed
                  ? 'need-analysis-retirement-fill-btn'
                  : 'need-analysis-retirement-no-fill-btn',
              ]"
            >
              WIDOWED</f7-button
            >
          </f7-row>
        </f7-list-item>
      </f7-list>
    </div>
    <!-- END    ONLY FOR MY PROFILE -->

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="{
        disabled: isGenderFixed,
      }"
    >
      <f7-list-item @click="focusText('gender-selector')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Gender </span>
        </f7-row>
        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 100%;
            height: 1.5em;
            border-radius: 4px;
            margin-bottom: 18.8px;
          "
        ></div>

        <f7-row
          v-else
          class="btns-block"
          style="padding-bottom: 10px; padding-top: 10px"
        >
          <f7-button
            id="maleBtn"
            style="margin-left: 5px; margin-right: 5px"
            @click="toggleGender($event)"
            :class="[
              isMale
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            MALE</f7-button
          >
          <f7-button
            id="femaleBtn"
            style="margin-left: 5px; margin-right: 5px"
            @click="toggleGender($event)"
            :class="[
              !isMale
                ? 'need-analysis-retirement-fill-btn'
                : 'need-analysis-retirement-no-fill-btn',
            ]"
          >
            FEMALE</f7-button
          >
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidDependentBDay ? 'invalid-Box-Input' : 'valid-Box-Input'"
    >
      <f7-list-item @click="focusText('d-bday')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span> Birth Year </span>
        </f7-row>

        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 100%;
            height: 1.5em;
            border-radius: 4px;
            margin-bottom: 18.8px;
          "
        ></div>
        <div v-else>
          <f7-input
            @keyup="inputvalidator('d-bday')"
            @change="inputvalidator('d-bday')"
            v-bind:value="dependentBDay"
            v-on:input="dependentBDay = $event.target.value"
            class="d-bday"
            ref="d-bday"
            type="text"
            placeholder="1990"
            clear-button
            error-message="Please enter a valid year!"
            required
            validate
            pattern="[0-9]*"
          ></f7-input>
        </div>
      </f7-list-item>
    </f7-list>

    <div v-if="!isMyProfile">
      <f7-list
        media-list
        inset
        class="insurance-details-tab"
        style="border: 1px solid #e1e7eb"
        :class="invalidAnnualExpenses ? 'invalid-Box-Input' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('annual-expenses')" class="">
          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>
          <f7-row v-else class="add-insured-insets-modal">
            <f7-toggle
              color="green"
              style="
                --f7-toggle-handle-color: #fff;
                --f7-toggle-inactive-color: #e5e5ea;
                --f7-toggle-width: 51px;
                --f7-toggle-height: 31px;
                --f7-theme-color: #11bba2;
              "
              :checked="isDependent ?? false"
              @click="dependentActivation($event.target.checked)"
            ></f7-toggle>
            <span class="disabled-dependent-text" style="margin-left: 9px"
              >Dependent</span
            >
          </f7-row>
        </f7-list-item>
      </f7-list>

      <f7-list
        v-if="isDependent"
        media-list
        inset
        class="insurance-details-tab"
        style="border: 1px solid #e1e7eb"
        :class="invalidAnnualExpenses ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
      >
        <f7-list-item @click="focusText('annual-expenses')" class="">
          <f7-row class="need-analysis-saving-habit-insets-modal">
            <span style="display: flex">
              Annual Expenses
              <img
                @click="aExpenses"
                class="tool-tip-info"
                src="@/assets/customIcons/custom-info-circle.svg"
                alt=""
              />
            </span>
          </f7-row>
          <div
            v-if="loading"
            class="skeleton-block"
            style="
              width: 100%;
              height: 1.5em;
              border-radius: 4px;
              margin-bottom: 18.8px;
            "
          ></div>

          <f7-row v-else no-gap>
            <input
              v-model="annualExpenses"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('annual-expenses')"
              @change="inputvalidator('annual-expenses')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >

            <!-- <f7-col class="box-dolar-sign" width="50" align="right"> S$</f7-col>
            <f7-col width="50" align="left" style="padding-left: 10px">
              <f7-input
                v-bind:value="annualExpenses"
                v-on:input="annualExpenses = $event.target.value"
                @keyup="inputvalidator('annual-expenses')"
                @change="inputvalidator('annual-expenses')"
                class="anual-expenses"
                ref="anual-expenses"
                type="text"
                placeholder="1000"
                clear-button
                error-message="Only numbers please!"
                required
                validate
                pattern="[0-9]*"
              ></f7-input>
            </f7-col> -->
          </f7-row>
        </f7-list-item>
      </f7-list>
    </div>

    <f7-list
      media-list
      inset
      class="insurance-details-tab"
      :class="invalidMonthIncomeInput ? 'invalid-Box-Input input-invalid' : 'valid-Box-Input'"
    >
      <f7-list-item @click="focusText('month-income')" class="">
        <f7-row class="need-analysis-saving-habit-insets">
          <span style="display: flex">
            Annual Income
            <img
              @click="aIncome()"
              class="tool-tip-info"
              src="@/assets/customIcons/custom-info-circle.svg"
              alt=""
            />
          </span>
        </f7-row>
        <div
          v-if="loading"
          class="skeleton-block"
          style="
            width: 100%;
            height: 1.5em;
            border-radius: 4px;
            margin-bottom: 18.8px;
          "
        ></div>
        <f7-row v-else no-gap>
          <input
              v-model="monthIncomeInput"
              v-inputmask
              :data-inputmask="maskOpt"
              @keyup="inputvalidator('month-income')"
              @change="inputvalidator('month-income')"
              class="saving-input"
              placeholder="S$ 1,000"
            />
            <span class="input-error-message"
              >Please, verify the input value!</span
            >
          <!-- <f7-col class="box-dolar-sign" width="50" align="right"> S$</f7-col>
          <f7-col width="50" align="left" style="padding-left: 10px">
            <f7-input
              v-bind:value="monthIncomeInput"
              v-on:input="monthIncomeInput = $event.target.value"
              @keyup="inputvalidator('month-income')"
              @change="inputvalidator('month-income')"
              class="month-income-input"
              ref="monthIncome"
              type="text"
              placeholder="1000"
              clear-button
              error-message="Only numbers please!"
              required
              validate
              pattern="[0-9]*"
            ></f7-input>
          </f7-col> -->
        </f7-row>
      </f7-list-item>
    </f7-list>

    <f7-block align="center" class="policy-information-modal">
      <f7-button
        @click="saveDependent"
        :disabled="!validForm"
        :class="[
          validForm ? 'need-analysis-btn-gray' : 'need-analysis-btn-gray',
        ]"
      >
        <div class="add-policy-btn-red-text">
          <!-- TODO: Pending Validation -->
          {{ existingProfile ? "Save" : "Save" }}
        </div></f7-button
      >
    </f7-block>
    <AnnualIncomeToolTip />
    <AnnualExpensesToolTip />
  </f7-block>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
} from "framework7-vue";

import { API, Auth } from "aws-amplify";
import AnnualExpensesToolTip from "@/components/modals/annual-expenses-tool-tip.vue";
import AnnualIncomeToolTip from "@/components/modals/annual-income-tooltip.vue";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
    AnnualIncomeToolTip,
    AnnualExpensesToolTip,
  },
  props: {
    defaultRelationship: null,
    insuredId: null,
    showAvatar: false,
    isSignup: false,
    username: null,
    signinPhoneNumber: null,
  },
  data() {
    return {
      loading: true,

      // Input Fields
      dependentRelationship: "",
      selectedDependentId: "",
      dependentFirstName: "",
      dependentLastName: "",
      dependentBDay: "",
      monthIncomeInput: "",
      annualExpenses: "",
      isMale: true,

      // my profile only
      email: "",
      phoneNumber: "",
      maritalStatus: "",
      isSingle: false,
      isMarried: false,
      isWidowed: false,

      // variables to Add color to the boxes
      invalidDependentFirstName: false,
      invalidDependentLastName: false,
      invalidDependentBDay: false,
      invalidMonthIncomeInput: false,
      invalidAnnualExpenses: false,

      // my profile only
      invalidEmail: false,
      // invalidPhoneNumber: false,

      //Full Form Variable
      dependentRelationshipId: "",
      validForm: false,

      existingProfile: false,
      isGenderFixed: false,
      insuredAvatar: null, // "@/assets/family_member1.svg",
      isDependent: false,

      isMyProfile: false,
      isConfirmed: false,
      isProfileSaved: false,
      maskOpt:
        "'alias': 'numeric', 'groupSeparator': ',', 'digits': 0, 'digitsOptional': false, 'prefix': 'S$ ', 'placeholder': '0'",
    };
  },
  methods: {
    dependentActivation(value) {
      if (typeof value !== "undefined") {
        this.isDependent = value;
      }
    },
    toggleMaritalStatus(event) {
      this.toggleMaritalStatusSelect(event.currentTarget.id);
      this.inputvalidator();
    },
    aIncome() {
      f7.sheet.open(".a-expenses-tt");
    },
    aExpenses() {
      f7.sheet.open(".a-income-tt");
    },

    toggleMaritalStatusSelect(id) {
      //   console.log(id);
      this.isSingle = false;
      this.isMarried = false;
      this.isWidowed = false;
      this.maritalStatus = "";
      switch (id) {
        case "singleBtn":
          this.isSingle = true;
          this.isMarried = false;
          this.isWidowed = false;
          this.maritalStatus = "single";
          break;

        case "marriedBtn":
          this.isSingle = false;
          this.isMarried = true;
          this.isWidowed = false;
          this.maritalStatus = "married";
          break;
        case "widowedBtn":
          this.isSingle = false;
          this.isMarried = false;
          this.isWidowed = true;
          this.maritalStatus = "widowed";
          break;
        default:
          this.isSingle = false;
          this.isMarried = false;
          this.isWidowed = false;
      }
    },

    toggleGender(event) {
      // console.log("toggleGender", event.currentTarget.id);
      if (event.currentTarget.id == "maleBtn") {
        this.isMale = true;
      } else {
        this.isMale = false;
      }

      this.insuredAvatar = this.$filters.getInsuredAvatar(
        this.dependentRelationshipId,
        this.isMale ? "male" : "female"
      );
      this.$emit(
        "frmPostUpdated",
        this.dependentRelationship,
        this.insuredAvatar
      );
      this.inputvalidator();
    },
    showDependantDetails(selectedInsured, defaultRelationship) {
      // console.log("selectedInsured ->", selectedInsured);
      // console.log("defaultRelationship -> ", defaultRelationship);
      if (selectedInsured) {
        this.selectedDependentId = selectedInsured.id;
        this.dependentFirstName = selectedInsured.firstName;
        this.dependentLastName = selectedInsured.lastName;
        this.dependentBDay = selectedInsured.yearOfBirth ?? "";
        this.isMale = (selectedInsured.gender ?? "male") == "male";
        this.monthIncomeInput = selectedInsured.annualIncome ?? "";
        this.annualExpenses = selectedInsured.annualExpense ?? "";
        this.existingProfile = true;

        this.toggleMaritalStatusSelect(
          (selectedInsured.maritalStatus ?? "") + "Btn"
        );
        this.insuredAvatar = this.$filters.getInsuredAvatar(
          selectedInsured.relationship,
          selectedInsured.gender
        );
        this.dependentRelationship = this.$filters.getRelationshipLabel(
          selectedInsured ? selectedInsured.relationship : ""
        ).label;
        this.email = selectedInsured.email ?? "";
        this.phoneNumber = selectedInsured.phoneNumber ?? "";
        this.isDependent = selectedInsured.dependent;
        this.dependentRelationshipId = selectedInsured.relationship;
      } else {
        this.dependentFirstName = "";
        this.dependentLastName = "";
        this.dependentBDay = "";
        this.isMale = true;
        this.monthIncomeInput = "";
        this.annualExpenses = "";
        this.existingProfile = false;
        this.toggleMaritalStatusSelect("");
        let parentsGender = null;
        switch (defaultRelationship) {
          case "mother":
            this.isMale = false;
            parentsGender = "female";
            // this.isGenderFixed = true;
            break;
          case "father":
            this.isMale = true;
            parentsGender = "male";
            // this.isGenderFixed = true;
            break;
          case "spouse":
            this.isMale = !((localStorage.Ownergender ?? "male") == "male");

            break;
          default:
            break;
        }

        this.insuredAvatar = this.$filters.getInsuredAvatar(
          defaultRelationship,
          this.isMale ? "male" : "female" //parentsGender
        );
        this.dependentRelationship =
          this.$filters.getRelationshipLabel(defaultRelationship).label;
        // this.email = "";
        this.phoneNumber = "";
        this.isDependent = false;
        this.dependentRelationshipId = defaultRelationship;
      }
      // console.log("this.dependentRelationship -> ", this.dependentRelationship);
      this.isGenderFixed = false;

      this.$emit(
        "frmPostUpdated",
        this.dependentRelationship,
        this.insuredAvatar
      );
      this.inputvalidator();
    },
    async saveDependent() {
      f7.dialog.preloader("Processing...");

      if (this.existingProfile) {
        var profile = await this.getInsuredProfile(this.insuredId);

        if (profile) {
          profile.firstName = this.dependentFirstName;
          profile.lastName = this.dependentLastName;
          profile.yearOfBirth = Number(this.dependentBDay);
          profile.gender = this.isMale ? "male" : "female";

          profile.annualIncome = Number(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, ""));
          profile.annualExpense = Number(this.annualExpenses.toString().replace(/,|[S$ ]/g, ""));
          if (!this.isMyProfile) {
            profile.dependent = this.isDependent;
          } else {
            profile.dependent = false;
          }

          profile.email = this.email ?? "";
          profile.phoneNumber = this.phoneNumber ?? "";
          profile.maritalStatus = this.maritalStatus ?? "";
          profile.confirmed = true;
          //   profile.relationship = '';

          await this.updateProfile(this.insuredId, profile);
        }
      } else {
        var profile = {};
        profile.firstName = this.dependentFirstName;
        profile.lastName = this.dependentLastName;
        profile.yearOfBirth = Number(this.dependentBDay);
        profile.gender = this.isMale ? "male" : "female";

        profile.annualIncome = Number(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, ""));
        profile.annualExpense = Number(this.annualExpenses.toString().replace(/,|[S$ ]/g, ""));
        if (!this.isMyProfile) {
          profile.dependent = this.isDependent;
        } else {
          profile.dependent = false;
        }

        profile.email = this.username ?? "";
        profile.phoneNumber = this.phoneNumber ?? "";
        profile.maritalStatus = this.maritalStatus ?? "";
        profile.relationship = this.defaultRelationship;
        profile.confirmed = true;
        // console.log(profile);
        profile = await API.post("insureds", "/insureds", {
          body: profile,
        })
          .then((result) => {
            this.isProfileSaved = true;
            // console.log("insured created -> ", result);
            return result;
          })
          .catch((err) => {
            this.isProfileSaved = false;
            console.log("insured created error -> ", err);
          });
      }

      f7.dialog.close();
      this.$emit("profileSaved", this.isProfileSaved);
    },

    async getInsuredProfile(insuredId) {
      let path = "/insureds/" + insuredId;

      var profile = await API.get("insureds", path, {})
        .then((result) => {
          // console.log(result);
          this.isProfileSaved = true;
          return result.data;
        })
        .catch((err) => {
          this.isProfileSaved = false;
          console.log(err);
          return null;
        });

      return profile;
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      // console.log('profile -> ', profile);
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },

    inputvalidator(selectedBox) {
      var regExp = /[0-9]+/g;
      var regExpString = /^([a-zA-Z]+[\s]?)*$/g;
      var regExpEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      switch (selectedBox) {
        case "first-name":
          var test = regExpString.test(this.dependentFirstName);
          if (!test) {
            this.invalidDependentFirstName = true;
          } else {
            this.invalidDependentFirstName = false;
          }
          break;
        case "last-name":
          var test = regExpString.test(this.dependentLastName);
          if (!test) {
            this.invalidDependentLastName = true;
          } else {
            this.invalidDependentLastName = false;
          }
          break;
        case "d-bday":
          var currentYear = new Date().getFullYear();
          var test = regExp.test(this.dependentBDay);
          if (!test) {
            this.invalidDependentBDay = true;
          } else {
            if (
              this.dependentBDay > 1900 &&
              this.dependentBDay < currentYear + 2
            )
              this.invalidDependentBDay = false;
            else this.invalidDependentBDay = true;
          }
          break;
        case "e-mail":
          var test = regExpEmail.test(this.email);
          if (!test) {
            this.invalidEmail = true;
          } else {
            this.invalidEmail = false;
          }
          break;
        case "phone-number":
          var test = regExp.test(this.phoneNumber);
          if (!test) {
            this.invalidPhoneNumber = true;
          } else {
            this.invalidPhoneNumber = false;
          }
          break;
        case "month-income":
          let valuePA = this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valuePA) || parseFloat(valuePA) < 0;
          if (!test) {
            this.invalidMonthIncomeInput = true;
          } else {
            this.invalidMonthIncomeInput = false;
          }
          break;
        case "annual-expenses":
          let valueAE = this.annualExpenses.toString().replace(/,|[S$ ]/g, "");
          var test = regExp.test(valueAE) || parseFloat(valueAE) < 0;
          if (!test) {
            this.invalidAnnualExpenses = true;
          } else {
            this.invalidAnnualExpenses = false;
          }
          break;
      }

      if (
        this.dependentFirstName.length > 0 &&
        this.dependentLastName.length > 0 &&
        this.dependentBDay > 0 &&
        parseFloat(this.monthIncomeInput.toString().replace(/,|[S$ ]/g, "")).toFixed(2) >= 0
      ) {
        // console.log("valid form");
        this.validForm = true;
      } else {
        // console.log("invalid form");
        this.validForm = false;
      }
    },
    async loadDataScreen(insuredId, defaultRelationship) {
      this.loading = true;
      var profile = null;
      //   console.log("insuredId ->", typeof insuredId);
      //   console.log("insuredId ->", insuredId);
      if (insuredId) {
        let path = "/insureds";
        profile = await this.getInsuredProfile(insuredId);
      }

      if (profile) {
        // console.log(profile);
        if (!profile.email && profile.relationship == "myself") {
          await Auth.currentAuthenticatedUser().then((authData) => {
            // this.authState = AuthState.SignedIn;
            // this.user = authData;
            // console.log(authData.attributes);
            profile.email = authData.attributes.email;
          });
        }

        this.isMyProfile = profile.relationship == "myself";
        this.existingProfile = true;
        if (profile.confirmed) this.isConfirmed = true;
      } else {
        this.isMyProfile = defaultRelationship == "myself";
        this.existingProfile = false;
      }

      this.showDependantDetails(profile, defaultRelationship);
      this.loading = false;
      return true;
    },
    focusText(tab1) {
      // console.log("option selected --->");
      // console.log(tab1);
      // this.$refs.totalSaving.$el.focus();
    },
  },

  mounted() {
    // console.log("updated");
    this.loadDataScreen(this.insuredId, this.defaultRelationship);
    if (this.isSignup) {
      this.phoneNumber = this.signinPhoneNumber;
    }

    // console.log(this.isSignup, this.phoneNumber, this.signinPhoneNumber);
  },
  created() {
    // console.log("created");
  },
  computed: {},
};
</script>

<style scoped>
.frmAvatar {
  margin-top: 0px;
}
.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

.need-analysis-subtitle {
  font-size: 17px;
  margin-top: 0px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #07182b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loved-ones-block-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;

  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: right;

  color: #929ba2;
}

.add-dependent-regular {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-dependent-red {
  background: #f4f8fb;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ff3333;
  text-transform: none;

  justify-content: initial;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dependent-couter {
  display: inline;
  align-items: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-couter-zero {
  display: inline;
  align-items: center;
  background: #c0cbd2;
  color: white;
  font-weight: bold;
  font-size: 11px;

  border-radius: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 9px;
  padding-right: 9px;
  margin-left: 7px;
}

.dependent-row {
  background-color: white;
  padding-top: 3px;
  /*   border-bottom-width: 13px;
  border-bottom-style: solid;
  border-bottom-color: #f4f8fb; /* f4f8fb */
}

.dependent-row-modal {
  background-color: white;
  padding-top: 14px;
  justify-content: center;
  padding-bottom: 14px;
  margin-top: 0px;
}

.dependent-image {
  padding-top: 3px;
}

.dependent-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;

  padding-top: 4%;
  padding-left: 15px;
  text-transform: capitalize;
}

.dependent-amount {
  font-size: 16px;
  line-height: 22px;

  text-align: left;

  color: #000000;
  padding-top: 4%;
  padding-left: 3px;
}

.modal-title {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}

.dependent-details .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.dependent-details .modal-header > span {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-top: -13px;
  margin-bottom: 0px;
}
</style>
<style>
.disabled .input-clear-button {
  display: none;
}

.disabled {
  opacity: 1 !important;
}

.disabled li.media-item {
  background: #eaeff3;
}
.disabled .need-analysis-saving-habit-insets-modal {
  color: #000000;
}

.disabled-dependent-text {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.2;
}
</style>


