<template>
  <f7-page style="background: white" id="scrollable">
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title"
        >SavvyEndowment 5</f7-nav-title
      >
      <f7-nav-right> </f7-nav-right>
    </f7-navbar>
    <div>
      <div class="plan-header">
        <span class="plan-title">SavvyEndowment 5</span>
        <span class="plan-description"
          >Grow your wealth with this short-term endowment plan and get your
          capital back upon maturity!</span
        >
        <div class="plan-header-values">
          <div>
            <img src="@/assets/purchase-coverage-icon.svg" alt="" />
            <span class="value">Full</span>
            <span class="label">Capital Back</span>
          </div>
          <hr />
          <div>
            <img src="@/assets/purchase-term-icon.svg" alt="" />
            <span class="value">3</span>
            <span class="label">Years</span>
          </div>
          <hr />
          <div>
            <img src="@/assets/purchase-amount-icon.svg" alt="" />
            <span class="value">SGD 5,000</span>
            <span class="label">Minimum Capital</span>
          </div>
        </div>
      </div>
      <f7-list accordion-list style="margin-top: 0px">
        <f7-list-item
          accordion-item
          title="Benefits"
          class="benefits accordion-item-opened disabled"
        >
          <template #media>
            <img src="@/assets/benefits-icon.svg" width="29" height="31" />
          </template>
          <f7-accordion-content class="plan-benefits">
            <span>Returns of up to 2.47% of single premium upon maturity</span>
            <span>Get your capital back at the end of policy term</span>
            <span>Death benefit of 101% of your premium</span>
            <span
              >One-time payment with cash or Supplementary Retirement Scheme
              (SRS)</span
            >
            <span>From SGD 5,000 to SGD 100,000</span>
          </f7-accordion-content>
        </f7-list-item>
        <f7-list-item link="#" title="How it works" @click="downloadFile" class="how-works">
          <template #media>
            <img src="@/assets/pdf-icon.svg" width="33" height="31.41" />
          </template>
        </f7-list-item>
        <f7-list-item link="#" title="FAQ" @click="openFAQ" class="faqs-class">
          <template #media>
            <img src="@/assets/FAQ-icon.svg" width="22" height="31.11" />
          </template>
        </f7-list-item>
        <f7-list-item link="#" title="Need help?" @click="openHelp" class="need-help">
          <template #media>
            <div class="assistantAvatar online">
              <img src="@/assets/raw_teleadvisor.png" alt="" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block align="center" class="policy-information-modal">
        <f7-button class="need-analysis-btn-gray" @click="openDisclaimer">
          <div class="add-policy-btn-red-text">Get started</div></f7-button
        >
      </f7-block>
      <GenericModal
        :bgVisible="false"
        modalTitle="Disclaimer"
        btnText="I agree"
        modalClassId="plan-process"
        modalText=""
        @modalBtnClick="modalBtnClick"
      />
    </div>
  </f7-page>
</template>

<script>
import { f7, f7Page, f7Block, f7Link } from "framework7-vue";
import GenericModal from "@/components/modals/generic-modal-purchase.vue";


export default {
  components: {
    f7,
    f7Page,
    f7Block,
    f7Link,
    GenericModal,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    openFAQ() {
      window.open(
        "https://www.dbs.com.sg/ibanking/help/insurance.html#savvy-endowment"
      );
    },
    openHelp() {
      window.open("https://www.dbs.com.sg/personal/TeleAdvisory");
    },
    downloadFile(){
      window.open(
        "static/temp.pdf"
      );
    },
    openDisclaimer() {
      console.log("openDisclaimer");
      // this.$refs.scrollable.$el.scrollTop = 0
      // this.$refs.scrollable.scrollTop = 0;

      var element = document.getElementById("scrollable");
      var top = element.offsetTop;
      window.scrollTo(0, top);
      f7.sheet.open(".plan-process");
    },
    modalBtnClick() {
      f7.sheet.close(".plan-process");
      f7.views.main.router.navigate(
        { name: "PlanCalculate1" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style>
.plan-header {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/purchase-bg.svg");
  padding: 31px 27px;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  background-repeat: no-repeat;
  background-size: cover;
}

.plan-header .plan-title {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.plan-header .plan-description {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 23px;
}

.plan-header > .plan-header-values {
  display: flex;
}

.plan-header > .plan-header-values div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.plan-header > .plan-header-values img {
  width: 24px;
  height: 27px;
}

.plan-header > .plan-header-values .value {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.plan-header > .plan-header-values .label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.plan-benefits {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.plan-benefits span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #07182b;
  margin-top: 10px;
  margin-bottom: 13px;
  display: flex;
}
.plan-benefits span::before {
  content: "";
  margin-right: 10px;
  display: flex;
  width: 25px;
  min-width: 25px;
  height: 25px;
  background-image: url("../../assets/benefits-bullets.svg");
}

.accordion-list .item-content {
  padding-left: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #07182b;
}

.benefits .item-media {
  margin-left: 8px !important;
}
.benefits .item-inner {
  padding-right: 0px !important;
  margin-left: 13px !important;
}

.benefits .item-inner::after,
.how-works .item-inner::after,
.faqs-class .item-inner::after,
.need-help .item-inner::after,
.need-help::after,
.list.accordion-list ul:after {
  display: none;
}

.how-works .item-link {
  border-top: 1px solid rgba(188, 187, 193, 0.5);
}
.benefits .item-link,
.how-works .item-link,
.faqs-class .item-link {
  border-bottom: 1px solid rgba(188, 187, 193, 0.5);
}

.how-works .item-media {
  margin-left: 8px !important;
}
.how-works .item-inner {
  padding-right: 0px !important;
  margin-left: 10px !important;
}

.faqs-class .item-media {
  margin-left: 10px !important;
}
.faqs-class .item-inner {
  padding-right: 0px !important;
  margin-left: 21px !important;
}
.need-help .item-inner {
  padding-right: 0px !important;
  margin-left: 0px !important;
}
.need-analysis-btn-gray {
  width: 275px;
}
</style>
<style scoped>
</style>