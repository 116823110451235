<template>
  <div
    @click="showDetail"
    class="policy-card"
    :class="{ disabled: policy.isDisabled }"
  >
    <div class="policy-header">
      <div class="">
        <img
          class="product-icon"
          style="
            padding-left: 5px;
            padding-top: 5px;
            padding-right: 5px;
            padding-bottom: 5px;
          "
          :src="policy.insurerLogo"
          :alt="policy.productName"
        />
      </div>
      <div class="product-details" style="padding-right: 15px">
        <span class="product-name">{{ policy.productName }}</span>
      </div>
      <!-- <a class="default-link" href="#"></a> -->
    </div>
    <div class="policy-detail">
      <div>
        <span class="value">{{ policy.yearTerms }}</span>
        <span class="label">Years</span>
      </div>
      <hr />
      <div>
        <span class="value">{{ policy.return }}</span>
        <span class="label">Returns</span>
      </div>
      <hr />
      <div>
        <span class="value">{{ policy.savingAmount }}</span>
        <span class="label">Savings Amount</span>
      </div>
    </div>
  </div>
</template>

<script>
import { f7 } from "framework7-vue";
import { Storage, API } from "aws-amplify";
export default {
  props: {
    policy: {
      type: Object,
    },
  },
  data() {
    return {
      cardLogo: null,
      coverageType: "",
      coverageAmount: 0,
    };
  },
  async mounted() {
    let logoRef = this.policy.insurerLogo ?? "DEFAULT.png";

    const signedURL = await Storage.get("insurers/" + logoRef, {
      level: "public",
    });
  },
  methods: {
    showDetail() {
      f7.views.main.router.navigate(
        { name: "PlanDetails" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style scoped>
/* policy tab */

.policy-card {
  display: flex;
  flex-direction: column;
  padding: 19px 21px 16px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin-bottom: 16px;
}

.policy-card.disabled {
  filter: grayscale(1);
}

.policy-card .policy-header {
  display: flex;
  align-items: center;
  position: relative;
  /* padding-bottom: 16px; */
  /* border-bottom: 1px solid #e1e7eb; */
}

.policy-card.disabled .product-details::after {
  display: none;
}

.policy-card .product-details::after {
  content: "chevron_right_ios";
  font-family: "framework7-core-icons";
  color: #c0cbd2;
  display: block;
  width: 8px;
  height: 14px;
  right: 0px;
  position: absolute;
}

.policy-card .policy-header .product-icon {
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e1e7eb;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  margin-right: 17px;
  /*   padding: 11px 9px 9.61px 10px; */
  position: relative;
}

.policy-card .policy-header .product-details {
  display: flex;
  flex-direction: column;
}

.policy-card .policy-header .product-name {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #07182b;
  margin-bottom: 5px;
}

.policy-detail {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.policy-detail > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-detail > hr {
  border: 0;
  border-left: 1px solid #e1e7eb;
}

.policy-detail > div .value {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  color: #5f6162;
}

.policy-detail > div .label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #07182b;
  opacity: 0.5;
}
</style>