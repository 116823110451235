<template>
  <f7-sheet
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.08);
      --f7-sheet-bg-color: #fff;
    "
    :class="modalClass + ' ' + modalClassId"
  >
    <f7-page-content>
      <div class="hangger"></div>
      <f7-block v-for="card in cards" :key="card.label" @click="modalBtnClick" class="card-selector">
        <img :src="card.logo" alt="" width="48" height="48" />
        <span>{{ card.label }}</span>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    modalClassId: {
      type: String,
      default: "",
    },
    cards: null,
  },
  data() {
    return { modalClass: "" };
  },
  mounted() {},
  methods: {
    modalBtnClick() {
      this.$emit("modalBtnClick");
    },
  },
};
</script>

<style>
.card-selector {
  display: flex;
  align-items: center;
}

.card-selector span {
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07182b;
}
.hangger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5px;
}

.hangger::before {
  margin-top: 8px;
  content: '';
  display: flex;
  background: #c4c4c4;
  width: 29px;
  height: 3px;
}
</style>
