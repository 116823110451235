<template>
  <f7-page class="page-suw">
    <div class="page-header">
      <div style="width: 28px"></div>
      <!-- <span>DBS Insurance Family Vault</span> -->
      <span>Insurance Family Vault</span>
      <f7-button @click="goToWelcome()" class="btn-close" icon-ios="f7:xmark">
      </f7-button>
    </div>
    <div class="page-subtitle">
      <span>OTP Verification</span>
      <small>We will send you an OTP on this number to keep your account secure.</small>
    </div>
    <div class="phoneNumberInputWrapper">
      <f7-input
        type="tel"
        placeholder="Enter phone number"
        validate
        clear-button
        v-bind:value="phoneNumber"
        v-on:input="phoneNumber = $event.target.value"
      />
    </div>
    <div class="btn-wapper">
      <f7-button class="btn-suw" @click="signIn"> Generate OTP </f7-button>
    </div>
  </f7-page>
</template>

<script>
import {
  f7Block,
  f7ListItem,
  f7Page,
  f7Input,
  f7Button,
  f7Icon,
  f7,
} from "framework7-vue";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { Auth } from "aws-amplify";

export default {
  components: {
    f7Block,
    f7ListItem,
    f7Page,
    f7Input,
    f7Button,
    f7Icon,
    VueTelInput,
  },
  props: {
    username: null,
    password: null,
  },
  data() {
    return {
      phoneNumber: "",
      loading: false,

      bindProps: {
        mode: "international",
        defaultCountry: "SG",
        placeholder: "Enter a phone number",
        enabledCountryCode: true,
        enabledFlags: true,
        onlyCountries: ["SG"],
        maxLen: 25,
      },
    };
  },
  methods: {
    async signIn() {
      console.log("Start of log in");

      f7.dialog.preloader("Loading...");

      try {
        let newUserRequest = {
          username: this.username.toLowerCase(),
          password: this.password,
          attributes: {
            email: this.username.toLowerCase(), // optional
            phone_number: localStorage.phoneNumberPrefix + this.phoneNumber.replace(/,/g, ""), // optional - E.164 number convention
            // other custom attributes
          },
        };
        const { user } = await Auth.signUp(newUserRequest);

        console.log(user);
        f7.dialog.close();
        f7.views.main.router.navigate(
          { name: "PhoneVerification" },
          {
            props: {
              username: this.username.toLowerCase(),
              password: this.password,
              phoneNumber: localStorage.phoneNumberPrefix + this.phoneNumber.replace(/,/g, ""),
            },
          }
        );
      } catch (error) {
        f7.dialog.close();
        f7.dialog.alert(error.message, () => {
          f7.loginScreen.close();
        });
        console.log("error signing up:", error);
      }

      console.log("Logged in!");
    },

    inputvalidator() {},

    goToWelcome() {
      f7.views.main.router.navigate(
        { name: "SignUp" },
        {
          props: {},
        }
      );
    },
  },
};
</script>

<style>
</style>