<template>
  <div v-if="isActive" class="loader-wrapper">
    <div class="loader">
      <img class="icon" :src="icon" :alt="title" />
      <span class="title">{{ title }}</span>
      <span class="subtitle">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import customicon from "@/assets/customIcons/custom-processing-load.svg";
export default {
  props: {
    title: {
      type: String,
      default: "Processing",
    },
    subtitle: {
      type: String,
      default: "You will be notified once it’s complete.",
    },
    icon: {
      type: String,
      default: customicon,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.loader-wrapper {
  margin-left: 16px;
  margin-right: 16px;
  border-radius: var(--f7-list-inset-border-radius);
  z-index: 999999;
  width: calc(100% - 32px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-around;
}
.loader {
  background: rgba(255, 255, 255, 0.9);
  /* background: rgba(0, 255, 255, 0.9); */
  border-radius: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: inherit;
  align-items: center;
  /* padding: 22px 70px; */
  justify-content: center;
}

.loader > .icon {
  /* margin-top: 80px; */
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: cover;
}

.loader > .title {
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: block;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 194px;
}

.loader > .subtitle {
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: block;
  align-items: center;
  text-align: center;
  color: #000000;
  width: 194px;
}
</style>