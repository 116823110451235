<template>
  <f7-page class="no-navbar" style="background: #606d77">
    <div class="page-content">
      <f7-navbar class="upload-nav" style="" back-link=""> </f7-navbar>

      <f7-block class="policy-summary-title"> Policy Summary </f7-block>

      <f7-block class="policy-summary-description">
        Please take a picture of your policy summary page
      </f7-block>
      <div class="add-policy-image-wrapper">
        <div
          class="add-policy-image-container"
          :style="{ 'background-image': `url(${previewImage})` }"
          @click="selectImage"
        >
          <img
            style="position: absolute; top: -15px; right: -15px"
            src="@/assets/customIcons/frame-right-top.svg"
            alt=""
          />
          <img
            style="position: absolute; bottom: -15px; right: -15px"
            src="@/assets/customIcons/frame-right-bottom.svg"
            alt=""
          />
          <img
            style="position: absolute; top: -15px; left: -15px"
            src="@/assets/customIcons/frame-left-top.svg"
            alt=""
          />
          <img
            style="position: absolute; left: -15px; bottom: -15px"
            src="@/assets/customIcons/frame-left-bottom.svg"
            alt=""
          />
        </div>
      </div>

      <f7-block>
        <f7-button
          @click="uploadFile()"
          style="margin-bottom: 15px; margin-top: 50px"
          class="add-policy-btn-red"
        >
          <img src="@/assets/customIcons/custom-check.svg" alt="" />
          <div class="add-policy-btn-red-text">My Policy is clear</div>
        </f7-button>
      </f7-block>

      <f7-block>
        <f7-button
          @click="$refs.fileInput.click()"
          class="add-policy-btn-white"
        >
          <img src="@/assets/customIcons/custom-reload.svg" alt="" />
          <div class="add-policy-btn-white-text">Take a new picture</div>
        </f7-button>

        <input
          style="display: none"
          type="file"
          accept="image/*"
          capture="camera"
          @input="pickFile"
          ref="fileInput"
        />
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
import { f7Page, f7Navbar, f7Block, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Navbar,

    f7Block,
  },
  data() {
    return {
      screenTitle: "Policy Summary",
      screenDescription: "Please take a picture of your policy summary page",
      previewImage: null,
      extension: null,
      _uploadedPhoto: null,
      _filename: null,
    };
  },

  props: {
    uploadedPhoto: null,
    filename: null,
  },
  mounted() {
    console.log("mounted");
    console.log(this.filename, this.extension);

    this._uploadedPhoto = this.uploadedPhoto;
    this._filename = this.filename;

    let reader = new FileReader();
    reader.onload = (event) => {
      this.previewImage = event.target.result;
    };
    reader.readAsDataURL(this.uploadedPhoto);
  },
  methods: {
    uploadFile() {
      f7.views.main.router.navigate(
        {
          name: "UploadPolicyDoc",
        },
        {
          props: {
            uploadedPhoto: this._uploadedPhoto,
            filename: this._filename,
          },
        }
      );
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files[0];
      this._uploadedPhoto = file;
      this._filename = file.name;
      let reader = new FileReader();
      reader.onload = (event) => {
        this.previewImage = event.target.result;
      };
      reader.readAsDataURL(this._uploadedPhoto);
    },
    onUpload() {
      // const fd = new FormData();
      // fd.append("image", this.selectImage.this.selectImage.name);
    },
  },
};
</script>

<style scoped>
.nav-back-btn {
  color: #9eaab1;
  align-content: center;
  display: flex;
  top: -30px;
}

.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.policy-summary-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  text-align: center;
  color: #ffffff;
}

.policy-summary-description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #ffffff;
  opacity: 0.6;
}

.add-policy-image-wrapper {
  position: relative;
  display: flex;
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  height: calc(60% - var(--f7-page-navbar-offset));
}

.add-policy-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* background: rgba(48, 61, 70, 0.81) */
</style>