<template>
  <f7-sheet
    class="modal-style coverage"
    style="
      height: auto;
      border-radius: 25px 25px 0px 0px;
      --f7-sheet-bg-color: #fff;
    "
    swipe-to-close
    backdrop
    :class="modalClass + ' ' + modalClassId"
  >
    <f7-page-content>
      <!-- <f7-button sheet-close>
        <f7-icon class="close-modal-no-title" f7="xmark"></f7-icon>
      </f7-button> -->

      <div class="policy-information-modal-header">
        <f7-button icon-ios="f7:xmark" sheet-close> </f7-button>
        <span class="modal-title">{{ modalTitle }}</span>
      </div>

      <f7-block class="policy-information-modal">
        <div class="modal-description">
          By proceeding, you acknowledge that you have read the
          <span @click="openExternal">Important Information</span> and agree to DBS collecting, using
          and disclosing your personal data to Manulife for the purpose of
          following up on your application.
          <br />
          <br />
          As per Section 25(5) of the Insurance Act (Cap. 142), please disclose
          the facts you know or ought to know. Otherwise, the policy may be
          void.
        </div>

        <f7-button
          @click="modalBtnClick"
          class="need-analysis-btn-gray"
          style="margin-top: 47px"
        >
          <div class="add-policy-btn-red-text">{{ btnText }}</div>
        </f7-button>
      </f7-block>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { f7Page, f7Block, f7Button, f7Icon, f7 } from "framework7-vue";

export default {
  components: {
    f7Page,
    f7Block,
    f7Button,
    f7Icon,
    f7,
  },
  props: {
    isCoverageStyle: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    modalText: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "Ok",
    },
    modalClassId: {
      type: String,
      default: "",
    },
    bgVisible: false,
  },
  data() {
    return { modalClass: "" };
  },
  mounted() {},
  methods: {
    modalBtnClick() {
      this.$emit("modalBtnClick");
    },
    openExternal(){
      window.open(
        "//www.dbs.com.sg/ibanking/help/terms/importantinformation-savvyendowment.html"
      );
    }
  },
};
</script>

<style>
.policy-information-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-information-modal-header span {
  padding: 30px 0px 0px 25px;
  width: 75%;
}

.modal-style {
  align-content: center;
}

.close-modal-no-title {
  color: #9eaab1;
  position: fixed;
  /* left: 15px; */
  /* top: 15px; */
}

.modal-style.coverage .sheet-close {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0px 0px 25px;
  height: auto;
  position: absolute;
  left: 0;
  color: #9eaab1;
}

.modal-style.coverage .close-modal-no-title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  left: unset;
  top: unset;
}

.policy-information-modal {
  /* TEXT */
  color: #07182b;
  font-size: 17px;
  text-align: center;
  padding: 6px 16px;

  text-transform: none;
  line-height: 26px;

  /*   MODAL STYLING */
  border-radius: 30px;
}
.policy-information-modal.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.policy-information-modal.delete img {
  width: 45%;
  height: auto;
}

.policy-information-modal.delete span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #07182b;
  margin-bottom: 25px;
}

.btn-red {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
  padding: 10px 120px 10px 120px !important;
  width: 262px !important;
  height: 43px !important;
  background: #ff3333 !important;
  border-radius: 34px !important;
  box-shadow: none !important;
}
.btn-red-text {
  position: static;
  text-transform: none !important;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.04em;
  flex: none;
  order: 1;
  flex-grow: 0;
}

</style>
<style scoped>
.modal-description {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #07182b;
  opacity: 0.5;
}

.modal-description span{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #07182b;
  text-decoration: underline;
}
</style>