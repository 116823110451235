<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow" back-link="">
      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>

      <f7-nav-right v-if="!isMyProfile">
        <f7-button style="padding-right: 10px" class="add-policy-navbar-btn">
          <img src="@/assets/customIcons/custom-red-trash-can.svg" alt="" />
        </f7-button>
      </f7-nav-right>
    </f7-navbar>
    <ProfileForm :insuredId="myInsuredId" defaultRelationship="myself" @profileSaved="profileSaved" />
  </f7-page>
</template>

<script>
import { f7 } from "framework7-vue";
import ProfileForm from "@/components/forms/profile-form.vue";
export default {
  components: {
    ProfileForm,
  },
  data() {
    return {
      myInsuredId: null,
      isMyProfile: true,
      navbarTitle: "My Profile",
    };
  },
  created() {
    if (localStorage.ownerInsuredId) {
      this.myInsuredId = localStorage.ownerInsuredId;
    }
  },
  methods: {
    profileSaved(isProfileSaved) {
      f7.views.main.router.navigate({ name: "settings" });
    },
  },
};
</script>

<style>
</style>