<template>
  <f7-page name="settings">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goHome()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>Settings</f7-nav-title>
      <f7-nav-right>
        <f7-button class="custom-logout" @click="signOut">Log out</f7-button>
      </f7-nav-right>
    </f7-navbar>

    <f7-page class="">
      <f7-block class="add-policy-title">
        {{ screenTitle }}
      </f7-block>

      <f7-block class="add-policy-description">
        {{ screenDescription }}
      </f7-block>

      <div class="">
        <!-- FIRST ROW -->
        <f7-row no-gap class="setting-row">
          <f7-button class="setting-btn" @click="goToMySavings">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-money.svg" alt="" />
            </div>
            <div style="setting-text">My Savings</div>
          </f7-button>

          <f7-button class="setting-btn" @click="goToMyRetirement">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-chair.svg" alt="" />
            </div>
            <div class="setting-text">My Retirement</div>
          </f7-button>
        </f7-row>
        <!-- SECOND ROW -->
        <f7-row no-gap class="setting-row">
          <f7-button
            class="setting-btn"
            @click="goToScreen('SettingsLovedOnes')"
          >
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-loved.svg" alt="" />
            </div>
            <div class="setting-text">My Loved Ones</div>
          </f7-button>

          <f7-button class="setting-btn" @click="goToMyHealth">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-heart.svg" alt="" />
            </div>
            <div class="setting-text">My Health</div>
          </f7-button>
        </f7-row>
        <!-- THIRD ROW -->
        <f7-row no-gap class="setting-row">
          <f7-button class="setting-btn" @click="goToMyProfile">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-single.svg" alt="" />
            </div>
            <div class="setting-text">My Profile</div>
          </f7-button>
          <f7-button @click="goToSharing" class="setting-btn">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-sharing.svg" alt="" />
            </div>
            <div class="setting-text">Sharing</div>
          </f7-button>
        </f7-row>
        <f7-row no-gap class="setting-row">

          <f7-button @click="reset" class="setting-btn-reset">
            <div class="setting-icon">
              <img src="@/assets/settingsIcons/big-reset.svg" alt="" />
            </div>
            <div class="setting-text">Reset Data</div>
          </f7-button>
          <div style="width: 158px;"></div>
        </f7-row>
        
      </div>

      <!-- <f7-button @click="reset">Reset data</f7-button> -->
      <!-- <f7-button @click="resetQuestionnaire">Reset questionnaire</f7-button> -->
      <!-- <f7-button @click="goToSignUp">Welcome to DBS</f7-button> -->
      <!-- <f7-button @click="getProducts">get product</f7-button> -->
    </f7-page>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7Toolbar,
  f7Tabs,
  f7Tab,
  f7Link,
  f7Block,
  f7,
} from "framework7-vue";

import { Auth, API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Navbar,
    f7NavRight,
    f7Toolbar,
    f7Tabs,
    f7Tab,
    f7Link,
    f7Block,
    Auth,
  },

  data() {
    return {
      screenTitle: "Manage your data",
      screenDescription:
        "Keep these information up to date so we can help you personalise your insurance needs.",
    };
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goToScreen(screenName) {
      f7.views.main.router.navigate({ name: screenName });
    },
    goToMyProfile() {
      f7.views.main.router.navigate(
        { name: "settingsProfile" },
        {
          props: {
            isMyProfile: true,
            screenHeader: "My Profile",
          },
        }
      );
    },
    goToMySavings() {
      f7.views.main.router.navigate(
        { name: "SavingHabits" },
        {
          props: {
            settingEdit: true,
            navBarTitle: "My Savings",
          },
        }
      );
    },
    goToMyRetirement() {
      f7.views.main.router.navigate(
        { name: "RetirementNeeds" },
        {
          props: {
            settingEdit: true,
            navBarTitle: "My Retirement",
          },
        }
      );
    },
    goToMyHealth() {
      f7.views.main.router.navigate(
        { name: "HealthStatus" },
        {
          props: {
            settingEdit: true,
            navBarTitle: "My Health",
          },
        }
      );
    },
    goToSharing() {
      f7.views.main.router.navigate(
        { name: "Sharing" },
        {
          props: {
            isPolicyDetail: true,
          },
        }
      );
    },
    signOut: async () => {
      try {
        await Auth.signOut();

        setTimeout(() => {
          f7.views.main.router.navigate(
            { name: "SignUp" },
            {
              props: {},
            }
          );
        }, 1000);
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    async resetQuestionnaire() {
      f7.dialog.preloader("Processing request...");
      await API.put("questionnaires", "/questionnaires", {
        body: null,
      })
        .then((result) => {
          f7.dialog.close();
        })
        .catch((err) => {
          console.log(err);
          f7.dialog.close();
        });

      this.goHome();
    },
    async reset() {
      const self = this;
      f7.dialog.preloader("Processing request...");

      await API.get("policies", "/policies", {})
        .then((result) => {
          let policies = result.data;
          policies.forEach(async (p) => {
            console.log("delete policy -> ", p.id);
            let path = "/policies/" + p.id;
            await API.del("policies", path, {})
              .then((result) => {
                console.log(result);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });

      /////

      await API.put("questionnaires", "/questionnaires", {
        body: null,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });

      /////
      await API.get("insureds", "/insureds", {})
        .then((result) => {
          let indureds = result.data;
          indureds.forEach(async (ins) => {
            if (ins.relationship != "myself") {
              console.log("delete insured -> ", ins.id);
              let path = "/insureds/" + ins.id;
              await API.del("insureds", path, {})
                .then((result) => {
                  console.log(result);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });

      /////
      await API.get("sharingGroups", "/sharing-groups/", {})
        .then((result) => {
          let group = result.data;
          console.log(group);
          group.forEach(async (g) => {
            console.log("delete sharingGroups -> ", g.id);
            let path = "/sharing-groups/" + g.id;
            await API.del("sharingGroups", path, {})
              .then((result) => {
                console.log(result);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      ////

      ////

      ////

      f7.dialog.close();

      setTimeout(() => {
        this.goHome();
      }, 1000);
    },
  },
};
</script>

<style>
</style>