<template>
  <f7-page>
    <f7-navbar class="add-policy-nav-bar-shadow">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          @click="goSettings()"
          style="color: #9eaab1"
        ></f7-link>
      </f7-nav-left>

      <f7-nav-title class="add-policy-navbar-title">{{
        navbarTitle
      }}</f7-nav-title>
    </f7-navbar>
    <div v-if="loading" class="skeleton-effect-wave">
      <div class="loved-one-card" style="margin-top: 30px">
        <div class="head-loved-card">
          <div class="head-loved-icon">
            <div
              class="skeleton-block"
              style="width: 48px; height: 48px; border-radius: 4px"
            ></div>
          </div>

          <div class="head-loved-name">
            <div
              class="skeleton-block"
              style="width: 50%; height: 1.5em; border-radius: 4px"
            ></div>
          </div>

          <div class="head-loved-arrow">
            <img src="@/assets/customIcons/custom-right-arrow.svg" alt="" />
          </div>
        </div>
        <div class="body-loved-card">
          <div class="body-loved-relationship">
            <div
              class="skeleton-block"
              style="width: 100%; height: 1.5em; border-radius: 4px"
            ></div>
          </div>

          <div class="body-loved-dependent"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="insured in insureds"
        :key="insured.id"
        class="loved-one-card"
        style="margin-top: 30px"
      >
        <div
          class="head-loved-card"
          @click="editProfile(insured.id, insured.relationshipLabel)"
        >
          <div class="head-loved-icon">
            <img style="display: inline-block" :src="insured.avatar" alt="" />
          </div>

          <div class="head-loved-name" :class="insured.healthStatus">
            {{ insured.firstName + " " + insured.lastName }}
          </div>

          <div class="head-loved-arrow">
            <img src="@/assets/customIcons/custom-right-arrow.svg" alt="" />
          </div>
        </div>
        <div class="body-loved-card">
          <div class="body-loved-relationship">
            {{ insured.relationshipLabel }}
          </div>

          <div class="body-loved-dependent">
            <span
              class="loved-one-inactive-dependent"
              style="margin-right: 9px"
              :class="[
                insured.dependent ? 'active-dependent' : 'inactive-dependent',
              ]"
              >Dependent</span
            >
            <f7-toggle
              color="green"
              style="
                --f7-toggle-handle-color: #fff;
                --f7-toggle-inactive-color: #e5e5ea;
                --f7-toggle-width: 51px;
                --f7-toggle-height: 31px;
                --f7-theme-color: #11bba2;
              "
              :checked="insured.dependent ?? false"
              @click="toggleDependent(insured.id, $event.target.checked)"
            ></f7-toggle>
          </div>
        </div>
      </div>

      <f7-button @click="addNewMember" class="loved-one-add-card">
        <img src="@/assets/customIcons/custom-plus-regular.svg" alt="" />
        <div style="padding-left: 20px; opacity: 0.5 !important">Add</div>
      </f7-button>
    </div>

     
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7Toolbar,
  f7Button,
  f7Block,
  f7Range,
  f7,
  f7Icon,
  f7ListItem,
} from "framework7-vue";

import { API } from "aws-amplify";

export default {
  components: {
    f7Page,
    f7Range,
    f7Navbar,
    f7Toolbar,
    f7Button,
    f7Icon,
    f7Block,
    f7ListItem,
  },
  props: {},
  data() {
    return {
      navbarTitle: "My Loved Ones",
      insureds: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await API.get("insureds", "/insureds", {})
      .then((result) => {
        // console.log("insureds -> ", result);
        let insuredList = result.data;

        insuredList.forEach((ins) => {
          let conf = this.$filters.getRelationshipLabel(ins.relationship);
          ins.index = conf.index;
        });
        insuredList.sort((a, b) =>
          a.index > b.index ? 1 : b.index > a.index ? -1 : 0
        );

        for (var ins in insuredList) {
          let insured = insuredList[ins];
          if (insured.relationship != "myself") {
            insured.selected = false;
            insured.ThereIsPolicy = false;

            insured.avatar = this.$filters.getInsuredAvatar(
              insured.relationship,
              insured.gender
            );
            insured.relationshipLabel = this.$filters.getRelationshipLabel(
              insured.relationship,
              false
            ).label;

            this.insureds.push(insured);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("mounted");
    this.insureds.forEach(async (ins) => {
      let analysis = await API.get("coverageAnalysis", "/coverage-analysis", {
        queryStringParameters: {
          insuredId: ins.id,
        },
      })
        .then((result) => {
          // console.log('getCoverageAnalysis -> ', result);
          return result;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      ins.analysis = analysis;
      if (typeof ins.analysis !== "undefined") {
        ins.healthStatus = this.$filters.getCoverageClass(
          ins.analysis.portfolioHealth.score
        );
      } else {
        ins.healthStatus = this.$filters.getCoverageClass(0);
      }
    });
    this.loading = false;
  },
  methods: {
    goHome() {
      f7.views.main.router.navigate({ name: "CoverageMain" });
    },
    goSettings() {
      f7.views.main.router.navigate({ name: "settings" });
    },
    editProfile(insuredId, relationship) {
      f7.views.main.router.navigate(
        { name: "settingsMyLovedOnes" },
        {
          props: {
            insuredId: insuredId,
            relationshipLabel: relationship,
          },
        }
      );
      // f7.views.main.router.navigate({ name: screenName });
    },
    addNewMember() {
      f7.views.main.router.navigate(
        { name: "AddPolicy3" },
        {
          props: {
            addMember: true,
          },
        }
      );
    },
    async toggleDependent(insuredId, value) {
      console.log("updation dependent", insuredId, value);
      if (typeof value !== "undefined") {
        f7.dialog.preloader("Loading...");
        let insurerResponse = await API.get(
          "insureds",
          "/insureds/" + insuredId,
          {}
        )
          .then((result) => {
            // console.log('insured -> ', result);
            return result.data;
          })
          .catch((err) => {
            console.log(err);
          });

        insurerResponse.dependent = value;

        await this.updateProfile(insuredId, insurerResponse);
        f7.dialog.close();
      }
    },
    async updateProfile(insuredId, profile) {
      let path = "/insureds/" + insuredId;
      var profileResponse = await API.patch("insureds", path, {
        body: profile,
      })
        .then((result) => {
          // console.log("insured updated -> ", result);
          return result;
        })
        .catch((err) => {
          // console.log("insured updated error -> ", err);
        });
      return profileResponse;
    },
    selectedUser(selectedUser, relationship) {
      this.dependentRelationship = relationship;
      this.selectedDependent2 = selectedUser;
      this.listOfDependent.forEach((element) => {
        if (element.id == selectedUser) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      });
    },
  },

  computed: {},
};
</script>

<style scoped>
.active-dependent {
  opacity: 1 !important;
}

.inactive-dependent {
  opacity: 0.5 !important;
}

.add-policy-nav-bar-shadow {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.07);
}

.add-policy-user-columns {
  margin-top: 0px;
}

.toolbar-inner {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
}

/* NEW CSS */

.loved-one-card {
  display: flex;
  flex-direction: column;
  padding: 16px 21px 16px 21px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.loved-one-add-card {
  border-radius: 12px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  border: 1px dashed rgba(0, 0, 0, 0.2);
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-transform: none;

  justify-content: initial;

  height: 68px;
}

.head-loved-card {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e1e7eb;
  height: 60px;
}

.head-loved-icon {
  width: 18%;
}
.head-loved-diamond {
  width: 5%;
}
.head-loved-name {
  width: 72%;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
}

.head-loved-name::before {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  transform: rotate(45deg);
  margin-right: 7px;
  margin-left: 7px;
}

.head-loved-name.alert::before {
  background: #ff3333;
}

.head-loved-name.warn::before {
  background: #fda937;
}

.head-loved-name.pass::before {
  background: #11bba2;
}

.head-loved-arrow {
  width: 5%;
  text-align: right;
}

.body-loved-card {
  display: flex;
  align-items: center;
  height: 60px;
}

.body-loved-card {
  display: flex;
  align-items: center;
}

.body-loved-relationship {
  width: 50%;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  color: #8e8e93;
  text-transform: capitalize;
}

.body-loved-dependent {
  width: 134px;
  text-align: right;
}

.loved-one-inactive-dependent {
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #8e8e93;
}
</style>


